import { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Modal, ModalContainer, Overlay } from "../../../components/modal";
import axiosInstance from '../../../consts/axiosInstance';


const InvoiceTandCModal = ({ close }) => {
  const [terms, setTerms] = useState([]);

  const getTerms = async () => {
    try {
      const response = await axiosInstance.get('selectpurchase_terms');
      setTerms(response.data);
    } catch (error) {
      // console.log(error.message);
    }
  };

  useEffect(() => {
    getTerms();
  }, [])

  return (
    <ModalContainer>
      <Modal>
        <Overlay>
          <Container>
            <H1>Terms and Conditions</H1>
            <div>
              {terms?.map(item => (
                <div key={item.id}>
                  {/* Rendering description as raw HTML */}
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              ))}
            </div>



            <ButtonContainer onClick={() => close(false)}>
              <AcceptBtn>Ok</AcceptBtn>
            </ButtonContainer>


          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default InvoiceTandCModal;

const Container = styled.div`
padding: 3rem 2rem 2rem;
max-width:50rem;
`;

const H1 = styled.div`
font-size:1.2rem;
font-weight: 500;
color:#4669E8;
`;
const ButtonContainer = styled.div`
display: flex;
justify-content: center;
margin-top:1rem;
`;
const BaseButton = styled.button`
        font-family: Poppins;
        text-align: center;
        color:white;
        border:none;
        display:flex;
        justify-content: center;
        border-radius: 5px;
        /* @media only screen and (min-width: 250px) and (max-width: 399px) {
          font-size: 0.8rem;
          padding:1rem;
        }
        @media only screen and (min-width: 250px) and (max-width: 767px) {padding:0.6rem 3rem;}
        @media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0.7rem 4rem;}
        @media only screen and (min-width: 1024px)  {padding:0.8rem 5rem;} */
        `;
const AcceptBtn = styled(BaseButton)`
        ${'' /* background-color: ${(props) => (props.enableBuy ? '#4669E8' : '#BCBCBC')}; */}
        background-color: #4669E8;
        color:white;
        font-size: 0.8rem;
        font-weight:300;
        padding:0.5rem 1rem;
      `;