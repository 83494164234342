// import styled from "styled-components";
// import { colors, fonts } from "../../../consts/themes";




// export const MainDiv = styled.div`
//   margin-top: 100px;
//   width: 100%;
//   height: 100vh;
//   display: flex;
//   flex-direction: row;
//   @media screen and (max-width: 980px) {
//     flex-direction: column;
//   }
// `;
// export const SubDiv = styled.div`
//   /* background-color: antiquewhite; */
//   padding-left: 100px;
//   width: 60%;
//   text-align: left;
//   @media screen and (max-width: 1500px) {
//     padding-left: 50px;
//     width: 70%;
//   }
//   @media screen and (max-width: 1050px) {
//     padding-left: 30px;
//     width: 60%;
//   }
//   // @media screen and (max-width: 800px){
//   //     width: 100%;
//   // }
//   @media (min-width: 700px) and (max-width: 800px) {
//     width: 730px;
//   }
//   @media (min-width: 400px) and (max-width: 500px) {
//     width: 390px;
//   }
//   @media (min-width: 350px) and (max-width: 380px) {
//     width: 330px;
//   }
//   @media (min-width: 300px) and (max-width: 330px) {
//     width: 290px;
//   }
// `;
// export const Heading = styled.div``;
// export const ContentDiv = styled.div`
//   /* background-color: yellow; */
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   h4 {
//     font-size: 12px;
//   }
// `;
// export const FormDiv = styled.div`
//   /* background-color: yellowgreen; */
//   // background-color:red;
//   width: 900px;
//   text-align: left;
//   display: flex;
//   flex-direction: row;
//   @media screen and (max-width: 1050px) {
//     width: 650px;
//     /* display: flex; */
//     /* flex-direction: column; */
//   }
//   @media screen and (max-width: 800px) {
//     width: 100%;
//     // width:60%;
//     display: flex;
//     flex-direction: column;
//   }
// `;
// export const Form = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 50%;
//   @media screen and (max-width: 800px) {
//     width: 100%;
//   }
// `;
// export const Label = styled.div`
//   display: flex;
//   flex-direction: row;
//   width: 35%;
//   align-items: center;
//   p {
//     font-size: 12px;
//     color: ${colors.ash};
//   }
//   label {
//     font-size: 12px;
//   }
//   @media screen and (max-width: 500px) {
//     width: 100%;
//   }
// `;
// export const Input = styled.div`
//   /* background-color: aqua; */
//   display: flex;
//   flex-direction: column;
//   width: 50%;
//   height: 7vh;
//   justify-content: space-around;
//   input {
//     ::-webkit-inner-spin-button,
//     ::-webkit-outer-spin-button {
//       -webkit-appearance: none;
//       margin: 0;
//     }
//     outline: none;
//     height: 35px;
//     border-radius: 8px;
//     border: 0.5px solid ${colors.line};
//   }
//   @media screen and (max-width: 1050px) {
//     width: 100%;
//     input {
//       width: 85%;
//     }
//   }
// `;


// export const Radio = styled.div`
//   display: flex;
//   flex-direction: row;
//   width: 100%;
//   input {
//     accent-color: ${colors.blue};
//   }
//   @media screen and (max-width: 500px) {
//     display: flex;
//     flex-direction: column;
//   }
// `;
// export const RadioInput = styled.div`
//   display: flex;
//   flex-direction: row;
//   p {
//     font-size: 12px;
//   }
// `;
// export const Ptag = styled.div`
//   margin-right: 6px;
//   p {
//     font-size: 12px;
//   }
// `;


// export const SideDiv = styled.div`
//   //  background-color: pink;
//   width: 40%;
//   display: flex;
//   flex-direction: column;
//   /* justify-content: center; */
//   align-items: center;
//   /* text-align: left; */
//   // @media screen and (max-width: 1500px){
//   //      width: 100%;
//   // }
//   @media (min-width: 700px) and (max-width: 800px) {
//     width: 100%;
//   }
//   @media (min-width: 300px) and (max-width: 500px) {
//     width: 100%;
//   }
// `;
// export const BoxsDiv = styled.div`
//   /* background-color: aliceblue; */
//   justify-content: center;
//   align-items: center;
//   width: 90%;
//   height: 100%;
//   margin-bottom: 10px;
//   margin-top: 10px;
// `;
// export const Box1 = styled.div`
//   /* background-color: aquamarine; */
//   justify-content: center;
//   align-items: center;
//   border-radius: 8px;
//   border: 0.5px solid ${colors.ash};
//   width: 90%;
//   height: 57%;
//   margin-top: 25px;
//   margin-top: 10px;
//   text-align: left;
//   p {
//     font-size: 12px;
//     /* font-size: large; */
//     text-align: left;
//     padding-left: 15px;
//     color: ${colors.ashs};
//     /* margin-top: 3px;
//     margin-bottom: 4px; */
//   }
//   @media screen and (max-width: 1500px) {
//     height: 60%;
//   }
//   @media screen and (max-width: 800px) {
//     height: 70%;
//   }
//   @media screen and (max-width: 500px) {
//     width: 100%;
//   }
// `;
// export const PriceDetails = styled.div`
//   width: 90%;
//   /* padding: 15px 10px 35px 0px; */
//   /* background-color: beige; */
//   /* justify-content: center; */
//   @media screen and (max-width: 800px) {
//     height: 40vh;
//   }
// `;
// export const Pdetails = styled.div`
//   /* background-color: blue; */
//   justify-content: center;
//   display: flex;
//   flex-direction: column;
//   font-size: 12px;
//   @media screen and (max-width: 1500px) {
//     height: 40vh;
//   }
//   @media screen and (max-width: 800px) {
//     height: 50vh;
//   }
// `;
// export const Div = styled.div`
//   /* background-color: brown; */
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   p {
//     /* margin-top: 0px; */
//     font-size: 12px;
//     color: ${colors.black};
//   }
//   img {
//     width: 12px;
//     height: 12px;
//   }
//   @media screen and (max-width: 1500px) {
//     margin-top: 0px;
//     margin-bottom: 0px;
//     height: 8vh;
//   }
//   @media screen and (max-width: 800px) {
//     height: 10vh;
//   }
// `;
// export const Total = styled.div`
//   /* background-color: antiquewhite; */

//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   /* border:5px dashed hidden dashed hidden ${colors.ashs}; */
//   margin-left: 15px;
//   font-size: 12px;
//   font-family: ${fonts.fontWeight4};
//   border-style: dashed;
//   border-color: ${colors.ash};
//   border-width: 3px 0px 3px 0px;
//   h4 {
//     /* font-size: large; */
//     color: ${colors.black};
//   }
//   @media screen and (max-width: 1500px) {
//     height: 5vh;
//     h4 {
//       margin-top: 5px;
//     }
//   }
//   @media screen and (max-width: 1500px) {
//     height: 10vh;
//     h4 {
//       margin-top: 10px;
//     }
//   }
// `;
// export const ButtonDiv = styled.div`
//   width: 90%;

//   align-items: center;
//   display: flex;
//   justify-content: center;

//   button {
//     background-color: ${colors.red};

//     border: none;
//     width: 90%;
//     height: 5.8vh;
//     margin-top: -150px;
//     margin-left: 2px;
//     // font-size:16px;
//     border-radius: 8px;
//     color: ${colors.white};
//     font-size: 12px;
//   }
//   @media (min-width: 1000px) and (max-width: 1100px) {
//     button {
//       width: 94%;
//       margin-left: 14px;
//     }
//   }
//   @media (min-width: 700px) and (max-width: 800px) {
//     width: 100%;
//     // width:95%;
//     button {
//       width: 90%;
//       margin-left: 5px;
//       margin-top: -200px;
//     }
//   }
//   @media (max-width: 800px) {
//     width: 90%;
//   }
//   @media screen and (max-width: 800px) {
//     /* margin-top: 0px;
//     margin-bottom: 0px; */
//     height: 10vh;
//   }
// `;
// export const Box2 = styled.div`
//   width: 90%;

//   @media (max-width: 800px) {
//     width: 100%;
//     // width:95%;
//   }
// `;
// export const Head = styled.div`
//   text-align: left;
//   /* overflow: none; */
// /* height: 10rem; */
//   /* display: flex;
// flex-direction: row; */
//   p {
//     font-size: 12px;
//     font-weight: ${fonts.fontWeight5};
//     /* margin-bottom: 5px; */
//     /* margin-top: auto; */
//   }
// `;
// export const Head1 = styled.div`
//   text-align: left;
//   overflow: scroll;
//   height: 20rem;
//   /* display: flex;
// flex-direction: row; */
//   p {
//     font-size: 12px;
//     font-weight: ${fonts.fontWeight5};
//     /* margin-bottom: 5px; */
//     /* margin-top: auto; */
//   }
// `;
// export const BoxDiv2 = styled.div`
//   border: 0.5px solid ${colors.ash};
//   border-radius: 8px;
//   padding: 0 5px;
//   height: 300px;
//   overflow: auto;
// `;
// export const Place = styled.div`
//   /* background-color: aqua; */
//   margin-top: 1px;
//   margin-bottom: 1rem;
//   border-radius: 8px;
//   border: 0.5px solid ${colors.line};
//   padding: 5px 8px;
//   width: auto;
//   height: auto;
//   /* overflow: scroll; */
//   p {
//     font-size: 12px;
//     // font-size: small;
//     font-weight: ${fonts.fontWeight2};
//     margin-top: 1px;
//     margin-bottom: 0px;
//   }
// `;
// export const Box3 = styled.div`
//   width: 90%;
//   margin-bottom: 10px;
//   @media (max-width: 800px) {
//     width: 100%;
//   }
// `;
// export const Button = styled.div`
//   border: 0.5px solid ${colors.blue};
//   border-radius: 8px;
//   margin-top: 10px;
//   height: 10vh;
//   cursor: pointer;
//   display: flex;
//     justify-content: center;
//     align-items: center;
//   /* justify-content: center; */
//   p {
//     /* margin-top: 6%; */
//     text-align: center;
//     align-items: center;
//     font-size: 13px;
//     cursor: pointer;
//   }
// `;
// export const Down = styled.div`
//   display: flex;
//   flex-direction: column;
// `;








// new



import styled from "styled-components";
import Fonts from "../../../consts/Font";
import { colors, fonts } from "../../../consts/themes";




const ContainerDiv = styled.div`
padding-left: 2rem;
padding-right: 2rem; 
/* margin-top:7rem; */
/* height:73vh; */
@media only screen and (min-width:1024px){
display: grid; 
grid-template-columns: repeat(12, minmax(0, 1fr)); 
gap: 2rem;
}
`;



const MainSide = styled.div`
grid-column: span 8 / span 8; 
`

const LeftSide = styled.div`
span{
    font-size:18px;
    color:${colors.black};
    font-weight:${fonts.fontWeight4};   
}
`

const RightSide = styled.div`
height:100vh;
@media (min-width:1000px){
box-shadow: -8px 2px 5px -1px rgba(230,230,230,0.77);
-webkit-box-shadow: -8px 2px 5px -1px rgba(230,230,230,0.77);
-moz-box-shadow: -8px 2px 5px -1px rgba(230,230,230,0.77);
display:flex;
::-webkit-scrollbar {
    display: none;
}}
@media (max-width:1000px){
    width:100%;
    margin-left:-20px;
    margin-top:20px;
}
@media (max-width:800px){
    display:none;
}
    
`
const BillingDiv = styled.div`
/* width:30%; */
display:flex;
flex-direction:column;
border:1px solid ${colors.lightblue};
/* padding-inline:10px; */
/* padding-bottom:8px; */
margin-top:20px;
border-radius:5px;
p{
    font-size:12px;
    /* color:${colors.black}; */
    font-weight:${fonts.fontWeight5};
}
span{
    font-size:12px;
    color:${colors.black};
    font-weight:${fonts.fontWeight5};
    
}
a{
    font-size:12px;
    color:${colors.black};
    
}
@media (max-width:500px){
    /* width:70%; */
}
`
export const Submit = styled.button`
    width:200px;
    height:40px;
    border-radius:8px;
    border:none;
    margin-right:10px;
    margin-top:2rem;
    cursor:pointer;
`
const ButtonDiv = styled.div`
// width:30%;
display:flex;
flex-direction:row;
margin:10px 0;
button{
    width:200px;
    height:40px;
    border-radius:8px;
    border:none;
    margin-right:10px;
    cursor:pointer;

}
@media (max-width:500px){
    flex-direction:row;
    button{
        width:150px;
    }
    
}
@media (max-width:325px){
    flex-direction:row;
    button{
        width:280px;
        margin-bottom:10px;
    }
    
}
`


const Dist = styled.div`
p{
    font-size:12px;
    font-weight:${Fonts.fontWeight4};
    color:${colors.black};
}`

const DetailsDropDown = styled.div`
width: 34%;
select{
    width: 100%;
    height: 44.5px;
    border: 1px solid #c9c7c7;
    border-radius: 8px;

    ${'' /* select { */}
        ${'' /* width: 100%; */}
        ${'' /* height: 30px;
        border: 0px solid #c9c7c7;
        border-radius: 8px; */}
        color: #878787;
        font-size: 12px;
        padding: 8px;

        appearance: none;
        background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E) !important;
        background-repeat: no-repeat, repeat !important;
        background-position: right 0.7em top 50%, 0 0 !important;
        background-size: 0.65em auto, 100% !important;
    }
    ${'' /* } */}

    @media screen and (min-width: 320px) and (max-width: 425px) {
        width: 86%;
        select {
            color: #878787;
            font-size: 12px;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1880px) {
        width: 86%;
        select {
            color: #878787;
            font-size: 14px;
        }
    }
`
const Right = styled.div`
    margin-top: 8rem;
    
    width:20rem;
`
const Scroll = styled.div`
    height: 21rem;
    overflow-y: scroll;
`

const Cont = styled.div`
max-width:500px;
border:1px solid ${colors.medium_gray};
padding:1rem;
border-radius:8px;
margin-bottom:1rem;

`
const Name = styled.span`
    display:block;
    font-size:14px;
    text-transform:capitalize;
    font-weight:500;
    `
const Type = styled.span`
    display:block;
    font-size:12px;
    text-transform:capitalize;
    `
const Text = styled.span`
display:block;
font-size:12px;
text-transform:capitalize;
`
const Rightcontainer = styled.div`
box-shadow: -8px 2px 5px -1px rgba(230,230,230,0.77);
-webkit-box-shadow: -8px 2px 5px -1px rgba(230,230,230,0.77);
-moz-box-shadow: -8px 2px 5px -1px rgba(230,230,230,0.77);
    width:30%;
    display:flex;
    // align-items:center;
    justify-content:center;
    height:100vh;
    `



const Radio = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  input {
    accent-color: ${colors.blue};
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;


const RadioInput = styled.div`
    display: flex;
    gap: 0.5Aarem;
    flex-direction: row;
    p {
      font-size: 12px;
    }
  `;

const Ptag = styled.div`
    margin-right: 6px;
    p {
      font-size: 12px;
    }
  `;



const SubDiv = styled.div`
   /* background-color: antiquewhite; */
   padding-left: 100px;
     width: 60%;
  text-align: left;

   `;

const BoxsDiv = styled.div`
   /* background-color: aliceblue; */
  ${'' /* justify-content: center;
   align-items: center;
   width: 90%;
   height: 100%;
   margin-bottom: 10px;
  margin-top: 10px; */}
 `;


const SideDiv = styled.div` 
grid-column: span 4 / span 4; 
${'' /* grid-column: span 6 / span 6; 
  display: flex;
  flex-direction: column; */}
  /* justify-content: center; */
  ${'' /* align-items: center; */}
  /* text-align: left; */
  ${'' /* background-color:green; */}
`;








export {
    MainSide,
    LeftSide,
    RightSide,
    BillingDiv,
    ButtonDiv,
    Dist,
    DetailsDropDown,
    Cont,
    Right,
    Name,
    Type,
    Text,
    Scroll,
    Rightcontainer,




    Radio,
    RadioInput,
    Ptag,



    SubDiv,
    BoxsDiv,
    SideDiv,
    ContainerDiv,

}





