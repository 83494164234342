import React, { useContext, useEffect, useState } from 'react'
import { ModalContainer, Modal, Overlay } from "../../../components/modal"
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { CloseContainer } from '../ModalStyled';
import { colors, fonts } from '../../../consts/themes';
import Colors from '../../../consts/Colors';
import { AuthContext } from '../../../consts/AuthContext';
import axiosInstance from '../../../consts/axiosInstance';


const AddAddressModal = ({ close, apiCallHandler }) => {



  const [authUser, setAuthUser] = useContext(AuthContext);
  const addname = JSON.parse(localStorage.getItem('addname') || '[]');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedDistrictId, setSelectedDistrictId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mobNumb1, setMobNumb1] = useState('');
  const [mobNumb2, setMobNumb2] = useState('');


  const [status, setStatus] = useState(0)

  function handleClicks(name) {
    setStatus(name);
  }


  const [data, setData] = useState({
    name: '',
    pincode: '',
    house: '',
    district: '',
    state: '',
    street: '',
    type: '',
    mob1: '',
    mob2: '',
    gst: '',
  });



  const handleDataChange = async (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  }




  const handleChange = (e) => {

    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));

    value.length > 0 && value.length <= 6 &&
      fetch(`https://api.postalpincode.in/pincode/${value}`)
        .then((response) => response.json())
        .then((response) => {

          // console.log(" pincode", response)

          if (response[0].Status !== 'Error') {
            setSelectedDistrict(response[0]?.PostOffice[0]?.District);
            setData((prev) => ({
              ...prev,
              district: response[0]?.PostOffice[0]?.District,
              state: response[0]?.PostOffice[0]?.State,
            }));
          }

          else {
            setSelectedDistrict('');
            setData((prev) => ({
              ...prev,
              district: '',
              state: '',
            }))
          }
        });
  };





  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('getDistrict');
        const data = response.data;

        data.map((district) => {
          district.district_name === selectedDistrict &&
            setSelectedDistrictId(district.id);
        });
      } catch (error) {
        console.error("Error while fetching district data:", error);
      }
    };

    fetchData();
  }, [selectedDistrict]);



  const submitHanlder = async () => {
    try {
      setLoading(true);
      const formData = {
        user_id: authUser?.user?.id,
        default: status ? status : 0,
        district: data.district,
        house_name: data.house,
        street_name: data.street,
        pincode: data.pincode,
        state: data.state,
        name: data.name,
        type_name: data.type,
        district_id: selectedDistrictId,
        mob1: data.mob1,
        mob2: data.mob2,
        gst: data.gst,
      };

      const options = {
        method: "POST",
        url: 'insertCustomerAddress',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };


      await axiosInstance.request(options).then((response) => {

        if (response.data.code === 200) {
          apiCallHandler();
        }
      })

    } catch (error) {
      setError(error);
      // console.log("🚀 ~ update cart ", error);

    }

    finally {
      setLoading(false);
      close(false)
    }
  };


  // const isSubmitButtonVisible = Object.values(data).every((value) => value !== '');
  const isSubmitButtonVisible = Object.values(data).slice(0, -2).every(value => value !== '');


  return (


    <ModalContainer>
      <style jsx>{`
      /* Remove arrows for number input */
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield; /* For Firefox */
      }
    `}</style>
      <Modal Modal>
        <Overlay>
          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => close(false)} />
            </CloseContainer>

            <InnerConatiner>
              <Head><label>Add Address</label></Head>
              <Details>
                <InputWrap className='space-y-2'>
                  <Input>
                    <input
                      type='text'
                      placeholder='Name'
                      onChange={(e) => handleDataChange({ target: { name: 'name', value: e.target.value } })}
                    />
                  </Input>

                  <Input>
                    <input
                      type='number'
                      placeholder='Pincode'
                      maxLength={6}
                      onChange={(e) => handleChange({ target: { name: 'pincode', value: e.target.value } })}
                      value={data?.pincode}
                    />
                  </Input>


                  <Input>
                    <input
                      type='text'
                      placeholder='House Name/ Organization Name'
                      onChange={(e) => handleDataChange({ target: { name: 'house', value: e.target.value } })}
                    />
                  </Input>


                  <Input>
                    <input
                      type='text'
                      placeholder='District'
                      value={data?.district}
                      // defaultValue={data?.District}
                      disabled={true}
                    />
                  </Input>


                  <Input>
                    <input
                      type='text'
                      placeholder='State'
                      value={data?.state}
                      // defaultValue={data?.State}
                      disabled={true}
                    />
                  </Input>

                  <Input>
                    <input
                      type='text'
                      placeholder='Street Name'
                      onChange={(e) => handleDataChange({ target: { name: 'street', value: e.target.value } })}
                    />
                  </Input>


                  <Input>
                    <select className='text-[14px] text-[#111111]'
                      onChange={(e) =>
                        handleDataChange({ target: { name: "type", value: e.target.value } })
                      }
                      defaultValue="" // Placeholder option
                    >
                      <option className='text-[14px] text-[#6f7071]' value="" disabled>
                        Select Type
                      </option>
                      <option className='text-[14px] text-[#6f7071]' value="home">Home</option>
                      <option className='text-[14px] text-[#6f7071]' value="work">Work</option>
                      <option className='text-[14px] text-[#6f7071]' value="office">Office</option>
                      <option className='text-[14px] text-[#6f7071]' value="others">Others</option>
                    </select>
                  </Input>
                  <Input>
                    <input
                      type="number"
                      placeholder='Mob Number'
                      value={mobNumb1}

                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setMobNumb1(e.target.value);

                        if (/^\d*$/.test(inputValue) && inputValue.length === 10) {
                          handleDataChange({ target: { name: 'mob1', value: inputValue } });
                        }

                        else if (inputValue.length > 10) {
                          console.error('Invalid input. Please enter a valid number with at most 10 digits.');
                          setMobNumb1('')
                          handleDataChange({ target: { name: 'mob1', value: '' } })
                        }
                      }}
                    />
                  </Input>

                  <Input>
                    <input
                      type="number"
                      placeholder='Secondary Mob Number (optional)'
                      value={mobNumb2}

                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setMobNumb2(e.target.value);
                        if (/^\d*$/.test(inputValue) && inputValue.length === 10) {
                          handleDataChange({ target: { name: 'mob2', value: inputValue } });
                        }
                        else if (inputValue.length > 10) {
                          console.error('Invalid input. Please enter a valid number with at most 10 digits.');
                          setMobNumb2('')
                          handleDataChange({ target: { name: 'mob2', value: '' } })
                        }
                      }}
                    />
                  </Input>

                  <Input>
                    <input
                      type='text'
                      placeholder='Gst (optional)'
                      onChange={(e) => handleDataChange({ target: { name: 'gst', value: e.target.value } })}
                    />
                  </Input>


                  <Radio>
                    <Ptag>
                      <p>Set as default Address.?</p>
                    </Ptag>

                    <RadioInput className='ml-2 flex space-x-4'>
                      <div className='flex space-x-1'>
                        <input
                          type='radio'
                          name='address_status'
                          onClick={(e) => [handleClicks(1)]}
                          value='1'
                          checked={status === 1}
                        />

                        <p>Yes</p>
                      </div>
                      <div className='flex space-x-1'>
                        <input
                          type='radio'
                          color='blue'
                          name='address_status'
                          value='0'
                          onClick={(e) => [handleClicks(0)]}
                          checked={status === 0}
                        />
                        <p>No</p>
                      </div>

                    </RadioInput>
                  </Radio>
                </InputWrap>

                {isSubmitButtonVisible &&
                  <SubmitBtn><button onClick={() => submitHanlder()}>Add Address</button></SubmitBtn>}

              </Details>

            </InnerConatiner>
          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default AddAddressModal;



export const Head1 = styled.div`
font-size:16px;
font-weight:400;
`



const SubmitBtn = styled.div`
display: flex;
justify-content: center;
button{
  max-width:8rem;
  padding:0.5rem 1rem;
  background-color:#4669E8;
  color:white;
  border: none;
  border-radius: 5px;
}
`;


export const Radio = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    input {
      accent-color: ${colors.blue};
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
`;

export const RadioInput = styled.div`

    p {
      font-size: 12px;
    }
`;

export const Ptag = styled.div`
    margin-right: 6px;
    p {
      font-size: 12px;
    }
`;


const Container = styled.div`
display: flex; 
flex-direction:column;
justify-content: center; 
align-items:center;
padding:1.5rem;
width:350px;
height: 100%;
max-width:100%;
@media only screen and (min-width: 350px) and (max-width:450px) {width:280px;}
@media only screen and (min-width: 300px) and (max-width:349px) {width:240px;}
`

const InnerConatiner = styled.div`
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap:0.5rem;
padding:0.5rem;
width: 100%;
`

const Head = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
width:100%;
border-bottom: .5px solid ${colors.line};
padding-bottom: 0.5rem;
label{
  font-size:16px;
  font-weight:${fonts.fontWeight6};
}
`

const Details = styled.div`
width: 100%;
padding:0.5rem;
height: 100%;
@media only screen and (min-width: 300px) and (max-width:767px) {height:15rem;}
overflow-y:scroll;
`

const InputWrap = styled.div`
display: flex;
flex-direction: column;
gap:0.5rem;
`

const Input = styled.div`
input,select{
  box-sizing: border-box;
  width: 100%;
  height: 2rem;
  border: 0.1px solid ${colors.line};
  border-radius: 5px;
  padding:0 0.5rem;
  &:focus {
      border-color: #587ef5;
      outline: 0;
    }
  }
input{
  ::placeholder{
    font-size: 14px;
  }
}
`