import './App.css';
import Feedback from './screens/sale/feedback/feedback';
import AccessoriesDetails from './screens/sale/product/AccessoriesDetails';
import Printerdet from './screens/sale/product/Printerdet';

import Helppage from '../src/components/Modal/Helppage';
import Category from './screens/sale/Category';
import Product from './screens/sale/Product';
import ProductDetails from './screens/sale/product/ProductDetails';
import Subcategory from './screens/sale/Subcategory';
import Subcategorytwo from './screens/sale/Subcategorytwo';
// import OrderHistory from "./screens/services/OrderHistory/OrderHistory";
import FormFile from './screens/sale/product/FormFile';
import TypeofSale from './screens/sale/product/TypeofSale';
// import  Home from '../src/components/Home/Home';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from '../src/components/Home/Home/Home';
import Progress from '../src/components/ProgressBar/ProgressBar/Index';
import Header from './components/Header/Header';
import LogIn from './components/home and user/screens/Login';
import Productcards from './screens/sale/product/productcards';
import UserGuide from './screens/sale/SalesType/UserGuide';
// import Form from "./screens/sale/product/Form";
import React, { useEffect } from 'react';
import OtpVerification from './components/home and user/screens/OtpVerification';
import SignUp from './components/home and user/screens/SignUp';
import AuctionProductDetail from './screens/Auction/AuctionProductDetail';
import Auctions from './screens/Auction/Auctions';
import BuyNow from './screens/Auction/BuyNow';
import BatteryExchange from './screens/exchange/BatteryExchange/BatteryExchange';
import Confirmation from './screens/exchange/Confirmation/Confirmation';
import DesktopExchange from './screens/exchange/DesktopExchange/DesktopExchange';
import Exchange from './screens/exchange/Exchange';
import LaptopExchange from './screens/exchange/LaptopExchange/LaptopExchange';
import MobileExchange from './screens/exchange/MobileExchange/MobileExchange';
import MonitorExchagne from './screens/exchange/MonitorExchange/MonitorExchagne';
import Options from './screens/exchange/Options/Options';
import UpsExchange from './screens/exchange/UpsExchange/UpsExchange';
import Notification from './screens/notification/Notification';
import MyOrders from './screens/Profile/Orders/MyOrders';
import OrderDetails from './screens/Profile/Orders/OrderDetails';
import Profile from './screens/Profile/Profile';
import ProfilePage from './screens/Profile/ProfilePage/ProfilePage';
import Quotation from './screens/Profile/Quotation/Quotation';
import RewardsP from './screens/Profile/Rewards/RewardsP';
import Services from './screens/Profile/Services/Services';
import WishList from './screens/Profile/WishList/WishList';
import QuizEnd from './screens/Quiz/QuizEnd';
import QuizMain from './screens/Quiz/QuizMain';
import QuizQues from './screens/Quiz/QuizQues';
import AccessoriesHome from './screens/sale/Accessories/AccessoriesHome';
import AccessoriesProductList from './screens/sale/Accessories/AccessoriesProductList';
import InstallHome from './screens/sale/Accessories/install/InstallHome';
import PrinterHome from './screens/sale/Accessories/printer/PrinterHome';
import CustomPcComponentsList from './screens/sale/customPc/CustomPcComponentsList';
import CustomPcDetails from './screens/sale/customPc/CustomPcDetails';
import CustomPcHome from './screens/sale/customPc/CustomPcHome';
import CustomPcProcessorList from './screens/sale/customPc/CustomPcProcessorList';
import OrderStatus from './screens/sale/Invoice/OrderStatus';
import SampleInvoice from './screens/sale/Invoice/SampleInvoice';
import BulkPurchaseForm from './screens/sale/product/bulk/BulkPurchaseForm';
import PdfReport from './screens/sale/product/bulk/PdfReport';
import CctvAccHome from './screens/sale/product/cctv/CctvAccHome';
import CctvCategories from './screens/sale/product/cctv/CctvCategories';
import CctvChannel from './screens/sale/product/cctv/CctvChannel';
import CctvCustomization from './screens/sale/product/cctv/CctvCustomization';
import CctvCustomProduct from './screens/sale/product/cctv/CctvCustomProduct';
import CctvProductDetail from './screens/sale/product/cctv/CctvProductDetail';
import CctvSubProducts from './screens/sale/product/cctv/CctvSubProducts';
import PowerSub from './screens/sale/product/PowerSub';
import UpsChart from './screens/sale/product/ups/UpsChart';
import GuideDetails from './screens/sale/SalesType/GuideDetails';
import ShopCart from './screens/sale/ShoppingCart/ShopCart';
import GlobalSearch from './screens/search/GlobalSearch';
import ReServiceReg from './screens/Service/ReServiceReg';
import ServiceReg from './screens/Service/ServiceReg';
import ServiceRegAddress from './screens/Service/ServiceRegAddress';
import ServiceSection from './screens/Service/ServiceSection';
import ServiceTypes from './screens/Service/ServiceTypes';
import Payment from './screens/services/Payment/Payment';
import Footer from './components/Footer/Footer';
import AboutUs from './screens/sale/FooterSections/AboutUs/AboutUs';
import Terms from './screens/sale/FooterSections/Terms/Terms';
import PrivacyPolicy from './screens/sale/FooterSections/PrivacyPolicy/PrivacyPolicy';
import ReturnPolicy from './screens/sale/FooterSections/ReturnPolicy/ReturnPolicy';
import ContactUs from './screens/sale/FooterSections/ContactUs/ContactUs';
import { DataProvider } from './consts/DataContext';
import PaymentDone from './screens/sale/Invoice/PaymentDone';



const PrivateRoute = ({ children }) => {
  const userId = localStorage.getItem("userid");
  const token = localStorage.getItem('token');
  // console.log('token', token)
  return token ? children : <Navigate to="/login" />;
};


function App() {
  // useCheckMobileScreen();

  const Sale = () => {
    return (
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/index' element={<Progress />} />
        <Route path='/signUp' element={<><Header /> <SignUp /><Footer /></>} />
        <Route path='/login' element={<><Header /> <LogIn /><Footer /></>} />
        <Route path='/otp/verification' element={<><Header /><OtpVerification /><Footer /> </>} />
        <Route path='/sales/help' element={<PrivateRoute> <Header /> <Helppage /><Footer /></PrivateRoute>} />
        {/* <Route path='/signup/verification' element={<><Header /> <Verification /> </>} /> */}
        {/* <Route path='/signup' element={<><Header /> <Signup /> </>} /> */}
        <Route path='/search' element={<PrivateRoute><Header /><GlobalSearch /><Footer /></PrivateRoute>} />
        <Route path='/notifications' element={<PrivateRoute><Header /><Notification /><Footer /></PrivateRoute>} />

        <Route path='/typeofsale' element={<PrivateRoute><Header /><TypeofSale /><Footer /></PrivateRoute>} />
        <Route path='/sales/productcard' element={<Productcards />} />
        <Route path='/sales/productcard/users' element={<PrivateRoute><Header /> <Subcategory /><Footer /> </PrivateRoute>} />
        <Route path='/sales/productcard/users/subusers' element={<PrivateRoute> <Header /> <Subcategorytwo /><Footer /></PrivateRoute>} />
        <Route path='/sales/productcard/users/products' element={<PrivateRoute><Header /> <Product /><Footer /> </PrivateRoute>} />
        <Route path='/sales/products/productdetails' element={<PrivateRoute><Header /> <ProductDetails /><Footer /></PrivateRoute>} />

        {/* <Route path='/sales/shoppingcart' element={<PrivateRoute><Header /><ShoppingCart /></PrivateRoute>} /> */}
        <Route path='/sales/shoppingcart' element={<PrivateRoute> <Header /><ShopCart /><Footer /></PrivateRoute>} />
        <Route path='/sales/billing' element={<PrivateRoute><Header /> <FormFile /><Footer /></PrivateRoute>} />
        {/* <Route path='/sales/invoice/:id' element={<PrivateRoute> <Header /> <Invoice /></PrivateRoute>} /> */}
        <Route path='/sales/invoice/:id' element={<PrivateRoute> <Header /><SampleInvoice /><Footer /></PrivateRoute>} />
        <Route path="/payment-done" element={<PrivateRoute><PaymentDone /></PrivateRoute>} />


        {/* cctv customization */}
        <Route path='/cctvcategory' element={<PrivateRoute><Header /> <CctvCategories /><Footer /></PrivateRoute>} />
        <Route path='/cctvchannels' element={<PrivateRoute><Header /> <CctvChannel /><Footer /></PrivateRoute>} />
        <Route path='/cctcustomproduct' element={<PrivateRoute><Header /> <CctvCustomProduct /><Footer /></PrivateRoute>} />
        {/* <Route path='/cctv/main/productdetail/:id' element={<PrivateRoute><Header /><CctvProductDetail /></PrivateRoute>} /> */}


        <Route path='/cctvcustomization' element={<PrivateRoute><Header /><CctvCustomization /><Footer /></PrivateRoute>} />
        <Route path='/cctvsubproduct/:id' element={<PrivateRoute><Header /><CctvSubProducts /><Footer /></PrivateRoute>} />
        <Route path='/cctv/productdetail/:id' element={<PrivateRoute><Header /><CctvProductDetail /><Footer /></PrivateRoute>} />
        {/* cctv accessories */}
        <Route path='/cctvacc/home' element={<PrivateRoute> <Header /><CctvAccHome /><Footer /></PrivateRoute>} />


        {/* ups purchase */}
        <Route path='/ups/accessories/categories' element={<PrivateRoute><Header /><CctvSubProducts /><Footer /></PrivateRoute>} />
        <Route path='/ups/accessories' element={<PrivateRoute><Header /><CctvSubProducts /><Footer /></PrivateRoute>} />
        <Route path='/ups/chart' element={<PrivateRoute><Header /><UpsChart /><Footer /></PrivateRoute>} />


        {/* bulk purchase */}
        <Route path='/category' element={<PrivateRoute><Header /><Category /><Footer /></PrivateRoute>} />
        <Route path='/purchase/form' element={<PrivateRoute> <Header /><BulkPurchaseForm /><Footer /></PrivateRoute>} />
        <Route path="/download-pdf-report/:id" element={<PrivateRoute> <Header /><PdfReport /><Footer /></PrivateRoute>} />

        {/* custom pc build */}
        <Route path='/custompc/home' element={<PrivateRoute><Header /><CustomPcHome /><Footer /></PrivateRoute>} />
        <Route path='/custompc/processor/list' element={<PrivateRoute><Header /><CustomPcProcessorList /><Footer /> </PrivateRoute>} />
        <Route path='/custompc/components/list' element={<PrivateRoute><Header /><CustomPcComponentsList /><Footer /> </PrivateRoute>} />
        <Route path='/custompc/product/details' element={<PrivateRoute><Header /><CustomPcDetails /> <Footer /></PrivateRoute>} />

        <Route path='/feedback' element={<PrivateRoute><Header /> <Feedback /> <Footer /></PrivateRoute>} />
        <Route path='/powerback' element={<PrivateRoute> <Header /> <PowerSub /><Footer /></PrivateRoute>} />

        {/* new accessories section  */}
        <Route path='/accessories/home' element={<PrivateRoute> <Header /><AccessoriesHome /><Footer /></PrivateRoute>} />
        <Route path='/accessories/productlist' element={<PrivateRoute> <Header /><AccessoriesProductList /><Footer /></PrivateRoute>} />
        <Route path='/sales/accessories/productsdetails' element={<PrivateRoute><Header /> <AccessoriesDetails /><Footer /></PrivateRoute>} />
        <Route path='/printer/categories' element={<PrivateRoute> <Header /><PrinterHome /><Footer /></PrivateRoute>} />
        <Route path='/printer' element={<PrivateRoute><Header /> <Printerdet /><Footer /></PrivateRoute>} />
        <Route path='/sales/installation' element={<PrivateRoute> <Header /><InstallHome /><Footer /></PrivateRoute>} />


        <Route path='/aboutus' element={<> <Header /><AboutUs /> <Footer /></>} />
        <Route path='/terms' element={<> <Header /><Terms /><Footer /></>} />
        <Route path='/privacypolicy' element={<> <Header /><PrivacyPolicy /><Footer /></>} />
        <Route path='/returnpolicy' element={<> <Header /><ReturnPolicy /><Footer /></>} />
        <Route path='/contactus' element={<> <Header /><ContactUs /><Footer /></>} />

      </Routes>
    );
  };


  const Service = () => {
    return (
      <Routes>
        <Route path='/payment' element={<PrivateRoute><Header /><Payment /><Footer /></PrivateRoute>} />
        <Route path='/service' element={<PrivateRoute><Header /><ServiceTypes /><Footer /></PrivateRoute>} />
        <Route path='/service/types' element={<PrivateRoute><Header /> <ServiceSection /><Footer /></PrivateRoute>} />
        <Route path='/service/registration' element={<PrivateRoute><Header /><ServiceReg /><Footer /></PrivateRoute>} />
        <Route path='/service/re-registration' element={<PrivateRoute><Header /><ReServiceReg /><Footer /></PrivateRoute>} />
        <Route path='/service/registration/address' element={<PrivateRoute><Header /><ServiceRegAddress /><Footer /></PrivateRoute>} />
      </Routes>
    );
  };

  const Homes = () => {
    return (
      <Routes>


        {/* quiz  start*/}
        <Route path="/quiz" element={<><Header /><QuizMain /><Footer /></>} />
        <Route path="/quiz/questions/:end" element={<><Header /><QuizQues /><Footer /></>} />
        <Route path="/quiz/end/:id" element={<><Header /><QuizEnd /><Footer /></>} />
        {/* quiz end*/}



        {/* bid  start*/}
        <Route path='/auction' element={<PrivateRoute><Header /><Auctions /><Footer /> </PrivateRoute>} />
        <Route path='/auction/bonusproduct/details' element={<PrivateRoute><Header /><AuctionProductDetail itemType="bonus" /><Footer /></PrivateRoute>} />
        <Route path='/auction/actualproduct/details' element={<PrivateRoute><Header /><AuctionProductDetail itemType="actual" /><Footer /></PrivateRoute>} />
        <Route path='/auction/buynow/:id/:end' element={<PrivateRoute><Header /><BuyNow /><Footer /></PrivateRoute>} />
        {/* bid end*/}




        {/* <Route path='/auction/entroll' element={<PrivateRoute><Header /><AuctionEntroll /> </PrivateRoute>} /> */}




        {/* exchange  start*/}
        <Route path='/exchange' element={<><Header /><Exchange /><Footer /></>} />
        <Route path='/exchange/options' element={<PrivateRoute><Header /> <Options /><Footer /></PrivateRoute>} />
        <Route path='/exchange/laptops' element={<> <Header /> <LaptopExchange /> <Footer /> </>} />
        <Route path='/exchange/mobiles' element={<><Header /><MobileExchange /><Footer /> </>} />
        <Route path='/exchange/desktop' element={<><Header /><DesktopExchange /><Footer /></>} />
        <Route path='/exchange/monitors' element={<><Header /><MonitorExchagne /><Footer /></>} />
        <Route path='/exchange/ups/inverter' element={<><Header /><UpsExchange /><Footer /> </>} />
        <Route path='/exchange/battery' element={<><Header /><BatteryExchange /><Footer /> </>} />
        <Route path='/exchange/confirmation' element={<><Header /><Confirmation /><Footer /></>} />
        {/* exchange end*/}




        {/* <Route path='/rewards' element={<><Header /><Rewards /> </>} /> */}
        <Route path='/user/guide' element={<><Header /><UserGuide /><Footer /> </>} />
        <Route path='/user/guide/:id' element={<><Header /><GuideDetails /></>} />

        <Route path='/order/details' element={<><Header /><OrderDetails /><Footer /> </>} />
        <Route path='/order/status/:transactionId' element={<><Header /><OrderStatus /><Footer /> </>} />

        <Route path='/profile' element={<PrivateRoute><Header /><Profile /></PrivateRoute>} >
          <Route index element={<ProfilePage />} />
          <Route path='services' element={<Services />} />
          <Route path='wishlist' element={<WishList />} />
          <Route path='reward' element={<RewardsP />} />
          <Route path='myorders' element={<MyOrders />} />
          <Route path='viewquotation' element={<Quotation />} />
          {/* <Route path='*' element={<Navigate to='/profile' />} /> */}
        </Route>
      </Routes>
    );
  };

  return (
    <div className='App min-h-screen flex flex-col justify-between'>
      <DataProvider>
        <Router>
          <Homes />
          <Sale />
          <Service />
        </Router>
      </DataProvider>
    </div>
  );
}
export default App;







// old un wanted routes
{/* old code */ }
{/* choosing category */ }
{/* <Route path='/sales/accessories' element={<PrivateRoute> <Header /><LaptopAccesories /> </PrivateRoute>} /> */ }
{/* list of accesories */ }
{/* <Route path='/sales/accessoriesdetails' element={<PrivateRoute> <Header /><Accessoriespage /></PrivateRoute>} /> */ }
{/* productdetails */ }
{/*printer as accessory */ }
{/* <Route path='/printer/subcategory' element={<PrivateRoute> <Header /><PrinterSub /></PrivateRoute>} />
        <Route path='/sales/products/installationpage' element={<PrivateRoute><Header /><Installationpage /></PrivateRoute>} /> */}
{/* <Route path='/sales/products/printer' element={<PrivateRoute><Header /> <PrinterModal /></PrivateRoute>} /> */ }
{/* ........................................Services..................................... */ }
{/* <Route path='/service' element={<PrivateRoute>   <Header /> <TypeofService /></PrivateRoute>} />
        <Route path='/servicepart' element={<PrivateRoute> <Header /> <ServicesPart /> </PrivateRoute>} />
        <Route path='/servicepart/servicehome' element={<PrivateRoute>  <Header /> <ServiceHome /> </PrivateRoute>} />
        <Route path='/servicepart/servicechecking' element={<PrivateRoute>  <Header /> <ServiceChecking /> </PrivateRoute>} /> */}
{/* <Route path='/addadress' element={<PrivateRoute><Header /> <Billing /></PrivateRoute>} /> */ }