import React, { useContext, useEffect, useState } from 'react'
import { BsUpload } from 'react-icons/bs'
import Switch from 'react-switch'
import { ComponentDiv, ComponentHeading, Dropdown, DropdownDiv, ExchangeContainer, ExchangeContents, Exchangediv, ExHeading, ItemName, ItemNameDiv, ItemsDiv, RadioDiv, SingleItem, SpecificationDiv, SubmitButtonDiv, SwitchDiv, SwitchDiv1, WorkingCondition } from '../LaptopExchange/LaptopExchangeStyle'
import SubmitModal from '../Modals/SubmitModal'
import OnOffSwitch from '../Switch/Switch'
import { InputField, TextArea } from './MobileExchangeStyle'
import axios from 'axios';
import Multiselect from 'multiselect-react-dropdown';
import { AuthContext } from '../../../consts/AuthContext';

import { listComplaint, getReason, insertExchangeform, list_estimatedprice, getDistrict, getCityList } from '../../../api/api';
import { useLocation, useNavigate } from 'react-router-dom'
import { RiExchangeFill } from 'react-icons/ri'
import axiosInstance from '../../../consts/axiosInstance'



const MobileExchange = () => {

    const { state } = useLocation();
    const navigate = useNavigate()

    const [isOnProcessor, setIsOnProcessor] = useState(false);
    const [isOnWifi, setIsOnWifi] = useState(false);
    const [isSpeaker, setIsSpeaker] = useState(false);
    const [isCamera, setIsCamera] = useState(false);
    const [complaints, setComplaints] = useState([]);
    const [items, setItems] = useState([])
    const [reason, setReason] = useState([]);


    //district lit
    const [district, setDistrict] = useState([]);
    const [selectedDistrictId, setSelectedDistrictId] = useState('')

    //city list based on district
    const [cityList, setCityList] = useState([]);

    const [authUser, setAuthUser] = useContext(AuthContext)
    const [exchangeData, setExchangeData] = useState([])

    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 5;
    const endYear = currentYear;
    const years = [];
    for (let i = startYear; i <= endYear; i++) {
        years.push(i);
    }



    const [values, setValues] = useState({
        product_cat_id: state?.id,
        user_id: authUser?.user?.id,
        processor: '',
        processor_status: 0,
        ram: '',
        storage: '',
        screen_size: '',
        brand: '',
        wifi: '',
        wifi_working: 0,
        speaker: '',
        speaker_working: 0,
        camera: '',
        camera_working: 0,
        physical_condition: '',
        working_condition: '',
        purchase_year: '',
        complaint_id: '',
        reason_for_exchange_id: '',
        address: '',
        pincode: '',
        district_id: '',
        city_id: '',
    })











    const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }


    const options =
        complaints.map(item => {
            return (
                {
                    name: item?.complaint,
                    id: item?.id

                }
            )
        })


    const handleSelect = (selectedList) => {
        setValues({
            ...values,
            complaint_id: JSON.stringify(selectedList)
        })
    };




    const handleRemove = (selectedList) => {
        setValues({
            ...values,
            complaint_id: JSON.stringify(selectedList)
        })
    };



    const listComplaints = async () => {
        try {
            const response = await axiosInstance.get(`getAllComplaint?product_cat_id=${state?.id}`)
            setComplaints(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const listReason = async () => {
        try {
            const response = await axiosInstance.get(`getReasonOfEcxhange?product_cat_id=${state?.id}`)
            setReason(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }



    const handleSubmit = () => {
        axiosInstance.post('insertexchangeform', values).then(resp => {
            // console.log(resp, "insertExchange")
            if (resp?.data.code == 200) {
                setIsOpen(true)
            } else {
                alert(resp.data.message)
            }
        })
    }




    useEffect(() => {
        listComplaints()
        listReason()
    }, [])



    const getDistrictdata = async () => {
        try {
            const response = await axiosInstance.get('getDistrict');
            setDistrict(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getDistrictdata();
    }, [])


    useEffect(() => {
        const getAvailableCity = async () => {
            try {
                if (selectedDistrictId) {
                    const response = await axiosInstance.get(`getCity/${selectedDistrictId}`);
                    setCityList(response?.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        getAvailableCity();
    }, [selectedDistrictId]);




    const handleDistrictChange = (e) => {
        setValues({ ...values, district_id: e.target.value, city_id: '' });
        setSelectedDistrictId(e.target.value);
    }




    //wifi onchnages
    const handleWifiChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            wifi: value,
            wifi_working: value === 0 ? 0 : prevValues.wifi_working,
        }));
        if (value === 0) {
            setIsOnWifi(false);
        }
    };


    //speaker onchnages
    const handleSpeakerChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            speaker: value,
            speaker_working: value === 0 ? 0 : prevValues.speaker_working,
        }));
        if (value === 0) {
            setIsSpeaker(false);
        }
    };



    //webcam onchnages
    const handleCamChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            camera: value,
            camera_working: value === 0 ? 0 : prevValues.camera_working,
        }));
        if (value === 0) {
            setIsCamera(false);
        }
    };


    const isSubmitButton = Object.values(values).every((value) => value !== '')
    const [isOpen, setIsOpen] = useState(false);


    return (
        <ExchangeContainer>
            {isOpen && <SubmitModal setIsOpen={setIsOpen} />}
            <Exchangediv>
                <ExHeading>
                    <div>
                        <p style={{ marginBottom: "0" }}>MOBILES</p>
                        <label>*only can exchange within the 5 years limit</label>
                    </div>
                </ExHeading>
                <ExchangeContents>
                    <ComponentHeading>
                        <ComponentDiv>
                            <h5>Components</h5>
                        </ComponentDiv>
                        <SpecificationDiv>
                            <h5>Specifications</h5>
                        </SpecificationDiv>
                        <WorkingCondition>
                            <h5>Working condition (Yes/No)</h5>
                        </WorkingCondition>
                    </ComponentHeading>
                    <ItemsDiv>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PROCESSOR</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <InputField name='processor' type='text' onChange={(e) => handleChange(e)} />
                            </DropdownDiv>
                            <SwitchDiv>
                                <Switch
                                    onChange={() => {
                                        setIsOnProcessor(prev => {
                                            const newProgress = !prev;
                                            setValues({ ...values, processor_status: newProgress ? 1 : 0 });
                                            return newProgress;
                                        });
                                    }}
                                    checked={isOnProcessor}
                                    height={25}
                                    width={45}
                                    handleDiameter={20}
                                    offColor="#ff0000"
                                    onColor="#4669E8"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#ffffff"
                                />
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>RAM</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <InputField name='ram' type='text' onChange={(e) => handleChange(e)} />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>STORAGE</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <InputField name='storage' type='text' onChange={(e) => handleChange(e)} />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>SCREEN SIZE</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <InputField name='screen_size' type='text' onChange={(e) => handleChange(e)} />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>BRAND</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <InputField name='brand' type='text' onChange={(e) => handleChange(e)} />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>WIFI</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="wifi" >
                                    <input type="radio" id="yes" name="wifi" value={1} onChange={() => handleWifiChange(1)} />
                                    <label for="Yes">Yes</label>
                                    <input type="radio" id="no" name="wifi" value={0} onChange={() => handleWifiChange(0)} />
                                    <label for="No">No</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                                {values.wifi == 1 &&
                                    <Switch
                                        onChange={() => {
                                            setIsOnWifi(prev => {
                                                const newProgress = !prev;
                                                setValues({ ...values, wifi_working: newProgress ? 1 : 0 });
                                                return newProgress;
                                            });
                                        }}
                                        checked={isOnWifi}
                                        height={25}
                                        width={45}
                                        handleDiameter={20}
                                        offColor="#ff0000"
                                        onColor="#4669E8"
                                        offHandleColor="#ffffff"
                                        onHandleColor="#ffffff"
                                    />}
                            </SwitchDiv>
                        </SingleItem>

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>SPEAKERS</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="speakers" >
                                    <input type="radio" id="yes" name="speaker" value={1} onChange={() => handleSpeakerChange(1)} />
                                    <label for="Yes">Yes</label>
                                    <input type="radio" id="no" name="speaker" value={0} onChange={() => handleSpeakerChange(0)} />
                                    <label for="No">No</label></RadioDiv>
                            </SwitchDiv1>

                            <SwitchDiv>
                                {values.speaker == 1 &&
                                    <Switch
                                        onChange={() => {
                                            setIsSpeaker(prev => {
                                                const newProgress = !prev;
                                                setValues({ ...values, speaker_working: newProgress ? 1 : 0 });
                                                return newProgress;
                                            });
                                        }}
                                        checked={isSpeaker}
                                        height={25}
                                        width={45}
                                        handleDiameter={20}
                                        offColor="#ff0000"
                                        onColor="#4669E8"
                                        offHandleColor="#ffffff"
                                        onHandleColor="#ffffff"
                                    />}
                            </SwitchDiv>
                        </SingleItem>

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>CAMERA</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="camera" >
                                    <input type="radio" id="yes" name="camera" value={1} onChange={() => handleCamChange(1)} />
                                    <label for="Yes">Yes</label>
                                    <input type="radio" id="no" name="camera" value={0} onChange={() => handleCamChange(0)} />
                                    <label for="No">No</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                                {values.camera == 1 &&
                                    <Switch
                                        onChange={() => {
                                            setIsCamera(prev => {
                                                const newProgress = !prev;
                                                setValues({ ...values, camera_working: newProgress ? 1 : 0 });
                                                return newProgress;
                                            });
                                        }}
                                        checked={isCamera}
                                        height={25}
                                        width={45}
                                        handleDiameter={20}
                                        offColor="#ff0000"
                                        onColor="#4669E8"
                                        offHandleColor="#ffffff"
                                        onHandleColor="#ffffff"
                                    />}
                            </SwitchDiv>
                        </SingleItem>

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PHYSICAL CONDITION</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="condition">
                                    <input type="radio" id="good" name="physical_condition" value="0" onChange={(e) => handleChange(e)} />
                                    <label for="good">Good</label>
                                    <input type="radio" id="fair" name="physical_condition" value="1" onChange={(e) => handleChange(e)} />
                                    <label for="fair">Fair</label>
                                    <input type="radio" id="excellent" name="physical_condition" value="2" onChange={(e) => handleChange(e)} />
                                    <label for="excellent">Excellent</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>WORKING CONDITION</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="condition" >
                                    <input type="radio" id="good" name="working_condition" value="0" onChange={(e) => handleChange(e)} />
                                    <label for="good">Good</label>
                                    <input type="radio" id="fair" name="working_condition" value="1" onChange={(e) => handleChange(e)} />
                                    <label for="fair">Fair</label>
                                    <input type="radio" id="excellent" name="working_condition" value="2" onChange={(e) => handleChange(e)} />
                                    <label for="excellent">Excellent</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PURCHASE YEAR</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown onChange={(e) => setValues({ ...values, purchase_year: e.target.value })}>
                                    <option value='' >Select purchase year</option>
                                    {years.map((year) => {
                                        return (
                                            <option value={year}> {year}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>COMPLAINT</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Multiselect
                                    options={options}
                                    selectedValues={items}
                                    onSelect={handleSelect}
                                    onRemove={handleRemove}
                                    displayValue="name"
                                />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>


                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>REASON OF EXCHANGE</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="reason" id="reason" onChange={(e) => setValues({
                                    ...values,
                                    reason_for_exchange_id: e.target.value
                                })}>
                                    <option value="">select</option>
                                    {reason.map((item) => {
                                        return (
                                            <option value={item?.id}>{item?.reason}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>CHOOSE DISTRICT</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="district" id="district" onChange={handleDistrictChange}>
                                    <option value="">select</option>
                                    {district?.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.district_name}</option>
                                    ))}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        {selectedDistrictId &&
                            <SingleItem>
                                <ItemNameDiv>
                                    <ItemName>CHOOSE CITY</ItemName>
                                </ItemNameDiv>
                                <DropdownDiv>
                                    <Dropdown name="city" id="city" onChange={(e) => setValues({
                                        ...values,
                                        city_id: e.target.value
                                    })} >
                                        <option value="">select</option>
                                        {cityList?.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.city_name}</option>
                                        ))}
                                    </Dropdown>
                                </DropdownDiv>
                                <SwitchDiv>
                                </SwitchDiv>
                            </SingleItem>
                        }
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>ADDRESS</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <TextArea name='address' type='text' rows={'6'} onChange={(e) => handleChange(e)} />
                            </DropdownDiv>
                            <SwitchDiv>

                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PIN CODE</ItemName></ItemNameDiv>

                            <DropdownDiv>
                                <InputField
                                    name='pincode'
                                    type='text'
                                    maxLength='6'
                                    onChange={(e) => {
                                        // Only allow numeric input
                                        if (/^\d*$/.test(e.target.value)) {
                                            handleChange(e);
                                        }
                                    }}
                                />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                    </ItemsDiv>
                </ExchangeContents>

                {isSubmitButton &&
                    <SubmitButtonDiv>
                        <button onClick={() => handleSubmit()}>Submit</button>
                    </SubmitButtonDiv>
                }
            </Exchangediv>

        </ExchangeContainer>
    )
}

export default MobileExchange;
