import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../consts/AuthContext";
import styled from "styled-components";
import { useContext } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import LoadingSpinner from "../../../components/Button/Loading/LoadingSpinner";
import ErrorComponent from "../../../components/Button/error/ErrorComponent";
import axiosInstance from "../../../consts/axiosInstance";
import CustomPcCart from "./CustomPcCart";
import line from "../../../assets/Images/png/Line44.png"
import { RiDeleteBinLine } from "react-icons/ri";
import CustomPcSubmitModal from "../../../components/Modal/CustomPcSubmitModal";
import ProcessorTypeModal from "../../../components/Modals/ProcessorTypeModal";



const CustomPcHome = () => {

  const navigation = useNavigate();
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [components, setComponent] = useState([]);
  const [pcComponents, setPcComponents] = useState([]);
  const [showPcCart, setShowPcCart] = useState(true);
  const [pcCart, setPcCart] = useState([]);
  const [customDesktopId, setCustomDesktopId] = useState(null);
  const [submitModal, setSubmitModal] = useState(false);

  const [processorModal, setProcessorModal] = useState(false);

  // console.log("pcCart", pcCart)


  // for mobile screen
  const [cartVisible, setCartVisible] = useState(false);


  const toggleCart = () => {
    setCartVisible(!cartVisible);
  };


  // category list
  const getComponentslist = async () => {
    try {
      setLoading(true);

      const response = await axiosInstance.get(`getComponents`);
      setComponent(response.data);
    } catch (error) {

      setError(error.message);
    } finally {

      setLoading(false);
    }
  };


  //items on the cart
  const getPcCart = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getbuildpc/${authUser?.user?.id}`);
      setCustomDesktopId(response?.data[0]?.id)
      setPcCart(response?.data[0]?.products || []);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (item) => {
    localStorage.setItem("componentsTypeId", JSON.stringify(item?.id))
    if (item.id === 1) {
      setProcessorModal(true);
    } else {
      navigation('/custompc/components/list');
    }

  }


  useEffect(() => {
    getComponentslist();
    getPcCart();
  }, []);



  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024 && cartVisible) {
        setCartVisible(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [cartVisible]);



  const HandleRemoveComponent = async () => {
    try {
      setLoading(true);

      const formData = {
        customdesktop_id: customDesktopId,
      };

      const options = {
        method: 'POST',
        url: 'deletebuildpc',
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData,
      };

      const response = await axiosInstance.request(options);
      // console.log('Response for pc delete', response.data);

    } catch (error) {
      console.error('Error in pc insertion:', error);
      setError('An error occurred while processing your request.');
    } finally {
      setLoading(false);
      getPcCart();
    }
  };







  const handleSubmitPcCart = async () => {
    try {
      setLoading(true);
      const formData = {
        customDesktopId: customDesktopId,
      };

      const options = {
        method: 'POST',
        url: 'submit',
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData,
      };

      const response = await axiosInstance.request(options);
      if (response.data.code == 200) {
        setSubmitModal(true);
      }

    } catch (error) {
      console.error('Error in pc insertion:', error);
      setError('An error occurred while processing your request.');
    } finally {
      setLoading(false);
      getPcCart();
    }
  }




  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }

  return (
    <>
      {/* {openProcessorModel && <ProcessorSelectModal closeFn={setOpenProcessorModel} />} */}
      {processorModal && <ProcessorTypeModal close={setProcessorModal} />}
      {submitModal && <CustomPcSubmitModal />}

      <Pdiv>

        <Cdiv1>
          <C1sub1>
            <div>Build Your Pc</div>
            <CartIcon onClick={toggleCart}>
              <AiOutlineShoppingCart size={30} />
            </CartIcon>
          </C1sub1>


          <C1sub2 className="h-screen">
            <C1sub3>
              {components?.map((item) => (
                <C1sub4 key={item.id} onClick={() => handleClick(item)}>
                  <Imgs src={item.component_image} alt={item.title} />
                  <div>{item.component_type_name}</div>
                </C1sub4>
              ))}
            </C1sub3>
          </C1sub2>
        </Cdiv1>

        <Cdiv2>
          <CustomPcCart data={pcCart} removeAll={HandleRemoveComponent} submit={handleSubmitPcCart} />
        </Cdiv2>

        {cartVisible && <MobileCart onClose={toggleCart} data={pcCart} submit={handleSubmitPcCart}
          removeAll={HandleRemoveComponent} />}

      </Pdiv>
    </>

  );
};
export default CustomPcHome;






const MobileCart = ({ onClose, data, removeAll, submit }) => {
  return (
    <CartWrapper>
      <CartHeader>
        <CloseButton onClick={onClose}>
          <GrFormClose size={25} />
        </CloseButton>


        <ButtonConatiner>
          {data.length > 0 &&
            <CloseButton >
              <span onClick={submit}>Submit</span>
            </CloseButton>
          }
          {data.length > 0 &&
            <CloseButton onClick={removeAll}>
              <span>Clear All</span><RiDeleteBinLine size={25} />
            </CloseButton>
          }
        </ButtonConatiner>


      </CartHeader>


      <CartItems>
        <CartItemsContainer>
          {data?.length > 0 && data.map((item) => (
            <div key={item.product_id}>
              <CartItemsConatiner>
                <div>
                  <ItemImg src={item?.productImages && (JSON.parse(item?.productImages)[0])} />
                </div>
                <CartDetailsConatiner>
                  <ItemTitle>{item?.title}</ItemTitle>
                  <ItemDetails>
                    <ItemDetail>
                      <div>1{item?.warranty_detail}</div>
                      <div>coin</div>
                    </ItemDetail>
                    {/* <RemoveButton>Remove</RemoveButton> */}
                  </ItemDetails>
                </CartDetailsConatiner>
              </CartItemsConatiner>
              <img src={line} alt="line"></img>
            </div>
          ))}
        </CartItemsContainer>
      </CartItems>



    </CartWrapper>
  );
};


const CartItemsContainer = styled.div`
margin-top:1rem;
margin-bottom:5rem;
`;

const Pdiv = styled.div`
padding-top: 3rem;
/* height: calc(100vh - 5rem); */
@media only screen and (min-width: 1024px) {
display: grid; 
grid-template-columns: repeat(12, minmax(0, 1fr)); 
}`;

const Cdiv1 = styled.div`
@media only screen and (min-width: 1024px) {grid-column: span 8;}
@media only screen and (min-width: 250px) and (max-width: 400px) {padding:0 1rem;}
@media only screen and (min-width: 401px) and (max-width: 767px) {padding:0 2rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0 4rem;}
@media only screen and (min-width: 1024px) and (max-width: 1499px) { padding:0 1rem;}
@media only screen and (min-width: 1500px)and (max-width: 1600px) { padding:0 4rem;}
@media only screen and (min-width: 1600px)and (max-width: 1799px) { padding:0 6rem;}
@media only screen and (min-width: 1800px) { padding:0 7rem;}
`;

const Cdiv2 = styled.div`
  @media only screen and (max-width: 1023px) {display:none;}
  @media only screen and (min-width: 1024px) {
  grid-column: span 4 / span 4; 
  box-shadow: 0px 7px 30px 0px #00000012;
  }
`;

const Imgs = styled.img`
object-fit:contain;
@media only screen and (min-width: 250px) and (max-width: 767px) {width:5rem; height:5rem}
@media only screen and (min-width: 768px) and (max-width: 1023px) {width:7rem; height:7rem}
@media only screen and (min-width: 1024px) and (max-width: 1499px){width:8rem; height:7rem}
@media only screen and (min-width: 1500px){width:10rem; height:9rem}
`

// new
const C1sub1 = styled.div`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  /* height: 2rem; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  display: flex;
  gap: 1rem;

  @media only screen and (min-width: 1024px) {
    justify-content: center;
  }
  @media only screen and (max-width: 1023px) {
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;


const C1sub2 = styled.div` 
/* height: calc(100vh - 9rem); */
overflow-y: auto;
`;

const C1sub3 = styled.div` 
display: grid; 
grid-template-columns: repeat(12, minmax(0, 1fr)); 
padding:1rem;
@media only screen and (min-width: 250px) and (max-width: 767px) {gap:1rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {gap:1rem;}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {gap:1rem;}
@media only screen and (min-width: 1200px) {gap:2rem;}
`;

const C1sub4 = styled.div`
background-color:#FFFFFF;
padding:0.5rem;
gap:0.5rem;
display:flex;
flex-direction: column;
justify-content: center;
align-items:center;
box-shadow: 0px 7px 30px 0px #00000012;
border-radius:10px;

@media only screen and (min-width: 250px) and (max-width: 767px) {grid-column: span 6 / span 6; }
@media only screen and (min-width: 768px) and (max-width: 1023px) {grid-column: span 4 / span 4; }
@media only screen and (min-width: 1024px) {grid-column: span 3 / span 3; }
@media only screen and (min-width: 1600px){max-width:12rem; max-height:12rem;}
`


const CartItemsConatiner = styled.div`
display: flex;
align-items: center;
gap:3rem;
padding-left:1.5rem;
padding-right:3.8rem;
`

const CartDetailsConatiner = styled.div`
flex-grow: 1;
`

const ItemImg = styled.img`
height:6.6rem;
width:6.6rem;
background-color: white;
border:1px solid black;
`

const ItemTitle = styled.div`
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

`


const ItemDetails = styled.div`

display:flex;
justify-content: space-between;
padding-top:1rem;
`
const ItemDetail = styled.div`
display:flex;
gap:0.5rem;
`


const CartWrapper = styled.div`
position: fixed;
top: 0;
right: 0;
bottom: 0;
width: 95%;
background-color:#F8F8F8;
z-index: 2;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
padding-top: 5rem;
height: calc(100vh - 5rem);
`;

const CartHeader = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
`;



const CloseButton = styled.button`
  color: #fff;
  border: none;
  cursor: pointer;
  margin:1rem;
  font-size:0.8rem;
  font-weight: bold;
  display:flex;
  justify-content: center;
  align-items: center;
  gap:0.5rem;
  border-radius:5px;
  background-color: #0095ff;
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
  height:2rem;
  padding:0 0.5rem;
`;



const CartItems = styled.div`
height: calc(100vh - 5rem);
overflow-y: auto;
`
const CartIcon = styled.div` 
  cursor: pointer;
  display: none;
  @media only screen and (max-width: 1023px) {display:block;}
`;


const ButtonConatiner = styled.div` 
display:flex;
gap:0.5rem;
align-items: center;
justify-content: center;
`;


