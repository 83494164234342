import React, { useState } from "react";
import {
  Installationmodal_main,
  DIv,
  Installationmodal_container,
  Installationmodal_content,
  Installation_text,
  Installation_Button,
  Installation_Button_one,
  Installation_Button_two,
} from "./Installationmodal.styled";
import Vector from "../../assets/sale/assistance/Vector.png";
import { ModalContainer, Modal, Overlay } from "../modal.js";
import { GrFormClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import PrinterModal from "./PrinterModal";




function AccessoriesModal({ close }) {

  const navigation = useNavigate();




  //do u need printer modal
  const [printer, setPrinter] = useState(false);


  //do u need installation modal
  const [view, setView] = useState(false);






  //color id of main catageory , lap , mob etc
  const categorycolor = JSON.parse(localStorage.getItem("productcode") || "[]");

  //desktop id 3 - customized desktop , 1- branded desktop
  const desktopid = JSON.parse(localStorage.getItem("desktopid") || "[]");







  const backfunction = () => {
    setPrinter(true);
    // navigation('/sales/accessories')
    navigation("/sales/products/installationpage");
  };

  const closefunction = () => {
    close(false);
  };







  const changefunction = () => {
    setPrinter(true);
  };









  const others = () => {
    setView(true);
  };



  const commandsetup = () => { };
  const openPrinter = () => {
    if (printer == false) {

    }
    else {
      setPrinter(true)
    }
  }



  // console.log("desktopid-alex", desktopid)
  // console.log("categorycolor-alex", categorycolor)





  return (
    <ModalContainer>
      {/* {
            printer === true
                ?
                <PrinterModal printermodal={setPrinter}/>
                :
                ("")
        } */}
      <Modal Modal>
        <Overlay>
          <Installationmodal_content>
            <DIv>
              <GrFormClose size={20} onClick={() => {
                setPrinter(true);
                printer && setView(true);
                view && navigation('/sales/shoppingcart')
              }} />
            </DIv>




            {/* questions section */}

            <>
              {/* 3rd question */}
              {view === true ? (<Installation_text>Do you Installation ?</Installation_text>) :
                (<>
                  {printer === true ? (<>{desktopid && categorycolor === "004" ? (<Installation_text>Do you need powerbackup solutions ?</Installation_text>) :
                    (<Installation_text>Do you need Printer ?</Installation_text>) //2nd question
                  }
                  </>) :
                    (
                      <Installation_text>Do you need Accessories ?</Installation_text> //1st question
                    )}
                </>
                )}
            </>






            {/*yes or no button section */}

            <>
              {view === true ? (
                <>
                  {/* installation 3rd question yes and no buttons */}
                  <Installation_Button>
                    <Installation_Button_one onClick={() => navigation("/sales/shoppingcart")}>
                      No
                    </Installation_Button_one>
                    <Installation_Button_two
                      onClick={() => navigation("/sales/products/installationpage")}>
                      Yes
                    </Installation_Button_two>
                  </Installation_Button>
                </>
              ) : (

                <>
                  {/* 1st question accessories  yes and no button */}
                  {printer === false ? (

                    <Installation_Button>
                      <Installation_Button_one onClick={() => changefunction()}>
                        No
                      </Installation_Button_one>

                      <Installation_Button_two onClick={() => navigation("/sales/accessories")}>
                        Yes
                      </Installation_Button_two>
                    </Installation_Button>
                  ) : (
                    <Installation_Button>
                      <Installation_Button_one onClick={() => others()}>No</Installation_Button_one>
                      {/* 2nd question printer no button */}


                      <>
                        {desktopid && categorycolor === "004" ? (
                          <Installation_Button_two
                            onClick={() => navigation("/powerback", { state: { ids: "010" } })}>
                            Yes
                          </Installation_Button_two>
                        ) : (
                          <Installation_Button_two onClick={() => navigation("/printer")}>
                            Yes
                          </Installation_Button_two>
                        )}
                        {/* 2nd question printer yes button */}
                      </>
                    </Installation_Button>
                  )}
                </>

              )}
            </>
          </Installationmodal_content>
        </Overlay>
      </Modal>
    </ModalContainer>
  );
}
export default AccessoriesModal;
