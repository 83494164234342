import styled from "styled-components";
import { colors, fonts } from "../../../consts/themes";




export const Select = styled.select`
    background: ${colors.white};
    padding: .5rem .4rem;
    border: 1px solid ${colors.medium_gray};
    border-radius: 10px;
    cursor:pointer;
    width:8rem;
`

export const Table = styled.table`
    padding-block:2rem;

`

export const Product = styled.td`
    width:10.5rem;
    display: flex;
    align-items: center;
    @media (max-width:1200px){
        width:20%
    }
    @media (max-width:1140px){
        width:25%
    }
    @media (max-width:700px){
        order:0;
        width:50%;
    }
`

export const ProductP = styled(Product)`
    padding-left:2rem;
`
export const Title = styled.h4`
    font-size:24px;
    color:${colors.black};
    font-weight:${fonts.fontWeight5};
    margin-bottom: 1rem;
    
`

export const Heading = styled.tr`
    display:flex;
    border-top:1px solid ${colors.medium_gray};
    border-bottom:1px solid ${colors.medium_gray};
    height: 3rem;
    @media (max-width:1140px) {
        display:none;
    }
`

export const Details = styled.td`
    width: 27rem;
    display:flex;
    align-items: center;
    justify-content: center;
    @media (max-width:1500px) {
        width:16rem;
    }
    @media (max-width:1200px){
        width: 50%;
    }
    @media (max-width:1140px){
        width: 70%;
    }
    @media (max-width:700px){
        order:2;
        width:100%;
    }
`
export const Item = styled.tr`
    display:flex;
    padding-block: 0.6rem;
    border-bottom:1px solid ${colors.medium_gray};
    @media (max-width:1140px){
        flex-wrap:wrap;
        row-gap: 1rem;
        padding-top: 2rem;
    }
`

export const Quantity = styled.td`
    width:13rem;
    display:flex;
    align-items: center;
    justify-content: center;
    @media (max-width:1500px) {
        width:8rem;
    }
    @media (max-width:1140px){
        width: 25%;
        justify-content: flex-start;
        padding-left: 4.3rem;
    }
    @media (max-width:700px){
        order:1;
        width: 50%;
        justify-content: flex-end;
        padding-right: 2rem;
    }
`
export const Rewardss = styled.td`
    width: 24rem;
    display:flex;
    align-items: center;
    justify-content: center;
    @media (max-width:1500px) {
        width:16rem;
    }
    @media (max-width:1200px){
        width: 50%;
    }
    @media (max-width:1140px){
        width: 70%;
    }
    @media (max-width:700px){
        order:2;
        width:100%;
    }
    img{
    width: 20px;
    height: 20px;
    margin: 10px;
}
`

export const Review = styled.td`
    width:18rem;
    display:flex;
    color: ${props => (props.txt ? 'black' : colors.blue)};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width:1500px) {
        width:13rem;
    }
    @media (max-width:1140px){
        width: 50%;
        padding-right: 2rem;
        justify-content: flex-start;
    }
    @media (max-width:700px){
        order:4;
        width:100%;
    }
`

export const Image = styled.img`
    height:8rem;
    width:8rem;
    object-fit:cover;
`
export const Box = styled.div`
    height: 9rem;
    width: 9rem;
    display:flex;
    justify-content:center;
    align-items:center;
    border:1px solid ${colors.medium_gray};
    border-radius:10px;
`

export const MyCustomDate= styled.span`
    display:block;
    color:${colors.light_grey};

`

export const DetailFlex = styled.div`
    display:flex;
    flex-direction:column;
    gap:1rem
`