import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import LoadingSpinner from "../../../components/Button/Loading/LoadingSpinner";
import { AuthContext } from "../../../consts/AuthContext";
import axiosInstance from "../../../consts/axiosInstance";
import { colors, fonts } from "../../../consts/themes";
import { IoClose } from "react-icons/io5";

import {
  Clikekart,
  Download
} from "../../../MappingDatas/InvoiceData";

import {
  Button,
  Check,
  Foot
} from "./InvoiceStyle";

import InvoiceTandCModal from "./InvoiceTandCModal";
import ErrorModal from '../../../components/Modals/ErrorModal';
import axios from 'axios';
import { server_api } from '../../../consts/api';



const SampleInvoice = () => {


  const { id } = useParams();
  const navigation = useNavigate();
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [totalAmount, setTotalAmount] = useState(0);
  const [rewardsRedeemed, setRewardRedeemed] = useState(0);
  const [termsModal, setTermsModal] = useState(false);
  const newDate = new Date()
  const futureDate = new Date(newDate.getTime() + 7 * 24 * 60 * 60 * 1000);
  const [message, setMessage] = useState(null)

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isProduct, setIsProduct] = useState([]);
  const [isBidProduct, setIsBidProduct] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [check, setCheck] = useState(0);
  const [invoice, setInvoice] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [billingAddress, setBillingAddress] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);

  const [paymentModel, setPaymentModel] = useState(false);
  const [paymentSelected, setPaymentselected] = useState(null)

  const [razorPayAmount, setRazorPayAmount] = useState(null);
  const [razorPayOrderID, setRazorPayOrderID] = useState(null);

  function handleclick(value) {
    setCheck(value);
    check === value && setCheck(0);
  }

  const getallcart = async () => {
    try {
      setLoading(true);
      // const response = await axiosInstance.get(`cartsDisplay/${authUser?.user?.id}`);
      const res = await axiosInstance.get('getActiveInvoice', {
        params: {
          'invoice_id': id
        }
      })
      // console.log('res.data', res.data)
      setFinalPrice(res?.data?.total);
      setIsProduct(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const fetchInvoiceAndAddresses = async () => {
    try {
      // Fetch invoice data
      const invoiceResponse = await axiosInstance.get('getInvoiceDetails', { params: { id: id } });
      const invoiceData = invoiceResponse.data;
      setInvoice(invoiceData);
      setIsBidProduct(invoiceData)

      // Fetch all addresses
      const addressesResponse = await axiosInstance.get(`getCustomerAddress/${authUser?.user?.id}`);
      const addressesData = addressesResponse.data;
      setAddresses(addressesData);

      // Find and set billing and shipping addresses based on IDs in the invoice
      const billingAddr = addressesData.find(addr => addr.id === invoiceData.billing_address_id);
      const shippingAddr = addressesData.find(addr => addr.id === invoiceData.shipping_address_id);
      setBillingAddress(billingAddr);
      setShippingAddress(shippingAddr);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  useEffect(() => {
    getallcart();
    fetchInvoiceAndAddresses();
  }, []);



  const [isOpen, setOpen] = React.useState(false);
  let total = 0;


  const downloadPDF = (divId) => {
    const input = document.getElementById(divId);
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 14, 10, 180, 130);
        pdf.save(`${divId}.pdf`);
      });
  };


  const handlePay = async () => {
    // console.log('id',base64AuthString)
    try {
      const response = await axiosInstance.post('initiatePayment', {
        invoice_id: id
      })
      // console.log('response.data', response.data)
      window.location.href = response?.data?.payment_links.web
    } catch (error) {
      // console.log('error', error)
      setMessage(error?.response?.data?.error)
    }

    // try {
    //   setLoading(true);
    //   const response = await axiosInstance.post(`PaymentSample/${id}`);
    //   if (response.data.code === 200) {
    //     Swal.fire({
    //       position: 'center',
    //       icon: 'success',
    //       color: '#4169e1',
    //       text: 'Payment Completed Successfully',
    //       showConfirmButton: false,
    //       timer: 1500
    //     });
    //     navigation('/');
    //   } else if (response.data.code === 401) {
    //     Swal.fire({
    //       position: 'center',
    //       icon: 'error',
    //       color: '#4169e1',
    //       text: `${response.data.message}`,
    //     });
    //   }
    // } catch (error) {
    //   console.error(error.message);
    // } finally {
    //   setLoading(false);
    // }
  };



  if (loading) {
    return <LoadingSpinner />
  }
  const nav = '/';

  const handlePaymentOption = () => {
    setPaymentModel(!paymentModel)
  }

  const handlePaymentSelected = async (method) => {
    setPaymentselected(method);
  };

  const handleRazorpayPayment = async () => {
    try {
      const token = localStorage.getItem("token"); // Authorization token
      const invoiceId = id; // Replace with actual invoice ID

      // Step 1: Create an order on the backend
      const response = await axios.post(
        `${server_api}user/createRazorpayOrder`,
        { invoice_id: invoiceId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const orderData = response.data.data;

      if (response?.data?.data) {
        const options = {
          key: "rzp_live_jnJznofXV6t3L9", // Replace with your Razorpay Key ID
          amount: orderData.amount,
          currency: orderData.currency,
          name: "Clikekart",
          description: "Payment for Invoice",
          order_id: orderData.id,
          handler: function (response) {
            // console.log("Payment Successful:", response);

            axios.post(
              `${server_api}user/handleRazorpayPayment`,
              {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            )
              .then((verificationResponse) => {
                // console.log("Verification Response:", verificationResponse.data);

                // Store payment success status in sessionStorage
                sessionStorage.setItem("paymentSuccess", "true");

                // Navigate to the payment-done page
                navigation("/payment-done", { replace: true });

                // Optionally, reset payment modal
                setPaymentModel(!paymentModel);
                setPaymentselected(null);
              })
              .catch((err) => {
                console.error("Payment Verification Failed:", err.response?.data || err.message);
              });
          },
          prefill: {
            name: "",
            email: "",
            contact: "",
          },
          theme: {
            color: "#F37254",
          },
        };

        const rzp1 = new window.Razorpay(options);

        // Handle payment failures
        rzp1.on("payment.failed", function (response) {
          console.error("Payment Failed:", response.error);
          alert("Payment Failed! Please try again.");
        });

        // Open Razorpay Checkout
        rzp1.open();

      }
    } catch (error) {
      alert("Payment Failed! Please try again.");
      console.error("Error creating Razorpay order:", error.response?.data || error.message);
    }
  };



  const handleProceedToPayment = () => {
    if (paymentSelected == 'HDFC') {
      handlePay()
    } else if (paymentSelected == 'Razorpay') {
      handleRazorpayPayment()
    }
  }


  const paymentOption = [
    {
      title: 'HDFC Payment',
      value: 'HDFC',
      image: '/image/hdfcbank.jpg'
    },
    {
      title: 'Razorpay Payment',
      value: 'Razorpay',
      image: 'https://badges.razorpay.com/badge-light.png'
    },
  ]




  const handleClose = () => {
    setPaymentselected('')
    setPaymentModel(!paymentModel)
  }


  return (
    <Screen className=''>
      {
        paymentModel && (
          <>
            <div className='absolute top-0 left-0 w-full h-full bg-gray-600 opacity-80 z-40'>
            </div>
            <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center flex-col'>
              <div className='w-[95%] relative sm:w-[400px] h-[400px] bg-white z-50 rounded-xl flex flex-col justify-between py-5 items-center'>
                <IoClose className='z-50 absolute -top-14 right-0  cursor-pointer' size={40} onClick={handleClose} fill='white' />
                <div className='w-full space-y-4'>
                  <h4 className='text-center text-xl font-normal'>Payment Method</h4>
                  <div>
                    {
                      paymentOption.map((option, index) => {
                        return (
                          <div div key={index} onClick={() => handlePaymentSelected(option.value)} className={`py-5 px-4 border-b flex items-center gap-5 hover:bg-[#f1f0f0] transition-all duration-200 ${paymentSelected == option.value ? 'bg-[#e9e7e7]' : ''}`}>
                            <img src={option.image} alt="" className='w-36 h-14' />
                            <h4 className='font-normal text-base'>{option.title}</h4>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <button
                  onClick={handleProceedToPayment}
                  className={`w-[80%] p-4  ${paymentSelected ? 'bg-[#4669e8] text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    }`}
                  disabled={!paymentSelected}>Pay Now</button>
              </div>
            </div>

          </>
        )
      }
      <Container>
        <SubOne>
          <Header>
            <Heads>
              {Download.map((item, index) => {
                return <span className='cursor-pointer flex items-center' onClick={() => downloadPDF('pdf')}><img key={index} src={item} /><p>Download Receipt</p></span>;
              })}</Heads>
          </Header>
          <InvoiceDiv id="pdf">
            <ImageName className='py-5'>
              {/* {Clikekart.map((item, index) => {
                return <img key={index} src={item} />;
              })} */}
              <p>&nbsp;Receipt</p>
            </ImageName>

            <Details>
              <IDname>
                {/* <Id><p>Invoice Id</p><span>{invoice?.invoice_id}</span></Id> */}
                <Id><p>Name</p><span>{invoice?.name}</span></Id>
              </IDname>
              <Date1>
                <Id><p>Date</p><span>{invoice?.created_at}</span></Id>
              </Date1>
            </Details>

            <AddressContainer>
              {/* <InvoiceAddress>
                <Addhead>Invoice address</Addhead>
                <div>
                  {billingAddress?.name}
                  <br />
                  {billingAddress?.house_name}
                  <br />
                  {billingAddress?.street_name}
                  <br />
                  {billingAddress?.district}
                  <br />
                  {billingAddress?.state}
                  <br />
                  {billingAddress?.pincode}
                  <br />
                  {billingAddress?.mob1}
                  <br />
                  {billingAddress?.mob2}
                </div>
              </InvoiceAddress> */}

              <InvoiceAddress>
                <Addhead>Shipping address</Addhead>
                <div>
                  {shippingAddress?.name}
                  <br />
                  {shippingAddress?.house_name} , {shippingAddress?.street_name}
                  <br />
                  {shippingAddress?.district} , {shippingAddress?.state}
                  <br />
                  {shippingAddress?.pincode}
                  <br />
                  {shippingAddress?.mob1}
                  <br />
                  {shippingAddress?.mob2}
                </div>
              </InvoiceAddress>
            </AddressContainer>

            {/* Hidden Table */}

            <Amount>
              <div>
                <Id><p>Delivery charge</p><span>₹ 0</span></Id>
                <Id><p>Coin Redeemed</p><span>₹ {invoice?.added_points}</span></Id>
              </div>
              <Id><p>Total</p><span>₹ {invoice?.total}</span></Id>
            </Amount>
            <Foot>
              <p className='mb-4'>Clikekart gives life time services facility for all products.</p>
            </Foot>
          </InvoiceDiv>
        </SubOne>

        <SubTwo className='py-10'>
          {/* <AddCon> */}
          {/* <InvoiceAddress>
              <Addhead>Shipping address</Addhead>
              <div>
                {shippingAddress?.name}
                <br />
                {shippingAddress?.house_name}
                <br />
                {shippingAddress?.street_name}
                <br />
                {shippingAddress?.district}
                <br />
                {shippingAddress?.state}
                <br />
                {shippingAddress?.pincode}
                <br />
                {shippingAddress?.mob1}
                <br />
                {shippingAddress?.mob2}
              </div>
            </InvoiceAddress> */}
          {/* </AddCon> */}

          <ButtonCon>
            <Check>
              <input type='checkbox' className='mr-2' onClick={() => handleclick(1)} />
              <p>
                I agree with the
                <a onClick={() => setTermsModal(true)} > Terms & conditions</a>
                {termsModal && <InvoiceTandCModal close={setTermsModal} />}
              </p>
            </Check>
            {/* {message?<div style={{color:'red',textAlign:'center'}}>{message}</div>:null} */}
            <div className='flex justify-center'>
              <Button disabled={check === 0}>
                <button disabled={check === 0} onClick={handlePaymentOption}>Proceed to pay</button>
              </Button>
            </div>
          </ButtonCon>

        </SubTwo>
        {message ? <ErrorModal nav={nav} text={message} /> : null}

      </Container>
    </Screen >



  );
};

export default SampleInvoice;


const AddCon = styled.div`
border: 1px solid #ddd;
margin:0.5rem;
padding:0.5rem;
border-radius:8px;
`;

const ButtonCon = styled.div`
display: flex ;
flex-direction: column;
gap: 1rem;
margin:0.5rem;
padding:0.5rem;
`;

const Row = styled.div`
font-size:14px;
font-weight: 800;
display: flex;
justify-content: center;
padding: 8px;
`;


const CatLine = styled.div`
border-bottom: 1px solid #ddd;
`;

const Cat = styled.div`
border-top: 1px solid #ddd;
`;

const Amount = styled.div`
border-top: 1px solid #ddd;
border-bottom: 1px solid #ddd;
margin: 1rem 0;
display: flex;
align-items: center;
justify-content: space-between;
padding:0.5rem;
`;

// const Tables = styled.div`
//   display: flex;
//   flex-direction: column;
//   /* border: 1px solid #ddd; */
//   width: 100%;
//   margin-top: 1rem;
// `;

// const TableHeader = styled.div`
//   display: flex;
//   font-weight: bold;
//   border-top: 1px solid #ddd;
//   border-bottom: 1px solid #ddd;
// `;

// const TableRow = styled.div`
//   display: flex;
// `;

// const TableCell = styled.div`
//   flex: 1;
//   padding: 8px;
//   text-align: center;
// `;

const Tables = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-top: 1rem;
`;

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  display: table-row;
`;

const TableHeader = styled.th`
  font-weight: bold;
  padding: 8px;
  text-align: center;
  border-bottom: 2px solid #ddd;
  font-size: 14px;
`;

const TableCell = styled.td`
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
`;


const Screen = styled.div`
  margin:0;
  padding-top:5rem;
`;
const Container = styled.div`
padding: 0 1rem;
display: flex;
justify-content: center;
margin-top:1rem;
padding-bottom: 1rem;
flex-direction: column;
  align-items: center;
  gap: 1rem;

/* @media only screen and (min-width: 250px) and (max-width: 1500px) {

} */
`

const SubOne = styled.div`
grid-column: span 10 / span 10; 
width:60%;
@media screen and (max-width: 600px) {
      width:100%;
    }
@media (min-width:600px) and (max-width: 1200px) {
      width:80%;
    }
height:100%;
`
const SubTwo = styled.div`
grid-column: span 2 / span 2; 
width: 60%;
/* min-height:calc(100vh - 6.5rem); */
`
export const Header = styled.div`
background-color: ${colors.blue};
`;

export const Heads = styled.div`
  align-items: center;
  height: 2.3rem;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding-left: 80%;
  @media screen and (max-width: 600px) {
      padding-left: 0px;
    }
  img {
    width: 18px;
    height: 18px;
  }
  p {
    padding-left: 10px;
    flex-shrink: 0;
    padding-right: 2rem;
    font-size: 1rem;
    color: ${colors.white};
    @media screen and (max-width: 320px) {
      padding-left: 0px;
    }
  }
`;

const ImageName = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  img {
    width: 100px;
    height: 18px;
  }
  p {
    padding-left: 5px;
    /* width: 10%; */
    font-size: 1.5rem;
    font-weight: ${fonts.fontWeight5};
  }
`;

const InvoiceDiv = styled.div`
  border: 0.5px solid ${colors.line};
`;

const Details = styled.div`
display: flex;
justify-content: space-between;
padding:0.8rem 10px;
`;

const IDname = styled.div`
display: flex;
flex-direction: column;
gap:0.5rem;
`;

const Date1 = styled.div`
display: flex;
justify-content: center;
`;

const Id = styled.div`
display: flex;
flex-direction: column;
/* align-items: center; */
/* gap:1rem; */
p{
margin: 0;
padding: 0;
font-size: 1rem;
font-weight: 500;
}
span{
font-size: 0.9rem;
}
`;

const AddressContainer = styled.div`
display: flex;
justify-content: space-between;
padding: 0 10px;
`;

const InvoiceAddress = styled.div`
display: flex;
flex-direction: column;
gap:0.5rem;
`;

const Addhead = styled.div`
font-size:0.9rem;
font-weight: 500;
`;


