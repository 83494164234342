import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import jsPDF from 'jspdf'
import styled from 'styled-components'
import { FaFileUpload } from "react-icons/fa";
import BulkConfirmModal from '../../../../components/Modals/BulkConfirmModal'
import BulkListModal from '../../../../components/Modals/BulkListModal'
import ErrorComponent from '../../../../components/Button/error/ErrorComponent'
import LoadingSpinner from '../../../../components/Button/Loading/LoadingSpinner'
import axiosInstance from '../../../../consts/axiosInstance'
import { AuthContext } from '../../../../consts/AuthContext'
import { colors } from '../../../../consts/themes'
import BulkEnquiryModal from '../../../../components/Modals/DetailsModals/BulkEnquiryModal'
import Swal from 'sweetalert2';




const BulkPurchaseForm = () => {


  const [brandName, setBrandName] = useState('')
  const [brandSearchModal, setBrandSearchModal] = useState(false)

  const navigation = useNavigate();
  const [isitem, setisItem] = useState();
  const [form, setForm] = useState([]);
  const [visible, setVisible] = useState([]);
  const [brows, setBrows] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [product, setProduct] = useState([]);
  const [pdfreport, setPdfreport] = useState([]);
  //id of bulk , retail etc..
  const purchaseTypeId = JSON.parse(localStorage.getItem('codeid'));

  //id of lap , mob , tab
  const product_category_id = JSON.parse(localStorage.getItem('productid'));

  //1st sub category of lap and desk in corporate segment
  const categoryId = JSON.parse(localStorage.getItem('categoryId'));

  console.log(categoryId)

  const [authUser, setAuthUser] = useContext(AuthContext);

  //id of lap , mob , tab
  const productid = JSON.parse(localStorage.getItem('productid'));


  //new edit
  //product selected
  const [productSelected, setProductSelected] = useState('');
  //selected product
  const [selectedProduct, setSelectedProduct] = useState('')
  //selected product id
  const [selectedProductId, setSelectedProductId] = useState('')
  //selected product barnd details
  const [brand, setBrand] = useState('');
  //quantity of product 
  const [productQnty, setProductQnty] = useState('')
  //search dropdown
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [openBulkSubmitModal, setOpenBulkSubmitModal] = useState(false);
  //input field
  const [searchTerm, setSearchTerm] = useState('')
  const [enquiry, setEnquiry] = useState('');

  //filter items by productid
  const filteredById = product.filter(item => item.product_category_id === productid);
  // console.log("first fiter", filteredById)

  //filter by search
  const filteredProducts = filteredById.filter(pdt =>
    pdt.title.toLowerCase().includes(searchTerm.toLowerCase())
  );



  //handle keyborad navigation on search
  const [selectedProductIndex, setSelectedProductIndex] = useState(-1);
  //enquiry modal
  const [openEnquiry, setOpenEnquiry] = useState(false);
  const [commnets, setCommnets] = useState();


  const Productlist = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('getProductList');
      // console.log(response.data, " response of product list type")
      setProduct(response.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  async function handlecheck(slno) {
    try {
      const response = await axiosInstance.get(`getCustomSaleReportById/${slno}`);
      setPdfreport(response.data[0]);
      // console.log('pdf=>', response.data);
      setTimeout(() => {
        jsPdfGenerator();
      }, 1000);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  useEffect(() => {
    const Brands = async () => {
      try {
        const response = await axiosInstance.get('getBrands');
        const brandNames = response.data.map(brand => brand.brand_name);
        setVisible(brandNames);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    Brands();
  }, []);



  function getTime(iitem) {
    var formatted = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(Date.parse(iitem))

    return formatted
  }



  function jsPdfGenerator() {
    var doc = new jsPDF()
    doc.autoTable({
      html: '#table1',
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      theme: 'plain',
      bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
      headStyles: { textColor: [255, 0, 0] },
    })

    var string = doc.output('datauristring')
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
    var x = window.open()
    x.document.open()
    x.document.write(embed)
    x.document.close()
  }


  //form data
  const details = [
    {
      specification: selectedProduct?.title,
      brand: selectedProduct?.brand_name,
      qnty: productQnty,
      productId: selectedProduct?.id,
    }
  ]


  // insert custom sales
  const customInsertProducts = async () => {

    // const formdata = {
    //   product_category_id: product_category_id,
    //   purchaseTypeId: purchaseTypeId,
    //   category: (product_category_id === 7 || product_category_id === 10) ? categoryId : 8,
    //   details: JSON.stringify(details),
    //   user_id: authUser?.user?.id,
    //   enquiry: enquiry ? enquiry : '',
    // }
    // console.log("formdata==>", formdata);
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('product_category_id', product_category_id);
      formData.append('purchaseTypeId', purchaseTypeId);
      formData.append('category', (product_category_id === 7 || product_category_id === 10) ? categoryId : 8);
      formData.append('details', JSON.stringify(details));
      formData.append('user_id', authUser?.user?.id);
      formData.append('enquiry', enquiry ? enquiry : '');
      const options = {
        method: 'POST',
        url: 'insertCustomSale',
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
        data: formData,
      };

      const response = await axiosInstance.request(options);
      if (response.data.code === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          color: '#4169e1',
          text: 'Enquiry Submitted Successfully',
          showConfirmButton: false,
          timer: 1500
        });
        getCustomSales();
      }
    } catch (error) {
      setError(error);
      // console.log("🚀 ~ update cart ", error);
    } finally {
      setProductQnty('');
      setSelectedProduct('');
      setSearchTerm('');
      setEnquiry('');
      setBrandName('');
      setLoading(false);
    }
  };






  const picker = (product) => {
    setSelectedProduct(product);
    setSearchTerm(product?.title);
    setSelectedProductId(product?.id);
    setBrandName(product?.brand_name);
    setBrows(false);
  };


  const dropdownHandler = (product) => {
    setSelectedProduct(product);
    setSearchTerm(product?.title);
    setSelectedProductId(product?.id);
    setBrandName(product?.brand_name);
    setBrandSearchModal(false);
    setIsSearchModalOpen(false);
  }



  // sales report list 
  const getCustomSales = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getCustomSaleReport/${authUser?.user?.id}/${product_category_id}/${purchaseTypeId}`);
      // console.log(response.data, " response of custom sale report...")
      // setPurchase(response.data)
      setForm(response.data)
      // const quotationId = response.data[0].quotation_details[0].id;
      // console.log("quotationId", quotationId)

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const [selectedQuotationId, setSelectedQuotationId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);


  const handleFileUpload = (e) => {
    const fileUserSelected = e.target.files[0];
    setSelectedFile(fileUserSelected);
  }



  const handleUpload = () => {
    const formData = new FormData();
    formData.append('purchase_order_pdf', selectedFile ? selectedFile : '');
    formData.append('id', selectedQuotationId ? selectedQuotationId : '');
    axiosInstance.post('PurchaseOrderpdfUpload', formData)
      .then(response => {
        // console.log("response of file upload", response.data.code);
        if (response.data.code === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            color: '#4169e1',
            text: 'Purchase order uploaded successfully',
            showConfirmButton: false,
            timer: 1500
          });
        }
      })
      .catch(error => {
        const errorMessage = error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'An error occurred while uploading the file.';
        Swal.fire({
          position: 'center',
          icon: 'error',
          color: '#4169e1',
          text: errorMessage,
        });
      });
  };






  useEffect(() => {
    if (selectedFile !== null) {
      handleUpload();
    }
  }, [selectedFile])



  useEffect(() => {
    Productlist();
    getCustomSales();
  }, [])




  //new
  const [reportId, setReportId] = useState(null);

  const verifyHandler = (id) => {
    setReportId(id);
    setOpenBulkSubmitModal(true);
  }



  // drop down
  const filterOptions = (input) => {
    return filteredById.filter(product => product.title.toLowerCase().includes(input.toLowerCase()));
  };

  const filteredOptions = filterOptions(searchTerm);



  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    setIsSearchModalOpen(true);
  };


  const handleBlur = () => {
    setIsSearchModalOpen(false);
    setBrandSearchModal(false);
  };





  // drop down brand
  const filterBrand = (input) => {
    return filteredById.filter(product => product.brand_name.toLowerCase().includes(input.toLowerCase()));
  };
  const filteredBrandOptions = filterBrand(brandName)

  const handleBrandChange = (event) => {
    setBrandSearchModal(true);
    setBrandName(event.target.value);
  }



  // Handler for keyboard events (arrow keys and enter key)
  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedProductIndex(prevIndex => Math.min(prevIndex + 1, filteredOptions.length - 1));
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedProductIndex(prevIndex => Math.max(prevIndex - 1, -1));
    } else if (event.key === 'Enter' && selectedProductIndex !== -1) {
      event.preventDefault();
      dropdownHandler(filteredOptions[selectedProductIndex]);
    }
  };


  // const allFieldsFilled = brand !== '' && selectedProduct !== '' && enquiry !== '' && productQnty !== '' && productQnty > 0;


  const allFieldsFilled = selectedProduct !== '' && searchTerm === selectedProduct.title && brandName === selectedProduct.brand_name &&
    enquiry !== '' && productQnty !== '' && productQnty > 0;


  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }
  return (
    <Screen>
      {brows && <BulkListModal close={setBrows} products={filteredById} picker={picker} />}
      {openEnquiry && <BulkEnquiryModal close={setOpenEnquiry} data={commnets} />}
      {openBulkSubmitModal && <BulkConfirmModal id={reportId} close={setOpenBulkSubmitModal} refresh={getCustomSales} />}
      <div className='w-full flex flex-col items-center justify-center'>
        <div className='w-full flex justify-center'>
          <SubmitBtn pb="1.5rem" className='w-[1300px] flex justify-end'><button className='' onClick={() => navigation('/sales/productcard')}>Choose Another Product</button></SubmitBtn>
        </div>
        <TopContainer>
          <SpecContainer>
            <label>Choose Your Product</label>

            <InputContainer>
              {/* <input type="text" placeholder="Enter your password here" /> */}
              <AutoContainer className="border-box">
                <Input className="border-box"
                  type="text"
                  placeholder="Search Product"
                  value={searchTerm}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                />
                {isSearchModalOpen && (
                  <DropDown className="border-box">
                    <Ul className="border-box">
                      {filteredOptions.map((item, index) => (
                        <List
                          className={index === selectedProductIndex ? 'autocomplete-search-result selected' : 'autocomplete-search-result'}
                          key={item?.id}
                          onMouseDown={() => dropdownHandler(item)}
                        >{item?.title}</List>
                      ))}
                    </Ul>
                  </DropDown>
                )}
              </AutoContainer>

              <SearchIconContainer>
                <FiSearch size={20} onClick={() => setBrows(true)} />
              </SearchIconContainer>
            </InputContainer>
          </SpecContainer>






          <InputContainer>
            <AutoContainer className="border-box">
              <InputBrand className="border-box"
                type="text"
                placeholder="Search brand"
                value={brandName}
                onChange={handleBrandChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
              />
              {brandSearchModal && (
                <DropDownBrand className="border-box">
                  <Ulbrand className="border-box">
                    {filteredBrandOptions.map((item, index) => (
                      <ListBrand
                        className={index === selectedProductIndex ? 'autocomplete-search-result selected' : 'autocomplete-search-result'}
                        key={item?.id}
                        onMouseDown={() => dropdownHandler(item)}
                      >{item?.title}</ListBrand>
                    ))}
                  </Ulbrand>
                </DropDownBrand>
              )}
            </AutoContainer>
            <SearchIconContainer>
              <FiSearch size={20} onClick={() => setBrows(true)} />
            </SearchIconContainer>
          </InputContainer>




          <QtyContainer>
            <label>Quantity</label>
            <input type="number" placeholder="quantity"
              onChange={(e) => setProductQnty(e.target.value)}
              value={productQnty} />
          </QtyContainer>

          <CmntContainer>
            <label>Enter your specifications</label>
            <input type="text" placeholder="comments"
              onChange={(e) => setEnquiry(e.target.value)}
              value={enquiry} />
          </CmntContainer>



          <SubmitBtn disabled={!allFieldsFilled}><button
            disabled={!allFieldsFilled}
            onClick={() => customInsertProducts()}>Submit</button></SubmitBtn>

        </TopContainer>
      </div>
      {form?.length != 0 && (
        <TableWrapper className='flex lg:justify-center'>
          <TableContainer>
            <Table>
              <Thead>

                <tr>
                  <Cell rowSpan={2}>Report Id</Cell>
                  <Cell rowSpan={2}>Category</Cell>
                  <Cell colSpan={4}>Enquiry Submitted</Cell>
                  <Cell colSpan={8}>Quotation</Cell>
                </tr>
                <tr>
                  <Cell >Product</Cell>
                  <Cell >Brand</Cell>
                  <Cell >Quantity</Cell>
                  <Cell >Specifications</Cell>
                  <Cell >Sl.no</Cell>
                  <Cell >Quotation</Cell>
                  <Cell >Date</Cell>
                  {/* <Cell >Purchase Order</Cell> */}
                  <Cell >Verify</Cell>
                  <Cell >Status</Cell>
                  <Cell >Comment</Cell>
                  <Cell >Purchase Order</Cell>
                </tr>
              </Thead>


              <Tbody>
                {form && form.map((item) => (
                  <tr>
                    <Td>{item?.request_id}</Td>
                    <Td>{item?.category_name}</Td>
                    <Td>{item?.enquiry_details[0]?.specification}</Td>
                    <Td>{item?.enquiry_details[0]?.brand}</Td>
                    <Td>{item?.enquiry_details[0]?.qnty}</Td>
                    {/* <Td>{item?.enquiry}</Td> */}

                    <Td><StyledButton onClick={() => [setOpenEnquiry(true), setCommnets(item?.enquiry)]}>
                      view</StyledButton></Td>

                    {item?.quotation_details ? (
                      <>
                        <Td>{item.quotation_details[0]?.id}</Td>



                        {/* 
                        <Td>
                          <StyledButton onClick={() => [setisItem(item.quotation_details[0]?.id),
                          handlecheck(item.quotation_details[0]?.id),]}>
                            view</StyledButton>
                        </Td> */}


                        <Td>
                          <StyledButton onClick={() => navigation(`/download-pdf-report/${item.quotation_details[0]?.id}`)}>
                            view</StyledButton>
                        </Td>


                        <Td>{getTime(item.quotation_details[0]?.date)}</Td>


                        {/* <Td>
                          <FileInputLabel>
                            <FaFileUpload size={20} />
                            <HiddenFileInput type="file"
                              accept=".pdf"
                              onChange={handleFileUpload}
                              onClick={() => setSelectedQuotationId(item?.quotation_details[0]?.id)} />
                          </FileInputLabel>
                        </Td> */}

                        <Td><StyledButton onClick={() => verifyHandler(item?.quotation_details[0]?.id)}>
                          verify</StyledButton>
                        </Td>

                      </>
                    ) : <>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      {/* <Td></Td> */}
                      <Td></Td>
                    </>}

                    {item?.quotation_details ? item?.quotation_details.map((qd) => (
                      <>
                        <Td>{qd.status == 1 ? 'Accept' : qd.status == 0 ? 'Reject' : 'Pending'}</Td>
                        <Td>{qd?.comment}</Td>
                      </>
                    )) :
                      <>
                        <Td></Td>
                        <Td></Td>
                      </>
                    }

                    {item?.quotation_details ? (
                      <>
                        <Td>
                          <FileInputLabel>
                            <FaFileUpload size={20} />
                            <HiddenFileInput type="file"
                              accept=".pdf"
                              onChange={handleFileUpload}
                              onClick={() => setSelectedQuotationId(item?.quotation_details[0]?.id)} />
                          </FileInputLabel>
                        </Td>
                      </>
                    ) : <> <Td></Td> </>}
                  </tr>
                ))}
              </Tbody>

            </Table>
          </TableContainer>
        </TableWrapper>
      )}


      <Show>
        <table id="table1">
          <tr>
            <th>No</th>
            <th>Model</th>
            <th>Item Description</th>
            <th>Warranty</th>
            <th>Qty</th>
            <th>Unit Price</th>
            <th>Total Price</th>
          </tr>
          {pdfreport?.product?.map((item, index) => (
            <>
              <tr>
                {/* {console.log("pdfreport", item?.id)} */}
                <td>{index + 1}</td>
                <td>{item?.model}</td>
                <td>{item?.title}</td>
                <td>{item?.warranty}yr</td>
                <td>{item?.qnty}</td>
                <td>{item?.unit_price}</td>
                <td>{item?.total}</td>
              </tr>
            </>
          ))}
          <tr style={{ textAlign: 'center' }}>
            <td style={{ color: 'red' }} colSpan={6}>
              TOTAL
            </td>
            <td colSpan={1}>{pdfreport?.product_total}</td>
          </tr>
          <tr>
            <th colSpan={3}>TAX-GST</th>
            <td>CGST</td>
            <td colSpan={2}>{pdfreport?.tax_charges?.cgst}%</td>
            <td colSpan={1}>{pdfreport?.tax_charges?.cgst_amount}</td>
          </tr>
          <tr>
            <td colSpan={3}></td>
            <td>SGST</td>
            <td colSpan={2}>{pdfreport?.tax_charges?.sgst}%</td>
            <td colSpan={1}>{pdfreport?.tax_charges?.sgst_amount}</td>
          </tr>
          <tr>
            <td colSpan={3}></td>
            <td>CESS</td>
            <td colSpan={2}>{pdfreport?.tax_charges?.cess}%</td>
            <td colSpan={1}>{pdfreport?.tax_charges?.cess_amount}</td>
          </tr>
          <tr>
            <td colSpan={6}>TOTAL</td>
            <td colSpan={1}>{pdfreport?.tax_total}</td>
          </tr>
          <tr>
            <th colSpan={7} style={{ textAlign: 'left' }}>
              DELIVERY CHARGES
            </th>
          </tr>
          <tr style={{ textAlign: 'left' }}>
            <th colSpan={4}>ITEM</th>
            <th>QTY</th>
            <th>UNIT PRICE</th>
            <th>TOTAL PRICE</th>
          </tr>
          {pdfreport?.delivery_charges?.map((item) => (
            <tr>
              <td colSpan={4}>{item?.title}</td>
              <td>{item?.qnty}</td>
              <td>{item?.unit_price}</td>
              <td>{item?.total}</td>
            </tr>
          ))}
          <tr>
            <th colSpan={7} style={{ textAlign: 'left' }}>
              INSTALLATION CHARGES
            </th>
          </tr>
          <tr style={{ textAlign: 'left' }}>
            <th colSpan={4}>ITEM</th>
            <th>QTY</th>
            <th>UNIT PRICE</th>
            <th>TOTAL PRICE</th>
          </tr>
          {pdfreport?.installation_charges?.map((item) => (
            <tr>
              <td colSpan={4}>{item?.title}</td>
              <td>{item?.qnty}</td>
              <td>{item?.unit_price}</td>
              <td>{item?.total}</td>
            </tr>
          ))}
          <tr>
            <td colSpan={6}>PRODUCT PRICE</td>
            <td colSpan={1}>{pdfreport.product_total}</td>
          </tr>
          <tr>
            <td colSpan={6}>Taxes</td>
            <td colSpan={1}>{pdfreport?.tax_total}</td>
          </tr>
          <tr>
            <td colSpan={6}>DELIVERY CHARGES</td>
            <td colSpan={1}>{pdfreport?.delivery_charges_total}</td>
          </tr>
          <tr>
            <td colSpan={6}>INSTALLATION CHARGES</td>
            <td colSpan={1}>{pdfreport?.installation_charges_total}</td>
          </tr>
          <tr>
            <td colSpan={6}>GRAND TOTAL</td>
            <td colSpan={1}>
              {pdfreport.product_total +
                pdfreport?.tax_total +
                pdfreport?.delivery_charges_total +
                pdfreport?.installation_charges_total}
            </td>
          </tr>
          <tr>
            <td colSpan={3}>AFTER SERVICE</td>
            <td colSpan={4}>{pdfreport?.vendor_details}</td>
          </tr>
          <tr>
            <td colSpan={3}>PAYMENT</td>
            <td colSpan={4}>{pdfreport?.payment}</td>
          </tr>
          <tr>
            <td colSpan={3}>DELIVERY TIME</td>
            <td colSpan={4}>{pdfreport?.delivery}</td>
          </tr>
          <tr>
            <td colSpan={3}>QUOTATION VALIDITY TIME</td>
            <td colSpan={4}>{pdfreport?.quotation_validity} days</td>
          </tr>
          <tr>
            <th colSpan={7} style={{ textAlign: 'left' }}>
              BANK DETAILS
            </th>
          </tr>
          <tr>
            <td colSpan={3}>ACCOUNT NAME</td>
            <td colSpan={4}>{pdfreport?.bank_details?.account_name}</td>
          </tr>
          <tr>
            <td colSpan={3}>ACCOUNT NO</td>
            <td colSpan={4}>{pdfreport?.bank_details?.account_no}</td>
          </tr>
          <tr>
            <td colSpan={3}>BANK NAME</td>
            <td colSpan={4}>{pdfreport?.bank_details?.bank_name}</td>
          </tr>
          <tr>
            <td colSpan={3}>BANK BRANCH</td>
            <td colSpan={4}>{pdfreport?.bank_details?.bank_branch}</td>
          </tr>
          <tr>
            <td colSpan={3}>IFSC CODE</td>
            <td colSpan={4}>{pdfreport?.bank_details?.ifsc_code}</td>
          </tr>
          <tr>
            <th colSpan={2}>PREPARED BY</th>
            <th>{pdfreport?.prepared_by?.name}</th>
            <th colSpan={2}>{pdfreport?.prepared_by?.designation}</th>
            <th colSpan={2}>{pdfreport?.prepared_by?.phone_no}</th>
          </tr>
          <tr>
            <th colSpan={2}>CHECKED BY</th>
            <th>{pdfreport?.checked_by?.name}</th>
            <th colSpan={2}>{pdfreport?.checked_by?.designation}</th>
            <th colSpan={2}>{pdfreport?.checked_by?.phone_no}</th>
          </tr>

          <tr>
            <th colSpan={2}>HOD</th>
            <th>{pdfreport?.hod?.name}</th>
            <th colSpan={2}>{pdfreport?.hod?.designation}</th>
            <th colSpan={2}>{pdfreport?.hod?.phone_no}</th>
          </tr>
        </table>
      </Show>

    </Screen>
  )
}

export default BulkPurchaseForm;



const InputBrand = styled.input`
width:150px;
height:100%;
border: 0.1px solid ${colors.line};
border-bottom-left-radius: 5px;
border-top-left-radius: 5px;
padding:0 0.5rem;
&:focus {
    border-color: #587ef5;
    outline: 0;
  }
  border: 0.1px solid ${colors.line};
`


const DropDownBrand = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  height:fit-content; 
  max-height:15rem;
  overflow-y: scroll;
`


const Ulbrand = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListBrand = styled.li`
  padding: 0.75rem 1rem;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    background-color: #f9fafc;
  }
`


















const SearchIconContainer = styled.div`
 background-color: ${colors.lightAsh};
border: 0.5px solid ${colors.line};
border-bottom-right-radius: 5px;
border-top-right-radius: 5px;
display: flex;
justify-content: center;
align-items: center;
padding:0 0.2rem;
`


const AutoContainer = styled.div`
  position: relative;
  /* width: 20rem; */
  height:100%;
`


const Input = styled.input`
width:250px;
height:100%;
border: 0.1px solid ${colors.line};
/* border-radius: 5px; */
border-bottom-left-radius: 5px;
border-top-left-radius: 5px;
padding:0 0.5rem;
&:focus {
    border-color: #587ef5;
    outline: 0;
  }
  border: 0.1px solid ${colors.line};
  @media only screen and (min-width: 250px) and (max-width: 380px) {
    width: 200px;
  }`


const DropDown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  height:fit-content; 
  max-height:15rem;
  overflow-y: scroll;
`
const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const List = styled.li`
  padding: 0.75rem 1rem;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    background-color: #f9fafc;
  }
`


const FileInputLabel = styled.label`
display: inline-block;
background-color: #e1ecf4;
border: 1px solid #7aa7c7;
color: black;
border-radius: 5px;
cursor: pointer;
padding: 5px;
width:fit-content;
`;

const HiddenFileInput = styled.input`
display: none;
`;




const Screen = styled.div`
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem);
  overflow-y: auto;
  margin-left:0.8rem;
  margin-right: 0.8rem;
`;


const TopContainer = styled.div`
margin: 0.8rem 0;
display:flex;
gap:1rem;
width:100%;
max-width:62rem;
@media only screen and (min-width: 1024px) {width: 62rem;align-items:flex-end;}
@media only screen and (min-width: 250px) and (max-width:1023px) {flex-direction: column;}
`;

const SpecContainer = styled.div`
display:flex;
flex-direction: column;
gap:0.3rem;
`;

const InputContainer = styled.div`
display:flex;
height:45px;
`;

const BrandContainer = styled.div`
display:flex;
flex-direction: column;
gap:0.3rem;
width:fit-content;
`;

const Brand = styled.div`
width: 150px;
height:42px;
border: 0.1px solid ${colors.line};
border-radius: 6px;
background-color: ${colors.lightAsh};
font-size: 12px;
display:flex;
justify-content:center;
align-items:center;
`;

const QtyContainer = styled.div`
display:flex;
flex-direction: column;
gap:0.3rem;
width:fit-content;
input{
width:100%;
max-width: 150px;
height:42px;
border: 0.1px solid ${colors.line};
border-radius: 5px;
padding:0 0.5rem;

&:focus {
    border-color: #587ef5;
    outline: 0;
  }

  /* Hide spinner controls */
   -moz-appearance: textfield; /* Firefox */
    appearance: none; /* Safari and Chrome */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none; /* Safari */
    }

}
`;


const CmntContainer = styled.div`
display:flex;
flex-direction: column;
gap:0.3rem;
width:fit-content;
input{
height:42px;
border: 0.1px solid ${colors.line};
border-radius: 5px;
/* width: 240px; */
width: 200px;
padding:0 0.5rem;
&:focus {
    border-color: #587ef5;
    outline: 0;
  }
}
`;

const SubmitBtn = styled.div`
padding-bottom: ${props => (props.pb ? props.pb : '0')};
button{
  
  padding:0.8rem;
  height:42px;
  background-color:#4669E8;
  color:white;
  border: none;
  border-radius: 5px;
     background-color: ${props => props.disabled ? '#D5E2FE' : '#4669E8'};
     cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
}
`;

const Show = styled.div`
  display: none;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  max-width: 100%;
`;


const TableContainer = styled.div`
width: fit-content;
margin:1rem 0rem;
`;

const Table = styled.table`
border-collapse: collapse;
max-width:1410px;
width: 100%;
tbody tr:nth-child(even) {background:#F8FBFB;}
tbody tr:nth-child(odd) {background:#e9eef4;}
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
`;

const Thead = styled.thead`
background-color: #1d5fd1;
`;

const Cell = styled.th`
border:0.1px solid #cccccc;
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
width:100px;
padding:0.8rem 0.5rem;
font-size:0.8rem;
font-weight: 500;
color: white;
`;

const Tbody = styled.tbody`
/* background-color: #f7f7f7; */
`;

const Td = styled.td`
text-align: center;
vertical-align: middle; 
padding:1rem 0.5rem;
border:0.1px solid #cccccc;
font-size: 0.8rem;
font-weight: 400;
`;

const StyledButton = styled.button`
  background-color: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #39739d;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding:0.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
`;














// old code
// import React, { useContext, useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { useState } from 'react'
// import { FiSearch } from 'react-icons/fi'
// import jsPDF from 'jspdf'
// import styled from 'styled-components'
// import { FaFileUpload } from "react-icons/fa";
// import BulkConfirmModal from '../../../../components/Modals/BulkConfirmModal'
// import BulkListModal from '../../../../components/Modals/BulkListModal'
// import ErrorComponent from '../../../../components/Button/error/ErrorComponent'
// import LoadingSpinner from '../../../../components/Button/Loading/LoadingSpinner'
// import axiosInstance from '../../../../consts/axiosInstance'
// import { AuthContext } from '../../../../consts/AuthContext'
// import { colors } from '../../../../consts/themes'
// import BulkEnquiryModal from '../../../../components/Modals/DetailsModals/BulkEnquiryModal'
// import Swal from 'sweetalert2';




// const BulkPurchaseForm = () => {
//   const navigation = useNavigate();
//   const [isitem, setisItem] = useState();
//   const [form, setForm] = useState([]);
//   const [visible, setVisible] = useState([]);
//   const [brows, setBrows] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [product, setProduct] = useState([]);
//   const [pdfreport, setPdfreport] = useState([]);
//   //id of bulk , retail etc..
//   const purchaseTypeId = JSON.parse(localStorage.getItem('codeid'));

//   //id of lap , mob , tab
//   const product_category_id = JSON.parse(localStorage.getItem('productid'));

//   //1st sub category of lap and desk in corporate segment
//   const categoryId = JSON.parse(localStorage.getItem('categoryId'));

//   const [authUser, setAuthUser] = useContext(AuthContext);

//   //id of lap , mob , tab
//   const productid = JSON.parse(localStorage.getItem('productid'));


//   //new edit
//   //product selected
//   const [productSelected, setProductSelected] = useState('');
//   //selected product title
//   const [selectedProduct, setSelectedProduct] = useState('')
//   //selected product id
//   const [selectedProductId, setSelectedProductId] = useState('')
//   //selected product barnd details
//   const [brand, setBrand] = useState('');
//   //quantity of product
//   const [productQnty, setProductQnty] = useState('')
//   //search dropdown
//   const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
//   const [openBulkSubmitModal, setOpenBulkSubmitModal] = useState(false);
//   //input field
//   const [searchTerm, setSearchTerm] = useState('')
//   const [enquiry, setEnquiry] = useState('');

//   //filter items by productid
//   const filteredById = product.filter(item => item.product_category_id === productid);
//   // console.log("first fiter", filteredById)
//   //filter by search
//   const filteredProducts = filteredById.filter(pdt =>
//     pdt.title.toLowerCase().includes(searchTerm.toLowerCase())
//   );



//   //handle keyborad navigation on search
//   const [selectedProductIndex, setSelectedProductIndex] = useState(-1);
//   //enquiry modal
//   const [openEnquiry, setOpenEnquiry] = useState(false);
//   const [commnets, setCommnets] = useState();


//   const Productlist = async () => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get('getProductList');
//       // console.log(response.data, " response of product list type")
//       setProduct(response.data)
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   async function handlecheck(slno) {
//     try {
//       const response = await axiosInstance.get(`getCustomSaleReportById/${slno}`);
//       setPdfreport(response.data[0]);
//       // console.log('pdf=>', response.data);
//       setTimeout(() => {
//         jsPdfGenerator();
//       }, 1000);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       // Handle error here
//     }
//   }


//   useEffect(() => {
//     const Brands = async () => {
//       try {
//         const response = await axiosInstance.get('getBrands');
//         const brandNames = response.data.map(brand => brand.brand_name);
//         setVisible(brandNames);
//       } catch (error) {
//         console.error('Error fetching brands:', error);
//         // Handle error here
//       }
//     };

//     Brands();
//   }, []);



//   function getTime(iitem) {
//     var formatted = new Intl.DateTimeFormat('en-GB', {
//       year: 'numeric',
//       month: 'long',
//       day: '2-digit',
//     }).format(Date.parse(iitem))

//     return formatted
//   }



//   function jsPdfGenerator() {
//     var doc = new jsPDF()
//     doc.autoTable({
//       html: '#table1',
//       tableLineColor: [0, 0, 0],
//       tableLineWidth: 0.1,
//       theme: 'plain',
//       bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
//       headStyles: { textColor: [255, 0, 0] },
//     })

//     var string = doc.output('datauristring')
//     var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
//     var x = window.open()
//     x.document.open()
//     x.document.write(embed)
//     x.document.close()
//   }


//   //form data
//   const details = [
//     {
//       specification: selectedProduct,
//       brand: brand?.brand_name,
//       qnty: productQnty,
//       productId: selectedProductId,
//     }
//   ]





//   // insert custom sales
//   const customInsertProducts = async () => {
//     try {
//       setLoading(true);
//       const formData = new FormData();

//       formData.append('product_category_id', product_category_id);
//       formData.append('purchaseTypeId', purchaseTypeId);
//       formData.append('category', (product_category_id === 7 || product_category_id === 10) ? categoryId : 8);
//       formData.append('details', JSON.stringify(details));
//       formData.append('user_id', authUser?.user?.id);
//       formData.append('enquiry', enquiry ? enquiry : '');

//       const options = {
//         method: 'POST',
//         url: 'insertCustomSale',
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Accept: 'application/json',
//         },
//         data: formData,
//       };

//       const response = await axiosInstance.request(options);
//       // console.log("🚀 ~ bulk order update response", response.data);
//       if (response.data.code === 200) {
//         Swal.fire({
//           position: 'center',
//           icon: 'success',
//           color: '#4169e1',
//           text: 'Enquiry Submitted Successfully',
//           showConfirmButton: false,
//           timer: 1500
//         });
//         getCustomSales();
//       }



//     } catch (error) {
//       setError(error);
//       console.log("🚀 ~ update cart ", error);
//     } finally {
//       setBrand('');
//       setProductQnty('');
//       setSelectedProduct('');
//       setSearchTerm('');
//       setSelectedProductId('');
//       setEnquiry('');
//       setLoading(false);
//     }
//   };




//   // const customInsertProducts = async () => {

//   //   if (productQnty <= 10) {
//   //     alert("enter quantity more than 10")
//   //   } else {

//   //     try {
//   //       setLoading(true);
//   //       const formData = new FormData();

//   //       formData.append('product_category_id', product_category_id);
//   //       formData.append('purchaseTypeId', purchaseTypeId);
//   //       formData.append('category', (product_category_id === 7 || product_category_id === 10) ? categoryId : 8);
//   //       formData.append('details', JSON.stringify(details));
//   //       formData.append('user_id', authUser?.user?.id);
//   //       formData.append('enquiry', enquiry ? enquiry : '',);
//   //       const options = {
//   //         method: 'POST',
//   //         url: 'insertCustomSale',
//   //         headers: {
//   //           'Content-Type': 'multipart/form-data',
//   //           Accept: 'application/json',
//   //         },
//   //         data: formData,
//   //       };

//   //       await axiosInstance.request(options).then((response) => {
//   //         console.log("🚀 ~ bulk order update response", response);
//   //         getCustomSales();
//   //       })


//   //     } catch (error) {
//   //       setError(error);
//   //       console.log("🚀 ~ update cart ", error);

//   //     } finally {
//   //       setBrand('');
//   //       setProductQnty('');
//   //       setSelectedProduct('');
//   //       setSearchTerm('')
//   //       setSelectedProductId('');
//   //       setEnquiry('');
//   //       setLoading(false);
//   //     }


//   //   }

//   // }


//   const fetchBrand = async (product) => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get(`getBrandById/${product.brandId}`);
//       console.log(response.data, " response of brand");
//       setBrand(response.data);
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };



//   // const modalHandler = (product) => {
//   //   setSelectedProduct(product?.title);
//   //   setSearchTerm(product?.title);
//   //   setSelectedProductId(product?.id);
//   //   fetchBrand(product);
//   // }


//   const picker = (product) => {
//     setSelectedProduct(product?.title);
//     setSearchTerm(product?.title);
//     setSelectedProductId(product?.id);
//     fetchBrand(product);
//     setBrows(false);
//   };


//   const dropdownHandler = (product) => {
//     setIsSearchModalOpen(false);
//     setSearchTerm(product?.title)
//     setSelectedProduct(product?.title);
//     setSelectedProductId(product?.id)
//     fetchBrand(product);
//   }



//   // sales report list
//   const getCustomSales = async () => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get(`getCustomSaleReport/${authUser?.user?.id}/${product_category_id}/${purchaseTypeId}`);
//       console.log(response.data, " response of custom sale report...")
//       // setPurchase(response.data)
//       setForm(response.data)
//       // const quotationId = response.data[0].quotation_details[0].id;
//       // console.log("quotationId", quotationId)

//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   const [selectedQuotationId, setSelectedQuotationId] = useState(null);
//   const [selectedFile, setSelectedFile] = useState(null);


//   const handleFileUpload = (e) => {
//     const fileUserSelected = e.target.files[0];
//     setSelectedFile(fileUserSelected);
//   }



//   const handleUpload = () => {
//     const formData = new FormData();
//     formData.append('purchase_order_pdf', selectedFile ? selectedFile : '');
//     formData.append('id', selectedQuotationId ? selectedQuotationId : '');
//     axiosInstance.post('PurchaseOrderpdfUpload', formData)
//       .then(response => {
//         console.log("response of file upload", response.data.code);
//         if (response.data.code === 200) {
//           Swal.fire({
//             position: 'center',
//             icon: 'success',
//             color: '#4169e1',
//             text: 'Purchase order uploaded successfully',
//             showConfirmButton: false,
//             timer: 1500
//           });
//         }
//       })
//       .catch(error => {
//         const errorMessage = error.response && error.response.data && error.response.data.error
//           ? error.response.data.error
//           : 'An error occurred while uploading the file.';
//         Swal.fire({
//           position: 'center',
//           icon: 'error',
//           color: '#4169e1',
//           text: errorMessage,
//         });
//       });
//   };






//   useEffect(() => {
//     if (selectedFile !== null) {
//       handleUpload();
//     }
//   }, [selectedFile])



//   useEffect(() => {
//     Productlist();
//     getCustomSales();
//   }, [])




//   //new
//   const [reportId, setReportId] = useState(null);

//   const verifyHandler = (id) => {
//     setReportId(id);
//     setOpenBulkSubmitModal(true);
//   }



//   // drop down
//   const filterOptions = (input) => {
//     return filteredById.filter(product => product.title.toLowerCase().includes(input.toLowerCase()));
//   };

//   const filteredOptions = filterOptions(searchTerm);


//   const handleInputChange = (event) => {
//     setSearchTerm(event.target.value);
//     setIsSearchModalOpen(true);
//   };


//   const handleBlur = () => {
//     setIsSearchModalOpen(false);
//   };


//   // const handleBlur = () => {
//   //   // Delay the closing of the dropdown to allow time for the click event to trigger
//   //   setTimeout(() => {
//   //     setIsSearchModalOpen(false);
//   //   }, 200); // Adjust the delay time as needed
//   // };









//   // Handler for keyboard events (arrow keys and enter key)
//   const handleKeyDown = (event) => {
//     if (event.key === 'ArrowDown') {
//       event.preventDefault();
//       setSelectedProductIndex(prevIndex => Math.min(prevIndex + 1, filteredOptions.length - 1));
//     } else if (event.key === 'ArrowUp') {
//       event.preventDefault();
//       setSelectedProductIndex(prevIndex => Math.max(prevIndex - 1, -1));
//     } else if (event.key === 'Enter' && selectedProductIndex !== -1) {
//       event.preventDefault();
//       dropdownHandler(filteredOptions[selectedProductIndex]);
//     }
//   };


//   const allFieldsFilled = brand !== '' && selectedProduct !== '' && enquiry !== '' && productQnty !== '' && productQnty > 0;




//   if (loading) {
//     return <LoadingSpinner />
//   }

//   if (error) {
//     return <ErrorComponent error={error.message} />
//   }
//   return (
//     <Screen>
//       {brows && <BulkListModal close={setBrows} products={filteredById} picker={picker} />}
//       {openEnquiry && <BulkEnquiryModal close={setOpenEnquiry} data={commnets} />}
//       {openBulkSubmitModal && <BulkConfirmModal id={reportId} close={setOpenBulkSubmitModal} refresh={getCustomSales} />}
//       <TopContainer>
//         <SpecContainer>
//           <label>Choose Your Product</label>

//           <InputContainer>
//             {/* <input type="text" placeholder="Enter your password here" /> */}
//             <AutoContainer className="border-box">
//               <Input className="border-box"
//                 type="text"
//                 placeholder="Search Product"
//                 value={searchTerm}
//                 onChange={handleInputChange}
//                 onBlur={handleBlur}
//                 onKeyDown={handleKeyDown}
//               />
//               {isSearchModalOpen && (
//                 <DropDown className="border-box">
//                   <Ul className="border-box">
//                     {filteredOptions.map((item, index) => (
//                       <List
//                         className={index === selectedProductIndex ? 'autocomplete-search-result selected' : 'autocomplete-search-result'}
//                         key={item?.id}
//                         onMouseDown={() => dropdownHandler(item)}
//                       >{item?.title}</List>
//                     ))}
//                   </Ul>
//                 </DropDown>
//               )}
//             </AutoContainer>

//             <SearchIconContainer>
//               <FiSearch size={20} onClick={() => setBrows(true)} />
//             </SearchIconContainer>
//           </InputContainer>
//         </SpecContainer>


//         <BrandContainer>
//           <label>Brand</label>
//           <Brand>{brand && brand.brand_name}</Brand>
//         </BrandContainer>

//         <QtyContainer>
//           <label>Quantity</label>
//           <input type="number" placeholder="quantity"
//             onChange={(e) => setProductQnty(e.target.value)}
//             value={productQnty} />
//         </QtyContainer>

//         <CmntContainer>
//           <label>Enter your specifications</label>
//           <input type="text" placeholder="comments"
//             onChange={(e) => setEnquiry(e.target.value)}
//             value={enquiry} />
//         </CmntContainer>


//         {/* {productQnty > 10 && brand && selectedProduct && enquiry !== '' && (
//           <SubmitBtn><button onClick={() => customInsertProducts()}>Submit</button></SubmitBtn>)} */}




//         {/* <SubmitBtn><button disabled={!productQnty || !brand || !selectedProduct}
//         onClick={() => customInsertProducts()}>Submit</button></SubmitBtn> */}


//         <SubmitBtn disabled={!allFieldsFilled}><button
//           disabled={!allFieldsFilled}
//           onClick={() => customInsertProducts()}>Submit</button></SubmitBtn>



//       </TopContainer>


//       {form?.length != 0 && (
//         <TableWrapper>
//           <TableContainer>
//             <Table>
//               <Thead>

//                 <tr>
//                   <Cell rowSpan={2}>Report Id</Cell>
//                   <Cell rowSpan={2}>Category</Cell>
//                   <Cell colSpan={4}>Enquiry Submitted</Cell>
//                   <Cell colSpan={8}>Quotation</Cell>
//                 </tr>
//                 <tr>
//                   <Cell >Product</Cell>
//                   <Cell >Brand</Cell>
//                   <Cell >Quantity</Cell>
//                   <Cell >Specifications</Cell>
//                   <Cell >Sl.no</Cell>
//                   <Cell >Quotation</Cell>
//                   <Cell >Date</Cell>
//                   {/* <Cell >Purchase Order</Cell> */}
//                   <Cell >Verify</Cell>
//                   <Cell >Status</Cell>
//                   <Cell >Comment</Cell>
//                   <Cell >Purchase Order</Cell>
//                 </tr>
//               </Thead>


//               <Tbody>
//                 {form && form.map((item) => (
//                   <tr>
//                     <Td>{item?.request_id}</Td>
//                     <Td>{item?.category_name}</Td>
//                     <Td>{item?.enquiry_details[0]?.specification}</Td>
//                     <Td>{item?.enquiry_details[0]?.brand}</Td>
//                     <Td>{item?.enquiry_details[0]?.qnty}</Td>
//                     {/* <Td>{item?.enquiry}</Td> */}

//                     <Td><StyledButton onClick={() => [setOpenEnquiry(true), setCommnets(item?.enquiry)]}>
//                       view</StyledButton></Td>

//                     {item?.quotation_details ? (
//                       <>
//                         <Td>{item.quotation_details[0]?.id}</Td>



//                         {/*
//                         <Td>
//                           <StyledButton onClick={() => [setisItem(item.quotation_details[0]?.id),
//                           handlecheck(item.quotation_details[0]?.id),]}>
//                             view</StyledButton>
//                         </Td> */}


//                         <Td>
//                           <StyledButton onClick={() => navigation(`/download-pdf-report/${item.quotation_details[0]?.id}`)}>
//                             view</StyledButton>
//                         </Td>


//                         <Td>{getTime(item.quotation_details[0]?.date)}</Td>


//                         {/* <Td>
//                           <FileInputLabel>
//                             <FaFileUpload size={20} />
//                             <HiddenFileInput type="file"
//                               accept=".pdf"
//                               onChange={handleFileUpload}
//                               onClick={() => setSelectedQuotationId(item?.quotation_details[0]?.id)} />
//                           </FileInputLabel>
//                         </Td> */}

//                         <Td><StyledButton onClick={() => verifyHandler(item?.quotation_details[0]?.id)}>
//                           verify</StyledButton>
//                         </Td>

//                       </>
//                     ) : <>
//                       <Td></Td>
//                       <Td></Td>
//                       <Td></Td>
//                       {/* <Td></Td> */}
//                       <Td></Td>
//                     </>}

//                     {item?.quotation_details ? item?.quotation_details.map((qd) => (
//                       <>
//                         <Td>{qd.status == 1 ? 'Accept' : qd.status == 0 ? 'Reject' : 'Pending'}</Td>
//                         <Td>{qd?.comment}</Td>
//                       </>
//                     )) :
//                       <>
//                         <Td></Td>
//                         <Td></Td>
//                       </>
//                     }

//                     {item?.quotation_details ? (
//                       <>
//                         <Td>
//                           <FileInputLabel>
//                             <FaFileUpload size={20} />
//                             <HiddenFileInput type="file"
//                               accept=".pdf"
//                               onChange={handleFileUpload}
//                               onClick={() => setSelectedQuotationId(item?.quotation_details[0]?.id)} />
//                           </FileInputLabel>
//                         </Td>
//                       </>
//                     ) : <> <Td></Td> </>}
//                   </tr>
//                 ))}
//               </Tbody>

//             </Table>
//           </TableContainer>
//         </TableWrapper>
//       )}

//       <SubmitBtn pb="1.5rem"><button onClick={() => navigation('/sales/productcard')}>Choose Another Product</button></SubmitBtn>

//       <Show>
//         <table id="table1">
//           <tr>
//             <th>No</th>
//             <th>Model</th>
//             <th>Item Description</th>
//             <th>Warranty</th>
//             <th>Qty</th>
//             <th>Unit Price</th>
//             <th>Total Price</th>
//           </tr>
//           {pdfreport?.product?.map((item, index) => (
//             <>
//               <tr>
//                 {/* {console.log("pdfreport", item?.id)} */}
//                 <td>{index + 1}</td>
//                 <td>{item?.model}</td>
//                 <td>{item?.title}</td>
//                 <td>{item?.warranty}yr</td>
//                 <td>{item?.qnty}</td>
//                 <td>{item?.unit_price}</td>
//                 <td>{item?.total}</td>
//               </tr>
//             </>
//           ))}
//           <tr style={{ textAlign: 'center' }}>
//             <td style={{ color: 'red' }} colSpan={6}>
//               TOTAL
//             </td>
//             <td colSpan={1}>{pdfreport?.product_total}</td>
//           </tr>
//           <tr>
//             <th colSpan={3}>TAX-GST</th>
//             <td>CGST</td>
//             <td colSpan={2}>{pdfreport?.tax_charges?.cgst}%</td>
//             <td colSpan={1}>{pdfreport?.tax_charges?.cgst_amount}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}></td>
//             <td>SGST</td>
//             <td colSpan={2}>{pdfreport?.tax_charges?.sgst}%</td>
//             <td colSpan={1}>{pdfreport?.tax_charges?.sgst_amount}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}></td>
//             <td>CESS</td>
//             <td colSpan={2}>{pdfreport?.tax_charges?.cess}%</td>
//             <td colSpan={1}>{pdfreport?.tax_charges?.cess_amount}</td>
//           </tr>
//           <tr>
//             <td colSpan={6}>TOTAL</td>
//             <td colSpan={1}>{pdfreport?.tax_total}</td>
//           </tr>
//           <tr>
//             <th colSpan={7} style={{ textAlign: 'left' }}>
//               DELIVERY CHARGES
//             </th>
//           </tr>
//           <tr style={{ textAlign: 'left' }}>
//             <th colSpan={4}>ITEM</th>
//             <th>QTY</th>
//             <th>UNIT PRICE</th>
//             <th>TOTAL PRICE</th>
//           </tr>
//           {pdfreport?.delivery_charges?.map((item) => (
//             <tr>
//               <td colSpan={4}>{item?.title}</td>
//               <td>{item?.qnty}</td>
//               <td>{item?.unit_price}</td>
//               <td>{item?.total}</td>
//             </tr>
//           ))}
//           <tr>
//             <th colSpan={7} style={{ textAlign: 'left' }}>
//               INSTALLATION CHARGES
//             </th>
//           </tr>
//           <tr style={{ textAlign: 'left' }}>
//             <th colSpan={4}>ITEM</th>
//             <th>QTY</th>
//             <th>UNIT PRICE</th>
//             <th>TOTAL PRICE</th>
//           </tr>
//           {pdfreport?.installation_charges?.map((item) => (
//             <tr>
//               <td colSpan={4}>{item?.title}</td>
//               <td>{item?.qnty}</td>
//               <td>{item?.unit_price}</td>
//               <td>{item?.total}</td>
//             </tr>
//           ))}
//           <tr>
//             <td colSpan={6}>PRODUCT PRICE</td>
//             <td colSpan={1}>{pdfreport.product_total}</td>
//           </tr>
//           <tr>
//             <td colSpan={6}>Taxes</td>
//             <td colSpan={1}>{pdfreport?.tax_total}</td>
//           </tr>
//           <tr>
//             <td colSpan={6}>DELIVERY CHARGES</td>
//             <td colSpan={1}>{pdfreport?.delivery_charges_total}</td>
//           </tr>
//           <tr>
//             <td colSpan={6}>INSTALLATION CHARGES</td>
//             <td colSpan={1}>{pdfreport?.installation_charges_total}</td>
//           </tr>
//           <tr>
//             <td colSpan={6}>GRAND TOTAL</td>
//             <td colSpan={1}>
//               {pdfreport.product_total +
//                 pdfreport?.tax_total +
//                 pdfreport?.delivery_charges_total +
//                 pdfreport?.installation_charges_total}
//             </td>
//           </tr>
//           <tr>
//             <td colSpan={3}>AFTER SERVICE</td>
//             <td colSpan={4}>{pdfreport?.vendor_details}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>PAYMENT</td>
//             <td colSpan={4}>{pdfreport?.payment}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>DELIVERY TIME</td>
//             <td colSpan={4}>{pdfreport?.delivery}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>QUOTATION VALIDITY TIME</td>
//             <td colSpan={4}>{pdfreport?.quotation_validity} days</td>
//           </tr>
//           <tr>
//             <th colSpan={7} style={{ textAlign: 'left' }}>
//               BANK DETAILS
//             </th>
//           </tr>
//           <tr>
//             <td colSpan={3}>ACCOUNT NAME</td>
//             <td colSpan={4}>{pdfreport?.bank_details?.account_name}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>ACCOUNT NO</td>
//             <td colSpan={4}>{pdfreport?.bank_details?.account_no}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>BANK NAME</td>
//             <td colSpan={4}>{pdfreport?.bank_details?.bank_name}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>BANK BRANCH</td>
//             <td colSpan={4}>{pdfreport?.bank_details?.bank_branch}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>IFSC CODE</td>
//             <td colSpan={4}>{pdfreport?.bank_details?.ifsc_code}</td>
//           </tr>
//           <tr>
//             <th colSpan={2}>PREPARED BY</th>
//             <th>{pdfreport?.prepared_by?.name}</th>
//             <th colSpan={2}>{pdfreport?.prepared_by?.designation}</th>
//             <th colSpan={2}>{pdfreport?.prepared_by?.phone_no}</th>
//           </tr>
//           <tr>
//             <th colSpan={2}>CHECKED BY</th>
//             <th>{pdfreport?.checked_by?.name}</th>
//             <th colSpan={2}>{pdfreport?.checked_by?.designation}</th>
//             <th colSpan={2}>{pdfreport?.checked_by?.phone_no}</th>
//           </tr>

//           <tr>
//             <th colSpan={2}>HOD</th>
//             <th>{pdfreport?.hod?.name}</th>
//             <th colSpan={2}>{pdfreport?.hod?.designation}</th>
//             <th colSpan={2}>{pdfreport?.hod?.phone_no}</th>
//           </tr>
//         </table>
//       </Show>

//     </Screen>
//   )
// }

// export default BulkPurchaseForm;


// const SearchIconContainer = styled.div`
//  background-color: ${colors.lightAsh};
// border: 0.5px solid ${colors.line};
// border-bottom-right-radius: 5px;
// border-top-right-radius: 5px;
// display: flex;
// justify-content: center;
// align-items: center;
// padding:0 0.2rem;
// `


// const AutoContainer = styled.div`
//   position: relative;
//   /* width: 20rem; */
//   height:100%;
// `


// const Input = styled.input`
// width:250px;
// height:100%;
// border: 0.1px solid ${colors.line};
// /* border-radius: 5px; */
// border-bottom-left-radius: 5px;
// border-top-left-radius: 5px;
// padding:0 0.5rem;
// &:focus {
//     border-color: #587ef5;
//     outline: 0;
//   }
//   border: 0.1px solid ${colors.line};
//   @media only screen and (min-width: 250px) and (max-width: 380px) {
//     width: 200px;
//   }`


// const DropDown = styled.div`
//   position: absolute;
//   top: 100%;
//   left: 0;
//   width: 100%;
//   box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
//   background-color: #fff;
//   height:fit-content;
//   max-height:15rem;
//   overflow-y: scroll;
// `
// const Ul = styled.ul`
//   margin: 0;
//   padding: 0;
//   list-style: none;
// `

// const List = styled.li`
//   padding: 0.75rem 1rem;
//   cursor: pointer;
//   &:hover,
//   &:focus,
//   &:active {
//     background-color: #f9fafc;
//   }
// `


// const FileInputLabel = styled.label`
// display: inline-block;
// background-color: #e1ecf4;
// border: 1px solid #7aa7c7;
// color: black;
// border-radius: 5px;
// cursor: pointer;
// padding: 5px;
// width:fit-content;
// `;

// const HiddenFileInput = styled.input`
// display: none;
// `;




// const Screen = styled.div`
//   margin-top: 5rem;
//   height: calc(100vh - 5rem);
//   max-height: calc(100vh - 5rem);
//   overflow-y: auto;
//   margin-left:0.8rem;
//   margin-right: 0.8rem;
// `;


// const TopContainer = styled.div`
// margin: 0.8rem 0;
// display:flex;
// gap:1rem;
// width:100%;
// max-width:62rem;
// @media only screen and (min-width: 1024px) {width: 62rem;align-items:flex-end;}
// @media only screen and (min-width: 250px) and (max-width:1023px) {flex-direction: column;}
// `;

// const SpecContainer = styled.div`
// display:flex;
// flex-direction: column;
// gap:0.3rem;
// `;

// const InputContainer = styled.div`
// display:flex;
// height:45px;
// `;

// const BrandContainer = styled.div`
// display:flex;
// flex-direction: column;
// gap:0.3rem;
// width:fit-content;
// `;

// const Brand = styled.div`
// width: 150px;
// height:42px;
// border: 0.1px solid ${colors.line};
// border-radius: 6px;
// background-color: ${colors.lightAsh};
// font-size: 12px;
// display:flex;
// justify-content:center;
// align-items:center;
// `;

// const QtyContainer = styled.div`
// display:flex;
// flex-direction: column;
// gap:0.3rem;
// width:fit-content;
// input{
// width:100%;
// max-width: 150px;
// height:42px;
// border: 0.1px solid ${colors.line};
// border-radius: 5px;
// padding:0 0.5rem;

// &:focus {
//     border-color: #587ef5;
//     outline: 0;
//   }

//   /* Hide spinner controls */
//    -moz-appearance: textfield; /* Firefox */
//     appearance: none; /* Safari and Chrome */
//     &::-webkit-outer-spin-button,
//     &::-webkit-inner-spin-button {
//       display: none; /* Safari */
//     }

// }
// `;


// const CmntContainer = styled.div`
// display:flex;
// flex-direction: column;
// gap:0.3rem;
// width:fit-content;
// input{
// height:42px;
// border: 0.1px solid ${colors.line};
// border-radius: 5px;
// width: 240px;
// padding:0 0.5rem;
// &:focus {
//     border-color: #587ef5;
//     outline: 0;
//   }
// }
// `;

// const SubmitBtn = styled.div`
// padding-bottom: ${props => (props.pb ? props.pb : '0')};
// button{
//   padding:0.8rem;
//   height:42px;
//   background-color:#4669E8;
//   color:white;
//   border: none;
//   border-radius: 5px;
//      background-color: ${props => props.disabled ? '#D5E2FE' : '#4669E8'};
//      cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
// }
// `;

// const Show = styled.div`
//   display: none;
// `;

// const TableWrapper = styled.div`
//   overflow-x: auto;
//   max-width: 100%;
// `;


// const TableContainer = styled.div`
// width: fit-content;
// margin:1rem;
// `;

// const Table = styled.table`
// border-collapse: collapse;
// max-width:1410px;
// width: 100%;
// tbody tr:nth-child(even) {background:#F8FBFB;}
// tbody tr:nth-child(odd) {background:#e9eef4;}
// box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
// `;

// const Thead = styled.thead`
// background-color: #1d5fd1;
// `;

// const Cell = styled.th`
// border:0.1px solid #cccccc;
// box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
// width:100px;
// padding:0.8rem 0.5rem;
// font-size:0.8rem;
// font-weight: 500;
// color: white;
// `;

// const Tbody = styled.tbody`
// /* background-color: #f7f7f7; */
// `;

// const Td = styled.td`
// text-align: center;
// vertical-align: middle;
// padding:1rem 0.5rem;
// border:0.1px solid #cccccc;
// font-size: 0.8rem;
// font-weight: 400;
// `;

// const StyledButton = styled.button`
//   background-color: #e1ecf4;
//   border-radius: 3px;
//   border: 1px solid #7aa7c7;
//   box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
//   box-sizing: border-box;
//   color: #39739d;
//   cursor: pointer;
//   display: inline-block;
//   font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
//   font-size: 13px;
//   font-weight: 400;
//   line-height: 1.15385;
//   margin: 0;
//   outline: none;
//   padding:0.5rem;
//   position: relative;
//   text-align: center;
//   text-decoration: none;
//   user-select: none;
//   -webkit-user-select: none;
//   touch-action: manipulation;
//   vertical-align: baseline;
//   white-space: nowrap;
// `;

























// new old
// import React, { useContext, useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { useState } from 'react'
// import { FiSearch } from 'react-icons/fi'
// import jsPDF from 'jspdf'
// import styled from 'styled-components'
// import { FaFileUpload } from "react-icons/fa";
// import BulkConfirmModal from '../../../../components/Modals/BulkConfirmModal'
// import BulkListModal from '../../../../components/Modals/BulkListModal'
// import ErrorComponent from '../../../../components/Button/error/ErrorComponent'
// import LoadingSpinner from '../../../../components/Button/Loading/LoadingSpinner'
// import axiosInstance from '../../../../consts/axiosInstance'
// import { AuthContext } from '../../../../consts/AuthContext'
// import { colors } from '../../../../consts/themes'
// import BulkEnquiryModal from '../../../../components/Modals/DetailsModals/BulkEnquiryModal'
// import Swal from 'sweetalert2';




// const BulkPurchaseForm = () => {


//   const [brandName, setBrandName] = useState('')
//   console.log("brandName: ", brandName);
//   const [brandSearchModal, setBrandSearchModal] = useState(false)







//   const navigation = useNavigate();
//   const [isitem, setisItem] = useState();
//   const [form, setForm] = useState([]);
//   const [visible, setVisible] = useState([]);
//   const [brows, setBrows] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [product, setProduct] = useState([]);
//   const [pdfreport, setPdfreport] = useState([]);
//   //id of bulk , retail etc..
//   const purchaseTypeId = JSON.parse(localStorage.getItem('codeid'));

//   //id of lap , mob , tab
//   const product_category_id = JSON.parse(localStorage.getItem('productid'));

//   //1st sub category of lap and desk in corporate segment
//   const categoryId = JSON.parse(localStorage.getItem('categoryId'));

//   const [authUser, setAuthUser] = useContext(AuthContext);

//   //id of lap , mob , tab
//   const productid = JSON.parse(localStorage.getItem('productid'));


//   //new edit
//   //product selected
//   const [productSelected, setProductSelected] = useState('');
//   //selected product title
//   const [selectedProduct, setSelectedProduct] = useState('')
//   //selected product id
//   const [selectedProductId, setSelectedProductId] = useState('')
//   //selected product barnd details
//   const [brand, setBrand] = useState('');
//   //quantity of product
//   const [productQnty, setProductQnty] = useState('')
//   //search dropdown
//   const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
//   const [openBulkSubmitModal, setOpenBulkSubmitModal] = useState(false);
//   //input field
//   const [searchTerm, setSearchTerm] = useState('')
//   const [enquiry, setEnquiry] = useState('');

//   //filter items by productid
//   const filteredById = product.filter(item => item.product_category_id === productid);
//   // console.log("first fiter", filteredById)

//   //filter by search
//   const filteredProducts = filteredById.filter(pdt =>
//     pdt.title.toLowerCase().includes(searchTerm.toLowerCase())
//   );



//   //handle keyborad navigation on search
//   const [selectedProductIndex, setSelectedProductIndex] = useState(-1);
//   //enquiry modal
//   const [openEnquiry, setOpenEnquiry] = useState(false);
//   const [commnets, setCommnets] = useState();


//   const Productlist = async () => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get('getProductList');
//       // console.log(response.data, " response of product list type")
//       setProduct(response.data)
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   async function handlecheck(slno) {
//     try {
//       const response = await axiosInstance.get(`getCustomSaleReportById/${slno}`);
//       setPdfreport(response.data[0]);
//       // console.log('pdf=>', response.data);
//       setTimeout(() => {
//         jsPdfGenerator();
//       }, 1000);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   }


//   useEffect(() => {
//     const Brands = async () => {
//       try {
//         const response = await axiosInstance.get('getBrands');
//         const brandNames = response.data.map(brand => brand.brand_name);
//         setVisible(brandNames);
//       } catch (error) {
//         console.error('Error fetching brands:', error);
//       }
//     };

//     Brands();
//   }, []);



//   function getTime(iitem) {
//     var formatted = new Intl.DateTimeFormat('en-GB', {
//       year: 'numeric',
//       month: 'long',
//       day: '2-digit',
//     }).format(Date.parse(iitem))

//     return formatted
//   }



//   function jsPdfGenerator() {
//     var doc = new jsPDF()
//     doc.autoTable({
//       html: '#table1',
//       tableLineColor: [0, 0, 0],
//       tableLineWidth: 0.1,
//       theme: 'plain',
//       bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
//       headStyles: { textColor: [255, 0, 0] },
//     })

//     var string = doc.output('datauristring')
//     var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
//     var x = window.open()
//     x.document.open()
//     x.document.write(embed)
//     x.document.close()
//   }


//   //form data
//   const details = [
//     {
//       specification: selectedProduct?.title,
//       brand: selectedProduct?.brand_name,
//       qnty: productQnty,
//       productId: selectedProductId,
//     }
//   ]


//   // insert custom sales
//   const customInsertProducts = async () => {
//     try {
//       setLoading(true);
//       const formData = new FormData();
//       formData.append('product_category_id', product_category_id);
//       formData.append('purchaseTypeId', purchaseTypeId);
//       formData.append('category', (product_category_id === 7 || product_category_id === 10) ? categoryId : 8);
//       formData.append('details', JSON.stringify(details));
//       formData.append('user_id', authUser?.user?.id);
//       formData.append('enquiry', enquiry ? enquiry : '');
//       const options = {
//         method: 'POST',
//         url: 'insertCustomSale',
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Accept: 'application/json',
//         },
//         data: formData,
//       };

//       const response = await axiosInstance.request(options);
//       if (response.data.code === 200) {
//         Swal.fire({
//           position: 'center',
//           icon: 'success',
//           color: '#4169e1',
//           text: 'Enquiry Submitted Successfully',
//           showConfirmButton: false,
//           timer: 1500
//         });
//         getCustomSales();
//       }
//     } catch (error) {
//       setError(error);
//       console.log("🚀 ~ update cart ", error);
//     } finally {
//       setBrand('');
//       setProductQnty('');
//       setSelectedProduct('');
//       setSearchTerm('');
//       setSelectedProductId('');
//       setEnquiry('');
//       setLoading(false);
//     }
//   };



//   const fetchBrand = async (product) => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get(`getBrandById/${product.brandId}`);
//       console.log(response.data, " response of brand");
//       setBrand(response.data);
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   const picker = (product) => {
//     setSelectedProduct(product);
//     setSearchTerm(product?.title);
//     setSelectedProductId(product);
//     fetchBrand(product);
//     setBrows(false);
//     setBrandName(product?.brand_name);
//   };


//   const dropdownHandler = (product) => {
//     setSelectedProduct(product);
//     setSearchTerm(product?.title)
//     setSelectedProductId(product?.id)
//     setBrandName(product?.brand_name);
//     setBrandSearchModal(false);
//     setIsSearchModalOpen(false);
//     // fetchBrand(product);
//   }



//   // sales report list
//   const getCustomSales = async () => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get(`getCustomSaleReport/${authUser?.user?.id}/${product_category_id}/${purchaseTypeId}`);
//       console.log(response.data, " response of custom sale report...")
//       // setPurchase(response.data)
//       setForm(response.data)
//       // const quotationId = response.data[0].quotation_details[0].id;
//       // console.log("quotationId", quotationId)

//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   const [selectedQuotationId, setSelectedQuotationId] = useState(null);
//   const [selectedFile, setSelectedFile] = useState(null);


//   const handleFileUpload = (e) => {
//     const fileUserSelected = e.target.files[0];
//     setSelectedFile(fileUserSelected);
//   }



//   const handleUpload = () => {
//     const formData = new FormData();
//     formData.append('purchase_order_pdf', selectedFile ? selectedFile : '');
//     formData.append('id', selectedQuotationId ? selectedQuotationId : '');
//     axiosInstance.post('PurchaseOrderpdfUpload', formData)
//       .then(response => {
//         console.log("response of file upload", response.data.code);
//         if (response.data.code === 200) {
//           Swal.fire({
//             position: 'center',
//             icon: 'success',
//             color: '#4169e1',
//             text: 'Purchase order uploaded successfully',
//             showConfirmButton: false,
//             timer: 1500
//           });
//         }
//       })
//       .catch(error => {
//         const errorMessage = error.response && error.response.data && error.response.data.error
//           ? error.response.data.error
//           : 'An error occurred while uploading the file.';
//         Swal.fire({
//           position: 'center',
//           icon: 'error',
//           color: '#4169e1',
//           text: errorMessage,
//         });
//       });
//   };






//   useEffect(() => {
//     if (selectedFile !== null) {
//       handleUpload();
//     }
//   }, [selectedFile])



//   useEffect(() => {
//     Productlist();
//     getCustomSales();
//   }, [])




//   //new
//   const [reportId, setReportId] = useState(null);

//   const verifyHandler = (id) => {
//     setReportId(id);
//     setOpenBulkSubmitModal(true);
//   }



//   // drop down
//   const filterOptions = (input) => {
//     return filteredById.filter(product => product.title.toLowerCase().includes(input.toLowerCase()));
//   };

//   const filteredOptions = filterOptions(searchTerm);



//   const handleInputChange = (event) => {
//     setSearchTerm(event.target.value);
//     setIsSearchModalOpen(true);
//   };


//   const handleBlur = () => {
//     setIsSearchModalOpen(false);
//     setBrandSearchModal(false);
//   };







//   // drop down brand
//   const filterBrand = (input) => {
//     return filteredById.filter(product => product.brand_name.toLowerCase().includes(input.toLowerCase()));
//   };
//   const filteredBrandOptions = filterBrand(brandName)

//   const handleBrandChange = (event) => {
//     setBrandSearchModal(true);
//     setBrandName(event.target.value);
//   }








//   // Handler for keyboard events (arrow keys and enter key)
//   const handleKeyDown = (event) => {
//     if (event.key === 'ArrowDown') {
//       event.preventDefault();
//       setSelectedProductIndex(prevIndex => Math.min(prevIndex + 1, filteredOptions.length - 1));
//     } else if (event.key === 'ArrowUp') {
//       event.preventDefault();
//       setSelectedProductIndex(prevIndex => Math.max(prevIndex - 1, -1));
//     } else if (event.key === 'Enter' && selectedProductIndex !== -1) {
//       event.preventDefault();
//       dropdownHandler(filteredOptions[selectedProductIndex]);
//     }
//   };


//   // const allFieldsFilled = brand !== '' && selectedProduct !== '' && enquiry !== '' && productQnty !== '' && productQnty > 0;



//   const allFieldsFilled = selectedProduct !== '' && searchTerm === selectedProduct.title && brandName === selectedProduct.brand_name &&
//     enquiry !== '' && productQnty !== '' && productQnty > 0;


//   if (loading) {
//     return <LoadingSpinner />
//   }

//   if (error) {
//     return <ErrorComponent error={error.message} />
//   }
//   return (
//     <Screen>
//       {brows && <BulkListModal close={setBrows} products={filteredById} picker={picker} />}
//       {openEnquiry && <BulkEnquiryModal close={setOpenEnquiry} data={commnets} />}
//       {openBulkSubmitModal && <BulkConfirmModal id={reportId} close={setOpenBulkSubmitModal} refresh={getCustomSales} />}
//       <TopContainer>
//         <SpecContainer>
//           <label>Choose Your Product</label>

//           <InputContainer>
//             {/* <input type="text" placeholder="Enter your password here" /> */}
//             <AutoContainer className="border-box">
//               <Input className="border-box"
//                 type="text"
//                 placeholder="Search Product"
//                 value={searchTerm}
//                 onChange={handleInputChange}
//                 onBlur={handleBlur}
//                 onKeyDown={handleKeyDown}
//               />
//               {isSearchModalOpen && (
//                 <DropDown className="border-box">
//                   <Ul className="border-box">
//                     {filteredOptions.map((item, index) => (
//                       <List
//                         className={index === selectedProductIndex ? 'autocomplete-search-result selected' : 'autocomplete-search-result'}
//                         key={item?.id}
//                         onMouseDown={() => dropdownHandler(item)}
//                       >{item?.title}</List>
//                     ))}
//                   </Ul>
//                 </DropDown>
//               )}
//             </AutoContainer>

//             <SearchIconContainer>
//               <FiSearch size={20} onClick={() => setBrows(true)} />
//             </SearchIconContainer>
//           </InputContainer>
//         </SpecContainer>






//         <InputContainer>
//           <AutoContainer className="border-box">
//             <InputBrand className="border-box"
//               type="text"
//               placeholder="Search brand"
//               value={brandName}
//               onChange={handleBrandChange}
//               onBlur={handleBlur}
//               onKeyDown={handleKeyDown}
//             />
//             {brandSearchModal && (
//               <DropDownBrand className="border-box">
//                 <Ulbrand className="border-box">
//                   {filteredBrandOptions.map((item, index) => (
//                     <ListBrand
//                       className={index === selectedProductIndex ? 'autocomplete-search-result selected' : 'autocomplete-search-result'}
//                       key={item?.id}
//                       onMouseDown={() => dropdownHandler(item)}
//                     >{item?.title}</ListBrand>
//                   ))}
//                 </Ulbrand>
//               </DropDownBrand>
//             )}
//           </AutoContainer>
//           <SearchIconContainer>
//             <FiSearch size={20} onClick={() => setBrows(true)} />
//           </SearchIconContainer>
//         </InputContainer>




//         <QtyContainer>
//           <label>Quantity</label>
//           <input type="number" placeholder="quantity"
//             onChange={(e) => setProductQnty(e.target.value)}
//             value={productQnty} />
//         </QtyContainer>

//         <CmntContainer>
//           <label>Enter your specifications</label>
//           <input type="text" placeholder="comments"
//             onChange={(e) => setEnquiry(e.target.value)}
//             value={enquiry} />
//         </CmntContainer>



//         <SubmitBtn disabled={!allFieldsFilled}><button
//           disabled={!allFieldsFilled}
//           onClick={() => customInsertProducts()}>Submit</button></SubmitBtn>

//       </TopContainer>




//       {form?.length != 0 && (
//         <TableWrapper>
//           <TableContainer>
//             <Table>
//               <Thead>

//                 <tr>
//                   <Cell rowSpan={2}>Report Id</Cell>
//                   <Cell rowSpan={2}>Category</Cell>
//                   <Cell colSpan={4}>Enquiry Submitted</Cell>
//                   <Cell colSpan={8}>Quotation</Cell>
//                 </tr>
//                 <tr>
//                   <Cell >Product</Cell>
//                   <Cell >Brand</Cell>
//                   <Cell >Quantity</Cell>
//                   <Cell >Specifications</Cell>
//                   <Cell >Sl.no</Cell>
//                   <Cell >Quotation</Cell>
//                   <Cell >Date</Cell>
//                   {/* <Cell >Purchase Order</Cell> */}
//                   <Cell >Verify</Cell>
//                   <Cell >Status</Cell>
//                   <Cell >Comment</Cell>
//                   <Cell >Purchase Order</Cell>
//                 </tr>
//               </Thead>


//               <Tbody>
//                 {form && form.map((item) => (
//                   <tr>
//                     <Td>{item?.request_id}</Td>
//                     <Td>{item?.category_name}</Td>
//                     <Td>{item?.enquiry_details[0]?.specification}</Td>
//                     <Td>{item?.enquiry_details[0]?.brand}</Td>
//                     <Td>{item?.enquiry_details[0]?.qnty}</Td>
//                     {/* <Td>{item?.enquiry}</Td> */}

//                     <Td><StyledButton onClick={() => [setOpenEnquiry(true), setCommnets(item?.enquiry)]}>
//                       view</StyledButton></Td>

//                     {item?.quotation_details ? (
//                       <>
//                         <Td>{item.quotation_details[0]?.id}</Td>



//                         {/*
//                         <Td>
//                           <StyledButton onClick={() => [setisItem(item.quotation_details[0]?.id),
//                           handlecheck(item.quotation_details[0]?.id),]}>
//                             view</StyledButton>
//                         </Td> */}


//                         <Td>
//                           <StyledButton onClick={() => navigation(`/download-pdf-report/${item.quotation_details[0]?.id}`)}>
//                             view</StyledButton>
//                         </Td>


//                         <Td>{getTime(item.quotation_details[0]?.date)}</Td>


//                         {/* <Td>
//                           <FileInputLabel>
//                             <FaFileUpload size={20} />
//                             <HiddenFileInput type="file"
//                               accept=".pdf"
//                               onChange={handleFileUpload}
//                               onClick={() => setSelectedQuotationId(item?.quotation_details[0]?.id)} />
//                           </FileInputLabel>
//                         </Td> */}

//                         <Td><StyledButton onClick={() => verifyHandler(item?.quotation_details[0]?.id)}>
//                           verify</StyledButton>
//                         </Td>

//                       </>
//                     ) : <>
//                       <Td></Td>
//                       <Td></Td>
//                       <Td></Td>
//                       {/* <Td></Td> */}
//                       <Td></Td>
//                     </>}

//                     {item?.quotation_details ? item?.quotation_details.map((qd) => (
//                       <>
//                         <Td>{qd.status == 1 ? 'Accept' : qd.status == 0 ? 'Reject' : 'Pending'}</Td>
//                         <Td>{qd?.comment}</Td>
//                       </>
//                     )) :
//                       <>
//                         <Td></Td>
//                         <Td></Td>
//                       </>
//                     }

//                     {item?.quotation_details ? (
//                       <>
//                         <Td>
//                           <FileInputLabel>
//                             <FaFileUpload size={20} />
//                             <HiddenFileInput type="file"
//                               accept=".pdf"
//                               onChange={handleFileUpload}
//                               onClick={() => setSelectedQuotationId(item?.quotation_details[0]?.id)} />
//                           </FileInputLabel>
//                         </Td>
//                       </>
//                     ) : <> <Td></Td> </>}
//                   </tr>
//                 ))}
//               </Tbody>

//             </Table>
//           </TableContainer>
//         </TableWrapper>
//       )}

//       <SubmitBtn pb="1.5rem"><button onClick={() => navigation('/sales/productcard')}>Choose Another Product</button></SubmitBtn>

//       <Show>
//         <table id="table1">
//           <tr>
//             <th>No</th>
//             <th>Model</th>
//             <th>Item Description</th>
//             <th>Warranty</th>
//             <th>Qty</th>
//             <th>Unit Price</th>
//             <th>Total Price</th>
//           </tr>
//           {pdfreport?.product?.map((item, index) => (
//             <>
//               <tr>
//                 {/* {console.log("pdfreport", item?.id)} */}
//                 <td>{index + 1}</td>
//                 <td>{item?.model}</td>
//                 <td>{item?.title}</td>
//                 <td>{item?.warranty}yr</td>
//                 <td>{item?.qnty}</td>
//                 <td>{item?.unit_price}</td>
//                 <td>{item?.total}</td>
//               </tr>
//             </>
//           ))}
//           <tr style={{ textAlign: 'center' }}>
//             <td style={{ color: 'red' }} colSpan={6}>
//               TOTAL
//             </td>
//             <td colSpan={1}>{pdfreport?.product_total}</td>
//           </tr>
//           <tr>
//             <th colSpan={3}>TAX-GST</th>
//             <td>CGST</td>
//             <td colSpan={2}>{pdfreport?.tax_charges?.cgst}%</td>
//             <td colSpan={1}>{pdfreport?.tax_charges?.cgst_amount}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}></td>
//             <td>SGST</td>
//             <td colSpan={2}>{pdfreport?.tax_charges?.sgst}%</td>
//             <td colSpan={1}>{pdfreport?.tax_charges?.sgst_amount}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}></td>
//             <td>CESS</td>
//             <td colSpan={2}>{pdfreport?.tax_charges?.cess}%</td>
//             <td colSpan={1}>{pdfreport?.tax_charges?.cess_amount}</td>
//           </tr>
//           <tr>
//             <td colSpan={6}>TOTAL</td>
//             <td colSpan={1}>{pdfreport?.tax_total}</td>
//           </tr>
//           <tr>
//             <th colSpan={7} style={{ textAlign: 'left' }}>
//               DELIVERY CHARGES
//             </th>
//           </tr>
//           <tr style={{ textAlign: 'left' }}>
//             <th colSpan={4}>ITEM</th>
//             <th>QTY</th>
//             <th>UNIT PRICE</th>
//             <th>TOTAL PRICE</th>
//           </tr>
//           {pdfreport?.delivery_charges?.map((item) => (
//             <tr>
//               <td colSpan={4}>{item?.title}</td>
//               <td>{item?.qnty}</td>
//               <td>{item?.unit_price}</td>
//               <td>{item?.total}</td>
//             </tr>
//           ))}
//           <tr>
//             <th colSpan={7} style={{ textAlign: 'left' }}>
//               INSTALLATION CHARGES
//             </th>
//           </tr>
//           <tr style={{ textAlign: 'left' }}>
//             <th colSpan={4}>ITEM</th>
//             <th>QTY</th>
//             <th>UNIT PRICE</th>
//             <th>TOTAL PRICE</th>
//           </tr>
//           {pdfreport?.installation_charges?.map((item) => (
//             <tr>
//               <td colSpan={4}>{item?.title}</td>
//               <td>{item?.qnty}</td>
//               <td>{item?.unit_price}</td>
//               <td>{item?.total}</td>
//             </tr>
//           ))}
//           <tr>
//             <td colSpan={6}>PRODUCT PRICE</td>
//             <td colSpan={1}>{pdfreport.product_total}</td>
//           </tr>
//           <tr>
//             <td colSpan={6}>Taxes</td>
//             <td colSpan={1}>{pdfreport?.tax_total}</td>
//           </tr>
//           <tr>
//             <td colSpan={6}>DELIVERY CHARGES</td>
//             <td colSpan={1}>{pdfreport?.delivery_charges_total}</td>
//           </tr>
//           <tr>
//             <td colSpan={6}>INSTALLATION CHARGES</td>
//             <td colSpan={1}>{pdfreport?.installation_charges_total}</td>
//           </tr>
//           <tr>
//             <td colSpan={6}>GRAND TOTAL</td>
//             <td colSpan={1}>
//               {pdfreport.product_total +
//                 pdfreport?.tax_total +
//                 pdfreport?.delivery_charges_total +
//                 pdfreport?.installation_charges_total}
//             </td>
//           </tr>
//           <tr>
//             <td colSpan={3}>AFTER SERVICE</td>
//             <td colSpan={4}>{pdfreport?.vendor_details}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>PAYMENT</td>
//             <td colSpan={4}>{pdfreport?.payment}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>DELIVERY TIME</td>
//             <td colSpan={4}>{pdfreport?.delivery}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>QUOTATION VALIDITY TIME</td>
//             <td colSpan={4}>{pdfreport?.quotation_validity} days</td>
//           </tr>
//           <tr>
//             <th colSpan={7} style={{ textAlign: 'left' }}>
//               BANK DETAILS
//             </th>
//           </tr>
//           <tr>
//             <td colSpan={3}>ACCOUNT NAME</td>
//             <td colSpan={4}>{pdfreport?.bank_details?.account_name}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>ACCOUNT NO</td>
//             <td colSpan={4}>{pdfreport?.bank_details?.account_no}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>BANK NAME</td>
//             <td colSpan={4}>{pdfreport?.bank_details?.bank_name}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>BANK BRANCH</td>
//             <td colSpan={4}>{pdfreport?.bank_details?.bank_branch}</td>
//           </tr>
//           <tr>
//             <td colSpan={3}>IFSC CODE</td>
//             <td colSpan={4}>{pdfreport?.bank_details?.ifsc_code}</td>
//           </tr>
//           <tr>
//             <th colSpan={2}>PREPARED BY</th>
//             <th>{pdfreport?.prepared_by?.name}</th>
//             <th colSpan={2}>{pdfreport?.prepared_by?.designation}</th>
//             <th colSpan={2}>{pdfreport?.prepared_by?.phone_no}</th>
//           </tr>
//           <tr>
//             <th colSpan={2}>CHECKED BY</th>
//             <th>{pdfreport?.checked_by?.name}</th>
//             <th colSpan={2}>{pdfreport?.checked_by?.designation}</th>
//             <th colSpan={2}>{pdfreport?.checked_by?.phone_no}</th>
//           </tr>

//           <tr>
//             <th colSpan={2}>HOD</th>
//             <th>{pdfreport?.hod?.name}</th>
//             <th colSpan={2}>{pdfreport?.hod?.designation}</th>
//             <th colSpan={2}>{pdfreport?.hod?.phone_no}</th>
//           </tr>
//         </table>
//       </Show>

//     </Screen>
//   )
// }

// export default BulkPurchaseForm;



// const InputBrand = styled.input`
// width:150px;
// height:100%;
// border: 0.1px solid ${colors.line};
// border-bottom-left-radius: 5px;
// border-top-left-radius: 5px;
// padding:0 0.5rem;
// &:focus {
//     border-color: #587ef5;
//     outline: 0;
//   }
//   border: 0.1px solid ${colors.line};
// `


// const DropDownBrand = styled.div`
//   position: absolute;
//   top: 100%;
//   left: 0;
//   width: 100%;
//   box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
//   background-color: #fff;
//   height:fit-content;
//   max-height:15rem;
//   overflow-y: scroll;
// `


// const Ulbrand = styled.ul`
//   margin: 0;
//   padding: 0;
//   list-style: none;
// `

// const ListBrand = styled.li`
//   padding: 0.75rem 1rem;
//   cursor: pointer;
//   &:hover,
//   &:focus,
//   &:active {
//     background-color: #f9fafc;
//   }
// `


















// const SearchIconContainer = styled.div`
//  background-color: ${colors.lightAsh};
// border: 0.5px solid ${colors.line};
// border-bottom-right-radius: 5px;
// border-top-right-radius: 5px;
// display: flex;
// justify-content: center;
// align-items: center;
// padding:0 0.2rem;
// `


// const AutoContainer = styled.div`
//   position: relative;
//   /* width: 20rem; */
//   height:100%;
// `


// const Input = styled.input`
// width:250px;
// height:100%;
// border: 0.1px solid ${colors.line};
// /* border-radius: 5px; */
// border-bottom-left-radius: 5px;
// border-top-left-radius: 5px;
// padding:0 0.5rem;
// &:focus {
//     border-color: #587ef5;
//     outline: 0;
//   }
//   border: 0.1px solid ${colors.line};
//   @media only screen and (min-width: 250px) and (max-width: 380px) {
//     width: 200px;
//   }`


// const DropDown = styled.div`
//   position: absolute;
//   top: 100%;
//   left: 0;
//   width: 100%;
//   box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
//   background-color: #fff;
//   height:fit-content;
//   max-height:15rem;
//   overflow-y: scroll;
// `
// const Ul = styled.ul`
//   margin: 0;
//   padding: 0;
//   list-style: none;
// `

// const List = styled.li`
//   padding: 0.75rem 1rem;
//   cursor: pointer;
//   &:hover,
//   &:focus,
//   &:active {
//     background-color: #f9fafc;
//   }
// `


// const FileInputLabel = styled.label`
// display: inline-block;
// background-color: #e1ecf4;
// border: 1px solid #7aa7c7;
// color: black;
// border-radius: 5px;
// cursor: pointer;
// padding: 5px;
// width:fit-content;
// `;

// const HiddenFileInput = styled.input`
// display: none;
// `;




// const Screen = styled.div`
//   margin-top: 5rem;
//   height: calc(100vh - 5rem);
//   max-height: calc(100vh - 5rem);
//   overflow-y: auto;
//   margin-left:0.8rem;
//   margin-right: 0.8rem;
// `;


// const TopContainer = styled.div`
// margin: 0.8rem 0;
// display:flex;
// gap:1rem;
// width:100%;
// max-width:62rem;
// @media only screen and (min-width: 1024px) {width: 62rem;align-items:flex-end;}
// @media only screen and (min-width: 250px) and (max-width:1023px) {flex-direction: column;}
// `;

// const SpecContainer = styled.div`
// display:flex;
// flex-direction: column;
// gap:0.3rem;
// `;

// const InputContainer = styled.div`
// display:flex;
// height:45px;
// `;

// const BrandContainer = styled.div`
// display:flex;
// flex-direction: column;
// gap:0.3rem;
// width:fit-content;
// `;

// const Brand = styled.div`
// width: 150px;
// height:42px;
// border: 0.1px solid ${colors.line};
// border-radius: 6px;
// background-color: ${colors.lightAsh};
// font-size: 12px;
// display:flex;
// justify-content:center;
// align-items:center;
// `;

// const QtyContainer = styled.div`
// display:flex;
// flex-direction: column;
// gap:0.3rem;
// width:fit-content;
// input{
// width:100%;
// max-width: 150px;
// height:42px;
// border: 0.1px solid ${colors.line};
// border-radius: 5px;
// padding:0 0.5rem;

// &:focus {
//     border-color: #587ef5;
//     outline: 0;
//   }

//   /* Hide spinner controls */
//    -moz-appearance: textfield; /* Firefox */
//     appearance: none; /* Safari and Chrome */
//     &::-webkit-outer-spin-button,
//     &::-webkit-inner-spin-button {
//       display: none; /* Safari */
//     }

// }
// `;


// const CmntContainer = styled.div`
// display:flex;
// flex-direction: column;
// gap:0.3rem;
// width:fit-content;
// input{
// height:42px;
// border: 0.1px solid ${colors.line};
// border-radius: 5px;
// /* width: 240px; */
// width: 200px;
// padding:0 0.5rem;
// &:focus {
//     border-color: #587ef5;
//     outline: 0;
//   }
// }
// `;

// const SubmitBtn = styled.div`
// padding-bottom: ${props => (props.pb ? props.pb : '0')};
// button{
//   padding:0.8rem;
//   height:42px;
//   background-color:#4669E8;
//   color:white;
//   border: none;
//   border-radius: 5px;
//      background-color: ${props => props.disabled ? '#D5E2FE' : '#4669E8'};
//      cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
// }
// `;

// const Show = styled.div`
//   display: none;
// `;

// const TableWrapper = styled.div`
//   overflow-x: auto;
//   max-width: 100%;
// `;


// const TableContainer = styled.div`
// width: fit-content;
// margin:1rem;
// `;

// const Table = styled.table`
// border-collapse: collapse;
// max-width:1410px;
// width: 100%;
// tbody tr:nth-child(even) {background:#F8FBFB;}
// tbody tr:nth-child(odd) {background:#e9eef4;}
// box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
// `;

// const Thead = styled.thead`
// background-color: #1d5fd1;
// `;

// const Cell = styled.th`
// border:0.1px solid #cccccc;
// box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
// width:100px;
// padding:0.8rem 0.5rem;
// font-size:0.8rem;
// font-weight: 500;
// color: white;
// `;

// const Tbody = styled.tbody`
// /* background-color: #f7f7f7; */
// `;

// const Td = styled.td`
// text-align: center;
// vertical-align: middle;
// padding:1rem 0.5rem;
// border:0.1px solid #cccccc;
// font-size: 0.8rem;
// font-weight: 400;
// `;

// const StyledButton = styled.button`
//   background-color: #e1ecf4;
//   border-radius: 3px;
//   border: 1px solid #7aa7c7;
//   box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
//   box-sizing: border-box;
//   color: #39739d;
//   cursor: pointer;
//   display: inline-block;
//   font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
//   font-size: 13px;
//   font-weight: 400;
//   line-height: 1.15385;
//   margin: 0;
//   outline: none;
//   padding:0.5rem;
//   position: relative;
//   text-align: center;
//   text-decoration: none;
//   user-select: none;
//   -webkit-user-select: none;
//   touch-action: manipulation;
//   vertical-align: baseline;
//   white-space: nowrap;
// `;








