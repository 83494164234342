import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
import ErrorComponent from '../../../components/Button/error/ErrorComponent';
import { colors } from '../../../consts/themes';
import Help from '../../../components/Help/help';
import axiosInstance from '../../../consts/axiosInstance';
import { AuthContext } from '../../../consts/AuthContext';




const CustomPcComponentsList = () => {

  const navigation = useNavigate();

  const componentId = JSON.parse(localStorage.getItem("componentsTypeId"));

  const [authUser, setAuthUser] = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [parentProductIds, setParentProductIds] = useState([]);




  const getCartProducts = async () => {
    setLoading(true);

    try {
      const response = await axiosInstance.get(`getbuildpc/${authUser?.user?.id}`);
      setCartData(response.data[0]?.products);

      if (response.data && Array.isArray(response.data)) {
        // Extract product ids and parent product ids from the API response
        const productIds = [];
        const parentIds = [];

        response.data.forEach(item => {
          if (item.products && Array.isArray(item.products)) {
            item.products.forEach(product => {
              productIds.push(product.product_id);
              parentIds.push(product.parent_product_id);
            });
          }
        });

        // Update state with the extracted ids
        setProductIds(productIds);
        setParentProductIds(parentIds);
      } else {
        console.error('Invalid API response:', response.data);
      }

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getCartProducts();
  }, []);



  const getAllProducts = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`allproductsrelated`);
      setAllProducts(response.data)
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    getAllProducts();
  }, [])


  // console.log("allProducts", allProducts)
  // console.log("productIds", productIds)
  // console.log("parentProductIds", parentProductIds)


  const filterDataByComponentTypeId = (componentTypeId) => {

    if (allProducts.length > 0) {
      // return allProducts.filter(item => item.componentTypeId == componentTypeId);
      const filteredProducts = allProducts.filter(product => {
        const relatedProducts = JSON.parse(product.related_products);
        return relatedProducts.some(id => productIds.includes(id)) || relatedProducts.length == 0;
      });

      return filteredProducts.filter(item => item.componentTypeId == componentTypeId);

    } else {
      return [];
    }
  };


  // Filter data based on component type ID
  const filteredData = filterDataByComponentTypeId(componentId);
  // console.log("filteredData", filteredData)







  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error} />
  }


  return (
    <Screen>

      {filteredData?.length === 0 ? <NoDisplay>No Products to display</NoDisplay> : null}


      <ScreenContent>
        {filteredData?.map((item) => (
          <CardImg key={item.id}>
            <Mappingcards
              onClick={() =>
                localStorage.setItem("Productid", JSON.stringify(item?.productId))
              }
            >


              {/* <img src={item.brand_image}></img> */}
              <Maproute onClick={() => navigation("/custompc/product/details")}>
                <Lapimage>
                  <img src={JSON.parse(item?.productImages)[0]}></img>
                </Lapimage>

                <Laptext>{item.title.slice(0, 15)} ...</Laptext>



                <Price>₹ {item.offer_price}</Price>

                <Offer>
                  <Offerwrong>₹{item.price}</Offerwrong>
                </Offer>


                <Coin_warrenty>
                  <Warranty>
                    {/* <img src={warranty}></img> */}
                    {item.warranty} year warranty
                  </Warranty>

                  <Coin>
                    {/* <img src={coin}></img> */}
                    {item.reward_points}Clike points
                  </Coin>
                </Coin_warrenty>

                <Name2>{item.name2}</Name2>
                <Demand
                  isOutOfStock={item?.stock === 0}
                  isLowStock={item?.stock > 0 && item?.stock < 4}
                >
                  {item?.stock === 0 ? (
                    <p>Out Of Stock</p>
                  ) : item?.stock < 4 ? (
                    <p>Only {item?.stock} left</p>  // Show stock message for low stock
                  ) : (
                    <p>Available</p>  // Show default stock status for other cases
                  )}
                </Demand>
              </Maproute>

            </Mappingcards>
          </CardImg>
        ))}
      </ScreenContent>
      <Help />
    </Screen>


  )
}

export default CustomPcComponentsList;








const NoDisplay = styled.div`
  font-style: italic;
  color: ${colors.ash};
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top:3rem;
`;


const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0;
  padding-top:5rem;
  height: calc(100vh - 5rem);
`;


const ScreenContent = styled.div`
display: grid;
grid-template-columns: repeat(12, minmax(0, 1fr));
overflow-y: scroll;
margin-top:1rem;
padding-bottom:2rem;
@media only screen and (min-width: 250px) and (max-width: 350px) {gap: 0.5rem;}
@media only screen and (min-width: 351px) and (max-width: 768px) {gap: 1rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {gap: 1.5rem;}
@media only screen and (min-width: 1024px) {gap: 2rem;}
`;


const CardImg = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
@media only screen and (min-width: 250px) and (max-width: 569px) {grid-column: span 12 / span 12;}
@media only screen and (min-width: 570px) and (max-width: 887px) {grid-column: span 6 / span 6;}
@media only screen and (min-width: 888px) and (max-width: 1199px) {grid-column: span 4 / span 4;}
@media only screen and (min-width: 1200px) {grid-column: span 3 / span 3;}
border: 1px solid ${colors.line};
padding:0.5rem;
border-radius:5px;
`;

const Mappingcards = styled.div`
max-width:15rem;
width:15rem;
height:auto;
`;


const Image_one = styled.div`
display:flex;
justify-content: flex-end;
`;


const FavDiv = styled.div`
img{
  height:1rem;
  width:1.2rem;
}
`;


const Lapimage = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 190px;
    height: 190px;
    object-fit: contain;
  }
`;

const Laptext = styled.div`
margin-top: 0.4rem;
display: flex;
justify-content: center;
text-align: center;
font-family: Arial;
font-size: 1.125rem;
font-style: normal;
font-weight: 400;
line-height:1.575rem;
`;




const Offer = styled.div`
margin-top: 0.7rem;
display: flex;
justify-content: center;
font-family: Arial;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height:0.8775rem;
color: ${colors.light_grey};
`;



const Offerwrong = styled.div`
  text-decoration-line: line-through;
`;



const Offercorrect = styled.div`
  font-size: 12px;
  color: red;
`;


const Price = styled.div`
margin-top: 0.4rem;
display: flex;
justify-content: center;
align-items: center;
color: ${colors.blue};
font-family: Arial;
font-size: 1.25rem;
font-style: normal;
font-weight: 700;
line-height:1.4625rem;
`;




const Coin_warrenty = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 12px;
  align-self: center;
`;



const Coin = styled.div`
  color: ${colors.blue};
  font-size: 12px;
  display: flex;
  text-align: center;

  img {
    width: 20px;
    height: 20px;
    padding-inline: 5px;

    @media (max-width: 1880px) {
      padding-inline: 4px;
    }
  }
`;
const Warranty = styled.div`
  font-size: 12px;
  display: flex;
  text-align: center;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
    padding-inline: 5px;
  }
`;

const Demand = styled.div`
  margin-top: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: ${(props) => {
    if (props.isOutOfStock) return "red";
    // if (props.isLowStock) return "red";
    return colors.green;  // Default color for normal stock
  }};
    margin: 0;
    text-align: center;
    font-family: Arial;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.17rem;
  }
`;

const Name2 = styled.div`
  margin-top: 10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  color: ${colors.green};
`;
export const Mapping = styled.div`  background-color:pink`;
export const Maproute = styled.div``;






