import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import axiosInstance from '../../../../../consts/axiosInstance';
import { colors } from '../../../../../consts/themes';

const LoadingChart = () => {

  const [chart, setChart] = useState([]);


  const getPowerChart = async () => {
    try {
      const response = await axiosInstance.get('loadingChart');
      setChart(response.data)
    } catch (error) {
      // console.log(error.message)
    }
  };



  useEffect(() => {
    getPowerChart();
  }, [])


  return (
    <>
      {chart?.length != 0 && (
        <TableWrapper>
          <TableContainer>
            <Table>
              <Thead>
                <tr>
                  <Cell >Product</Cell>
                  <Cell >Connect Load</Cell>
                  <Cell >Selecting Battery Numbers</Cell>
                </tr>
              </Thead>
              <Tbody>
                {chart.length > 0 &&
                  chart.map((item) => (
                    <tr key={item.id}>
                      <Td>{item?.product}</Td>
                      <Td>{item?.connected_load}</Td>
                      <Td>{item?.battery_no}</Td>
                    </tr>
                  ))
                }
              </Tbody>
            </Table>
          </TableContainer>
        </TableWrapper>
      )}


    </>
  )
}

export default LoadingChart;




const Screen = styled.div`
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem);
  overflow-y: auto;

  margin-left:0.8rem;
  margin-right: 0.8rem;
`;



const TableWrapper = styled.div`
  overflow-x: auto;
  max-width: 100%;
`;


const TableContainer = styled.div`
width: fit-content;
margin:1rem;
`;



const Table = styled.table`
border-collapse: collapse;
max-width:1410px;
width: 100%;
tbody tr:nth-child(even) {background:#F8FBFB;}
tbody tr:nth-child(odd) {background:#e9eef4;}
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
`;


const Thead = styled.thead`
background-color: #1d5fd1;
`;


const Cell = styled.th`
border:0.1px solid #cccccc;
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
width:170px;
padding:0.8rem 0.5rem;
font-size:0.8rem;
font-weight: 500;
color: white;
`;



const Tbody = styled.tbody`
/* background-color: #f7f7f7; */
`;

const Td = styled.td`
text-align: center;
vertical-align: middle; 
padding:1rem 0.5rem;
border:0.1px solid #cccccc;
font-size: 0.8rem;
font-weight: 400;
`;

const StyledButton = styled.button`
  background-color: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #39739d;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding:0.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
`;
