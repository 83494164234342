import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import Help from '../../components/Help/help';
import axiosInstance from '../../consts/axiosInstance';
import { colors } from '../../consts/themes';
import LoadingSpinner from '../../components/Button/Loading/LoadingSpinner';
import ErrorComponent from '../../components/Button/error/ErrorComponent';
import { useNavigate } from 'react-router-dom';



const Notification = () => {
  const navigation = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 1000); // Fetch every 5 seconds

    return () => clearInterval(interval); // Clean up on component unmount
  }, []);


  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('notifications');
      setNotifications(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchNotifications();
  }, []);


  const readAtHandler = async (id) => {
    try {
      const response = await axiosInstance.post(`markAsRead/${id}`);
      if (response.data.code == 200) {
        fetchNotifications();
      } else if (response.data.code == 401) {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };


  const navHandler = (item) => {
    if (item.title === "Desktop customization") {
      readAtHandler(item.id);
      navigation('/custompc/home');
    } else if (item.title.toLowerCase().includes('quiz')) {
      readAtHandler(item.id);
      navigation('/quiz');
    }
    else if (item.title.toLowerCase().includes('bid')) {
      readAtHandler(item.id);
      navigation('/auction');
    }
    else if (item.title.toLowerCase().includes('sale')) {
      readAtHandler(item.id);
      navigation('/auction');
    }
    else if (item.title.toLowerCase().includes('cctv')) {
      readAtHandler(item.id);
      navigation('/auction');
    }
    else if (item.title.toLowerCase().includes('service')) {
      readAtHandler(item.id);
      navigation('/profile/services');
    }
    else if (item.title.toLowerCase().includes('exchange')) {
      readAtHandler(item.id);
      navigation('/exchange');
    }
    else {
      readAtHandler(item.id);
    }
  }


  // if (loading) {
  //   return <LoadingSpinner />
  // }

  // if (error) {
  //   return <ErrorComponent error={error.message} />
  // }


  return (
    <Screen>
      {notifications?.length === 0 && <NoDisplay>No Notification</NoDisplay>}
      <ScreenContent>
        <ItemContainer>
          {notifications?.map((item) => (
            <SearchItem onClick={() => navHandler(item)}>
              <ItemImg>
                <img src={item?.image} alt='img' />
              </ItemImg>
              <ItemText>
                <ItemTitle>{item?.title}</ItemTitle>
                <ItemDec>{item?.message}</ItemDec>
              </ItemText>

              <ItemPrice>
                {item?.read_at === null && <Dot></Dot>}
              </ItemPrice>

            </SearchItem>
          ))}
        </ItemContainer>

      </ScreenContent>

      <Help />
    </Screen>
  )
}

export default Notification


const NoDisplay = styled.div`
  font-style: italic;
  color: ${colors.ash};
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top:6rem;
`;


const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: blue;
  border-radius: 50%;
`;


const Screen = styled.div`
  margin-top: 5rem;
  /* height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem); */
  /* overflow-y: auto; */
`;


const ScreenContent = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top:7rem;
padding:0 8rem;
@media only screen and (min-width:1024px) and (max-width:1499px) {
  padding:0 4rem;
}
@media only screen and (min-width:768px) and (max-width:1023px) {
  padding:0 2rem;
}
@media only screen and (min-width:300px) and (max-width:767px) {
  padding:0 1rem;
}
`;


const ItemContainer = styled.div`
display: flex;
flex-direction: column;
gap:0.8rem;
height: calc(100vh - 10rem);
overflow-y: scroll;
margin-top:1rem;
padding-bottom: 1rem;
max-width: 70rem;
width: 100%;
`;






const SearchItem = styled.div`
border: 1px solid ${colors.line};
display: flex;
justify-content:space-between;
align-items: center;
border-radius: 10px;
`;




const ItemImg = styled.div`
flex:1;
height:100%;
display: flex;
justify-content: center;
align-items: center;
padding:0.5rem;

img{
width:9rem;
height:8rem;
object-fit: contain;
@media only screen and (min-width:768px) and (max-width:1023px) {
width:7rem;
height:6rem;
}
@media only screen and (min-width:450px) and (max-width:767px) {
width:6rem;
height:5rem;
}
@media only screen and (min-width:300px) and (max-width:449px) {
width:4rem;
height:3rem;
}
}

`;



const ItemText = styled.div`
flex:4;
height:100%;
display: flex;
flex-direction: column;
gap:0.5rem;
justify-content: center;
align-items: center;
padding:0.5rem;
`;


const ItemTitle = styled.div`
font-weight: 500;
font-size:1rem;
@media only screen and (min-width:300px) and (max-width:549px) {
font-size: 0.8rem;
}
`;




const ItemDec = styled.div`
font-size: 0.9rem;
font-weight: 300;
@media only screen and (min-width:768px) and (max-width:1023px) {
font-size: 0.8rem;
}
@media only screen and (min-width:500px) and (max-width:767px) {
font-size: 0.7rem;
}
@media only screen and (min-width:300px) and (max-width:499px) {
font-size: 0.6rem;
}
/* @media only screen and (min-width:300px) and (max-width:499px) {
display: none;
} */
`;

const ItemPrice = styled.div`
flex:1;
height:100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap:0.5rem;
padding:0.5rem;
`;
