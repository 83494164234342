import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Modal, ModalContainer, Overlay } from "../../components/modal";
import { useNavigate } from 'react-router-dom';
import { selectQuizTerms } from '../../api/api';
import axios from 'axios';
import axiosInstance from '../../consts/axiosInstance';


const QuizTandCModal = ({ endTime }) => {

  const navigation = useNavigate();
  // console.log(" endTime", endTime)
  const [check, setCheck] = useState(false);
  const [terms, setTerms] = useState([]);

  function handleclick(name) {
    setCheck(1);
    check === name && setCheck(0);
  }

  const getTerms = async () => {
    try {
      const response = await axiosInstance.get('selectQuizTerms');
      setTerms(response.data);
    } catch (error) {
      // console.log(error.message);
    }
  };

  useEffect(() => {
    getTerms();
  }, [])

  return (
    <ModalContainer>
      <Modal>
        <Overlay>
          <Container>
            <H1>Terms and Conditions</H1>
            <div>
              {terms?.map(item => (
                <div key={item.id}>
                  {/* Rendering description as raw HTML */}
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              ))}
            </div>

            <Accept>
              <input type='checkbox' onClick={() => handleclick(1)} />
              <p>I have read and agreed to the <span>terms and conditions</span></p>
            </Accept>


            <ButtonContainer>
              <AcceptBtn onClick={() => {
                navigation(`/quiz/questions/${endTime && endTime}`)
              }} disabled={!check}
                style={{ backgroundColor: check === 1 ? '#4669E8' : '#ccc' }}>Proceeed</AcceptBtn>
            </ButtonContainer>


          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default QuizTandCModal;

const ButtonContainer = styled.div`
display: flex;
justify-content: center;
margin-top:1rem;
        `

const BaseButton = styled.button`
        font-family: Poppins;
        text-align: center;
        color:white;
        border:none;
        display:flex;
        justify-content: center;
        border-radius: 5px;
        
        @media only screen and (min-width: 250px) and (max-width: 399px) {
          font-size: 0.8rem;
          padding:1rem;
        }
        
        @media only screen and (min-width: 250px) and (max-width: 767px) {padding:0.6rem 3rem;}
        @media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0.7rem 4rem;}
        @media only screen and (min-width: 1024px)  {padding:0.8rem 5rem;}
        `

const AcceptBtn = styled(BaseButton)`
        ${'' /* background-color: ${(props) => (props.enableBuy ? '#4669E8' : '#BCBCBC')}; */}
        background-color: #4669E8;
        color:white;
        font-size: 1rem;
        font-weight: 400;
        max-width: 200px
      `;

const Buttonbar = styled.button`
   width:250px;
   margin:10px 2px;
   outline:none;
   border:none;
   border-radius:8px;
  
   padding:12px 0;
   font-size:14px;
   font-weight:400;
   color:white;
   @media(min-width:2000px) {
      font-size:16px;
      margin:12px 2px;
      padding:10px 0;
   }
   @media (max-width:500px){
      margin:10px 50px;
   }
   @media (max-width:380px){
      margin:10px 28px;
   }
   `



const Accept = styled.div`
display:flex;
align-items:center;
margin-top: 1rem;

p{
margin:0 5px;
font-size:0.9rem;
font-weight:400;
}
span{
color:#4669E8;
}
`

const Container = styled.div`
padding: 3rem 2rem 2rem;
max-width:50rem;
`

const H1 = styled.div`
font-size:1.2rem;
font-weight: 500;
color:#4669E8;
`


// export const ModalContainer = styled.div`
//   /* background-color: rgb(0, 0, 0, 0.01); */
//   width: 100%;
//   position: fixed;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 1;
//   transition: opacity 0.5s, visibility 0.5s;
//   height: 100%;
//   z-index: 99999;
//   flex-direction: column;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   overflow: scroll;
//   display: flex;
//   backdrop-filter: blur(1px);
// `;




// export const Modal = styled.div`
//   display: flex;
//   height: auto;
//   /* position: relative; */
//   border-radius: 10px;

//   @media (max-width: 1024px) {
//     width: auto;
//     margin: 20px;
//   }
//   @media (max-width: 425px) {
//     width: 90%;
//   }
// `;



// export const Overlay = styled.div`
//   display: flex;
//   background-color:white;
//   border-radius: 10px;
//   z-index: 1000;
//   transition: 0.5s;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   box-shadow: 2px 2px 25px 2px rgba(204, 204, 204, 0.45);

//   textarea {
//     outline: none;
//     border: none;
//     padding-block: 1rem;
//     /* overflow: scroll; */
//     height: 10vh;
//     width: 90%;
//     padding-inline: 1rem;
//     margin-inline: 1rem;
//     margin-block: 1.5rem;
//     background-color: #f6f6f6;
//     border-radius: 10px;
//     /* line-break: strict; */

//     @media (max-width: 425px) {
//       /* padding-block: 3rem; */
//       padding-inline: 0.2rem;
//       margin-block: 1.5rem;
//     }
//   }
//   @media (max-width: 425px) {
//     width: 100%;
//   }
// `;




