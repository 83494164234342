import React from 'react'
import styled from 'styled-components';
import LoadingChart from './table/LoadingChart';
import PowerConsumptionChart from './table/PowerConsumptionChart';

const UpsChart = () => {
  return (
    <Screen className=' flex-col flex items-center justify-center py-10'>
      <div className='w-[95%] flex-col flex items-center'>
        <div className=''>
          <p className='ml-4 text-black text-2xl'>UPS/Inverter Loading Chart</p>
          <LoadingChart />
          <p className='ml-4 text-black text-2xl'>Power Consumption Chart</p>
          <PowerConsumptionChart />
        </div>
      </div>
    </Screen>
  )
}

export default UpsChart;




const Screen = styled.div`
  /* margin-top: 5rem; */
  /* height: calc(100vh - 5rem); */
  /* max-height: calc(100vh - 5rem); */
  overflow-y: auto;
`;