import React from "react";
import { Bg, Btn, Card, Cont, Input, Button, Div } from "./feedbackStyle";
import { Modal, ModalContainer, Overlay } from "../../../components/modal";
// import Button from '../../../components/Button/Button';
import Done from "../../../assets/sale/Done.png";
import { insertPurchaseFeedback } from "../../../api/api";
import axios from "axios";
import { AuthContext } from "../../../consts/AuthContext";
import { useContext } from "react";
import { DIv } from "../../../components/Modal/Installationmodal.styled";
import { GrFormClose } from "react-icons/gr";

const Data = [
  {
    id: 1,
    title: "Excellent",
  },
  {
    id: 2,
    title: "Very good",
  },
  {
    id: 3,
    title: "Good",
  },
  {
    id: 4,
    title: "Poor",
  },
  {
    id: 5,
    title: "Very poor",
  },
];

function Feedback({ setOpens }) {
  const [isOpen, setOpen] = React.useState(false);
  const [data, setData] = React.useState();
  const [authUser, setAuthUser] = useContext(AuthContext);

  // console.log("data?.comment", isOpen?.title);

  const handleCheck = (it) => {
    setOpen(it);

    // it?.id === isOpen?.id && (
    //     setOpen(0)
    // )
  };

  async function insertfeedback() {
    const form = new FormData();

    form.append("ratingText", isOpen?.title);
    form.append("comment", data?.comment?.target?.value);
    form.append("user_id", authUser?.id);

    const options = {
      method: "POST",
      url: insertPurchaseFeedback,
      headers: { "Content-Type": "multipart/form-data", Accept: "application/json" },
      data: form,
    };
    await axios
      .request(options)
      .then(function (response) {
        // console.log("inserted", response);
        alert(`${response?.data?.message}`);
        response?.data?.code === 200 && setOpens(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  return (
    <>
      {/* <button style={{ backgroundColor: "#000000", }} onClick={() => setOpen(!isOpen)} /> */}
      {/* {isOpen && ( */}
      <ModalContainer>
        <Modal>
          <Overlay>
            <Div>
              <GrFormClose size={20} onClick={() => setOpens(false)} />
            </Div>
            <Bg>
              <img src={Done} />
              <h2>Send your feedback</h2>
              <p>How was experience of product choosing ?</p>

              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
            </Bg>
            <Cont>
              {/* <p>How was experience of product choosing ?</p> */}
              <Btn>
                {Data.map((val, i) => {
                  return (
                    <Card
                      onClick={() => handleCheck(val)}
                      style={{
                        backgroundColor: isOpen?.id == val?.id ? "#4169e1" : "",
                        color: isOpen?.id == val?.id ? "#fff" : "#000",
                      }}
                    >
                      <p>{val.title}</p>
                    </Card>
                  );
                })}
              </Btn>
            </Cont>
            {/* <Input> */}
            {/* <p>Comment your experience here</p> */}
            <textarea
              placeholder="Comment your experience here"
              onChange={(e) => setData((prev) => ({ ...prev, comment: e }))}
            ></textarea>
            {/* </Input> */}
            <Button onClick={() => insertfeedback()}>Submit</Button>
          </Overlay>
        </Modal>
      </ModalContainer>
      {/* )
            } */}
    </>
  );
}

export default Feedback;
