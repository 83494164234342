import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import add from "../../assets/Images/png/add.png";
import remove from "../../assets/Images/png/remove.png";
import LoadingSpinner from "../../components/Button/Loading/LoadingSpinner";
import ErrorComponent from "../../components/Button/error/ErrorComponent";
import Help from "../../components/Help/help";
import AssistModal from "../../components/Modals/AssistModal";
import { AuthContext } from "../../consts/AuthContext";
import axiosInstance from "../../consts/axiosInstance";
import { NoDisplay, colors } from "../../consts/themes";

function Product() {

  const navigation = useNavigate();
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [needhelp, setNeedhelp] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState(null);
  const [favProducts, setFavProducts] = useState(null);
  const [fav, setFav] = useState([]);
  const [favslist, setFavslist] = useState();
  const FavProductid = JSON.parse(localStorage.getItem("Productid"));
  const per = JSON.parse(localStorage.getItem("Percentage"));
  const [sharedItem, setSharedItem] = useState([])
  const [favItem, setFavItem] = useState(false)

  // const [reload, setReload] = useState(false)

  //printer id from printertype component
  const PrinterfilterId = JSON.parse(localStorage.getItem("PrinterfilterId"));

  //color id of  mob , tab , pc etc
  const productsid = JSON.parse(localStorage.getItem("productcode"));

  //normal id of  mob , tab , pc etc
  const productid = JSON.parse(localStorage.getItem("productid"));

  //id of 1st sub catageory
  let categoryid = JSON.parse(localStorage.getItem("id"));

  //id of 2nd sub catageory
  let subcatid = JSON.parse(localStorage.getItem("subcateid"));

  //1st sub category of cctv ,network adaptor , accessory ,printer
  const Typeid = JSON.parse(localStorage.getItem("Typeids"));



  const filteredProducts = (products || []).filter((product) => product.printerCate === PrinterfilterId);

  // console.log(filteredProducts)



  const Productlist = async () => {
    try {
      setLoading(true);
      // productListBasedOnCategory
      const response = await axiosInstance.get(`getProductListBasedOnCategory?product_category_id=${productid}&customer_category_id=${productid == "12" ||
        productid == "13" ||
        productid == "17" ||
        productid == "18" ||
        productid == "8" ||
        productid == "9" ||
        productid == "15" ||
        productid == "11"
        ? 0
        : categoryid
        }&customer_sub_category_id=${productid == "11" ||
          productid == "14" ||
          productid == "12" ||
          productid == "13" ||
          productid == "17" ||
          productid == "18" ||
          productid == "8" ||
          productid == "9" ||
          productid == "15"
          ? 0
          : subcatid
        }&type_id=${productid == "18" || productid == "17" || productid == "12" || productid == "11" ? Typeid : 0}`);
      // console.log(response.data, " response of product list")
      setProducts(response.data);
      // console.log(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const favProductlist = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getFavListById/${authUser?.user?.id}`);
      // console.log(response.data, " response of fav product list")
      setFavProducts(response.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const favHandler = async (id) => {
    try {
      setLoading(true);
      const formData = {
        user_id: authUser?.user?.id,
        product_id: id,
        product_category_id: productid,
      };
      const response = await axiosInstance.post('insertCustomerFav', formData);
      // console.log('Response:', response.data);
    } catch (error) {
      setError(error);
      console.error("Error updating cart:", error);
    } finally {
      Productlist();
      favProductlist();
      setLoading(false);
    }
  };

  useEffect(() => {
    Productlist();
    favProductlist();
  }, [])



  const truncate = (string) => {
    return string?.length > 15 ? string?.substr(0, 15 - 1) + '...' : string
  }


  if (loading) {
    return <LoadingSpinner />
  }
  if (error) {
    return <ErrorComponent error={error.message} />
  }


  const nav = '/sales/help';
  const text = 'Do you need our help to choose the right product ?'


  return (
    <Screen className="h-screen">

      {products?.length === 0 ? <NoDisplay>No Products to display</NoDisplay> : null}

      {/* lap,tab,mob,desk */}
      {/* {productsid == "001" && needhelp === true ? <Assistance close={setNeedhelp} /> : ""}*/}
      {productsid == "001" && needhelp === true ? <AssistModal close={setNeedhelp} nav={nav} text={text} /> : ""}
      {productsid == "002" && needhelp === true ? <AssistModal close={setNeedhelp} nav={nav} text={text} /> : ""}
      {productsid == "003" && needhelp === true ? <AssistModal close={setNeedhelp} nav={nav} text={text} /> : ""}

      <ScreenContent >
        {/* printer */}
        {productsid === "005" ?

          <>
            {filteredProducts?.map((item, i) => (
              <CardImg key={i}>
                <Mappingcards
                  onClick={() => localStorage.setItem("Productid", JSON.stringify(item?.productId))}>
                  <Image_one
                    onClick={localStorage.setItem("productcolor", JSON.stringify(item?.color))} >

                    {Array.isArray(favProducts) && favProducts?.find(fav => fav.productId === item.productId) ?
                      <FavDiv onClick={() => favHandler(item?.productId)}><img src={add} alt="fav-icon" /></FavDiv> : <FavDiv onClick={() => favHandler(item?.productId)}><img src={remove} /></FavDiv>}

                  </Image_one>

                  <Maproute onClick={() => navigation("/sales/products/productdetails")}>
                    <Lapimage>
                      <img src={JSON.parse(item?.productImages)[0]} alt={item.name2}></img>
                    </Lapimage>
                    {
                      <Laptext>{truncate(item.title)}</Laptext>}
                    {/* <Laptext>{item.title.slice(0, 15)} ...</Laptext> */}
                    <Price>₹ {item.offer_price}</Price>

                    <Offer>
                      <Offerwrong>₹{item.price}</Offerwrong>
                    </Offer>



                    <Coin_warrenty>
                      <Warranty>
                        {item.warranty} year warranty
                      </Warranty>

                      <Coin>

                        {item.reward_points}Clike points
                      </Coin>
                    </Coin_warrenty>

                    <Name2>{item.name2}</Name2>
                    <Demand
                      isOutOfStock={item?.stock === 0}
                      isLowStock={item?.stock > 0 && item?.stock < 4}
                    >
                      {item?.stock === 0 ? (
                        <p>Out Of Stock</p>
                      ) : item?.stock < 4 ? (
                        <p>Only {item?.stock} left</p>  // Show stock message for low stock
                      ) : (
                        <p>Available</p>  // Show default stock status for other cases
                      )}
                    </Demand>
                  </Maproute>

                </Mappingcards>
              </CardImg>
            ))}
          </>
          :
          <>
            {products?.map((item, i) => (
              <CardImg key={i}>
                <Mappingcards
                  onClick={() =>
                    localStorage.setItem("Productid", JSON.stringify(item?.productId))
                  }
                >
                  <Image_one
                    onClick={localStorage.setItem("productcolor", JSON.stringify(item?.color))}  >
                    {Array.isArray(favProducts) && favProducts?.find(fav => fav.productId === item.productId) ?
                      <FavDiv onClick={() => favHandler(item?.productId)}><img src={add} /></FavDiv> : <FavDiv onClick={() => favHandler(item?.productId)}><img src={remove} /></FavDiv>}
                  </Image_one>

                  {/* <img src={item.brand_image}></img> */}
                  <Maproute onClick={() => navigation("/sales/products/productdetails")}>
                    <Lapimage>
                      <img src={JSON.parse(item?.productImages)[0]}></img>
                    </Lapimage>

                    <Laptext>{truncate(item.title)}</Laptext>

                    <Price>₹ {item.offer_price}</Price>

                    <Offer>
                      <Offerwrong>₹{item.price}</Offerwrong>
                    </Offer>

                    <Coin_warrenty>
                      <Warranty>
                        {/* <img src={warranty}></img> */}
                        {item.warranty} year warranty
                      </Warranty>

                      <Coin>
                        {/* <img src={coin}></img> */}
                        {item.reward_points}Clike points
                      </Coin>
                    </Coin_warrenty>

                    <Name2>{item.name2}</Name2>
                    <Demand
                      isOutOfStock={item?.stock === 0}
                      isLowStock={item?.stock > 0 && item?.stock < 4}
                    >
                      {item?.stock === 0 ? (
                        <p>Out Of Stock</p>
                      ) : item?.stock < 4 ? (
                        <p>Only {item?.stock} left</p>  // Show stock message for low stock
                      ) : (
                        <p>Available</p>  // Show default stock status for other cases
                      )}
                    </Demand>

                  </Maproute>

                </Mappingcards>
              </CardImg>
            ))}
          </>
        }
      </ScreenContent>
      <Help />
    </Screen >
  );
}
export default Product;



const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0;
  padding-top:5rem;
  padding-bottom:5rem;
  /* height:100vh; */
`;


const ScreenContent = styled.div`
display: grid;
grid-template-columns: repeat(12, minmax(0, 1fr));
overflow-y: scroll;
margin-top:1rem;
padding-bottom:2rem;
@media only screen and (min-width: 250px) and (max-width: 350px) {gap: 0.5rem;}
@media only screen and (min-width: 351px) and (max-width: 768px) {gap: 1rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {gap: 1.5rem;}
@media only screen and (min-width: 1024px) {gap: 2rem;}
`;


const CardImg = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
@media only screen and (min-width: 250px) and (max-width: 569px) {grid-column: span 12 / span 12;}
@media only screen and (min-width: 570px) and (max-width: 887px) {grid-column: span 6 / span 6;}
@media only screen and (min-width: 888px) and (max-width: 1199px) {grid-column: span 4 / span 4;}
@media only screen and (min-width: 1200px) {grid-column: span 3 / span 3;}
border: 1px solid ${colors.line};
padding:0.5rem;
border-radius:5px;
`;

const Mappingcards = styled.div`
max-width:15rem;
width:15rem;
height:auto;
`;


const Image_one = styled.div`
display:flex;
justify-content: flex-end;
`;


const FavDiv = styled.div`
img{
  height:1rem;
  width:1.2rem;
}
`;


const Lapimage = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 190px;
    height: 190px;
    object-fit: contain;
  }
`;

const Laptext = styled.div`
margin-top: 0.4rem;
display: flex;
justify-content: center;
text-align: center;
font-family: Arial;
font-size: 1.125rem;
font-style: normal;
font-weight: 400;
line-height:1.575rem;
`;




const Offer = styled.div`
margin-top: 0.7rem;
display: flex;
justify-content: center;
font-family: Arial;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height:0.8775rem;
color: ${colors.light_grey};
`;



const Offerwrong = styled.div`
  text-decoration-line: line-through;
`;



const Offercorrect = styled.div`
  font-size: 12px;
  color: red;
`;


const Price = styled.div`
margin-top: 0.4rem;
display: flex;
justify-content: center;
align-items: center;
color: ${colors.blue};
font-family: Arial;
font-size: 1.25rem;
font-style: normal;
font-weight: 700;
line-height:1.4625rem;
`;




const Coin_warrenty = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 12px;
  align-self: center;
`;



const Coin = styled.div`
  color: ${colors.blue};
  font-size: 12px;
  display: flex;
  text-align: center;

  img {
    width: 20px;
    height: 20px;
    padding-inline: 5px;

    @media (max-width: 1880px) {
      padding-inline: 4px;
    }
  }
`;
const Warranty = styled.div`
  font-size: 12px;
  display: flex;
  text-align: center;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
    padding-inline: 5px;
  }
`;

const Demand = styled.div`
  margin-top: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: ${(props) => {
    if (props.isOutOfStock) return "red";
    // if (props.isLowStock) return "red";
    return colors.green;  // Default color for normal stock
  }};
    margin: 0;
    text-align: center;
    font-family: Arial;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.17rem;
  }
`;

const Name2 = styled.div`
  margin-top: 10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  color: ${colors.green};
`;
export const Mapping = styled.div`  background-color:pink`;
export const Maproute = styled.div``;
