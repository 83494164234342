import { colors, fonts } from '../../../consts/themes';
import styled from "styled-components";

const MainContainer = styled.div`
display:flex;
flex-direction:row;
justify-content: space-around;
height:100vh;
@media (max-width:560px){
   flex-direction:column;
   align-items:start;
   padding: 10px;
   height:160vh;
}
`
const Left = styled.div`
   display:flex;
   flex-direction:column;
   align-items:center;
   justify-content:center;
   width:25%;
   // background-color:blue;
   padding-left:35px;
   img{

   }
   p{
      margin:0;
      margin-top: 15px;
      width:300px;
      font-size:14px;
      color:${colors.black};
      text-align: center;
   };
   @media(min-width:1250px) {
      width:30%;
   }
   @media(min-width:1000px) and (max-width:1200px) {
      width:35%;
   }
   @media (max-width:990px){
   margin-top: 15%;
   
}
   @media(min-width:700px) and (max-width:800px){
      /* width:55%; */
      padding-left: 0px;
      margin-top: 15%;
      justify-content:flex-start;

   }
  
   @media (max-width:450px){
     margin-top: 20%;
   }
   @media (max-width:700px){
     margin-top: 15%;
     padding-left: 80px;
   }
   @media (max-width:350px){
     margin-top: 20%;
   }
   `
const Right = styled.div`
   display:flex;
   flex-direction:column;
   padding-top:10%;
  
   @media (min-width:350px) and (max-width:610px) {
      /* margin-inline:38px; */
      padding-left: 8px;
   }
   @media (max-width:350px) {
      width:300px;
      padding-left:0px;
   }
   /* @media (max-width:380px) {
      padding-left:0;
      margin-inline:30px;
   } */
   @media (max-width:450px) {
      margin-bottom: 80px;
   }
   @media (max-width:990px) {
      padding-top:8%;
   }
   
   @media (min-width:700px) and (max-width:800px) {
      margin-top: 5%;

   }
   @media (min-width:800px) and (max-width:990px){
      margin-top: 4%;
   }
   @media (min-width:800px) and (max-width:990px){
      margin-top: 4%;
   }
   @media (max-width:700px){
      padding-left: 0;
   }
   `
const Inputbar = styled.input`
width:200px;
height:30px;
border:1px solid ${colors.line};
outline:none;
margin:5px 0;
border-radius:8px;
color: ${colors.black};
padding-left:5px;
@media (max-width:560px){
   width:93vw;
}
::placeholder{
   color:${colors.light_grey};
   font-size:12px;
   }
   @media(min-width:2000px) {
      ::placeholder{
         font-size:14px;
      }
   }
`
const Inputlongbar = styled.textarea`
width:600px;
height:120px;
padding:5px 5px;
border:1px solid ${colors.line};
outline:none;
margin:5px 0;
border-radius:8px;
color: ${colors.black};
padding-left:5px;

::placeholder{
   color:${colors.light_grey};
   font-size:12.2px;
   font-family: 'Poppins', sans-serif;
   font-weight:${fonts.fontWeight3}
   
   
   }
   @media(min-width:2000px) {
      ::placeholder{
         font-size:14px;
      }
   }
@media(min-width:700px) and (max-width:800px){
   width:350px;
}
@media (min-width:800px) and (max-width:990px){
     width: 500px;
   }
@media (min-width:561px) and (max-width:700px){
   width:290px;
}
@media (max-width:560px){
   width:93vw;
}
   `

const Selecting = styled.div`
   display:flex;
   flex-direction:column;
   
   justify-content:space-between;
   

   `
const Select = styled.div`
   display:flex;
   width:270px;
   flex-direction:row;
   
   justify-content:space-between;
   height:auto;
   align-items:center;
   @media(min-width:2000px) {
      width:300px;
   }
   `
const Rupees = styled.div`
padding-top:6px;
   p{
      font-size:12px;
      margin:0;
   }
   @media(min-width:2000px) {
      p{
         font-size:14px;
      }
   }
   `
const Div = styled.div`
   display:flex;
   flex-direction:column;
   label{
      font-size:14px;
      font-weight:${fonts.fontWeight5};
   }
      `
const Rightline = styled.div`
   display:flex;
   flex-direction:row;
   align-items:center;
   input{
      margin:6px 0 0 6px;
      accent-color:${colors.blue};
      width:12px;
      height:12px;
   }
   p{
      margin:5px 0 0 5px;
      font-size:12px;
      color:${colors.black};
      // font-weight:${fonts.fontWeight3};
     
   }
   @media(min-width:2000px) {
      p{
         font-size:14px;
      }
   }
   `     
const Terms = styled.div`
margin:5px 4px;
span{
   font-size:12px;
   font-weight:${fonts.fontWeight5};
}
li{
   font-size:12px;
   font-weight:${fonts.fontWeight5};
   
   color:${colors.black};
}
@media(min-width:2000px) {
   span{
      font-size:14px;
   }
   li{
      font-size:14px;
   }
}
`
const Buttonbar = styled.button`
   width:250px;
   margin:10px 2px;
   outline:none;
   border:none;
   border-radius:8px;
  
   padding:12px 0;
   font-size:14px;
   font-weight:${fonts.fontWeight5};
   color:${colors.white};
   @media(min-width:2000px) {
      font-size:16px;
      margin:12px 2px;
      padding:10px 0;
   }
   @media (max-width:500px){
      margin:10px 50px;
   }
   @media (max-width:380px){
      margin:10px 28px;
   }
   `
const Filter = styled.div`
     /* flex-direction: column; */
   select{
       
      width:210px;
      /* height:30px; */
      /* padding-left:1px; */
      margin:2px 0;
      color:${colors.black};
       font-size:12px;
       border-radius:7px;
       font-weight:${fonts.fontWeight5};
       border:1px solid ${colors.line};
       @media (max-width:560px){
         width:95vw
      }
       }
       select:focus{
           outline:none;
        }


        
        `
const Lists = styled.div`
        display:flex;
        flex-direction:row;
        align-items:center;
       
        p{
         margin:0 5px;
         font-size:12px;
         line-height:2;
         font-weight:${fonts.fontWeight5};
         color:${colors.black};
        }
        @media (min-width:700px) and (max-width:800px){
         p{
            margin:0 0 0 5px;
         }
        }
        @media (max-width:300px) {
         width:250px;
        }
        `
const Accept = styled.div`
        display:flex;
        flex-direction:row;
        align-items:center;
       margin-top: 1rem;
        p{
         margin:0 5px;
         font-size:12px;
         line-height:2;
         font-weight:${fonts.fontWeight5};
         color:${colors.black};
        }
       
        `
const Dot = styled.div`
   width:4px;
   height:4px;
   border-radius:50%;
   background-color:${colors.black};
   @media (min-width:700px) and (max-width:800px){
     
         margin:1px 3px;
     
     }
   `
const Dotbar = styled.div`
   width:6px;
   `

const Dist = styled.div`
p{
    font-size:12px;
    font-weight:${fonts.fontWeight4};
    color:${colors.black};
}`

const DetailsDropDown = styled.div`
width: 34%;
select{
    width: 100%;
    height: 44.5px;
    border: 1px solid #c9c7c7;
    border-radius: 8px;

    ${'' /* select { */}
        ${'' /* width: 100%; */}
        ${'' /* height: 30px;
        border: 0px solid #c9c7c7;
        border-radius: 8px; */}
        color: #878787;
        font-size: 12px;
        padding: 8px;

        appearance: none;
        background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E) !important;
        background-repeat: no-repeat, repeat !important;
        background-position: right 0.7em top 50%, 0 0 !important;
        background-size: 0.65em auto, 100% !important;
    }
    ${'' /* } */}

    @media screen and (min-width: 320px) and (max-width: 425px) {
        width: 86%;
        select {
            color: #878787;
            font-size: 12px;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1880px) {
        width: 86%;
        select {
            color: #878787;
            font-size: 14px;
        }
    }
`
export {
   MainContainer,
   Left,
   Right,
   Filter,
   Inputbar,
   Inputlongbar,
   Selecting,
   Select,
   Rupees,
   Div,
   Rightline,
   Terms,
   Buttonbar,
   Lists,
   Dot,
   Dotbar,
   Accept,
   DetailsDropDown,
   Dist

}