// import React, { useContext, useEffect, useState } from 'react'
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import styled from 'styled-components'
// import { AuthContext } from '../../consts/AuthContext';
// import CountDown from './CountDown';
// import axiosInstance from '../../consts/axiosInstance';
// import Swal from 'sweetalert2';

// const BuyNow = () => {

//   const { id, end } = useParams();
//   const location = useLocation().pathname;
//   const navigation = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [authUser, setAuthUser] = useContext(AuthContext);
//   const [bidId, setBidId] = useState(null);
//   const [actualImg, setActaulImg] = useState([]);
//   const [bidProducts, setBidProducts] = useState([]);
//   const [userInput, setUserInput] = useState('');
//   const [data, setData] = useState(null);
//   const [bidPlaced, setBidIdPlaced] = useState('')

//   const [bidPassword, setBidPassword] = useState('');
//   const [isPasswordValid, setIsPasswordValid] = useState(false);

//   const alnHandler = async () => {
//     try {
//       setLoading(true);
//       const formData = new FormData();
//       formData.append('bid_id', bidId);
//       formData.append('password', bidPassword);

//       const response = await axiosInstance.post('http://192.168.1.39:8001/api/user/checkPassword', formData);

//       if (response.status === 200) {
//         setIsPasswordValid(true);
//         Swal.fire({
//           position: 'center',
//           icon: 'success',
//           text: 'Password verified successfully!',
//         });
//       }
//     } catch (error) {
//       setIsPasswordValid(false);
//       Swal.fire({
//         position: 'center',
//         icon: 'error',
//         text: 'Password verification failed. Please try again.',
//       });
//     } finally {
//       setLoading(false);
//     }
//   };




//   //getting bid id
//   const getBidProducts = async () => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get('customerBidProduct');
//       setBidProducts(response?.data);
//       setBidId(response?.data?.bid_details_id);
//       response.data?.actual_image && setActaulImg(JSON?.parse(response.data?.actual_image));
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   const fetchData = async () => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get(`getplacedbid/${id}`);
//       setData(response?.data[0].bid_price);
//       setBidIdPlaced(response?.data[0].user_id);
//       if (response?.data[0].bid_status === 2) {
//         navigation('/auction');
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };



//   useEffect(() => {
//     // Initial API call when the component mounts
//     fetchData();

//     // Set up the interval to make the API call every 5 seconds
//     const intervalId = setInterval(() => {
//       fetchData();
//       console.log(" 5 second call")
//     }, 5000);
//     // Clean up the interval when the component is unmounted
//     return () => clearInterval(intervalId);
//   }, []);




//   const handleInputChange = (e) => {
//     setUserInput(e.target.value);
//   }


//   const handlePasswordChange = (e) => {
//     setBidPassword(e.target.value);
//   };


//   // const placeBidHandler = async () => {
//   //   if (userInput) {
//   //     try {
//   //       setLoading(true);

//   //       const formData = {
//   //         user_id: authUser?.user?.id,
//   //         bid_price: userInput && userInput,
//   //         bid_id: bidId && bidId,
//   //       }

//   //       const options = {
//   //         method: 'POST',
//   //         url: 'placebid',
//   //         headers: {
//   //           'Content-Type': 'multipart/form-data',
//   //           Accept: 'application/json',
//   //         },
//   //         data: formData,
//   //       };
//   //       await axiosInstance.request(options).then((response) => {
//   //         // console.log("🚀 ~ bulk order update response", response);

//   //       })
//   //     } catch (error) {
//   //       setError(error);
//   //       console.log("🚀 ~ update cart ", error);

//   //     } finally {
//   //       setUserInput('');
//   //       fetchData();
//   //     }
//   //   }
//   // }


//   const placeBidHandler = async () => {
//     if (userInput) {
//       try {
//         setLoading(true);

//         const formData = {
//           user_id: authUser?.user?.id,
//           bid_price: userInput && userInput,
//           bid_id: bidId && bidId,
//         };

//         const options = {
//           method: 'POST',
//           url: 'placebid',
//           headers: {
//             'Content-Type': 'multipart/form-data',
//             Accept: 'application/json',
//           },
//           data: formData,
//         };

//         const response = await axiosInstance.request(options);
//         if (response.data.code === 400) {
//           Swal.fire({
//             position: 'center',
//             icon: 'error',
//             color: '#4169e1',
//             text: `${response.data.message}`,
//           });
//         }

//       } catch (error) {
//         if (error?.response?.data?.error) {
//           // Handle 400 Bad Request error
//           const errorMessage = error?.response?.data?.error;
//           Swal.fire({
//             position: 'center',
//             icon: 'error',
//             color: '#4169e1',
//             text: errorMessage,
//           });
//         } else {
//           setError(error);
//           console.log("🚀 ~ update cart ", error);
//           Swal.fire({
//             position: 'center',
//             icon: 'error',
//             color: '#4169e1',
//             text: 'An error occurred',
//           });
//         }

//       } finally {
//         setLoading(false);
//         setUserInput('');
//         fetchData();
//       }
//     }
//   };







//   useEffect(() => {
//     getBidProducts();
//   }, []);


//   let btnStatus = (authUser?.user?.id) === bidPlaced ? true : false

//   return (
//     <Main>
//       <Container1>
//         <SubDiv>
//           <ImgContainer>
//             <img src={actualImg[0]} alt='bid-product' />
//           </ImgContainer>
//           <Price>MRP ₹{bidProducts?.actual_product_price}</Price>
//           <Title>{bidProducts?.actual_product_title}</Title>
//           <CodeContainer>
//             <CodeTitle>Enter your Unique BID Password</CodeTitle>
//             <CodeInput type='password' value={bidPassword} onChange={handlePasswordChange} />
//             <EntrollButton onClick={alnHandler} >JOIN</EntrollButton>
//           </CodeContainer>
//         </SubDiv>
//       </Container1>

//       <Container2>
//         <CountDown endTime={end} data={bidProducts} />
//         <SubDiv2>
//           <DetailSection>
//             <Header>Current Bid</Header>
//             <BidContainer>
//               <BidPrice>MRP ₹{data}</BidPrice>
//               <CodeTitle> To Participate in the bid buy the secondary product and get your password.</CodeTitle>
//               <BidSub>
//                 <BidInput type='number' value={userInput} onChange={handleInputChange} />
//                 <BidButton disabled={btnStatus} onClick={placeBidHandler}>Place Bid</BidButton>
//               </BidSub>
//             </BidContainer>
//           </DetailSection>

//         </SubDiv2>
//       </Container2>
//     </Main>
//   )
// }
// export default BuyNow;


import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components'
import { AuthContext } from '../../consts/AuthContext';
import CountDown from './CountDown';
import axiosInstance from '../../consts/axiosInstance';
import Swal from 'sweetalert2';
import { server_api } from '../../consts/api';

const BuyNow = () => {
  const { id, end } = useParams();
  const location = useLocation().pathname;
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [bidId, setBidId] = useState(null);
  const [actualImg, setActaulImg] = useState([]);
  const [bidProducts, setBidProducts] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [data, setData] = useState(null);
  const [bidPlaced, setBidIdPlaced] = useState('');

  const [bidPassword, setBidPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const alnHandler = async () => {
    // console.log("JOIN button clicked");
    // console.log("Password entered:", bidPassword);

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('bid_id', bidId);
      formData.append('password', bidPassword);

      const response = await axiosInstance.post(`${server_api}user/checkPassword`, formData);

      if (response.status === 200) {
        setIsPasswordValid(true);
        Swal.fire({
          position: 'center',
          icon: 'success',
          text: 'Password verified successfully!',
        });
        setBidPassword('');
        // console.log("Password verified successfully!");
      }
    } catch (error) {
      setIsPasswordValid(false);
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: 'Password verification failed. Please try again.',
      });
      setBidPassword('');
      console.error("Password verification failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getplacedbid/${id}`);
      setData(response?.data[0].bid_price);
      setBidIdPlaced(response?.data[0].user_id);
      // console.log("Fetched bid data:", response?.data);

      if (response?.data[0].bid_status === 2) {
        navigation('/auction');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
      // console.log("5-second call to fetch data");
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
    // console.log("Bid input value changed:", e.target.value);
  };

  const handlePasswordChange = (e) => {
    setBidPassword(e.target.value);
    // console.log("Password input value changed:", e.target.value);
  };

  const placeBidHandler = async () => {
    // console.log("Place Bid button clicked");
    // console.log("Bid input value:", userInput);

    if (userInput) {
      try {
        setLoading(true);

        const formData = {
          user_id: authUser?.user?.id,
          bid_price: userInput,
          bid_id: bidId,
        };

        const options = {
          method: 'POST',
          url: 'placebid',
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          },
          data: formData,
        };

        const response = await axiosInstance.request(options);
        if (response.data.code === 400) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            color: '#4169e1',
            text: `${response.data.message}`,
          });
          console.error("Error placing bid:", response.data.message);
        } else {
          // console.log("Bid placed successfully:", response.data);
        }
      } catch (error) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          color: '#4169e1',
          text: error?.response?.data?.error || 'An error occurred',
        });
        console.error("Error during place bid request:", error);
      } finally {
        setLoading(false);
        setUserInput('');
        fetchData();
      }
    }
  };
  const getBidProducts = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('customerBidProduct');
      setBidProducts(response?.data);
      setBidId(response?.data?.bid_details_id);
      response.data?.actual_image && setActaulImg(JSON?.parse(response.data?.actual_image));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBidProducts();
  }, []);

  const btnStatus = (authUser?.user?.id) === bidPlaced || !isPasswordValid;
  // console.log("Button status (disabled):", btnStatus);

  return (
    <Main>
      <Container1>
        <SubDiv>
          <ImgContainer>
            <img src={actualImg[0]} alt='bid-product' />
          </ImgContainer>
          <Price>MRP ₹{bidProducts?.actual_product_price}</Price>
          <Title>{bidProducts?.actual_product_title}</Title>
          <CodeContainer>
            <CodeTitle>Enter your Unique BID Password</CodeTitle>
            <CodeInput type='password' maxLength={6} value={bidPassword} onChange={handlePasswordChange} />
            <EntrollButton onClick={alnHandler}>JOIN</EntrollButton>
          </CodeContainer>
        </SubDiv>
      </Container1>

      <Container2>
        <CountDown endTime={end} data={bidProducts} />
        <SubDiv2>
          <DetailSection>
            <Header>Current Bid</Header>
            <BidContainer>
              <BidPrice>MRP ₹{data}</BidPrice>
              <CodeTitle>To participate, buy the secondary product and get your password.</CodeTitle>
              <BidSub>
                <BidInput type='number' value={userInput} onChange={handleInputChange} />
                <BidButton disabled={btnStatus} onClick={placeBidHandler}>Place Bid</BidButton>
              </BidSub>
            </BidContainer>
          </DetailSection>
        </SubDiv2>
      </Container2>
    </Main>
  );
};

export default BuyNow;



const SubDiv2 = styled.div`
@media only screen and (min-width: 1024px) {
  width:30rem;
  padding: 2.1rem 4rem;
} 

@media only screen and (min-width: 250px) and (max-width: 374px) {padding:0 0.5rem 2rem} 
@media only screen and (min-width: 375px) and (max-width: 499px) {padding:0 1rem 2rem} 
@media only screen and (min-width: 500px) and (max-width: 767px) {padding:0 2rem 2rem}            
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0 6rem 2rem}


@media only screen and (min-width: 250px) and (max-width: 1023px) {margin-top:1rem;}
`



const TimeSection = styled.div`
background-color:red;
@media only screen and (min-width: 250px) and (max-width: 1023px) {
display:flex;
flex-direction: column;
gap:0.5rem;
}
@media (min-width: 1024px) { 
display:flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid black;
}
@media only screen and (min-width: 1200px) and (max-width: 1450px) {
padding:0.5rem 1rem;
}
@media only screen and (min-width: 1451px) {
padding:0.5rem 4rem;
}
`

const DetailSection = styled.div`
`





const TimerWraper = styled.div`
display: flex;
flex-direction:column;
align-items: center;
`




const Timer = styled.div`
${'' /* line-height: 2.3rem; */}
font-size:1.25rem;
font-weight:500;

@media only screen and (min-width: 250px) and (max-width: 1450px) {
font-size:1rem;
}
`


const TimerTitle = styled.div`
line-height:0.9rem;
font-size:0.6rem;
font-weight:500;
`



const ClockContent = styled.div`
background-color:white;
display:flex;
gap:0.5rem;
padding:0 1.625rem;
${'' /* border:1px solid black; */}
`



const Timeclock = styled.div`
display:flex;
align-items:center;
gap:0.8rem;
`




const Timetext = styled.div`
display:flex;
gap:3.5rem;

@media only screen and (min-width: 250px) and (max-width: 1023px) {
flex-direction:column;
gap:0.5rem;
}


@media only screen and (min-width: 250px) and (max-width: 374px) {padding:0 0.5rem } 
@media only screen and (min-width: 375px) and (max-width: 499px) {padding:0 1rem} 
@media only screen and (min-width: 500px) and (max-width: 767px) {padding:0 2rem}            
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0 6rem}


`



const TimeStart = styled.div`
display: flex;

align-items: center;
gap:0.68rem;

@media only screen and (min-width: 1024px) {
  justify-content: space-between;
}

`

const TimeStartText = styled.div`
color:${props => props.blue ? '#4669E8' : ''};





@media only screen and (min-width: 1024px) and (max-width: 1199px) {
font-size:0.75rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1450px) {
font-size:0.8rem;
}


@media only screen and (min-width: 1451px) {
font-weight:400;
font-size:1rem;
line-height:1.8rem;
}

`


const BidContainer = styled.div`

`


const BidPrice = styled.div`
font-weight:700;
font-size:3.4rem;
line-height:5rem;
`


const BidSub = styled.div`
display:flex;
gap:2rem;
margin-top:2rem;
width:100%;



@media only screen and (min-width: 250px) and (max-width: 1023px) {
flex-direction:column;
gap:1rem;
} 

@media only screen and (min-width: 1024px) {
align-items:center;
} 



`





const BidInput = styled.input`

${'' /* height:2.5rem; */}



 outline-color: rgba(0, 0, 0, 0.3);
    padding: 1rem 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    outline-color: rgba(0, 0, 0, 0.3);


@media only screen and (min-width: 1024px) {
width:100%;
} 


`



const BidButton = styled.button`
  width: 100%;
  padding: 0.8rem;
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: 400;
  text-align: center;
  color: white;
  border: none;
  background-color: ${(props) => (props.disabled ? '#BCBCBC' : '#4669E8')}; /* Red if disabled, blue otherwise */

  /* Explicitly target the disabled state */
  &:disabled {
    background-color: #BCBCBC; /* Red background for disabled state */
    cursor: not-allowed;
  }

  @media only screen and (min-width: 250px) and (max-width: 399px) {
    font-size: 0.8rem;
    padding: 1rem;
  }
  @media only screen and (min-width: 250px) and (max-width: 767px) {padding:0.8rem 2rem;}
  @media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0.8rem 4rem;}
  @media only screen and (min-width: 1024px) and (max-width: 1199px) {padding:0.8rem 1rem;}
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {padding:0.8rem 2rem}
  @media only screen and (min-width: 1500px) {padding:0.8rem 3rem;}
`;

// const BidButton = styled.button`
// width:100%;

// background-color: ${(props) => (props.btn ? '#BCBCBC' : '#4669E8')};


//   font-family: Poppins;
//             font-size: 0.9rem;
//             font-weight: 400;
//             text-align: center;
//             color:white;
//             border:none;
//             ${'' /* background-color:#4669E8; */}


//             @media only screen and (min-width: 250px) and (max-width: 399px) {
//               font-size: 0.8rem;
//               padding:1rem;
//             }

//             @media only screen and (min-width: 250px) and (max-width: 767px) {padding:0.8rem 2rem;}
//             @media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0.8rem 4rem;}
//             @media only screen and (min-width: 1024px) and (max-width: 1199px) {padding:0.8rem 1rem;}
//             @media only screen and (min-width: 1200px) and (max-width: 1499px) {padding:0.8rem 2rem}
//             @media only screen and (min-width: 1500px) {padding:0.8rem  3rem;}

// `;









const TimeSub = styled.div`
@media only screen and (min-width: 250px) and (max-width: 374px) {padding:0 0.5rem } 
@media only screen and (min-width: 375px) and (max-width: 499px) {padding:0 1rem} 
@media only screen and (min-width: 500px) and (max-width: 767px) {padding:0 2rem}            
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0 6rem}
`





const CodeContainer = styled.div`
display:flex;
flex-direction:column;
margin-top:2rem;
width:100%;
`

const CodeTitle = styled.div`
font-size:0.9rem;
font-weight:400;
line-height:1.3rem;
`

const CodeInput = styled.input`
margin-top:0.5rem;
${'' /* width:30rem; */}
${'' /* height:2.5rem; */}

    outline-color: rgba(0, 0, 0, 0.3);
    padding: 1rem 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    outline-color: rgba(0, 0, 0, 0.3);
`





// main
const Main = styled.div`
@media (min-width: 1024px) { 
display: grid; 
grid-template-columns: repeat(12, minmax(0, 1fr)); 
}
height:100vh;
padding-top:5rem;
  `



const Container1 = styled.div`
  @media (min-width: 1024px) {
    grid-column: span 5 / span 5;
    background-color: #F5F5F5;
  }
`




const Container2 = styled.div`
@media only screen and (min-width: 250px) and (max-width: 1023px) {
margin-top:2rem;
}

@media (min-width: 1024px) { 
grid-column: span 7 / span 7;
${'' /* padding: 2.1rem 4rem; */}
}
`;





const SubDiv = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;

@media only screen and (min-width: 250px) and (max-width: 374px) {padding:0 0.5rem} 
@media only screen and (min-width: 375px) and (max-width: 499px) {padding:0 1rem} 
@media only screen and (min-width: 500px) and (max-width: 767px) {padding:0 2rem}            
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0 6rem}
@media only screen and (min-width: 1024px) and (max-width: 1500px) {padding:0 4rem}
@media only screen and (min-width: 1501px) {padding:0 8rem}
 `







const Price = styled.div`
margin-top: 1.5rem;
color:#4669E8;
font-weight:500;
font-size:1.563rem;
`

const Title = styled.div`
font-size:0.9rem;
line-height:1.375rem;
span{
color:#4669E8;
}
`





const ImgContainer = styled.div`
margin-top:1.5rem;
display:flex;
justify-content:center;
align-items:center;
border:1px solid #00000033;

img{
object-fit: contain;
width:380px;
height:380px;
@media only screen and (min-width: 355px) and (max-width: 480px) {
width:300px;
height:300px;
}
@media only screen and (min-width: 250px) and (max-width: 354px) {
width:260px;
height:300px;
}
}



`




const BtnContainer = styled.div`
          display:flex;
           justify-content:space-between;
            margin-top:2rem;
            width:100%;
            `


const BaseButton = styled.button`
            font-family: Poppins;
            font-size: 0.9rem;
            font-weight: 400;
            text-align: center;
            color:white;
            border:none;
            background-color:#4669E8;
            
            
            @media only screen and (min-width: 250px) and (max-width: 399px) {
              font-size: 0.8rem;
              padding:1rem;
            }
            
            @media only screen and (min-width: 250px) and (max-width: 767px) {padding:0.8rem 2rem;}
            @media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0.8rem 4rem;}
            @media only screen and (min-width: 1024px) and (max-width: 1199px) {padding:0.8rem 1rem;}
            @media only screen and (min-width: 1200px) and (max-width: 1499px) {padding:0.8rem 2rem}
            @media only screen and (min-width: 1500px) {padding:0.8rem  3rem;}
            `





const EntrollButton = styled(BaseButton)`
  margin-top:1rem;
  ${'' /* background-color: ${(props) => props.primary ? '#4669E8' : '#BCBCBC'}; */}
`;


const ParticipateButton = styled(BaseButton)`
  background-color: ${(props) => props.secondary ? '#4669E8' : '#BCBCBC'};
`;





const BoxContainer = styled.div`
margin-top: ${props => props.mt || '0'};
${'' /* margin-top:1.6rem; */}
background-color: ${props => props.bg || '#FDFDFD'};
display:flex;
align-items: center;
${'' /* width:30rem; */}
width:100%;
img{
height:5.4rem;
width:5.4rem;
}
            `


const Footer = styled.div`
margin-top:1.3rem;
width:100%;
font-size:0.8rem;
font-weight: 400;
line-height:1.3rem;
`







// conatiner 2




const Header = styled.div`
  font-size:1.2rem;
  font-weight:400;
  line-height:1.5rem; 
  `


const EntrollSub = styled.div`
width:30rem;
margin-top:2rem;
display:flex;
flex-direction:column;
align-items:end;
            `

const EntrollPrice = styled.div`
font-weight:500;
color:#4669E8;
font-size:1.5rem;
`

const Details = styled.div`
color:#4669E8;
            `
