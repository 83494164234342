import React, { useContext, useEffect, useState } from 'react'
import { ModalContainer, Modal, Overlay } from "../../../components/modal"
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { CloseContainer } from '../ModalStyled';
import { colors, fonts } from '../../../consts/themes';
import Colors from '../../../consts/Colors';
import { AuthContext } from '../../../consts/AuthContext';
import axiosInstance from '../../../consts/axiosInstance';
import AddAddressModal from './AddAddressModal';




const AddressListModal = ({ close, setActiveAddress, setSelectedAddressId, values, setValues }) => {

  const [authUser, setAuthUser] = useContext(AuthContext);
  const [addressList, setAddressList] = useState([]);
  const [showModal, setShowModal] = useState(false);

  // getting list of all entered address
  const getAddress = async () => {
    try {
      const response = await axiosInstance.get(`getCustomerAddress/${authUser?.user?.id}`);
      setAddressList(response.data);
    } catch (error) {
      // console.log(error.message)
    }
  };



  //add new address
  const addNewAddressHandler = () => {
    setShowModal(true);
  };

  useEffect(() => {
    getAddress()
  }, []);


  return (
    <>
      <ModalContainer>
        <Modal className='w-[80%] sm:w-full flex justify-center'>
          <Overlay >
            <Container >
              {showModal && <AddAddressModal close={setShowModal} apiCallHandler={getAddress} />}
              <CloseContainer >
                <IoClose className='cursor-pointer' size={22} onClick={() => close(false)} />
              </CloseContainer>
              <InnerConatiner>
                <Head>
                  <label>Select Your Shipping Address</label>
                  <ADD onClick={() => addNewAddressHandler()}>
                    <p>+ Add shipping address</p>
                  </ADD>
                </Head>

                <Select>
                  {addressList.length > 0 &&
                    addressList?.map((item) => (
                      <Addressbar
                        key={item?.id}
                        onClick={() => {
                          close(false);
                          setActiveAddress && setActiveAddress(item);
                          setSelectedAddressId && setSelectedAddressId(item?.id);
                          {
                            values &&
                              setValues({
                                ...values,
                                shipping_address_id: item?.id,
                                address_status: 0
                              })
                          }
                          localStorage.setItem(
                            'addname',
                            JSON.stringify(item?.name)
                          );
                        }}
                      >
                        <span>{item?.name}</span>
                        <p>
                          {item?.house_name}, {item?.street_name}, {item?.district},{' '}
                          {item?.state}.
                        </p>
                        <p>Pincode: {item?.pincode}.</p>
                      </Addressbar>
                    ))}
                </Select>

              </InnerConatiner>
            </Container>
          </Overlay>
        </Modal>
      </ModalContainer>
    </>
  )
}

export default AddressListModal;




const Container = styled.div`
display: flex; 
flex-direction:column;
justify-content: center; 
align-items:center;
padding:1rem;
/* width:300px; */
height: 100%;
max-width:100%;
max-height: 29rem;
@media only screen and (min-width: 350px) and (max-width:400px) {width:270px;}
@media only screen and (min-width: 250px) and (max-width:349px) {width:240px;}
@media only screen and (min-width: 250px) and (max-width:768px) {height:27rem;}
`


const InnerConatiner = styled.div`
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap:1.5rem;
overflow-y:scroll;
padding:0.5rem;
`

const Addressbar = styled.div`
  border:1px solid ${colors.line};
  border-radius:8px;
  cursor: pointer;
  margin:10px;
  
  span{
    margin:10px;
    font-size:14px;
    font-weight:${fonts.fontWeight3}
  }
  p{
    margin: 5px 10px;
    font-size:13px;
    font-weight:${fonts.fontWeight3}
  }
  
  @media (max-width: 425px) {
  margin: 10px 15px 10px;
  }
  `


const Select = styled.div`
 display:flex;
 flex-direction:column;
 overflow:scroll;
 `


const ADD = styled.div`
  width:90%;
  cursor:pointer;
  border:1px dashed ${Colors.royalblue};
  border-radius:8px;
  height:60px;
  margin:10px 0 5px;
  display:flex;
  align-items:center;
  justify-content:center;
  
  p{
    font-size:14px;
    margin:0;
    font-weight:${fonts.fontWeight3}
  }
  `

const Head = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
width:100%;

label{
  font-size:16px;
  font-weight:${fonts.fontWeight6};
}

`