import parse from "html-react-parser";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axiosInstance from '../../../../consts/axiosInstance';
import LoadingSpinner from "../../../../components/Button/Loading/LoadingSpinner";
import axios from "axios";

const Wrapper = styled.div`
padding: 35px 80PX;
max-width: 950px;
margin: 100px auto;
line-height: 1.6;
box-shadow: 0px 5px 11px 6px #80808087;
`;

const Header = styled.h1`
  color: #333;
`;

const Header4 = styled.h4`
  color: #595959;
`;

const Header2 = styled.h2`
  color: #555;
`;

const Header3 = styled.h3`
  color: #555;
`;

const Paragraph = styled.p`
  color: #595959;
  font-size: 14px;
`;

const ReturnPolicy = () => {
  const [terms, setTerms] = useState(null)
  const getTerms = async () => {
    try {
      // const response = await axios.get("http://192.168.1.39:8001/api/getReturnPolicy");
      const response = await axios.get("https://dev.clikekart.com/api/getReturnPolicy");
      setTerms(response.data)
    } catch (error) {
      // console.log('error', error)
    }
  }
  useEffect(() => {
    getTerms();
  }, [])
  return (
    <Wrapper>
      {terms ? parse(terms.description) : <LoadingSpinner />}
    </Wrapper>
  )

};

export default ReturnPolicy;
