import React, { useContext } from 'react'
import styled from 'styled-components';
import { useEffect, useState } from 'react'
import axiosInstance from '../../../consts/axiosInstance';
import { AuthContext } from '../../../consts/AuthContext';
import { colors, fonts } from '../../../consts/themes';
import clikeLogo from '../../../assets/Images/png/clicke.png'
import { MdDeleteOutline } from "react-icons/md";
import service from "../../../assets/sale/assistance/inst.png";
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Pusher from 'pusher-js'; // Import Pusher
import { server_api } from '../../../consts/api';


const CartContainer = () => {
  const navigation = useNavigate();
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isProduct, setIsProduct] = useState([]);
  const [cartId, setCartId] = useState('');
  const [total, setTotal] = useState(0);
  const [bidCart, setBidCart] = useState([]);
  const [customPcCart, setCustomPcCart] = useState([]);
  const [cctvCart, setCctvCart] = useState([]);
  const [reward, setReward] = useState(0);
  const [availableReward, setAvailableReward] = useState(0);
  const [userEnterPoints, setUserEnterPoints] = useState(false);



  useEffect(() => {
    const pusher = new Pusher('a349a9c71da9ccbf6d6f', {
      cluster: 'ap2',
      wsHost: '3.111.113.249',
      wsPort: 6001,
      forceTLS: true,
      encrypted: true,
      enableStats: false,
    });

    const channel = pusher.subscribe('product-data-updates');
    channel.bind('App\\Events\\productDetailsChanged', (data) => {
      // Update the cart based on the data received
      setIsProduct(data.cart);
      setTotal(data.total);
      // You can also set bidCart, customPcCart, and cctvCart based on the data
    });

    // Cleanup function to unsubscribe from the channel when the component unmounts
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, []);

  //products cart
  const getallcart = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`cartsDisplay/${authUser?.user?.id}`);
      setIsProduct(response?.data?.cart);
      setCustomPcCart(response?.data?.custompc);
      setCctvCart(response?.data?.cctv);
      setBidCart(response?.data?.bid);
      setTotal(response?.data?.total);
      setCartId(response?.data?.cart[0]?.id);
      setReward(response?.data?.cart[0]?.gained_rewards);
      setAvailableReward(response?.data?.cart[0]?.previousRewards?.rewards);
      // console.log(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const initialProducts = bidCart.filter(item => item.payment_status === 0);
  const winningProducts = bidCart.filter(item => item.payment_status === 1 && item?.bidproduct?.productArray?.length > 0);

  //increase quantity
  const handleAddQuantity = (item) => {
    if (item?.qnty < (item?.stock > 10 ? 10 : item?.stock)) {
      increaseQuantity(item)
    }
  };

  async function increaseQuantity(item) {
    // Store the initial quantity and stock in variables
    const currentStock = item.stock;
    const currentQuantity = item.qnty;

    // If the quantity is less than 4 and greater than 0, show items left
    if (currentStock <= 4 && currentStock > 0) {
      // console.log(`${currentStock} items left`);
    }

    // If the quantity reaches 0, indicate out of stock
    if (currentStock === 0) {
      // console.log("Item is out of stock");
      return; // Prevent any further action if the item is out of stock
    }

    // Ensure the stock value is updated before incrementing the quantity
    if (currentQuantity < currentStock) {
      try {
        const formData = {
          id: cartId,
          product_category_id: item.product_category_id,
          product_id: item.productId,
          amount: item.parent_product_id
            ? item.comboPrice
            : item.offer_price && item.offer_price !== 0
              ? item.offer_price
              : item.price,
          total_amount: 1,
          qnty: currentQuantity + 1,
        };

        const options = {
          method: "POST",
          url: "changeQuantity",
          headers: {
            "Content-Type": "application/json",
          },
          data: formData,
        };

        await axiosInstance.request(options);

        // Re-fetch cart to reflect the updated quantity and stock
        getallcart();

      } catch (error) {
        console.error("Error:", error.message || error);
      }
    } else {
      // console.log("Cannot increase quantity beyond available stock.");
    }
  }
  // decrease quantity
  const handleRemoveQuantity = (item) => {
    if (item?.qnty > 1) {
      decreaseQuantity(item)
    }
  };

  async function decreaseQuantity(item) {
    // Ensure the stock value is updated before decrementing the quantity
    if (item.qnty > 1) {
      try {
        const formData = {
          id: cartId,
          product_category_id: item.product_category_id,
          product_id: item.productId,
          amount: item.parent_product_id ? item.comboPrice : item.offer_price && item.offer_price !== 0 ? item.offer_price : item.price,
          total_amount: -1,
          qnty: item.qnty - 1,
        };

        const options = {
          method: "POST",
          url: 'changeQuantity',
          headers: {
            "Content-Type": "application/json",
          },
          data: formData,
        };

        await axiosInstance.request(options);
        getallcart();
      } catch (error) {
        console.error("Error:", error.message || error);
      }
    }
  }
  // remove product from cart
  const removeCart = (item) => {
    removeItemFromCart(item);
  }

  async function removeItemFromCart(item) {
    try {
      const formData = {
        cart_id: cartId,
        product_id: item.productId,
        total_amount: item.parent_product_id ? (-(item.qnty * item.comboPrice)) :
          item.offer_price && item.offer_price !== 0 ? (-(item.qnty * item.offer_price)) :
            (-(item.qnty * item.price)),
      };

      const options = {
        method: "POST",
        url: 'removeProduct',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      const response = await axiosInstance.request(options);
      getallcart();

    } catch (error) {
      console.error("Error:", error.message || error);
    }
  }



  // remove bid from cart
  async function removeBid(item) {
    try {
      const formData = {
        bid_id: item?.bid_id,
        user_id: authUser?.user?.id,
      };
      const options = {
        method: "DELETE",
        url: 'removeFromBidCart',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      const response = await axiosInstance.request(options);
      getallcart();
    } catch (error) {
      console.error("Error:", error.message || error);
    }
  }
  async function removeInitialBid(item) {
    try {
      const formData = {
        bid_id: item?.bid_id,
        user_id: authUser?.user?.id,
      };
      const options = {
        method: "DELETE",
        url: `${server_api}user/removeBidInitial?bid_id=${formData.bid_id}&user_id=${formData.user_id}`,
        // url: `${server_api}user/removeBidInitial?bid_id=${formData.bid_id}&user_id=${formData.user_id}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axiosInstance.request(options);
      getallcart(); // Assuming this updates your cart after a bid is removed
    } catch (error) {
      console.error("Error:", error.message || error);
    }
  }


  // remove custom desktop items
  const removeCustomDesktop = async (item) => {
    try {
      setLoading(true);
      const formData = {
        customdesktop_id: item.id,
      };
      const options = {
        method: 'POST',
        url: 'deletebuildpc',
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData,
      };
      const response = await axiosInstance.request(options);
    } catch (error) {
      console.error('Error in pc insertion:', error);
      setError('An error occurred while processing your request.');
    } finally {
      setLoading(false);
      getallcart();
    }
  };




  // remove custom cctv items
  const removeCustomCctv = async (item) => {
    try {
      setLoading(true);
      const formData = {
        cartid: item.id,
      };
      const options = {
        method: 'DELETE',
        url: 'deletecctvcart',
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData,
      };
      const response = await axiosInstance.request(options);
    } catch (error) {
      console.error('Error in pc deletion:', error);
      setError('An error occurred while processing your request.');
    } finally {
      setLoading(false);
      getallcart();
    }
  };

  useEffect(() => {
    getallcart();
  }, [authUser?.user?.id]);


  useEffect(() => {
    localStorage.removeItem('redeemtotal');
    localStorage.removeItem('coininserted');
  }, [])



  const handleApplyClick = async () => {
    const { value: inputValue } = await Swal.fire({
      title: 'Redeem',
      input: 'text',
      inputPlaceholder: 'Enter your value here',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'This field is required';
        }
        if (!/^\d+$/.test(value)) {
          return 'Please enter a valid number without any alphabets or special characters';
        }
        if (parseInt(value, 10) <= 0) {
          return 'Number must be greater than 0';
        }
        return null;
      }
    });

    if (inputValue) {
      sendInputToAPI(inputValue);
      setUserEnterPoints(true);
    }
  };


  const sendInputToAPI = async (value) => {
    try {
      const formData = {
        addpoints: value,
        total: total,
      }
      const options = {
        method: 'POST',
        url: 'applyclikepoints',
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData,
      };
      const response = await axiosInstance.request(options);
      // console.log(response.data);
      if (response.data.code === 200) {
        Swal.fire('Success', `${response.data.message}`, 'success');
        setTotal(response.data.total);
        localStorage.setItem('redeemtotal', response.data.total);
        localStorage.setItem('coininserted', value);
      } else {
        Swal.fire('Error', `${response.data.message}`, 'error');
        setUserEnterPoints(false);
      }
    } catch (error) {
      Swal.fire('Error', 'There was an error submitting your value', 'error');
    }
  };

  const handleSubmit = () => {
    // Filter out the out-of-stock items
    const outOfStockItems = isProduct[0]?.productArray.filter(item => item?.stock === 0);
    // console.log("submit clicked")
    if (outOfStockItems?.length > 0) {
      // Show SweetAlert if there are out-of-stock items
      Swal.fire({
        title: 'Move out of Stock Items',
        html: `One or more item(s) in your cart are out of stock. Please remove them to proceed
        `,
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    } else {
      // If no out-of-stock items, navigate to the billing page
      navigation("/sales/billing");
    }
  };


  const redeemtotal = localStorage.getItem('redeemtotal');
  // console.log("coinredeem", redeemtotal);



  const coininserted = localStorage.getItem('coininserted');

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Conatiner>
      {/* product cart */}
      {isProduct !== undefined && isProduct.length > 0 && isProduct[0].productArray.length > 0 &&
        <Section>
          <ItemContainer>
            {isProduct[0].productArray.map((item, index) => (
              <div key={index}>
                <SearchItem>
                  <SubContainer className='w-full md:w-11/12 flex-col md:flex-row'>
                    {/* <div className='w-32 h-32'>
                      <img className='w-full h-full object-cover' src={item?.productImages && JSON.parse(item?.productImages)[0]} alt="" />
                    </div> */}
                    <ItemImg className=' w-full md:w-1/4'>
                      <img className='w-full h-full object-contain' src={item?.productImages && JSON.parse(item?.productImages)[0]} alt='img' />
                    </ItemImg>
                    <ItemText className='w-full md:w-3/4'>
                      <ItemHead>{item?.title}</ItemHead>
                      <ItemDes className='line-clamp-5'>{item?.description}</ItemDes>
                      <OfferPrice>₹{item.qnty * (item.parent_product_id ? item.comboPrice :
                        item.amount && item.amount !== 0 ? item.amount :
                          item.price)}</OfferPrice>
                      {item.offer_price && item.offer_price !== 0 && <CutPrice>M.R.P ₹{item?.price}</CutPrice>}
                      <Coin><img src={clikeLogo} className='w-8 h-6' alt='coin=img' />{item.qnty * item?.reward_points} Clike Coins</Coin>
                      {
                        item?.stock == 0 ?
                          (
                            <p style={{ color: 'red', fontWeight: 500 }}>Out of Stock</p>
                          ) : (<Features>
                            <button onClick={() => handleRemoveQuantity(item)}>-</button>
                            <Count>{item?.qnty}</Count>
                            <button onClick={() => handleAddQuantity(item)}>+</button>
                            <DeleteIcon onClick={() => removeCart(item)} />
                          </Features>)
                      }
                    </ItemText>
                  </SubContainer>
                  <Remove onClick={() => removeCart(item)}>remove</Remove>
                </SearchItem>
                {item?.installationArray &&
                  <SearchItem>
                    <SubContainer>
                      {/* <ItemImg>
                        <img src={service} alt='installation-img' />
                      </ItemImg> */}
                      <ItemText>
                        <ItemHead>{item?.installationArray?.title}</ItemHead>
                        <ItemDes>{item?.installationArray?.description}</ItemDes>
                        <OfferPrice>₹{item?.installationArray?.amount * item.qnty}</OfferPrice>
                        <Features>
                          <DeleteIcon />
                        </Features>
                      </ItemText>
                    </SubContainer>
                    {/* <Remove>remove</Remove> */}
                  </SearchItem>
                }
              </div>
            ))}
          </ItemContainer>
        </Section>
      }

      {/* bid cart */}
      {bidCart && bidCart.length > 0 && (
        <Section>
          <CartHeadContainer>
            <CartHead>Bid Product</CartHead>
          </CartHeadContainer>
          <ItemContainer>

            {initialProducts.length > 0 && (
              <>
                <h4>Initial Product</h4>
                {initialProducts.map((item, itemIndex) => (
                  <div key={`initial-${itemIndex}`}>
                    <SearchItem>
                      <SubContainer className='w-full md:w-11/12 flex-col md:flex-row'>
                        <ItemImg className=' w-full md:w-1/4'>
                          <img className='w-full h-full object-contain' src={item?.images && JSON.parse(item.images)[0]} alt="img" />
                        </ItemImg>
                        <ItemText className='w-full md:w-3/4'>
                          <ItemHead>{item?.title}</ItemHead>
                          <ItemDes>{item?.description}</ItemDes>
                          <OfferPrice>₹ {item.offer_price}</OfferPrice>
                          <Features>
                            <Count>1</Count>
                            <DeleteIcon onClick={() => removeBid(item)} />
                          </Features>
                        </ItemText>
                      </SubContainer>
                      <Remove onClick={() => removeInitialBid(item)}>remove</Remove>
                    </SearchItem>
                  </div>
                ))}
              </>
            )}

            {/* Check for winning products and show the heading if they exist */}
            {winningProducts.length > 0 && (
              <>
                <h4>Winning Product</h4>
                {winningProducts.map((item, itemIndex) => (
                  <div key={`winning-${itemIndex}`}>
                    {item.bidproduct.productArray.map((product, productIndex) => (
                      <SearchItem key={productIndex}>
                        <SubContainer className='w-full md:w-11/12 flex-col md:flex-row'>
                          <ItemImg className=' w-full md:w-1/4'>
                            <img className='w-full h-full object-contain' src={product?.productImages && JSON.parse(product.productImages)} alt="img" />
                          </ItemImg>
                          <ItemText className='w-full md:w-3/4'>
                            <ItemHead>{product?.title}</ItemHead>
                            <ItemDes>{product?.description}</ItemDes>
                            <OfferPrice>₹ {product?.bidAmount}</OfferPrice>
                          </ItemText>
                        </SubContainer>
                        <Remove onClick={() => removeBid(item)}>remove</Remove>
                      </SearchItem>
                    ))}
                  </div>
                ))}
              </>
            )}
          </ItemContainer>
        </Section>
      )}
      {/* custom pc cart */}
      {
        customPcCart !== undefined && customPcCart.length > 0 && (
          customPcCart.map((data) => (
            <Section>
              <CartHeadContainer>
                <CartHead>Desktop Customization</CartHead>
                <Delete onClick={() => removeCustomDesktop(data)} />
              </CartHeadContainer>
              <ItemContainer>
                {data.products.map((item) => (
                  <>
                    <SearchItem>
                      <SubContainer className='w-full md:w-11/12 flex-col md:flex-row'>
                        <ItemImg className=' w-full md:w-1/4'>
                          <img className='w-full h-full object-contain' src={item?.images && JSON.parse(item.images)[0]} alt='img' />
                        </ItemImg>
                        <ItemText className='w-full md:w-3/4'>
                          <ItemHead>{item?.title}</ItemHead>
                          <ItemDes>{item?.description}</ItemDes>
                          <OfferPrice>₹ {item.amount}</OfferPrice>
                          <Features>
                            <Count>{item?.quantity}</Count>
                            <DeleteIcon />
                          </Features>
                        </ItemText>
                      </SubContainer>
                    </SearchItem>
                  </>
                ))}
              </ItemContainer>
            </Section>
          )))
      }

      {/* custom cctv  cart */}
      {
        cctvCart !== undefined && cctvCart.length > 0 && (
          cctvCart.map((data) => (
            <Section>
              <CartHeadContainer>
                <CartHead>Cctv Customization</CartHead>
                <Delete onClick={() => removeCustomCctv(data)} />
              </CartHeadContainer>
              <ItemContainer>
                {data?.products?.map((item) => (
                  <>
                    <SearchItem>
                      <SubContainer className='w-full md:w-11/12 flex-col md:flex-row'>
                        <ItemImg className='w-full md:w-1/4'>
                          <img className='w-full h-full object-contain' src={item?.images && JSON.parse(item.images)[0]} alt='img' />
                        </ItemImg>
                        <ItemText className='w-full md:w-3/4'>
                          <ItemHead>{item?.title}</ItemHead>
                          <ItemDes>{item?.description}</ItemDes>
                          <OfferPrice>₹ {item.amount}</OfferPrice>
                          <Features>
                            <Count>{item?.quantity}</Count>
                            <DeleteIcon />
                          </Features>
                        </ItemText>
                      </SubContainer>
                    </SearchItem>
                  </>
                ))}
              </ItemContainer>
            </Section>
          )))
      }

      <RedeemContainer>
        <RedeemMin>
          <RedeemSubOne>
            <RedeemWrap>
              <RedeemTextMain>Redeem your Clicke points</RedeemTextMain>
              <RedeemTextSub>{`${availableReward} clikepoints (worth ₹ ${availableReward * 10})`}</RedeemTextSub>
            </RedeemWrap>
            <Coin ><img src={clikeLogo} className='w-8 h-6' alt='coin-img' />
              {isProduct !== undefined && isProduct.length > 0 && `Reward Points : ${availableReward} + ${reward} Clike Coins (worth ₹ ${reward * 10})`}
            </Coin>
          </RedeemSubOne>
          {!userEnterPoints && (<Apply onClick={handleApplyClick}>Apply</Apply>)}
        </RedeemMin>
      </RedeemContainer>

      <TotalContainer>
        <TotalMain>
          <TotalHead>Total Amount</TotalHead>
          <TotalAmount>₹{total}</TotalAmount>
        </TotalMain>
      </TotalContainer>

      <SubmitContainer>
        <SubmitMain>
          <SubmitContinue onClick={() => navigation("/")}>Continue Shopping</SubmitContinue>
          {total > 0 && <SubmitBtn onClick={() => handleSubmit()}>Submit</SubmitBtn>}
        </SubmitMain>
      </SubmitContainer>

    </Conatiner >
  )
}

export default CartContainer;












const SubmitContainer = styled.div`
width:100%;
border-radius:8px;
display: flex;
justify-content: flex-end;
`

const SubmitMain = styled.div`
display: flex;
align-items: center;
gap:2rem;
@media only screen and (min-width:300px) and (max-width:400px) {
flex-direction: column;
gap:0.5rem;
}
`

const SubmitContinue = styled.div`
color: #4669E8;
font-weight: 500;
font-size: 0.8rem;
@media only screen and (min-width:300px) and (max-width:767px) {
font-size:0.7rem;
}
`
const SubmitBtn = styled.div`
font-size:0.9rem;
padding:0.5rem 4rem;
background-color:#4669E8 ;
color: white;
border-radius: 10px;
cursor: pointer;
@media only screen and (min-width:300px) and (max-width:767px) {
padding:0.5rem 3rem;
font-size:0.7rem;

}
`

const TotalContainer = styled.div`
width:100%;
border-radius:8px;
display: flex;
justify-content: flex-end;
`



const TotalMain = styled.div`
display: flex;
align-items: center;
gap:1rem;
@media only screen and (min-width:300px) and (max-width:400px) {
flex-direction: column;
gap:0.5rem;
}
`

const TotalHead = styled.div`
font-size: 1rem;
font-weight:600;
@media only screen and (min-width:300px) and (max-width:767px) {
font-size:0.8rem;
}
`
const TotalAmount = styled.div`
font-size: 1rem;
font-weight:500;
@media only screen and (min-width:300px) and (max-width:767px) {
font-size:0.8rem;
}
`

const RedeemSubOne = styled.div`
@media only screen and (min-width:300px) and (max-width:1023px) {
flex-direction: column;
gap:0.7rem;
}
display: flex;
gap:1rem;
`


const RedeemMin = styled.div`
padding:20px 50px;
display: flex;
justify-content:space-between;
align-items: center;
@media only screen and (min-width:300px) and (max-width:1023px) {
padding:1rem;
}
`


const Apply = styled.div`
color:#4669E8;
font-size: 0.8rem;
font-weight:500;
cursor:pointer;
&:hover {
  color: #2e50b7; 
}
`

const RedeemContainer = styled.div`
width:100%;
border-radius:8px;
background-color: #E7E9F0;
`


const RedeemWrap = styled.div`
`

const RedeemTextMain = styled.div`
font-size: 0.9rem;
font-weight:500;
@media only screen and (min-width:300px) and (max-width:400px) {
font-size:0.7rem;
}
`

const RedeemTextSub = styled.div`
color: black;
font-size:0.75rem;
@media only screen and (min-width:300px) and (max-width:400px) {
font-size:0.6rem;
}
`

const CartHeadContainer = styled.div`
margin-top:1rem;
display: flex;
justify-content: center;
align-items: center;
gap:0.5rem;
`

const CartHead = styled.div`
border: 1px solid ${colors.line};
border-radius: 8px;
font-weight: 500;
font-size:0.9rem;
padding:0.5rem;
@media only screen and (min-width:300px) and (max-width:549px) {
font-size: 0.8rem;
}
`

const Delete = styled(MdDeleteOutline)`
color:red;
width:24px;
height:24px;
`

const DeleteIcon = styled(MdDeleteOutline)`
@media only screen and (min-width:768px){
  display: none;
}
color:red;
width:24px;
height:24px;
padding-left:6px;
`

const Count = styled.div`
        width:70px;
        margin:0 8px;
        border:1px solid ${colors.light_grey};
        height:25px;
        border-radius:8px;
        display:flex;
        align-items:center;
        justify-content:center;
        font-size:13px;
        @media (max-width:425px){
            width:40px;
        }
        `

const Features = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    margin:5px 0px 0px;
    a{
        font-size:12px;
        color:${colors.line};
        font-weight:${fonts.fontWeight3};
        margin:0;
    }
    button{
        background-color:${colors.white};
        border:1px solid ${colors.light_grey};
        width:25px;
        height:25px;
        border-radius:30%;
        font-size:12px;
        outline:none;
    }
        `

const ItemHead = styled.div`
font-weight: 500;
font-size:1rem;
@media only screen and (min-width:300px) and (max-width:549px) {
font-size: 0.8rem;
}
`
const ItemDes = styled.div`
font-size: 0.8rem;
font-weight: 400;

@media only screen and (min-width:768px) and (max-width:1023px) {
font-size: 0.8rem;
}
@media only screen and (min-width:300px) and (max-width:767px) {
font-size: 0.7rem;
}
`

const SubContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
gap:0.8rem;
`

const Conatiner = styled.div`
margin:1rem 0;
padding: 0 2rem;
display: flex;
flex-direction: column;
gap:2rem;
@media only screen and (min-width:350px) and (max-width:1500px) {
  padding:0 1rem;
}
@media only screen and (min-width:300px) and (max-width:349px) {
  padding:0 0.5rem;
}
`

const Section = styled.div`
width:100%;
border: 1px solid ${colors.line};
border-radius:8px;
`

const ItemContainer = styled.div`
margin:1rem;
display: flex;
flex-direction: column;
gap:1rem;
@media only screen and (min-width:300px) and (max-width:349px) {
margin:0.5rem;
}
`;

const SearchItem = styled.div`
border: 1px solid ${colors.line};
display: flex;
justify-content:space-between;
align-items: center;
border-radius: 10px;
padding:0.7rem;
`;

const ItemImg = styled.div`
border: 1px solid ${colors.line};
padding:5px;
display: flex;
justify-content: center;

img{
@media only screen and (min-width:768px) and (max-width:1023px) {
width:9rem;
height:9rem;
}
@media only screen and (min-width:450px) and (max-width:767px) {
width:8rem;
height:8rem;
}
@media only screen and (min-width:300px) and (max-width:449px) {
width:6rem;
height:6rem;
}
}

`;

const ItemText = styled.div`
display: flex;
flex-direction: column;
gap:0.4rem;
`;


const Remove = styled.div`
@media only screen and (min-width:300px) and (max-width:767px) {
display: none;
}
color:red;
cursor: pointer;
`;


const CutPrice = styled.div`
color: ${colors.light_grey};
text-decoration-line: line-through;
font-size: 0.8rem;
@media only screen and (min-width:768px) and (max-width:1023px) {
font-size:0.7rem;
}
@media only screen and (min-width:300px) and (max-width:767px) {
font-size:0.6rem;
}
`;


const OfferPrice = styled.div`
font-family: Arial;
font-size: 1rem;
font-style: normal;
font-weight: 600;
@media only screen and (min-width:768px) and (max-width:1023px) {
font-size:1rem;
}
@media only screen and (min-width:300px) and (max-width:767px) {
font-size:0.8rem;
}
`;


const Coin = styled.div`
color: ${colors.blue};
display: flex;
align-items: center;
font-size: 0.8rem;
@media only screen and (min-width:768px) and (max-width:1023px) {
  font-size: 0.7rem;
}
@media only screen and (min-width:300px) and (max-width:767px) {
font-size:0.6rem;
}

  img {
    /* width: 18px; */
    /* height: 18px; */
    padding-inline: 5px;

@media only screen and (min-width:300px) and (max-width:767px) {
width:12px;
height:12px;
}
  }
`;





// old cart code
// const CartContainer = () => {
//   const navigation = useNavigate();
//   const [authUser, setAuthUser] = useContext(AuthContext);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [isProduct, setIsProduct] = useState([]);
//   const [cartId, setCartId] = useState('');
//   const [total, setTotal] = useState(0);
//   const [bidCart, setBidCart] = useState([]);
//   const [customPcCart, setCustomPcCart] = useState([]);
//   const [cctvCart, setCctvCart] = useState([]);
//   const [reward, setReward] = useState(0);

//   //products cart
//   const getallcart = async () => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get(`getAllCart/${authUser?.user?.id}`);
//       console.log('Response: of get all cart', response.data[0].productArray);
//       setTotal(response?.data[0]?.total_amount)
//       setIsProduct(response?.data[0]?.productArray);
//       setCartId(response.data[0].id);
//       setReward(response?.data[0]?.gained_rewards)

//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   // bid cart
//   const getCustomerBidCart = async () => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get(`customerBidCart/${authUser?.user?.id}`);
//       setBidCart(response?.data);
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   // desktop custom cart
//   const getDesktopCart = async () => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get(`customPCCart/${authUser?.user?.id}`);
//       setCustomPcCart(response?.data);
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };



//   // cctv custom cart
//   const getCctvCart = async () => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get(`cctvfinalcart/${authUser?.user?.id}`);
//       setCctvCart(response?.data);
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };



//   //increase quantity
//   const handleAddQuantity = (item) => {
//     if (item?.qnty < 10) {
//       increaseQuantity(item)
//     }
//   };

//   async function increaseQuantity(item) {
//     try {
//       const formData = {
//         id: cartId,
//         product_category_id: item.product_category_id,
//         product_id: item.productId,
//         // amount: item.offer_price,
//         // amount: item.parent_product_id ? item.comboPrice : item.offer_price,
//         amount: item.parent_product_id ? item.comboPrice : item.offer_price && item.offer_price !== 0 ? item.offer_price : item.price,
//         total_amount: 1,
//         qnty: ++item.qnty,
//       };
//       const options = {
//         method: "POST",
//         url: 'changeQuantity',
//         headers: {
//           "Content-Type": "application/json",
//         },
//         data: formData,
//       };
//       const response = await axiosInstance.request(options);
//       console.log("Response: for increase quantity", response.data);
//       getallcart()
//     } catch (error) {
//       console.error("Error:", error.message || error);
//     }
//   }



//   // decrease quantity
//   const handleRemoveQuantity = (item) => {
//     if (item?.qnty > 1) {
//       decreaseQuantity(item)
//     }
//   };

//   async function decreaseQuantity(item) {
//     try {
//       const formData = {
//         id: cartId,
//         product_category_id: item.product_category_id,
//         product_id: item.productId,
//         // amount: item.parent_product_id ? item.comboPrice : item.offer_price,
//         amount: item.parent_product_id ? item.comboPrice : item.offer_price && item.offer_price !== 0 ? item.offer_price : item.price,
//         total_amount: -1,
//         qnty: --item.qnty,
//       };

//       const options = {
//         method: "POST",
//         url: 'changeQuantity',
//         headers: {
//           "Content-Type": "application/json",
//         },
//         data: formData,
//       };

//       const response = await axiosInstance.request(options);
//       console.log("Response:", response.data);
//       getallcart();

//     } catch (error) {
//       console.error("Error:", error.message || error);
//     }
//   }


//   // remove product from cart
//   const removeCart = (item) => {
//     removeItemFromCart(item);
//   }

//   async function removeItemFromCart(item) {
//     try {
//       const formData = {
//         cart_id: cartId,
//         product_id: item.productId,
//         // total_amount: -(item.qnty * item.offer_price),
//         // total_amount: item.parent_product_id ? (-(item.qnty * item.comboPrice)) : (-(item.qnty * item.offer_price)),
//         total_amount: item.parent_product_id ? (-(item.qnty * item.comboPrice)) :
//           item.offer_price && item.offer_price !== 0 ? (-(item.qnty * item.offer_price)) :
//             (-(item.qnty * item.price)),
//       };

//       const options = {
//         method: "POST",
//         url: 'removeProduct',
//         headers: {
//           "Content-Type": "application/json",
//         },
//         data: formData,
//       };

//       const response = await axiosInstance.request(options);
//       console.log("Response:", response.data);
//       getallcart()

//     } catch (error) {
//       console.error("Error:", error.message || error);
//     }
//   }



//   // remove bid from cart
//   async function removeBid(item) {
//     try {
//       const formData = {
//         bid_id: item?.bid_id,
//         user_id: authUser?.user?.id,
//       };
//       const options = {
//         method: "DELETE",
//         url: 'removeFromBidCart',
//         headers: {
//           "Content-Type": "application/json",
//         },
//         data: formData,
//       };
//       const response = await axiosInstance.request(options);
//       console.log("Response:", response.data);
//       getCustomerBidCart();
//     } catch (error) {
//       console.error("Error:", error.message || error);
//     }
//   }

//   // remove custom desktop items
//   const removeCustomDesktop = async (item) => {
//     try {
//       setLoading(true);
//       const formData = {
//         customdesktop_id: item.id,
//       };
//       const options = {
//         method: 'POST',
//         url: 'deletebuildpc',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         data: formData,
//       };
//       const response = await axiosInstance.request(options);
//       console.log('Response for pc delete', response.data);
//     } catch (error) {
//       console.error('Error in pc insertion:', error);
//       setError('An error occurred while processing your request.');
//     } finally {
//       setLoading(false);
//       getDesktopCart();
//     }
//   };

//   // remove custom cctv items
//   const removeCustomCctv = async (item) => {
//     try {
//       setLoading(true);
//       const formData = {
//         cartid: item.id,
//       };
//       const options = {
//         method: 'DELETE',
//         url: 'deletecctvcart',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         data: formData,
//       };
//       const response = await axiosInstance.request(options);
//       console.log('Response for pc delete', response);
//     } catch (error) {
//       console.error('Error in pc deletion:', error);
//       setError('An error occurred while processing your request.');
//     } finally {
//       setLoading(false);
//       getCctvCart();
//     }
//   };

//   useEffect(() => {
//     getallcart();
//     getCustomerBidCart();
//     getDesktopCart();
//     getCctvCart();
//   }, []);


//   if (loading) {
//     return <LoadingSpinner />
//   }

//   return (
//     <Conatiner>
//       {/* product cart */}


//       {isProduct.length > 0 &&
//         <Section>
//           <ItemContainer>
//             {isProduct.length > 0 && isProduct?.map((item) => (
//               <>
//                 <SearchItem>
//                   <SubContainer>
//                     <ItemImg>
//                       <img src={item.productImages && JSON.parse(item.productImages)[0]} alt='img' />
//                     </ItemImg>
//                     <ItemText>
//                       <ItemHead>{item?.title}</ItemHead>
//                       <ItemDes>{item?.description}</ItemDes>

//                       <OfferPrice>₹{item.qnty * (item.parent_product_id ? item.comboPrice :
//                         item.offer_price && item.offer_price !== 0 ? item.offer_price :
//                           item.price)}</OfferPrice>


//                       {item.offer_price && item.offer_price !== 0 && <CutPrice>M.R.P ₹{item?.price}</CutPrice>}


//                       <Coin><img src={clikeLogo} alt='coin=img' />{item.qnty * item?.reward_points} Clike Coins</Coin>
//                       <Features>
//                         <button onClick={() => handleRemoveQuantity(item)}>-</button>
//                         <Count>{item?.qnty}</Count>
//                         <button onClick={() => handleAddQuantity(item)}>+</button>
//                         <DeleteIcon onClick={() => removeCart(item)} />
//                       </Features>
//                     </ItemText>
//                   </SubContainer>
//                   <Remove onClick={() => removeCart(item)}>remove</Remove>
//                 </SearchItem>
//                 {item?.installationArray &&
//                   <SearchItem>
//                     <SubContainer>
//                       <ItemImg>
//                         <img src={service} alt='installation-img' />
//                       </ItemImg>
//                       <ItemText>
//                         <ItemHead>{item?.installationArray?.title}</ItemHead>
//                         <ItemDes>{item?.installationArray?.description}</ItemDes>
//                         <OfferPrice>₹{item?.installationArray?.amount * item.qnty}</OfferPrice>
//                         <Features>
//                           <DeleteIcon />
//                         </Features>
//                       </ItemText>
//                     </SubContainer>
//                     {/* <Remove>remove</Remove> */}
//                   </SearchItem>
//                 }
//               </>
//             ))}
//           </ItemContainer>
//         </Section>
//       }

//       {/* bid cart */}
//       {bidCart !== undefined && bidCart.length > 0 && (
//         <Section>
//           <CartHeadContainer><CartHead>Bid Product</CartHead></CartHeadContainer>
//           <ItemContainer>
//             {bidCart !== undefined && bidCart.length > 0 && bidCart.map((item) => (
//               <SearchItem>
//                 <SubContainer>
//                   <ItemImg>
//                     <img src={item?.images && JSON.parse(item.images)[0]} alt='img' />
//                   </ItemImg>
//                   <ItemText>
//                     <ItemHead>{item?.title}</ItemHead>
//                     <ItemDes>{item?.description}</ItemDes>
//                     <OfferPrice>₹ {item.amount}</OfferPrice>
//                     <Features>
//                       <Count>1</Count>
//                       <DeleteIcon onClick={() => removeBid(item)} />
//                     </Features>
//                   </ItemText>
//                 </SubContainer>
//                 <Remove onClick={() => removeBid(item)}>remove</Remove>
//               </SearchItem>
//             ))}
//           </ItemContainer>
//         </Section>
//       )}


//       {/* custom pc cart */}
//       {customPcCart !== undefined && customPcCart.length > 0 && (
//         customPcCart.map((data) => (
//           <Section>
//             <CartHeadContainer>
//               <CartHead>Desktop Customization</CartHead>
//               <Delete onClick={() => removeCustomDesktop(data)} />
//             </CartHeadContainer>
//             <ItemContainer>
//               {data?.products?.map((item) => (
//                 <>
//                   <SearchItem>
//                     <SubContainer>
//                       <ItemImg>
//                         <img src={item?.images && JSON.parse(item.images)[0]} alt='img' />
//                       </ItemImg>
//                       <ItemText>
//                         <ItemHead>{item?.title}</ItemHead>
//                         <ItemDes>{item?.description}</ItemDes>
//                         <OfferPrice>₹ {item.amount}</OfferPrice>
//                         <Features>
//                           <Count>{item?.quantity}</Count>
//                           <DeleteIcon />
//                         </Features>
//                       </ItemText>
//                     </SubContainer>
//                   </SearchItem>
//                 </>
//               ))}
//             </ItemContainer>
//           </Section>
//         )))}


//       {/* custom cctv  cart */}
//       {cctvCart !== undefined && cctvCart.length > 0 && (
//         cctvCart.map((data) => (
//           <Section>
//             <CartHeadContainer>
//               <CartHead>Cctv Customization</CartHead>
//               <Delete onClick={() => removeCustomCctv(data)} />
//             </CartHeadContainer>
//             <ItemContainer>
//               {data?.products?.map((item) => (
//                 <>
//                   <SearchItem>
//                     <SubContainer>
//                       <ItemImg>
//                         <img src={item?.images && JSON.parse(item.images)[0]} alt='img' />
//                       </ItemImg>
//                       <ItemText>
//                         <ItemHead>{item?.title}</ItemHead>
//                         <ItemDes>{item?.description}</ItemDes>
//                         <OfferPrice>₹ {item.amount}</OfferPrice>
//                         <Features>
//                           <Count>{item?.quantity}</Count>
//                           <DeleteIcon />
//                         </Features>
//                       </ItemText>
//                     </SubContainer>
//                   </SearchItem>
//                 </>
//               ))}
//             </ItemContainer>
//           </Section>
//         )))}

//       <RedeemContainer>
//         <RedeemMin>
//           <RedeemSubOne>
//             <RedeemWrap>
//               <RedeemTextMain>Redeem your Clicke points</RedeemTextMain>
//               <RedeemTextSub>(Minunum 100 clike points required)</RedeemTextSub>
//             </RedeemWrap>
//             <Coin><img src={clikeLogo} alt='coin=img' />{reward} Clike Coins (worth ₹{reward * 10})</Coin>
//           </RedeemSubOne>
//           <Apply>Apply</Apply>
//         </RedeemMin>
//       </RedeemContainer>

//       <TotalContainer>
//         <TotalMain>
//           <TotalHead>Total Amount</TotalHead>
//           <TotalAmount>₹{total}</TotalAmount>
//         </TotalMain>
//       </TotalContainer>

//       <SubmitContainer>
//         <SubmitMain>
//           <SubmitContinue onClick={() => navigation("/")}>Continue Shopping</SubmitContinue>
//           {isProduct.length === 0 ? '' : <SubmitBtn onClick={() => navigation("/sales/billing")}>Submit</SubmitBtn>}
//         </SubmitMain>
//       </SubmitContainer>

//     </Conatiner>
//   )
// }

// export default CartContainer;






