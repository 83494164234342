import React, { useState, useEffect, useContext } from 'react';
import { Radio, RadioInput, ButtonDiv, MainSide, LeftSide, BillingDiv, BoxsDiv, SideDiv, ContainerDiv, } from '../../screens/sale/product/FormfileStyle';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../consts/AuthContext';
import LoadingSpinner from '../../components/Button/Loading/LoadingSpinner';
import ErrorComponent from '../../components/Button/error/ErrorComponent';
import AddressListModal from '../../components/Modals/Address/AddressListModal';
import axiosInstance from '../../consts/axiosInstance';
import Swal from 'sweetalert2';
import axios from 'axios';
import { server_api } from '../../consts/api';

const ServiceRegAddress = () => {
  const { state } = useLocation();
  const { servData } = state;
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authUser, setAuthUser] = useContext(AuthContext);

  // open address modal
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState(1)
  const [sameActiveAddress, setsameActiveAddress] = useState(null);
  const [billingActiveAddress, setBillingActiveAddress] = useState(null);
  const [serviceActiveAddress, setserviceActiveAddress] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  //fetch default address
  const fetchAddresses = async () => {
    try {
      const response = await axiosInstance.get(`getCustomerAddress/${authUser?.user?.id}`);
      const data = response.data;
      // Find the address with "default": 1
      const defaultAddr = data.find(address => address.default === 1);
      setsameActiveAddress(defaultAddr);
      setserviceActiveAddress(defaultAddr);
      setBillingActiveAddress(defaultAddr);
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  // new section
  function handleClicks(val) {
    setStatus(val);
    if (val === 1) {
      setserviceActiveAddress(null);
      setBillingActiveAddress(null);
      fetchAddresses();
    } else if (val === 0) {
      setsameActiveAddress(null);
      fetchAddresses();
    }
  }

  const onSelectionHandler = (addressFromModal) => {
    if (selectedSection === 1) {
      setsameActiveAddress(addressFromModal);
    } else if (selectedSection === 2) {
      setserviceActiveAddress(addressFromModal);
    } else if (selectedSection === 3) {
      setBillingActiveAddress(addressFromModal);
    }
  }

  const addressModalOpenHandler = (value) => {
    setOpenModal(true);
    setSelectedSection(value);
  }

  useEffect(() => {
    fetchAddresses();
  }, []);


  const registerServiceHandler = async () => {
    try {
      setLoading(true);
      const formData = {
        product_category_id: servData?.product_category_id,
        serviceTypes: servData?.serviceTypes,
        brand: servData?.brand,
        slno: servData?.slno,
        yearOfPurchase: servData?.yearOfPurchase,
        warranty: servData?.warranty ? servData?.warranty : 0,
        warrantyExpiryDate: servData?.warrantyExpiryDate,
        complaint: servData?.complaint,
        serviceCategoryId: servData?.serviceCategoryId,
        user_id: authUser?.user?.id,
        district: servData?.district,
        city: servData?.city,
        product_id: servData?.product_id ? servData?.product_id : 0,
        shipping_address_id: status === 1 && sameActiveAddress ? sameActiveAddress.id : status === 0 && serviceActiveAddress ? serviceActiveAddress.id : '',
        billing_address_id: status === 1 && sameActiveAddress ? sameActiveAddress.id : status === 0 && billingActiveAddress ? billingActiveAddress.id : '',
        cart_id: servData?.cart_id,
        customer_visibility: "",
        service_validity: "",
        advance_payment: "",
        total_amount: "",
        payment_status: "",
        servicePackDate: "",
        servicePackExpDate: "",
      };
      // console.log('formData', formData)
      const options = {
        method: "POST",
        url: 'insertServiceReg',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      await axiosInstance.request(options).then((response) => {
        // console.log(" ~ update address response", response);
        if (response.data.code === 200) {


          // handleRazorpayPayment(response.data.serviceDetails.id)

          Swal.fire({
            position: 'center',
            icon: 'success',
            color: '#4169e1',
            text: 'service registered successfully Complete the Initial Payment for further processing.',
            showConfirmButton: true, // This shows the OK button
            confirmButtonText: 'OK', // Optional: Custom text for the button
          });
        }
      })

    } catch (error) {
      setError(error);
      // console.log("error servicve reg", error);
    }

    finally {
      setLoading(false);
      navigation('/profile/services');
    }
  };


  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }

  return (
    <ContainerDiv className='py-10'>
      {openModal && <AddressListModal setActiveAddress={onSelectionHandler} close={setOpenModal} />}
      <MainSide >
        <LeftSide className=' flex flex-col items-center'>
          <div className='w-full sm:w-1/2'>
            <div>
              <span>Is shipping address and billing address the Same?</span>
              <Radio>

                <RadioInput className='space-x-2 mt-4'>
                  <input
                    type='radio'
                    name='address_selection'
                    value='1'
                    onClick={() => handleClicks(1)}
                    checked={status === 1}
                  />
                  <p>Yes</p>

                  <input
                    type='radio'
                    name='address_selection'
                    value='0'
                    onClick={() => handleClicks(0)}
                    checked={status === 0}
                  />
                  <p>No</p>
                </RadioInput>
              </Radio>
            </div>

            {status === 1 &&
              <div>
                <BillingDiv className='p-4'>
                  <p>Shipping & Billing Address</p>
                  {sameActiveAddress &&
                    <>
                      <span>{sameActiveAddress?.name}</span>
                      <a>{sameActiveAddress?.type_name}</a>
                      <a>{sameActiveAddress?.mob1}</a>
                      <a>{sameActiveAddress?.house_name} , {sameActiveAddress?.street_name}</a>
                      <a>{sameActiveAddress?.pincode}</a>
                      <a>{sameActiveAddress?.district} , {sameActiveAddress?.state}</a>
                    </>}
                </BillingDiv>
                <ButtonDiv>
                  <button style={{ color: 'black' }} onClick={() => addressModalOpenHandler(1)}>
                    Choose address
                  </button>
                </ButtonDiv>

              </div>
            }

            {status === 0 &&
              <div>
                <div>
                  <BillingDiv>
                    <p>Shipping Address</p>
                    {serviceActiveAddress &&
                      <>
                        <span>{serviceActiveAddress?.name}</span>
                        <a>{serviceActiveAddress?.type_name}</a>
                        <a>{serviceActiveAddress?.mob1}</a>
                        <a>{serviceActiveAddress?.house_name}</a>
                        <a>{serviceActiveAddress?.street_name}</a>
                        <a>{serviceActiveAddress?.pincode}</a>
                        <a>{serviceActiveAddress?.district},{serviceActiveAddress?.state}</a>
                      </>}
                  </BillingDiv>
                  <ButtonDiv>
                    <button style={{ color: 'black' }} onClick={() => addressModalOpenHandler(2)}>
                      Choose address
                    </button>
                  </ButtonDiv>
                </div>

                <div>
                  <BillingDiv>
                    <p>Billing Address</p>
                    {billingActiveAddress &&
                      <>
                        <span>{billingActiveAddress?.name}</span>
                        <a>{billingActiveAddress?.type_name}</a>
                        <a>{billingActiveAddress?.mob1}</a>
                        <a>{billingActiveAddress?.house_name}</a>
                        <a>{billingActiveAddress?.street_name}</a>
                        <a>{billingActiveAddress?.pincode}</a>
                        <a>{billingActiveAddress?.district},{serviceActiveAddress?.state}</a>
                      </>}
                  </BillingDiv>

                  <ButtonDiv>
                    <button style={{ color: 'black' }} onClick={() => addressModalOpenHandler(3)}>
                      Choose address
                    </button>
                  </ButtonDiv>
                </div>

              </div>
            }
          </div>

        </LeftSide>
      </MainSide>


      <SideDiv>
        <div className='flex items-end w-full h-full justify-center'>
          {((status === 1 && sameActiveAddress) || (status === 0 && billingActiveAddress && serviceActiveAddress)) ? (
            <ProceedPayDiv>
              <button onClick={registerServiceHandler}>Submit</button>
            </ProceedPayDiv>
          ) : ''}
        </div>
      </SideDiv>
    </ContainerDiv>

  );
};

export default ServiceRegAddress;

const ProceedPayDiv = styled.div`
width:50%;
display:flex;
flex-direction:row;
margin:10px 0;

button{
width:200px;
height:50px;
border-radius:8px;
border:none;
margin-right:10px;
cursor:pointer;
background-color:#4669E8;
color:white;
}`













