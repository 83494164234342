import React from "react";
import {
  HeaderContainer,
  HeaderChild,
  HeaderRightChild,
  HeaderLogo,
  NavbarLink,
} from "./HeaderStyle";
// import image from "../../assets/Images/png/logoHeader.png";
import image from "../../assets/Images/png/logoHeaderNew.png";
import menuIcon from "../../assets/Images/png/menu 1.png";
import profileIcon from "../../assets/Images/png/user(4) 1.png";
import cartIcon from "../../assets/Images/png/Group 1.png";
import { Popover, Typography, Button } from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Login from "../Modal/Login";
import { Logout } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { AuthContext } from "../../consts/AuthContext";
import { userLogin, userLogout } from "../../api/api";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axiosInstance from "../../consts/axiosInstance";
import styled from "styled-components";
import { FaRegBell } from "react-icons/fa6";
import { BsCart2 } from "react-icons/bs";




function Header() {


  const location = useLocation().pathname;

  const [authUser, setAuthUser] = React.useContext(AuthContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isService, setIsService] = useState();
  const navigation = useNavigate();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (location === "/servicepart/servicehome") {
      setIsService(true);
    }
  }, [location]);




  // async function Logoutfunc() {
  //   console.log("authUser", authUser);




  //   const form = new FormData();


  //   form.append("mob", authUser?.user.mob1);
  //   form.append("device_type", width <= 768 ? 1 : 0);

  //   const options = {
  //     method: "POST",
  //     url: userLogout,
  //     headers: { "Content-Type": "multipart/form-data", Accept: "application/json" },
  //     data: form,
  //   };


  //   await axios
  //     .request(options)
  //     .then(function (response) {
  //       console.log("Logout", response);
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         color: '#4169e1',
  //         text: 'Logged out successfully',
  //         showConfirmButton: false,
  //         timer: 1500
  //       })

  //       try {
  //         response?.data?.code === 200 && localStorage?.removeItem("userid");

  //         // console.log('success');


  //         return true;
  //       } catch (exception) {
  //         // console.log('failed');
  //         return false;
  //       }
  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //     });
  // }


  // onClick={() => { setAuthUser([]); Logoutfunc(); localStorage.clear(); navigation('/login'); }}




  async function Logoutfunc() {
    try {
      const form = new FormData();
      form.append("mob", authUser?.user.mob1);
      form.append("device_type", width <= 768 ? 1 : 0);

      const options = {
        method: "POST",
        url: 'userLogout', // Ensure userLogout is defined somewhere
        headers: { "Content-Type": "multipart/form-data", Accept: "application/json" },
        data: form,
      };

      const response = await axiosInstance.request(options);

      // console.log("Logout", response);

      if (response.data && response.data.code === 200) {
        localStorage.removeItem("userid");
        Swal.fire({
          position: 'center',
          icon: 'success',
          color: '#4169e1',
          text: 'Logged out successfully',
          showConfirmButton: false,
          timer: 1500
        });
        return true;
      } else {
        // If response code is not 200 or if response data is empty
        console.error("Logout failed");
        return false;
      }
    } catch (error) {
      console.error("Error occurred during logout:", error);
      return false;

    } finally {
      setAuthUser([]);
      localStorage.clear();
      navigation('/login');
    }
  }









  // notification

  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const fetchNotifications = async () => {
    try {
      const response = await axiosInstance.get('notifications');
      setNotifications(response.data);
      const unreadNotifications = response.data.filter(notification => notification.read_at === null);
      setUnreadCount(unreadNotifications.length);
    } catch (error) {
      console.error("Error fetching notifications", error);
    }
  };


  // useEffect(() => {
  //   fetchNotifications();
  //   const interval = setInterval(fetchNotifications, 5000); // Fetch every 5 seconds
  //   return () => clearInterval(interval); // Clean up on component unmount
  // }, []);


  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && authUser?.user.id) {
      fetchNotifications();
      const interval = setInterval(fetchNotifications, 5000); // Fetch every 5 seconds
      return () => clearInterval(interval); // Clean up on component unmount
    }
  }, [authUser]);

  return (
    <HeaderContainer isService={isService}>
      <HeaderChild>
        <HeaderLogo style={{ cursor: 'pointer' }} width={"18%"} onClick={() => navigation('/')}>
          <img src={image} alt="logo" />
        </HeaderLogo>
      </HeaderChild>
      {location == '/login' || location == '/signup/verification' || location == '/signup' ? '' :

        <HeaderRightChild>

          {/* <NavbarLink >
          <img
            src={profileIcon}
            alt="contactIcon"
            width={"28px"}
            height={"28px"}
          />
        </NavbarLink> */}



          <NavbarLink>

            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <>


                  <img
                    {...bindTrigger(popupState)}
                    src={profileIcon}
                    alt="contactIcon"
                  // width={'40px'}
                  // height={"28px"}
                  />





                  <Popover
                    sx={{ mt: 1 }}
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >{
                      authUser ? '' :
                        <Typography
                          sx={{
                            px: 2,
                            py: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          style={{ cursor: "pointer" }}
                          onClick={() => navigation('/login')}
                        >
                          <LoginIcon
                            fontSize="small"
                            style={{ paddingRight: "6px", width: "15px", marginTop: "2px" }}
                          />
                          Login
                        </Typography>
                    }

                    {
                      authUser ? '' :
                        <Typography
                          sx={{ px: 2, py: 1, display: "flex", justifyContent: "center" }}
                          style={{ cursor: "pointer" }}
                          onClick={() => navigation('/signup')}
                        >
                          <PersonAddAltIcon
                            fontSize="small"
                            style={{ paddingRight: "6px", width: "17px", marginTop: "2px" }}
                          />
                          Signup
                        </Typography>
                    }

                    {authUser && <Typography
                      sx={{ px: 2, py: 1, display: "flex", justifyContent: "center" }}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigation("/profile")}
                    >
                      <PersonAddAltIcon
                        fontSize="small"
                        style={{ paddingRight: "6px", width: "17px", marginTop: "2px" }}
                      />
                      Profile
                    </Typography>}

                    {authUser ?
                      <Typography
                        sx={{
                          px: 2,
                          py: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        style={{ cursor: "pointer" }}
                        onClick={Logoutfunc}

                      >
                        <LoginIcon
                          fontSize="small"
                          style={{ paddingRight: "6px", width: "15px", marginTop: "2px" }}
                        />
                        Log out
                      </Typography> : ''}
                  </Popover>
                </>
              )}
            </PopupState>
          </NavbarLink>


          <NavbarLink>
            {/* <img src={cartIcon} alt="cart icon"
              onClick={() => navigation('/sales/shoppingcart')} /> */}

            <ResponsiveIconCart onClick={() => navigation('/sales/shoppingcart')} />


          </NavbarLink>

          {/* 
          <NavbarLink>
            <img src={menuIcon} alt="menuIcon" width={"28px"} height={"28px"} />
          </NavbarLink> */}

          <NavbarLink>
            <NotIcon onClick={() => navigation('/notifications')}>
              <ResponsiveIcon />
              {unreadCount > 0 && <NotBadge>{unreadCount}</NotBadge>}
            </NotIcon>
          </NavbarLink>

        </HeaderRightChild>}
    </HeaderContainer>
  );
}

export default Header;






const ResponsiveIconCart = styled(BsCart2)`
color:white;
width: 22px;
height: 22px;
@media screen and (min-width: 300px) and (max-width: 767px) {
width: 20px;
height: 20px;
}
`;













// notificataion
const ResponsiveIcon = styled(FaRegBell)`
display: flex;
justify-content: center;
align-items: center;
width: 22px;
height: 22px;
@media screen and (min-width: 300px) and (max-width: 767px) {
width: 20px;
height: 20px;
}
`;

const NotIcon = styled.div`
 position: relative;
  display: inline-block;
  color: white;
`;

const NotBadge = styled.span`
position: absolute;
top: -7px;
right: -10px;
background: red;
color: white;
border-radius: 50%;
font-size: 12px;
width:18px;
height:18px;
display: flex;
justify-content: center;
align-items: center;
`;