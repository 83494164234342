import { useContext, useEffect, useState } from 'react'
import { ModalContainer, Modal, Overlay } from "../../../components/modal"
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { CloseContainer } from '../ModalStyled';
import { colors, fonts } from '../../../consts/themes';
import { AuthContext } from '../../../consts/AuthContext';
import axiosInstance from '../../../consts/axiosInstance';



const AddSubUserModal = ({ close, apiCallHandler, setExisting }) => {

  const [authUser, setAuthUser] = useContext(AuthContext);
  const [subName, setSubName] = useState('');
  const [nameError, setNameError] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [mobError, setMobError] = useState('');


  // primary mob handler
  const handlePrimaryChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setMobileNumber(value);
    }
  };

  // primary mob validation
  const validatePrimaryMobileNumber = () => {
    let error = '';
    if (mobileNumber.length > 0 && mobileNumber.length < 10) {
      error = 'Invalid mobile number';
    }
    setMobError(error);
  };


  useEffect(() => {
    validatePrimaryMobileNumber();
  }, [mobileNumber]);


  const handleNameChange = (e) => {
    const value = e.target.value;
    if (/^[A-Za-z\s]*$/.test(value) && value.length <= 25) {
      setSubName(value);
      if (value.length > 0 && value.length < 3) {
        setNameError('Name should be between 3 to 25 characters');
      } else {
        setNameError('');
      }
    }
  };


  const submitHanlder = async () => {
    try {
      const formData = {
        mob: mobileNumber,
        name: subName,
        parentId: authUser?.user?.id,
      };
      const options = {
        method: "POST",
        url: 'addSubCustomers',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      await axiosInstance.request(options).then((response) => {
        // console.log(response.data, "sub rep");

        if (response.data.code === 200) {
          apiCallHandler();
        } else if (response.data.code === 401) {
          setExisting(true);
        }
      })

    } catch (error) {
      // console.log("sub user error", error);
    }
    finally {
      close(false)
    }
  };


  const btnVisible = mobileNumber !== '' && subName !== '' && nameError === "" && mobError === "";

  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => close(false)} />
            </CloseContainer>
            <InnerConatiner>
              <Head><label>Add Subuser</label></Head>
              <Details>
                <InputWrap>
                  <Input>
                    <input
                      type="text"
                      placeholder='Enter your name'
                      name="name"
                      value={subName}
                      onChange={handleNameChange}
                    />
                    {nameError && <Error>{nameError}</Error>}
                  </Input>
                  <Input>
                    <input
                      type="number"
                      placeholder='Mob Number'
                      id="mobileNumber"
                      name="mobileNumber"
                      value={mobileNumber}
                      onChange={handlePrimaryChange}
                    />
                    {mobError && <Error>{mobError}</Error>}
                  </Input>
                </InputWrap>
                <SubmitBtn disabled={!btnVisible}><button disabled={!btnVisible} onClick={() => submitHanlder()}>Add</button></SubmitBtn>
              </Details>
            </InnerConatiner>
          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default AddSubUserModal;



const Error = styled.div`
font-size:12px;
color:red;
`

export const Head1 = styled.div`
font-size:16px;
font-weight:400;
`



const SubmitBtn = styled.div`
display: flex;
justify-content: center;
button{
background-color: ${props => props.disabled ? '#D5E2FE' : '#4669E8'};
cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  max-width:8rem;
  padding:0.5rem 1rem;
  color:white;
  border: none;
  border-radius: 5px;
}
`;


const Radio = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    input {
      accent-color: ${colors.blue};
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
`;

const RadioInput = styled.div`
    display: flex;
    flex-direction: row;
    p {
      font-size: 12px;
    }
`;

const Ptag = styled.div`
    margin-right: 6px;
    p {
      font-size: 12px;
    }
`;


const Container = styled.div`
display: flex; 
flex-direction:column;
justify-content: center; 
align-items:center;
padding:1.5rem;
width:350px;
height: 100%;
max-width:100%;
@media only screen and (min-width: 350px) and (max-width:450px) {width:280px;}
@media only screen and (min-width: 300px) and (max-width:349px) {width:240px;}
`

const InnerConatiner = styled.div`
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap:0.5rem;
padding:0.5rem;
width: 100%;
`

const Head = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
width:100%;
border-bottom: .5px solid ${colors.line};
padding-bottom: 0.5rem;
label{
  font-size:16px;
  font-weight:${fonts.fontWeight6};
}
`

const Details = styled.div`
display: flex;
flex-direction: column;
gap:0.5rem;
width: 100%;
padding:0.5rem;
height: 100%;
/* @media only screen and (min-width: 300px) and (max-width:767px) {height:15rem;}
overflow-y:scroll; */
`

const InputWrap = styled.div`
display: flex;
flex-direction: column;
gap:0.5rem;
`

const Input = styled.div`
input{
  box-sizing: border-box;
  width: 100%;
  height: 2rem;
  border: 0.1px solid ${colors.line};
  border-radius: 5px;
  padding:0 0.5rem;
  &:focus {
      border-color: #587ef5;
      outline: 0;
    }

   /* Hide spinner controls */
   -moz-appearance: textfield; /* Firefox */
    appearance: none; /* Safari and Chrome */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none; /* Safari */
    }

  }

`