import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import logo from "../../../assets/Images/png/logoHeaderNew.png";
import image1 from "../../../assets/Images/png/Image1.png";
import { colors } from '../../../consts/themes';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { userLogin } from '../../../api/api';
import { AuthContext } from '../../../consts/AuthContext';



const Login = () => {

    // const navigation = useNavigate();

    // const [width, setWidth] = useState(window.innerWidth);
    // const [authUser, setAuthUser] = useContext(AuthContext);
    // const [mobileNumber, setMobileNumber] = useState('');
    // const [error, setError] = useState('');



    // // const handleMobileNumberChange = (event) => {
    // //     const { value } = event.target;
    // //     // Regular expression for a 10-digit mobile number
    // //     const mobileNumberRegex = /^[0-9]{10}$/;
    // //     setMobileNumber(value);
    // //     if (!mobileNumberRegex.test(value)) {
    // //         setError('Please enter a valid 10-digit mobile number');
    // //     } else {
    // //         setError('');
    // //     }
    // // };



    // async function Loginfunc() {

    //     const form = new FormData();
    //     form.append("mob", mobileNumber);
    //     form.append("device_type", width <= 768 ? 1 : 0);

    //     const options = {
    //         method: 'POST',
    //         url: userLogin,
    //         headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
    //         data: form
    //     };

    //     try {
    //         const response = await axios.request(options);

    //         if (response.data.code === 200) {

    //             localStorage.setItem('token', response.data.token);
    //             localStorage.setItem("userid", response?.data?.user?.userid);
    //             setAuthUser({ user: response?.data?.user });
    //             // navigation('/signup/verification');
    //             navigation('/otp/verification')
    //         } else if (response.data.code === 401) {
    //             alert(`${response.data.message}`);
    //         }

    //     } catch (error) {
    //         console.error(error);
    //     }
    // }


    // const handleWindowSizeChange = () => {
    //     setWidth(window.innerWidth);
    // };


    // useEffect(() => {
    //     window.addEventListener('resize', handleWindowSizeChange);
    //     return () => {
    //         window.removeEventListener('resize', handleWindowSizeChange);
    //     };
    // }, []);


    // // new
    // const handleMobileNumberChange = (e) => {
    //     const value = e.target.value;
    //     if (/^\d*$/.test(value) && value.length <= 10) {
    //         setMobileNumber(value);
    //     }
    // };

    // const validatePrimaryMobileNumber = () => {
    //     let error = '';
    //     if (mobileNumber.length > 0 && mobileNumber.length < 10) {
    //         error = 'enter a valid 10-digit mobile number';
    //     }
    //     setError(error);
    // };

    // useEffect(() => {
    //     validatePrimaryMobileNumber();
    // }, [mobileNumber]);

    const navigation = useNavigate();

    const [width, setWidth] = useState(window.innerWidth);
    const [authUser, setAuthUser] = useContext(AuthContext);
    const [mobileNumber, setMobileNumber] = useState('');
    const [error, setError] = useState('');


    const [showPoster, setShowPoster] = useState(false); // State for poster visibility

    useEffect(() => {
        const timer = setTimeout(() => {
            if (window.innerWidth <= 768) { // Mobile screen condition
                setShowPoster(true);
            }
        }, 3000);
        return () => clearTimeout(timer); // Cleanup the timer
    }, []);
    // const handleMobileNumberChange = (event) => {
    //     const { value } = event.target;
    //     // Regular expression for a 10-digit mobile number
    //     const mobileNumberRegex = /^[0-9]{10}$/;
    //     setMobileNumber(value);
    //     if (!mobileNumberRegex.test(value)) {
    //         setError('Please enter a valid 10-digit mobile number');
    //     } else {
    //         setError('');
    //     }
    // };



    async function Loginfunc() {

        //     const form = new FormData();
        //     form.append("mob", mobileNumber);
        //     form.append("device_type", width <= 768 ? 1 : 0);

        //     const options = {
        //         method: 'POST',
        //         url: userLogin,
        //         headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
        //         data: form
        //     };

        //     try {
        //         const response = await axios.request(options);

        //         if (response.data.code === 200) {

        //             localStorage.setItem('token', response.data.token);
        //             localStorage.setItem("userid", response?.data?.user?.userid);
        //             setAuthUser({ user: response?.data?.user });
        //             // navigation('/signup/verification');
        //             navigation('/otp/verification')
        //         } else if (response.data.code === 401) {
        //             alert(`${response.data.message}`);
        //         }

        //     } catch (error) {
        //         console.error(error);
        //     }
        // }

        const form = new FormData();
        form.append("mob", mobileNumber);
        const options = {
            method: 'POST',
            url: userLogin,
            headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
            data: form
        };

        try {
            const response = await axios.request(options);

            if (response.data.code === 200) {
                // Store the token and user id in localStorage
                // localStorage.setItem('token', response.data.token);
                // localStorage.setItem("userid", response?.data?.user?.userid);

                // // Store user data and mobile number in AuthContext
                // setAuthUser({ user: response?.data?.user, mobileNumber: mobileNumber });

                // console.log({ user: response?.data?.user, mobileNumber });

                // Navigate to OTP verification page
                navigation('/otp/verification', { state: { mobileNumber: mobileNumber } });
            } else if (response.data.code === 401) {
                alert(`${response.data.message}`);
            }

        } catch (error) {
            console.error(error);
        }
    }

    // const handleWindowSizeChange = () => {
    //     setWidth(window.innerWidth);
    // };


    // useEffect(() => {
    //     window.addEventListener('resize', handleWindowSizeChange);
    //     return () => {
    //         window.removeEventListener('resize', handleWindowSizeChange);
    //     };
    // }, []);


    // new
    const handleMobileNumberChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 10) {
            setMobileNumber(value);
        }
    };

    const validatePrimaryMobileNumber = () => {
        let error = '';
        if (mobileNumber.length > 0 && mobileNumber.length < 10) {
            error = 'enter a valid 10-digit mobile number';
        }
        setError(error);
    };

    useEffect(() => {
        validatePrimaryMobileNumber();
    }, [mobileNumber]);



    return (
        <Screen className='h-screen flex items-center justify-center'>
            {showPoster && (
                <PosterContainer>
                    <div className="relative flex flex-col items-center">
                        {/* <CloseButton className="-top-10 right-4" onClick={() => setShowPoster(false)}>×</CloseButton> */}
                        <PosterContent>
                            <div className="flex justify-center mb-4">
                                <svg
                                    width="113"
                                    height="22"
                                    viewBox="0 0 193 34"
                                    fill=""
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0.32 18.92C0.32 16.1467 0.92 13.68 2.12 11.52C3.32 9.33333 4.98667 7.64 7.12 6.44C9.28 5.21333 11.72 4.6 14.44
      4.6C17.7733 4.6 20.6267 5.48 23 7.24C25.3733 9 26.96 11.4 27.76 14.44H20.24C19.68 13.2667 18.88 12.3733 17.84 11.76C16.8267
      11.1467 15.6667 10.84 14.36 10.84C12.2533 10.84 10.5467 11.5733 9.24 13.04C7.93333 14.5067 7.28 16.4667 7.28 18.92C7.28
      21.3733 7.93333 23.3333 9.24 24.8C10.5467 26.2667 12.2533 27 14.36 27C15.6667 27 16.8267 26.6933 17.84 26.08C18.88 25.4667
      19.68 24.5733 20.24 23.4H27.76C26.96 26.44 25.3733 28.84 23 30.6C20.6267 32.3333 17.7733 33.2 14.44 33.2C11.72 33.2 9.28
      32.6 7.12 31.4C4.98667 30.1733 3.32 28.48 2.12 26.32C0.92 24.16 0.32 21.6933 0.32 18.92ZM39.5887 3.4V33H32.7487V3.4H39.5887ZM48.7856
      8.36C47.5856 8.36 46.599 8.01333 45.8256 7.32C45.079 6.6 44.7056 5.72 44.7056 4.68C44.7056 3.61333 45.079 2.73333 45.8256 2.04C46.599
      1.32 47.5856 0.959998 48.7856 0.959998C49.959 0.959998 50.919 1.32 51.6656 2.04C52.439 2.73333 52.8256 3.61333 52.8256 4.68C52.8256
      5.72 52.439 6.6 51.6656 7.32C50.919 8.01333 49.959 8.36 48.7856 8.36ZM52.1856 10.68V33H45.3456V10.68H52.1856ZM71.5825 33L64.7825
      23.64V33H57.9425V3.4H64.7825V19.76L71.5425 10.68H79.9825L70.7025 21.88L80.0625 33H71.5825ZM104.509 21.48C104.509 22.12 104.469
      22.7867 104.389 23.48H88.9091C89.0157 24.8667 89.4557 25.9333 90.2291 26.68C91.0291 27.4 92.0024 27.76 93.1491 27.76C94.8557
      27.76 96.0424 27.04 96.7091 25.6H103.989C103.616 27.0667 102.936 28.3867 101.949 29.56C100.989 30.7333 99.7757 31.6533 98.3091
      32.32C96.8424 32.9867 95.2024 33.32 93.3891 33.32C91.2024 33.32 89.2557 32.8533 87.5491 31.92C85.8424 30.9867 84.5091 29.6533
      83.5491 27.92C82.5891 26.1867 82.1091 24.16 82.1091 21.84C82.1091 19.52 82.5757 17.4933 83.5091 15.76C84.4691 14.0267 85.8024
      12.6933 87.5091 11.76C89.2157 10.8267 91.1757 10.36 93.3891 10.36C95.5491 10.36 97.4691 10.8133 99.1491 11.72C100.829 12.6267
      102.136 13.92 103.069 15.6C104.029 17.28 104.509 19.24 104.509 21.48ZM97.5091 19.68C97.5091 18.5067 97.1091 17.5733 96.3091
      16.88C95.5091 16.1867 94.5091 15.84 93.3091 15.84C92.1624 15.84 91.1891 16.1733 90.3891 16.84C89.6157 17.5067 89.1357 18.4533 88.9491
      19.68H97.5091ZM122.558 33L115.758 23.64V33H108.918V3.4H115.758V19.76L122.518 10.68H130.958L121.678 21.88L131.038 33H122.558ZM133.084
      21.8C133.084 19.5067 133.511 17.4933 134.364 15.76C135.244 14.0267 136.431 12.6933 137.924 11.76C139.417 10.8267 141.084 10.36
      142.924 10.36C144.497 10.36 145.871 10.68 147.044 11.32C148.244 11.96 149.164 12.8 149.804 13.84V10.68H156.644V33H149.804V29.84C149.137
      30.88 148.204 31.72 147.004 32.36C145.831 33 144.457 33.32 142.884 33.32C141.071 33.32 139.417 32.8533 137.924 31.92C136.431 30.96
      135.244 29.6133 134.364 27.88C133.511 26.12 133.084 24.0933 133.084 21.8ZM149.804 21.84C149.804 20.1333 149.324 18.7867 148.364
      17.8C147.431 16.8133 146.284 16.32 144.924 16.32C143.564 16.32 142.404 16.8133 141.444 17.8C140.511 18.76 140.044 20.0933
      140.044 21.8C140.044 23.5067 140.511 24.8667 141.444 25.88C142.404 26.8667 143.564 27.36 144.924 27.36C146.284 27.36
      147.431 26.8667 148.364 25.88C149.324 24.8933 149.804 23.5467 149.804 21.84ZM169.233 14.4C170.033 13.1733 171.033 12.2133 172.233
      11.52C173.433 10.8 174.766 10.44 176.233 10.44V17.68H174.353C172.646 17.68 171.366 18.0533 170.513 18.8C169.659 19.52 169.233 20.8
      169.233 22.64V33H162.393V10.68H169.233V14.4ZM192.742 27.2V33H189.262C186.782 33 184.849 32.4 183.462 31.2C182.075 29.9733 181.382
      27.9867 181.382 25.24V16.36H178.662V10.68H181.382V5.24H188.222V10.68H192.702V16.36H188.222V25.32C188.222 25.9867 188.382 26.4667
      188.702 26.76C189.022 27.0533 189.555 27.2 190.302 27.2H192.742Z"
                                        fill="#007AFF"
                                    ></path>
                                </svg>
                            </div>

                            <p>Download our app for a seamless shopping experience!</p>
                            <ButtonContainer>
                                <a className="w-1/2 h-full"
                                    href="https://play.google.com/store/apps/details?id=com.Clikekartmobile.wox"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img className="w-full h-full" src="/image/Playstore.png" alt="" />
                                </a>
                                <a
                                    className="w-1/2"
                                    href="https://www.apple.com/app-store/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img className="w-full h-full object-contain" src="/image/AppleStore.png" alt="" />
                                </a>
                            </ButtonContainer>
                        </PosterContent>
                    </div>
                </PosterContainer>
            )}
            <Container>
                <LeftContainer>
                    <ImageSection>
                        <InputSec>
                            <ImgSecLogo>
                                <img src={logo} alt="logo" />
                            </ImgSecLogo>
                            <ImgSecImg >
                                <img src={image1} alt='login-cover' />
                            </ImgSecImg>
                        </InputSec>
                        <AccSec gp={"0.2rem"}>
                            <ImgSecText fs={"1rem"} fw={"500"}>Login to Explore the clikekart World</ImgSecText>
                            {/* <ImgSecText fs={"0.8em"} fw={"300"}>rem ipsum dolor sit amet, consectetur adipiscing elit,</ImgSecText> */}
                        </AccSec>
                    </ImageSection>
                </LeftContainer>

                <RightContainer>
                    <LoginSection>
                        <InputSec>
                            <LoginText fw={"600"} fs={"1rem"}>LogIn</LoginText>
                            <LoginText mt={"0.5rem"} fw={"600"} fs={"0.8rem"} clr={"#00000080"}>Enter your mobile number to login.</LoginText>
                            <Input>
                                <input
                                    type="number"
                                    placeholder='Mob Number'
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    value={mobileNumber}
                                    onChange={handleMobileNumberChange}
                                />
                                {error && <Error>{error}</Error>}
                            </Input>
                            <SubmitBtn><button onClick={Loginfunc}>Log in</button></SubmitBtn>
                        </InputSec>
                        <AccSec gp={"1rem"}>
                            <LoginText fw={"500"} fs={"0.8rem"} clr={"#00000080"}>New to Clikekart ?
                                <Span clr={"#5173EA"} onClick={() => navigation('/signup')}> Create an account</Span> </LoginText>
                            <LoginText fw={"600"} fs={"0.75rem"} clr={"#00000080"}>By creating an account  or logginig in, you agree to clikekart
                                <Span clr={"#5173EA"} onClick={() => navigation('/terms')}> condition of use </Span> and  <Span clr={"#5173EA"} onClick={() => navigation('/privacypolicy')}>privacy policy</Span> </LoginText>
                        </AccSec>
                    </LoginSection>
                </RightContainer>
            </Container>
        </Screen >
    )
}

export default Login;

// import React, { useContext, useEffect, useState } from 'react'
// import styled from 'styled-components';
// import logo from "../../../assets/Images/png/logoHeaderNew.png";
// import image1 from "../../../assets/Images/png/Image1.png";
// import { colors } from '../../../consts/themes';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { userLogin } from '../../../api/api';
// import { AuthContext } from '../../../consts/AuthContext';



// const Login = () => {


//     const navigation = useNavigate();

//     const [width, setWidth] = useState(window.innerWidth);
//     const [authUser, setAuthUser] = useContext(AuthContext);
//     const [mobileNumber, setMobileNumber] = useState('');
//     const [error, setError] = useState('');



//     // const handleMobileNumberChange = (event) => {
//     //     const { value } = event.target;
//     //     // Regular expression for a 10-digit mobile number
//     //     const mobileNumberRegex = /^[0-9]{10}$/;
//     //     setMobileNumber(value);
//     //     if (!mobileNumberRegex.test(value)) {
//     //         setError('Please enter a valid 10-digit mobile number');
//     //     } else {
//     //         setError('');
//     //     }
//     // };



//     async function Loginfunc() {

//         // const form = new FormData();
//         // form.append("mob", mobileNumber);
//         // form.append("device_type", width <= 768 ? 1 : 0);

//         // const options = {
//         //     method: 'POST',
//         //     url: userLogin,
//         //     headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
//         //     data: form
//         // };

//         // try {
//         //     const response = await axios.request(options);

//         //     if (response.data.code === 200) {

//         //         localStorage.setItem('token', response.data.token);
//         //         localStorage.setItem("userid", response?.data?.user?.userid);
//         //         setAuthUser({ user: response?.data?.user });
//         //         // navigation('/signup/verification');
//         //         navigation('/otp/verification')
//         //     } else if (response.data.code === 401) {
//         //         alert(`${response.data.message}`);
//         //     }

//         // } catch (error) {
//         //     console.error(error);
//         // }

//         const form = new FormData();
//         form.append("mob", mobileNumber);
//         form.append("device_type", width <= 768 ? 1 : 0);

//         const options = {
//             method: 'POST',
//             url: userLogin,
//             headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
//             data: form
//         };

//         try {
//             const response = await axios.request(options);

//             if (response.data.code === 200) {

//                 localStorage.setItem('token', response.data.token);
//                 localStorage.setItem("userid", response?.data?.user?.userid);
//                 setAuthUser({ user: response?.data?.user });
//                 // navigation('/signup/verification');
//                 navigation('/otp/verification')

//             } else if (response.data.code === 401) {
//                 alert(`${response.data.message}`);
//             }

//         } catch (error) {
//             console.error(error);
//         }
//     }




//     const handleWindowSizeChange = () => {
//         setWidth(window.innerWidth);
//     };


//     useEffect(() => {
//         window.addEventListener('resize', handleWindowSizeChange);
//         return () => {
//             window.removeEventListener('resize', handleWindowSizeChange);
//         };
//     }, []);


//     // new
//     const handleMobileNumberChange = (e) => {
//         const value = e.target.value;
//         if (/^\d*$/.test(value) && value.length <= 10) {
//             setMobileNumber(value);
//         }
//     };

//     const validatePrimaryMobileNumber = () => {
//         let error = '';
//         if (mobileNumber.length > 0 && mobileNumber.length < 10) {
//             error = 'enter a valid 10-digit mobile number';
//         }
//         setError(error);
//     };

//     useEffect(() => {
//         validatePrimaryMobileNumber();
//     }, [mobileNumber]);



//     return (
//         <Screen className='h-[85vh] flex items-center justify-center'>
//             <Container>
//                 <LeftContainer>
//                     <ImageSection>
//                         <InputSec>
//                             <ImgSecLogo>
//                                 <img src={logo} alt="logo" />
//                             </ImgSecLogo>
//                             <ImgSecImg >
//                                 <img src={image1} alt='login-cover' />
//                             </ImgSecImg>
//                         </InputSec>
//                         <AccSec gp={"0.2rem"}>
//                             <ImgSecText fs={"1rem"} fw={"500"}>Login to Explore the clikekart World</ImgSecText>
//                             <ImgSecText fs={"0.8em"} fw={"300"}>rem ipsum dolor sit amet, consectetur adipiscing elit,</ImgSecText>
//                         </AccSec>
//                     </ImageSection>
//                 </LeftContainer>

//                 <RightContainer>
//                     <LoginSection>
//                         <InputSec>
//                             <LoginText fw={"600"} fs={"1rem"}>LogIn</LoginText>
//                             <LoginText mt={"0.5rem"} fw={"600"} fs={"0.8rem"} clr={"#00000080"}>Enter your mobile number to login.</LoginText>
//                             <Input>
//                                 <input
//                                     type="number"
//                                     placeholder='Mob Number'
//                                     id="mobileNumber"
//                                     name="mobileNumber"
//                                     value={mobileNumber}
//                                     onChange={handleMobileNumberChange}
//                                 />
//                                 {error && <Error>{error}</Error>}
//                             </Input>
//                             <SubmitBtn><button onClick={Loginfunc}>Log in</button></SubmitBtn>
//                         </InputSec>
//                         <AccSec gp={"1rem"}>
//                             <LoginText fw={"500"} fs={"0.8rem"} clr={"#00000080"}>New to Clikekart ?
//                                 <Span clr={"#5173EA"} onClick={() => navigation('/signup')}> Create an account</Span> </LoginText>
//                             <LoginText fw={"600"} fs={"0.75rem"} clr={"#00000080"}>By creating an account  or logginig in, you agree to clikekart
//                                 <Span clr={"#5173EA"}> condition of use </Span> and  <Span clr={"#5173EA"}>privacy policy</Span> </LoginText>
//                         </AccSec>
//                     </LoginSection>
//                 </RightContainer>
//             </Container>
//         </Screen>
//     )
// }

// export default Login;
const PosterContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(5px);
`;
const PosterContent = styled.div`
  background: white;
  padding: 1rem;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;

  h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #333;
  }

  p {
    /* margin-bottom: 1.5rem; */
    color: #666;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  /* margin-top: 1rem; */

  button {
    background: #007aff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.7rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;

    &.playstore {
      background: #34a853;
    }

    &.appstore {
      background: #007aff;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  a {
    text-decoration: none;
  }
`;
const CloseButton = styled.button`
  position: absolute;
  /* top: 10px; */
  /* right: 15px; */
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #ffffff;
`;

const Screen = styled.div`
  /* position: absolute; */
  /* top: calc(2.5rem + 50%);
  left: 50%;
  transform: translate(-50%, -50%); */
  max-width: 100%;
  overflow: auto;
  /* max-height: calc(100% - 5rem); */
`;


const Container = styled.div`
display: flex;
height:530px;
@media only screen and (max-width: 1023px) {
  height:370px;
}
`;

const LeftContainer = styled.div`
border-top-left-radius: 15px;
border-bottom-left-radius: 15px;
background: #4669E8;
width:380px;
padding:1.5rem;
@media only screen and (max-width: 1023px) {
display: none;
}
`;


const RightContainer = styled.div`
border:1px solid rgba(0, 0, 0, 0.2);
border-top-right-radius: 15px;
border-bottom-right-radius: 15px;
width:380px;
padding:1.5rem;
@media only screen and (max-width: 1023px) {border-radius: 15px;}
@media only screen and (max-width: 550px) {
width:300px;
margin:1rem;
}
`;

const ImageSection = styled.div`
height:100%;
display: flex;
flex-direction: column;
`;

const ImgSecLogo = styled.div`
width:100%;
display: flex;
justify-content:center;
img{
  width:100px;
  height:35px;
  object-fit: contain;
}
`;

const ImgSecImg = styled.div`
margin-top: 1rem;
width:100%;
display: flex;
justify-content:center;
img{
  width:270px;
  height:300px;
}
`;


const ImgSecText = styled.div`
margin-top: ${(props) => props.mt};
font-size:${(props) => props.fs};
font-weight: ${(props) => props.fw};
color: #FFFFFF;
width:100%;
display: flex;
justify-content:center;
`;

const LoginSection = styled.div`
height:100%;
display: flex;
flex-direction: column;
`;

const InputSec = styled.div`
flex-grow: 1;
display: flex;
flex-direction: column;
align-items: center;
`;

const AccSec = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap:${(props) => props.gp};
`;

const LoginText = styled.div`
margin-top:${(props) => props.mt};
font-size:${(props) => props.fs};
font-weight: ${(props) => props.fw};
color:${(props) => props.clr};
`;

const Span = styled.span`
font-size:${(props) => props.fs};
font-weight: ${(props) => props.fw};
color:${(props) => props.clr};
`;

const Input = styled.div`
margin-top: 1.5rem;
width:100%;
input{
  box-sizing: border-box;
  width: 100%;
  height: 2.5rem;
  border: 0.1px solid ${colors.line};
  border-radius: 5px;
  padding:0 0.5rem;
  &:focus {
      border-color: #587ef5;
      outline: 0;
    }
   /* Hide spinner controls */
   -moz-appearance: textfield; /* Firefox */
    appearance: none; /* Safari and Chrome */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none; /* Safari */
    }
  }
`

const Error = styled.div`
font-size:12px;
color:red;
`
const SubmitBtn = styled.div`
margin-top: 0.5rem;
width:100%;
display: flex;
justify-content: center;
button{
  width: 100%;
  padding:0.8rem 0;
  background-color:#4669E8;
  color:white;
  border: none;
  border-radius: 5px;
}
`;
