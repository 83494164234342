// src/pages/PrivacyPolicy.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import parse from "html-react-parser";
import axiosInstance from '../../../../consts/axiosInstance';
import LoadingSpinner from '../../../../components/Button/Loading/LoadingSpinner';
import axios from 'axios';

const Wrapper = styled.div`
padding: 35px 80PX;
max-width: 950px;
margin: 100px auto;
line-height: 1.6;
box-shadow: 0px 5px 11px 6px #80808087;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
`;

const Subtitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #555;
`;
const Subsubtitle = styled.h3`
  font-size: 1.25em;
  margin-bottom: 15px;
  color: #555;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
  color: #666;
`;

const PrivacyPolicy = () => {
  const [terms, setTerms] = useState(null)
  const getTerms = async () => {
    try {
      // const response = await axios.get("http://192.168.1.39:8001/api/getprivacyPolicy");
      const response = await axios.get("https://dev.clikekart.com/api/getprivacyPolicy");
      setTerms(response.data)
    } catch (error) {
      // console.log('error', error)
    }
  }
  useEffect(() => {
    getTerms();
  }, [])
  return (
    <Wrapper>
      {terms ? parse(terms.description) : <LoadingSpinner />}
    </Wrapper>
  )

}
export default PrivacyPolicy
