import React, { useContext, useEffect, useState } from "react";
import {
  BottomSubMenuContainer,
  Button,
  ChildWrapper,
  HomeContainer,
  LeftChild,
  MenuContainer,
  MiddleMenuContainer,
  SubChild,
  SubMenuContainer,
  TopSection,
  PosterContainer,
  PosterContent,
  ButtonContainer,
  CloseButton,

} from "./HomeStyle";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../consts/AuthContext";
import axios from "axios";
import { server_api } from "../../../consts/api";
import LoadingSpinner from "../../Button/Loading/LoadingSpinner";
import ErrorComponent from "../../Button/error/ErrorComponent";
import Swal from "sweetalert2";


function Home() {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const [data, setData] = useState([]);
  const navigation = useNavigate();
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [quote, setQuote] = useState('')
  const [showPoster, setShowPoster] = useState(false); // State for poster visibility

  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.innerWidth <= 768) { // Mobile screen condition
        setShowPoster(true);
      }
    }, 3000);
    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  const sendReq = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${server_api}getallnotes`);
      // console.log(response.data, " response of quote")
      setQuote(response?.data[0]?.home_notes)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const getThemelist = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${server_api}getThemeList`);
      // console.log(response.data, " response of theme list")
      setData(response?.data)
      // console.log(response?.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getThemelist();
    sendReq();
  }, []);



  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }



  return (
    <>
      {showPoster && (
        <PosterContainer>
          <div className="relative flex flex-col items-center">
            {/* <CloseButton className="-top-10 right-4" onClick={() => setShowPoster(false)}>×</CloseButton> */}

            <PosterContent>
              <div className="flex justify-center mb-4">
                <svg
                  width="113"
                  height="22"
                  viewBox="0 0 193 34"
                  fill=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.32 18.92C0.32 16.1467 0.92 13.68 2.12 11.52C3.32 9.33333 4.98667 7.64 7.12 6.44C9.28 5.21333 11.72 4.6 14.44
      4.6C17.7733 4.6 20.6267 5.48 23 7.24C25.3733 9 26.96 11.4 27.76 14.44H20.24C19.68 13.2667 18.88 12.3733 17.84 11.76C16.8267
      11.1467 15.6667 10.84 14.36 10.84C12.2533 10.84 10.5467 11.5733 9.24 13.04C7.93333 14.5067 7.28 16.4667 7.28 18.92C7.28
      21.3733 7.93333 23.3333 9.24 24.8C10.5467 26.2667 12.2533 27 14.36 27C15.6667 27 16.8267 26.6933 17.84 26.08C18.88 25.4667
      19.68 24.5733 20.24 23.4H27.76C26.96 26.44 25.3733 28.84 23 30.6C20.6267 32.3333 17.7733 33.2 14.44 33.2C11.72 33.2 9.28
      32.6 7.12 31.4C4.98667 30.1733 3.32 28.48 2.12 26.32C0.92 24.16 0.32 21.6933 0.32 18.92ZM39.5887 3.4V33H32.7487V3.4H39.5887ZM48.7856
      8.36C47.5856 8.36 46.599 8.01333 45.8256 7.32C45.079 6.6 44.7056 5.72 44.7056 4.68C44.7056 3.61333 45.079 2.73333 45.8256 2.04C46.599
      1.32 47.5856 0.959998 48.7856 0.959998C49.959 0.959998 50.919 1.32 51.6656 2.04C52.439 2.73333 52.8256 3.61333 52.8256 4.68C52.8256
      5.72 52.439 6.6 51.6656 7.32C50.919 8.01333 49.959 8.36 48.7856 8.36ZM52.1856 10.68V33H45.3456V10.68H52.1856ZM71.5825 33L64.7825
      23.64V33H57.9425V3.4H64.7825V19.76L71.5425 10.68H79.9825L70.7025 21.88L80.0625 33H71.5825ZM104.509 21.48C104.509 22.12 104.469
      22.7867 104.389 23.48H88.9091C89.0157 24.8667 89.4557 25.9333 90.2291 26.68C91.0291 27.4 92.0024 27.76 93.1491 27.76C94.8557
      27.76 96.0424 27.04 96.7091 25.6H103.989C103.616 27.0667 102.936 28.3867 101.949 29.56C100.989 30.7333 99.7757 31.6533 98.3091
      32.32C96.8424 32.9867 95.2024 33.32 93.3891 33.32C91.2024 33.32 89.2557 32.8533 87.5491 31.92C85.8424 30.9867 84.5091 29.6533
      83.5491 27.92C82.5891 26.1867 82.1091 24.16 82.1091 21.84C82.1091 19.52 82.5757 17.4933 83.5091 15.76C84.4691 14.0267 85.8024
      12.6933 87.5091 11.76C89.2157 10.8267 91.1757 10.36 93.3891 10.36C95.5491 10.36 97.4691 10.8133 99.1491 11.72C100.829 12.6267
      102.136 13.92 103.069 15.6C104.029 17.28 104.509 19.24 104.509 21.48ZM97.5091 19.68C97.5091 18.5067 97.1091 17.5733 96.3091
      16.88C95.5091 16.1867 94.5091 15.84 93.3091 15.84C92.1624 15.84 91.1891 16.1733 90.3891 16.84C89.6157 17.5067 89.1357 18.4533 88.9491
      19.68H97.5091ZM122.558 33L115.758 23.64V33H108.918V3.4H115.758V19.76L122.518 10.68H130.958L121.678 21.88L131.038 33H122.558ZM133.084
      21.8C133.084 19.5067 133.511 17.4933 134.364 15.76C135.244 14.0267 136.431 12.6933 137.924 11.76C139.417 10.8267 141.084 10.36
      142.924 10.36C144.497 10.36 145.871 10.68 147.044 11.32C148.244 11.96 149.164 12.8 149.804 13.84V10.68H156.644V33H149.804V29.84C149.137
      30.88 148.204 31.72 147.004 32.36C145.831 33 144.457 33.32 142.884 33.32C141.071 33.32 139.417 32.8533 137.924 31.92C136.431 30.96
      135.244 29.6133 134.364 27.88C133.511 26.12 133.084 24.0933 133.084 21.8ZM149.804 21.84C149.804 20.1333 149.324 18.7867 148.364
      17.8C147.431 16.8133 146.284 16.32 144.924 16.32C143.564 16.32 142.404 16.8133 141.444 17.8C140.511 18.76 140.044 20.0933
      140.044 21.8C140.044 23.5067 140.511 24.8667 141.444 25.88C142.404 26.8667 143.564 27.36 144.924 27.36C146.284 27.36
      147.431 26.8667 148.364 25.88C149.324 24.8933 149.804 23.5467 149.804 21.84ZM169.233 14.4C170.033 13.1733 171.033 12.2133 172.233
      11.52C173.433 10.8 174.766 10.44 176.233 10.44V17.68H174.353C172.646 17.68 171.366 18.0533 170.513 18.8C169.659 19.52 169.233 20.8
      169.233 22.64V33H162.393V10.68H169.233V14.4ZM192.742 27.2V33H189.262C186.782 33 184.849 32.4 183.462 31.2C182.075 29.9733 181.382
      27.9867 181.382 25.24V16.36H178.662V10.68H181.382V5.24H188.222V10.68H192.702V16.36H188.222V25.32C188.222 25.9867 188.382 26.4667
      188.702 26.76C189.022 27.0533 189.555 27.2 190.302 27.2H192.742Z"
                    fill="#007AFF"
                  ></path>
                </svg>
              </div>

              <p>Download our app for a seamless shopping experience!</p>
              <ButtonContainer>
                <a className="w-1/2 h-full"
                  href="https://play.google.com/store/apps/details?id=com.Clikekartmobile.wox"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-full h-full" src="/image/Playstore.png" alt="" />
                </a>
                <a
                className="w-1/2"
                  href="https://www.apple.com/app-store/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-full h-full object-contain" src="/image/AppleStore.png" alt="" />
                </a>
              </ButtonContainer>
            </PosterContent>
          </div>
        </PosterContainer>
      )}

      <HomeContainer>
        <MenuContainer>
          <SubMenuContainer>
            {data.map((item, index) => {
              if (item.code === "001") {
                return (
                  <LeftChild
                    key={index}
                    width="65%"
                    onClick={() => (
                      navigation("/typeofsale"),
                      localStorage.setItem("homeid", JSON.stringify(item?.code)))} >
                    <img src={item.image} alt="shopping" />
                    <h3>{item.layoutname}</h3>
                  </LeftChild>
                );
              }
            })}
            <ChildWrapper width="35%">
              {data.map((item, index) => {
                if (item.code === "003") {
                  return (
                    <SubChild
                      key={index}
                      primary
                      onClick={() => { navigation("/quiz"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}>
                      <h3>{item.layoutname}</h3>
                      <img src={item.image} alt="quiz" />
                    </SubChild>
                  );
                }
                if (item.code === "011") {
                  return (
                    <SubChild
                      key={index}
                      primary
                      onClick={() => { navigation("/profile/myorders"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}>
                      <h3>{item.layoutname}</h3>
                      <img src={item.image} alt="purchase-history" />
                    </SubChild>
                  );
                }
              })}
            </ChildWrapper>
          </SubMenuContainer>

          <MiddleMenuContainer>
            {data.map((item, index) => {
              if (item.code === "002") {
                return (
                  <LeftChild
                    key={index}
                    width="35%"
                    onClick={() => { navigation("/service"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}>
                    <img src={item.image} alt="service" />
                    <h3>{item.layoutname}</h3>
                  </LeftChild>
                  // <LeftChild
                  //   width="35%"
                  //   onClick={() => {Swal.fire({
                  //     position: 'center',
                  //     icon: 'warning',
                  //     color: '#4169e1',
                  //     text: `We are under maintenance. Will be back soon !`,
                  //   })}}>
                  //   <img src={item.image} alt="service" />
                  //   <h3>{item.layoutname}</h3>
                  // </LeftChild>
                );
              }
            })}


            <ChildWrapper width="28%">
              {data.map((item, index) => {
                if (item.code === "004") {
                  return (
                    <SubChild
                      key={index}
                      primary
                      onClick={() => { navigation("/auction"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }} >
                      <img src={item.image} alt="bid" />
                      <h3>{item.layoutname}</h3>
                    </SubChild>
                  );
                }
                if (item.code === "005") {
                  return (
                    <SubChild
                      key={index}
                      primary
                      onClick={() => { navigation("/exchange"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}>
                      <img src={item.image} alt="exchange" />
                      <h3>{item.layoutname}</h3>
                    </SubChild>
                  );
                }
              })}
            </ChildWrapper>

            <ChildWrapper width="35%">
              {data.map((item, index) => {
                if (item.code === "007") {
                  return (
                    <SubChild
                      primary
                      key={index}
                      onClick={() => { navigation("/profile/reward"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}>
                      <img src={item.image} alt="rewards" />
                      <h3>{item.layoutname}</h3>
                    </SubChild>
                  );
                }
                if (item.code === "008") {
                  return (
                    <SubChild
                      primary
                      key={index}
                      onClick={() => { navigation("/user/guide"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}>
                      <img src={item.image} alt="history" />
                      <h3>{item.layoutname}</h3>
                    </SubChild>
                  );
                }
              })}
            </ChildWrapper>
          </MiddleMenuContainer>

          <BottomSubMenuContainer>
            {data.map((item, index) => {
              if (item.code == "010") {
                return (
                  <SubChild
                    primary
                    key={index}
                    width="35%"
                    onClick={() => { navigation("/profile"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
                    bottom >
                    <img src={item.image} alt="profile" />
                    <h3>{item.layoutname}</h3>
                  </SubChild>
                );
              }
            })}
            {data.map((item, index) => {
              if (item.code === "006") {
                return (
                  <SubChild
                    key={index}
                    width="28%"
                    primary
                    onClick={() => { navigation("/search"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
                    bottom>
                    <img src={item.image} alt="search" />
                    <h3>{item.layoutname}</h3>
                  </SubChild>
                );
              }
            })}
            {data.map((item, index) => {
              if (item.code == "009") {
                return (
                  <SubChild
                    key={index}
                    primary
                    width="35%"
                    onClick={() => { navigation("/notifications"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
                    bottom >
                    <img src={item.image} alt="notification" />
                    <h3>{item.layoutname}</h3>
                  </SubChild>
                );
              }
            })}
          </BottomSubMenuContainer>
        </MenuContainer>
        <TopSection>
          <p>{quote}</p>
        </TopSection>
      </HomeContainer>

    </>
  );
}

export default Home;



// old
// import React, { useContext, useEffect, useState } from "react";
// import {
//   BottomSubMenuContainer,
//   Button,
//   ChildWrapper,
//   HomeContainer,
//   LeftChild,
//   MenuContainer,
//   MiddleMenuContainer,
//   SubChild,
//   SubMenuContainer,
//   TopSection,
// } from "./HomeStyle";
// import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../../../consts/AuthContext";
// import axios from "axios";
// import { themeList } from "../../../api/api";
// import { server_api } from "../../../consts/api";
// import LoadingSpinner from "../../Button/Loading/LoadingSpinner";
// import ErrorComponent from "../../Button/error/ErrorComponent";


// function Home() {

//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);


//   const [data, setData] = useState([]);
//   const navigation = useNavigate();
//   const [authUser, setAuthUser] = useContext(AuthContext);
//   const [quote, setQuote] = useState('')




//   const sendReq = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get(`${server_api}getallnotes`);
//       // console.log(response.data, " response of quote")
//       setQuote(response?.data[0]?.home_notes)
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   const getThemelist = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get(`${server_api}getThemeList`);
//       // console.log(response.data, " response of theme list")
//       setData(response?.data)
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   useEffect(() => {
//     getThemelist();
//     sendReq();
//   }, []);



//   if (loading) {
//     return <LoadingSpinner />
//   }

//   if (error) {
//     return <ErrorComponent error={error.message} />
//   }



//   return (
//     <>
//       <HomeContainer>
//         {/* <TopSection>
//           <h1>Clikekart gives you all products that are in factory packing</h1> */}
//         {/* <Button>
//             <span>Discover</span>
//           </Button> */}
//         {/* </TopSection> */}

//         <MenuContainer>
//           <SubMenuContainer>
//             {data.map((item) => {
//               if (item.code === "001") {
//                 return (
//                   <LeftChild
//                     width="65%"
//                     onClick={() => (
//                       navigation("/typeofsale"),
//                       localStorage.setItem("homeid", JSON.stringify(item?.code)) //themelist code
//                       // localStorage.setItem('isNotFirst', false)
//                     )}
//                   // background="linear-gradient(270deg, #03EFFE 0%, #4FADFE 100%)"
//                   >
//                     <img src={item.image} alt="shopping" />
//                     <h3>{item.layoutname}</h3>
//                   </LeftChild>
//                 );
//               }
//             })}
//             <ChildWrapper width="35%">
//               {data.map((item) => {
//                 if (item.code === "003") {
//                   return (
//                     <SubChild
//                       primary
//                       onClick={() => { navigation("/quiz"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(358.66deg,rgba(0, 0, 0, 0.5) 1.84%, rgba(0, 0, 0, 0) 99.39%)"
//                     >
//                       <h3>{item.layoutname}</h3>
//                       <img src={item.image} alt="quiz" />
//                     </SubChild>
//                   );
//                 }
//                 if (item.code === "011") {
//                   return (
//                     <SubChild
//                     // background="linear-gradient(244.64deg,rgba(19, 84, 122, 0.93) -5.02%,rgba(61, 162, 150, 0.79) 52.5%)"

//                     >
//                       <h3>{item.layoutname}</h3>
//                       <img src={item.image} alt="purchase-history" />
//                     </SubChild>
//                   );
//                 }
//               })}
//             </ChildWrapper>
//           </SubMenuContainer>

//           <MiddleMenuContainer>
//             {data.map((item) => {
//               if (item.code === "002") {
//                 return (
//                   <LeftChild
//                     width="35%"
//                     onClick={() => {
//                       navigation("/service"); localStorage.setItem("homeid", JSON.stringify(item?.code))
//                     }
//                     }
//                   // background="linear-gradient(90deg, #F55D7A 0%, #F091F5 91.67%)"
//                   >
//                     <img src={item.image} alt="service" />
//                     <h3>{item.layoutname}</h3>
//                   </LeftChild>
//                 );
//               }
//             })}


//             <ChildWrapper width="28%">
//               {data.map((item) => {
//                 if (item.code === "004") {
//                   return (
//                     <SubChild
//                       primary
//                       onClick={() => { navigation("/auction"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(262.65deg, rgba(240, 152, 25, 0.22) 15.97%, rgba(234, 70, 70, 0.83) 99.17%), linear-gradient(270deg, rgba(0, 0, 0, 0.2) -0.08%, rgba(0, 0, 0, 0) 56.09%)"
//                     >
//                       <img src={item.image} alt="bid" />
//                       <h3>{item.layoutname}</h3>
//                     </SubChild>
//                   );
//                 }
//                 if (item.code === "005") {
//                   return (
//                     <SubChild
//                       onClick={() => { navigation("/exchange"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(256.17deg, rgba(48, 207, 208, 0.74) 12.35%, rgba(117, 19, 236, 0.39) 98.93%)"
//                     >
//                       <img src={item.image} alt="exchange" />
//                       <h3>{item.layoutname}</h3>
//                     </SubChild>
//                   );
//                 }
//               })}
//             </ChildWrapper>

//             <ChildWrapper width="35%">
//               {data.map((item) => {
//                 if (item.code === "007") {
//                   return (
//                     <SubChild
//                       primary
//                       onClick={() => { navigation("/rewards"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(0deg, rgba(0, 0, 0, 0.5) -0.11%, rgba(0, 0, 0, 0) 100%)"
//                     >
//                       <img src={item.image} alt="rewards" />
//                       <h3>{item.layoutname}</h3>
//                     </SubChild>
//                   );
//                 }
//                 if (item.code === "008") {
//                   return (
//                     <SubChild
//                       primary
//                       onClick={() => { navigation("/orderHistory"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(246.46deg, rgba(13, 53, 132, 0.38) 29.19%, rgba(103, 136, 203, 0.42) 86.54%), linear-gradient(0deg, rgba(0, 0, 0, 0.5) -0.11%, rgba(0, 0, 0, 0) 100%)"
//                     >
//                       <img src={item.image} alt="history" />
//                       <h3>{item.layoutname}</h3>
//                     </SubChild>
//                   );
//                 }
//               })}
//             </ChildWrapper>
//           </MiddleMenuContainer>

//           <BottomSubMenuContainer>
//             {data.map((item) => {
//               if (item.code == "010") {
//                 return (
//                   <SubChild
//                     width="35%"
//                     onClick={() => { navigation("/profile"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(90deg, #F55D7A 0%, #F091F5 91.67%)"
//                     bottom
//                   >
//                     <img src={item.image} alt="profile" />
//                     <h3>{item.layoutname}</h3>
//                   </SubChild>
//                 );
//               }
//             })}
//             {data.map((item) => {
//               if (item.code === "006") {
//                 return (
//                   <SubChild
//                     width="28%"
//                     primary
//                     onClick={() => { navigation("/search"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(90deg, rgba(0, 0, 0, 0.29) 1.15%, rgba(0, 0, 0, 0) 49.89%)"
//                     bottom
//                   >
//                     <img src={item.image} alt="search" />
//                     <h3>{item.layoutname}</h3>
//                   </SubChild>
//                 );
//               }
//             })}
//             {data.map((item) => {
//               if (item.code == "009") {
//                 return (
//                   <SubChild
//                     width="35%"
//                     onClick={() => { navigation("/notifications"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(246.46deg, rgba(13, 53, 132, 0.76) 29.19%, rgba(103, 136, 203, 0.84) 86.54%)"
//                     bottom
//                   >
//                     <img src={item.image} alt="notification" />
//                     <h3>{item.layoutname}</h3>
//                   </SubChild>
//                 );
//               }
//             })}
//           </BottomSubMenuContainer>
//         </MenuContainer>
//         <TopSection>
//           <p>{quote}</p>
//         </TopSection>
//       </HomeContainer>
//     </>
//   );
// }

// export default Home;