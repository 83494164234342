import axios from 'axios'
import Multiselect from 'multiselect-react-dropdown'
import React, { useContext, useEffect, useState } from 'react'
import { BsUpload } from 'react-icons/bs'
import { RiExchangeFill } from 'react-icons/ri'
import { useLocation, useNavigate } from 'react-router-dom'
import Switch from 'react-switch'
import { getCityList, getDistrict, getReason, insertExchangeform, listComplaint, list_estimatedprice } from '../../../api/api'
import { AuthContext } from '../../../consts/AuthContext'
import { ComponentDiv, ComponentHeading, Dropdown, DropdownDiv, ExchangeContainer, ExchangeContents, Exchangediv, ExHeading, ItemName, ItemNameDiv, ItemsDiv, RadioDiv, SingleItem, SpecificationDiv, SubmitButtonDiv, SwitchDiv, SwitchDiv1, WorkingCondition } from '../LaptopExchange/LaptopExchangeStyle'
import { InputField, TextArea } from '../MobileExchange/MobileExchangeStyle'
import SubmitModal from '../Modals/SubmitModal'
import OnOffSwitch from '../Switch/Switch'
import axiosInstance from '../../../consts/axiosInstance'

const UpsExchange = () => {

    const { state } = useLocation();
    // console.log('state: ', state);
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const [authUser, setAuthUser] = useContext(AuthContext)
    const [isOnSpeakers, setIsOnSpeakers] = useState(false);
    const [isOnBattery, setIsOnBattery] = useState(false);
    const [complaints, setComplaints] = useState([])
    const [items, setItems] = useState([])
    const [reason, setReason] = useState([])
    const [exchangeData, setExchangeData] = useState([])


    const [isType, setIsType] = useState(false);

    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 8;
    const endYear = currentYear;
    const years = [];

    for (let i = startYear; i <= endYear; i++) {
        years.push(i);

    }


    //district lit
    const [district, setDistrict] = useState([]);
    const [selectedDistrictId, setSelectedDistrictId] = useState('')
    //city list based on district
    const [cityList, setCityList] = useState([]);






    const [values, setValues] = useState({
        product_cat_id: state?.id,
        user_id: authUser?.user?.id,
        load_capacity: '',
        battery_numbers: '',
        type: '',
        type_working: 0,
        battery_built_working: 0,
        battery_built: '',
        physical_condition: '',
        working_condition: '',
        purchase_year: '',
        reason_for_exchange_id: '',
        district_id: '',
        city_id: '',
        address: '',
        pincode: '',
    })


    // console.log('values: ', values);


    const listComplaints = async () => {
        try {
            const response = await axiosInstance.get(`getAllComplaint?product_cat_id=${state?.id}`)
            setComplaints(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const listReason = async () => {
        try {
            const response = await axiosInstance.get(`getReasonOfEcxhange?product_cat_id=${state?.id}`)
            setReason(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    useEffect(() => {
        listComplaints()
        listReason()
    }, [])




    const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const options =
        complaints.map(item => {
            return (
                {
                    name: item?.complaint,
                    id: item?.id
                }
            )
        })

    const handleSelect = (selectedList) => {
        setValues({
            ...values,
            complaint_id: JSON.stringify(selectedList)
        })
    };

    const handleRemove = (selectedList) => {
        setValues({
            ...values,
            complaint_id: JSON.stringify(selectedList)
        })
    };


    const handleSubmit = () => {
        axiosInstance.post('insertexchangeform', values).then(resp => {
            // console.log(resp, "insertExchange")
            if (resp?.data.code == 200) {
                setIsOpen(true)
            } else {
                alert(resp.data.message)
            }
        })
    }



    const getDistrictdata = async () => {
        try {
            const response = await axiosInstance.get('getDistrict');
            setDistrict(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getDistrictdata();
    }, [])


    useEffect(() => {
        const getAvailableCity = async () => {
            try {
                if (selectedDistrictId) {
                    const response = await axiosInstance.get(`getCity/${selectedDistrictId}`);
                    setCityList(response?.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        getAvailableCity();
    }, [selectedDistrictId]);



    const handleDistrictChange = (e) => {
        setValues({ ...values, district_id: e.target.value, city_id: '' });
        setSelectedDistrictId(e.target.value);
    }



    const handleTypeChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            type: value,
            type_working: value === 0 ? '' : prevValues.type_working,
        }));
        if (value === 0) {
            setIsType(false);
        }
    };


    const handleBuildChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            battery_built: value,
            battery_built_working: value === 0 ? '' : prevValues.battery_built_working,
        }));
        if (value === 0) {
            setIsOnBattery(false);
        }
    };

    const isSubmitButton = Object.values(values).every((value) => value !== '')

    return (
        <ExchangeContainer>
            {isOpen && <SubmitModal setIsOpen={setIsOpen} />}
            <Exchangediv>
                <ExHeading>
                    <div>
                        <p style={{ marginBottom: "0" }}>UPS</p>
                        <label>*only can exchange within the 7 years limit</label>
                    </div>
                    {/* { exchangeData.length > 0 && <SubmitButtonDiv style={{display: 'flex' }}>
                    <button style={{width: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' 
                }} onClick={() => navigate('/exchange/confirmation')}>Exchange Cart <RiExchangeFill size={25}/></button>
                </SubmitButtonDiv>} */}
                </ExHeading>
                <ExchangeContents>
                    <ComponentHeading>
                        <ComponentDiv>
                            <h5>Components</h5>



                        </ComponentDiv>
                        <SpecificationDiv>
                            <h5>Specifications</h5>



                        </SpecificationDiv>
                        <WorkingCondition>
                            <h5>Working condition (Yes/No)</h5>


                        </WorkingCondition>
                    </ComponentHeading>
                    <ItemsDiv>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>LOAD CAPACITY</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <InputField name="load_capacity" type="text" onChange={(e) => handleChange(e)} />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>BATTERY NUMBERS</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <InputField name="battery_numbers" type="text" onChange={(e) => handleChange(e)} />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>




                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>TYPE</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv>
                                    <input type="radio" id="online" name="type" value={0} onChange={(e) => handleChange(e)} />
                                    <label for="online">Online</label>
                                    <input type="radio" id="offline" name="type" value={1} onChange={(e) => handleChange(e)} />
                                    <label for="offline">Offline</label></RadioDiv>
                            </SwitchDiv1>

                            <SwitchDiv>
                                <Switch
                                    onChange={() => {
                                        setIsType(prev => {
                                            const newProgress = !prev;
                                            setValues({ ...values, type_working: newProgress ? 1 : 0 });
                                            return newProgress;
                                        });
                                    }}
                                    checked={isType}
                                    height={25}
                                    width={45}
                                    handleDiameter={20}
                                    offColor="#ff0000"
                                    onColor="#4669E8"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#ffffff"
                                />
                            </SwitchDiv>
                        </SingleItem>


                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>BATTERY BUILT</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv>
                                    <input type="radio" id="inbuilt" name="battery_built" value={0} onChange={(e) => handleChange(e)} />
                                    <label for="Inbuilt">Inbuilt</label>
                                    <input type="radio" id="external" name="battery_built" value={1} onChange={(e) => handleChange(e)} />
                                    <label for="Offline">External</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                                {/* <Switch backgroundColor= { {on: '#00a922', off: '#ee3333' }} borderColor ={{on: '#00a922', off: '#ee3333'}} /> */}
                                <Switch
                                    onChange={() => {
                                        setIsOnBattery(prev => {
                                            const newProgress = !prev;
                                            setValues({ ...values, battery_built_working: newProgress ? 1 : 0 });
                                            return newProgress;
                                        });
                                    }}
                                    checked={isOnBattery}
                                    height={25}
                                    width={45}
                                    handleDiameter={20}
                                    offColor="#ff0000"
                                    onColor="#4669E8"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#ffffff"
                                />
                            </SwitchDiv>
                        </SingleItem>

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PHYSICAL CONDITION</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="condition" >
                                    <input type="radio" id="good" name="physical_condition" value="0" onChange={(e) => handleChange(e)} />
                                    <label for="good">Good</label>
                                    <input type="radio" id="fair" name="physical_condition" value="1" onChange={(e) => handleChange(e)} />
                                    <label for="fair">Fair</label>
                                    <input type="radio" id="excellent" name="physical_condition" value="2" onChange={(e) => handleChange(e)} />
                                    <label for="excellent">Excellent</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>WORKING CONDITION</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="condition">
                                    <input type="radio" id="good" name="working_condition" value="0" onChange={(e) => handleChange(e)} />
                                    <label for="good">Good</label>
                                    <input type="radio" id="fair" name="working_condition" value="1" onChange={(e) => handleChange(e)} />
                                    <label for="fair">Fair</label>
                                    <input type="radio" id="excellent" name="working_condition" value="2" onChange={(e) => handleChange(e)} />
                                    <label for="excellent">Excellent</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PURCHASE YEAR</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown
                                    onChange={(e) => setValues({ ...values, purchase_year: e.target.value })}>
                                    <option value="">Select purchase year</option>
                                    {years.map((year) => {
                                        return (<option value={year}>{year}</option>)
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>COMPLAINT</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                {/* <Dropdown>
                                <option>select</option>
                                <option>Not working</option>
                                <option>Not working</option>
                                <option>Not working</option>
                                <option>Not working</option>
                               
                            </Dropdown> */}
                                <Multiselect
                                    options={options}
                                    selectedValues={items}
                                    onSelect={handleSelect}
                                    onRemove={handleRemove}
                                    displayValue="name"
                                />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>REASON OF EXCHANGE</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="reason" id="reason" onChange={(e) => setValues({
                                    ...values,
                                    reason_for_exchange_id: e.target.value
                                })}>
                                    <option value="">select</option>
                                    {reason.map((item) => {
                                        return (
                                            <option value={item?.id}>{item?.reason}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>



                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>CHOOSE DISTRICT</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="district" id="district" onChange={handleDistrictChange}>
                                    <option value="">select</option>
                                    {district?.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.district_name}</option>
                                    ))}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>


                        {selectedDistrictId &&
                            <SingleItem>
                                <ItemNameDiv>
                                    <ItemName>CHOOSE CITY</ItemName>
                                </ItemNameDiv>
                                <DropdownDiv>
                                    <Dropdown name="city" id="city" onChange={(e) => setValues({
                                        ...values,
                                        city_id: e.target.value
                                    })} >
                                        <option value="">select</option>
                                        {cityList?.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.city_name}</option>
                                        ))}
                                    </Dropdown>
                                </DropdownDiv>
                                <SwitchDiv>
                                </SwitchDiv>
                            </SingleItem>
                        }


                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>ADDRESS</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <TextArea name='address' type='text' rows={'6'} onChange={(e) => handleChange(e)} />
                            </DropdownDiv>
                            <SwitchDiv>

                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PIN CODE</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <InputField
                                    name='pincode'
                                    type='text'
                                    maxLength='6'
                                    onChange={(e) => {
                                        // Only allow numeric input
                                        if (/^\d*$/.test(e.target.value)) {
                                            handleChange(e);
                                        }
                                    }}
                                />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                    </ItemsDiv>
                </ExchangeContents>
                {isSubmitButton &&
                    <SubmitButtonDiv>
                        <button onClick={() => handleSubmit()}>Submit</button>
                    </SubmitButtonDiv>
                }
            </Exchangediv>
        </ExchangeContainer>
    )
}

export default UpsExchange