import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import axiosInstance from '../../consts/axiosInstance';
import ServiceCart from './cart/ServiceCart';
import LoadingSpinner from '../../components/Button/Loading/LoadingSpinner';
import { LuMenu } from "react-icons/lu";
import { GrFormClose } from "react-icons/gr";
import ServiceCartMob from './cart/ServiceCartMob';


const ServiceSection = () => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  //active product category id, default is laptop = 7
  const [activeProduct, setActiveProduct] = useState(7);
  const [cartVisible, setCartVisible] = useState(false);

  const toggleCart = () => {
    setCartVisible(!cartVisible);
  };

  const productCategories = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('getProductCategory');
      const excludedIds = [83, 18, 13]; // IDs of Battery, Accessories, and Speaker
      const filteredCategories = response.data.filter(category =>
        !excludedIds.includes(category.id)
      );
      setCategory(filteredCategories);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    productCategories();
  }, [])




  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024 && cartVisible) {
        setCartVisible(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [cartVisible]);



  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Pdiv>
      <Cdiv1>

        <C1sub1>
          <Title>
            <section>Services</section>
            <span>Choose the product which you need service.</span>
          </Title>
          <CartIcon onClick={toggleCart} >
            <LuMenu size={24} />
          </CartIcon>
        </C1sub1>

        <C1sub2>
          <C1sub3>
            {category?.map((item) => (
              <C1sub4 key={item?.id} onClick={() => setActiveProduct(item?.id)} isActive={activeProduct === item?.id}>
                <Imgs src={item?.product_category_image} alt={item.title} />
                <span>{item?.product_category_name}</span>
              </C1sub4>
            ))}
          </C1sub3>
        </C1sub2>

      </Cdiv1>

      <Cdiv2>
        <ServiceCart activeProductCategoryId={activeProduct} />
      </Cdiv2>

      {cartVisible && <ServiceCartMob onClose={toggleCart} activeProductCategoryId={activeProduct} />}


    </Pdiv>
  )
}

export default ServiceSection;








const Title = styled.div`
section{
  font-size: 18px;
  font-weight: 600;
  @media only screen and (min-width: 250px) and (max-width: 499px) {  font-size: 16px;}
}
span{
  font-size: 14px;
  font-weight: 400;
  @media only screen and (min-width: 250px) and (max-width: 499px) {  font-size: 12px;}
}
`;





const CartItemsContainer = styled.div`
margin-top:1rem;
margin-bottom:5rem;
`;

const Pdiv = styled.div`
padding-top: 5rem;
/* height: calc(100vh - 5rem); */
@media only screen and (min-width: 1024px) {
display: grid; 
grid-template-columns: repeat(12, minmax(0, 1fr)); 
}`;

const Cdiv1 = styled.div`
@media only screen and (min-width: 1024px) {grid-column: span 8;}
/* @media only screen and (min-width: 250px) and (max-width: 400px) {padding:0 1rem;}
@media only screen and (min-width: 401px) and (max-width: 767px) {padding:0 2rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0 4rem;}
@media only screen and (min-width: 1024px) and (max-width: 1499px) { padding:0 1rem;}
@media only screen and (min-width: 1500px)and (max-width: 1600px) { padding:0 4rem;}
@media only screen and (min-width: 1600px)and (max-width: 1799px) { padding:0 6rem;}
@media only screen and (min-width: 1800px) { padding:0 7rem;} */

`;

const Cdiv2 = styled.div`
/* margin-top: 2rem; */
  @media only screen and (max-width: 1023px) {display:none;}
  @media only screen and (min-width: 1024px) {
  grid-column: span 4 / span 4; 
  box-shadow: 0px 7px 30px 0px #00000012;
  }
`;

const Imgs = styled.img`
object-fit:contain;
@media only screen and (min-width: 250px) and (max-width: 767px) {width:5rem; height:5rem}
@media only screen and (min-width: 768px) and (max-width: 1023px) {width:7rem; height:7rem}
@media only screen and (min-width: 1024px) and (max-width: 1499px){width:8rem; height:7rem}
@media only screen and (min-width: 1500px){width:10rem; height:9rem}
`

// new
const C1sub1 = styled.div`

margin-top: 2rem;
  /* height: 2rem; */

display: flex;
justify-content: space-between;
align-items: center;
padding: 0 35px;
@media only screen and (min-width: 250px) and (max-width: 797px) {margin-top:0.5rem; padding: 0 10px;}
`;


const C1sub2 = styled.div` 
margin-top: 2rem;
height: calc(100vh - 12rem);
overflow-y: auto;
@media only screen and (min-width:1600px){padding-left:5rem;}
@media only screen and (min-width: 250px) and (max-width: 797px) {margin-top:0.5rem;}
`;

const C1sub3 = styled.div` 
display: grid; 
grid-template-columns: repeat(12, minmax(0, 1fr)); 
padding:1rem;
max-width: 840px;
@media only screen and (min-width: 250px) and (max-width: 767px) {gap:1rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {gap:1rem;}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {gap:1rem;}
@media only screen and (min-width: 1200px) {gap:2rem;}
`;

const C1sub4 = styled.div`
 border: ${props => (props.isActive ? '1px solid #4669E8' : 'none')};
display:flex;
flex-direction: column;
justify-content: center;
align-items:center;
box-shadow: 0px 7px 30px 0px #00000012;


@media only screen and (min-width: 250px) and (max-width: 767px) {grid-column: span 6 / span 6; }
@media only screen and (min-width: 768px) and (max-width: 1023px) {grid-column: span 4 / span 4; }
@media only screen and (min-width: 1024px) {grid-column: span 3 / span 3; }
@media only screen and (min-width: 1600px){max-width:12rem; max-height:12rem;}

span{
  font-size: 12px;
  font-weight: 400;
}
`


const CartItemsConatiner = styled.div`
display: flex;
align-items: center;
gap:3rem;
padding-left:1.5rem;
padding-right:3.8rem;
`

const CartDetailsConatiner = styled.div`
flex-grow: 1;
`

const ItemImg = styled.img`
height:6.6rem;
width:6.6rem;
background-color: white;
border:1px solid black;
`

const ItemTitle = styled.div`
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

`


const ItemDetails = styled.div`

display:flex;
justify-content: space-between;
padding-top:1rem;
`
const ItemDetail = styled.div`
display:flex;
gap:0.5rem;
`


const CartWrapper = styled.div`
position: fixed;
top: 0;
right: 0;
bottom: 0;
width: 95%;
background-color:#F8F8F8;
z-index: 2;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
padding-top: 5rem;
height: calc(100vh - 5rem);
`;

const CartHeader = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
`;



const CloseButton = styled.button`
  color: #fff;
  border: none;
  cursor: pointer;
  margin:1rem;
  font-size:0.8rem;
  font-weight: bold;
  display:flex;
  justify-content: center;
  align-items: center;
  gap:0.5rem;
  border-radius:5px;
  background-color: #0095ff;
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
  height:2rem;
  padding:0 0.5rem;
`;



const CartItems = styled.div`
height: calc(100vh - 5rem);
overflow-y: auto;
`
const CartIcon = styled.div` 
  cursor: pointer;
  display: none;
  @media only screen and (max-width: 1023px) {display:block;}
`;


const ButtonConatiner = styled.div` 
display:flex;
gap:0.5rem;
align-items: center;
justify-content: center;
`;


