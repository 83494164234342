import React, { useEffect, useState } from "react";
import { Modal, ModalContainer, Overlay } from "../../../components/modal";
import {
  Letter,
  Main,
} from "./InvoiceModalStyle";
import { IoClose } from "react-icons/io5";
import { listQuotid } from "../../../api/api";
import moment from "moment";
import styled from "styled-components";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { FcDownload } from "react-icons/fc";

const InvoiceModal = ({ setIsOpen, activeQtn }) => {

  // console.log("activeQtn", activeQtn)

  const [quatation, setQuatation] = useState();
  var totalPrice = 0;


  const [data, setData] = useState([]);



  async function getQtn() {
    await fetch(listQuotid + "/" + activeQtn)
      .then((response) => response.json())
      .then((response) => {
        setQuatation(response);
        setData(response);
      });
  }

  useEffect(() => {
    getQtn();
  }, []);





  const handleClick = () => {
    const doc = new jsPDF();
    autoTable(doc, {
      html: "#my-table",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      theme: "plain",
      bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
    });
    doc.save("report.pdf");
  };












  return (

    <ModalContainer>
      <Overlay style={{ position: "relative", marginTop: "2rem" }}>
        <Modal>

          <Main>



            <IoClose
              onClick={() => {
                setIsOpen(false);
              }}
            />


            <Letter>
              <LetterHead>
                <span>CLICKEKART</span>
              </LetterHead>



              <Button onClick={handleClick}>Download</Button>

              <DateContainer>
                <p>Quotation No : {data?.quot_no} </p>
                <p>Date : {moment(data?.created_at).format("DD-MM-YYYY")}</p>
              </DateContainer>

              <PdfTable className="report__table" id="my-table">
                <tr>
                  <th>No</th>
                  <th>Item</th>
                  <th>Warranty</th>
                  <th>Unit Price</th>
                  <th>Qty</th>
                  <th>CGST</th>
                  <th>SGSt</th>
                  <th>Total Price</th>
                </tr>
                {data?.productArray?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.title}</td>
                    <td>{item?.warranty}</td>
                    <td>{item?.offer_price}</td>
                    <td>{item?.qnty}</td>
                    <td>{Number(item?.gst) / 2} %</td>
                    <td>{Number(item?.gst) / 2} %</td>
                    <td>
                      {/* {item?.offer_price * item?.qnty +
                        (item?.offer_price * item?.qnty * Number(item?.gst)) / 100} */}

                      {item?.total}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={7}>Service Charge</td>
                  <td>{data.service_charge}</td>
                </tr>
                <tr>
                  <td colSpan={7}>GRAND TOTAL</td>
                  <td>
                    {/* {data?.productArray?.map((item, index) => {
                  TotalAmt = TotalAmt + parseInt(item?.offer_price);
                })}
                {TotalAmt} */}
                    {/* {grandTotal
                  ? grandTotal + location.state.serviceCharge
                  : location.state.serviceCharge} */}
                    {data?.grand_total}
                  </td>
                </tr>
                {/* <tr colSpan={6}>Words</tr> */}
              </PdfTable>
              <TermsAndConditions>
                <p>Terms & conditions : {data.terms_conditions} </p>
                {/* <p></p> */}
                <p>Prepared By : {data?.prepared_by?.name}</p>
              </TermsAndConditions>
            </Letter>
          </Main>
        </Modal>
      </Overlay>
    </ModalContainer>
  );
};

export default InvoiceModal;


// const Letter = styled.div`
// width: 100%;
// border: 1px solid black;
// margin-top: 80px;
// padding: 15px;
// @media screen and (max-width: 768px) {
//   /* width: 500px; */
//   width: 100%;
//   overflow-x: scroll;
// }
// `;


const SubDiv = styled.div`
padding: 100px 20px;
margin: 0 20px;
margin-right: 40px;
@media screen and (max-width: 768px) {
  padding: 0;
  padding-right: 40px;
  padding-top: 30px;
  margin-right: 10px;
}
`;
const Container = styled.div`
position: relative;
margin-left: 15%;
margin-top: 30px;
width: 85vw;
@media screen and (max-width: 768px) {
  width: 100vw;
  margin: 50px 0px !important;
}
@media screen and (max-width: 1025px) {
  width: 100vw;
  margin-left: 0px;
}
`;

const LetterHead = styled.div`
display: flex;
margin: 20px;
justify-content: center;
`;
const Narration = styled.div`
display: flex;
/* margin: 20px; */
padding-bottom: 50px;
`;
const TermsAndConditions = styled.div`
display: flex;
padding-top: 20px;
flex-direction: column;
row-gap: 70px;
`;
const DateContainer = styled.div`
display: flex;
justify-content: space-between;
`;

const PdfTable = styled.table`
border: 0.5px solid;
/* display: none; */
width: 100%;
border-collapse: collapse;
td {
  border: 0.5px solid rgba(0, 0, 0, 0.5);
}
th {
  border: 0.5px solid rgba(0, 0, 0, 0.5);
  color: red;
}
`;

// const Button = styled.button`
// float: right;
// margin: 20px 0px;
// background-color: #4668e9;
// cursor: pointer;
// color: #fff;
// border: none;
// padding: 8px 16px;
// border-radius: 5px;
// font-size: 12px;
// font-weight: 100;
// `;



const Button = styled.button`
background-color: #4668e9;
cursor: pointer;
color: #fff;
border: none;
padding: 8px 16px;
border-radius: 5px;
font-size: 12px;
font-weight: 100;
`;




































// old code
// import React, { useEffect, useState } from "react";
// import { Modal, ModalContainer, Overlay } from "../../../components/modal";
// import {
//   FlexBetween,
//   FlexCol,
//   Item,
//   Items,
//   Letter,
//   Main,
//   Narration,
//   Num,
//   Prepared,
//   Price,
//   Quantity,
//   Row,
//   Sgst,
//   SubHead,
//   Table,
//   Terms,
//   Total,
//   TotalFull,
//   TotalSpan,
//   Warranty,
// } from "./InvoiceModalStyle";
// import { IoClose } from "react-icons/io5";
// import { listQuotid } from "../../../api/api";
// import moment from "moment";

// const InvoiceModal = ({ setIsOpen, activeQtn }) => {

//   console.log("activeQtn", activeQtn)

//   const [quatation, setQuatation] = useState();
//   var totalPrice = 0;

//   async function getQtn() {
//     await fetch(listQuotid + "/" + activeQtn)
//       .then((response) => response.json())
//       .then((response) => {
//         setQuatation(response);
//       });
//   }

//   useEffect(() => {
//     getQtn();
//     console.log(listQuotid + "/" + activeQtn);
//   }, []);

//   return (

//     <ModalContainer>
//       <Overlay style={{ position: "relative", marginTop: "2rem" }}>
//         <Modal>
//           <Main>
//             <IoClose
//               onClick={() => {
//                 setIsOpen(false);
//               }}
//             />
//             <Letter>
//               <FlexCol>
//                 <div>
//                   <SubHead>Letterhead</SubHead>
//                   <FlexBetween>
//                     <Items>Qtn no: {quatation?.quot_no}</Items>
//                     <Items>
//                       date:- {moment.parseZone(quatation?.updated_at).format("DD-MMM-YY")}
//                     </Items>
//                   </FlexBetween>
//                   <Narration>Narration :-</Narration>
//                   <Table>
//                     <thead>
//                       <Row>
//                         <Num>no</Num>
//                         <Item>item</Item>
//                         <Warranty>warranty</Warranty>
//                         <Price>Unit price</Price>
//                         <Quantity>qty</Quantity>
//                         <Total>total price</Total>
//                       </Row>
//                     </thead>
//                     <tbody>
//                       {quatation?.productArray.map((item, index) => {
//                         totalPrice += item?.offer_price * item?.qnty;

//                         return (
//                           <Row>
//                             <Num>{index}</Num>
//                             <Item>{item?.title}</Item>
//                             <Warranty>{item?.warranty}</Warranty>
//                             <Price>{item?.offer_price}</Price>
//                             <Quantity>{item?.qnty}</Quantity>
//                             <Total>Rs. {item?.offer_price * item?.qnty}</Total>
//                           </Row>
//                         );
//                       })}

//                       <Row>
//                         <Sgst>SGST</Sgst>
//                         <Quantity>18%</Quantity>
//                         <Total></Total>
//                       </Row>
//                       <Row>
//                         <Sgst>CGST</Sgst>
//                         <Quantity>18%</Quantity>
//                         <Total></Total>
//                       </Row>
//                       <Row>
//                         <TotalSpan>GRAND TOTAL : Rs. {totalPrice}</TotalSpan>
//                         <Total></Total>
//                       </Row>
//                       <Row>
//                         <TotalFull>WORDS: </TotalFull>
//                       </Row>
//                     </tbody>
//                   </Table>
//                   <Terms>TERMS AND CONDITIONS : {quatation?.terms_conditions}</Terms>
//                 </div>
//                 <Prepared>Prepared By : {quatation?.prepared_by?.name}</Prepared>
//               </FlexCol>
//             </Letter>
//           </Main>
//         </Modal>
//       </Overlay>
//     </ModalContainer>
//   );
// };

// export default InvoiceModal;

















