import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useContext } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import AccessoriesCart from "../AccessoriesCart";
import AccessoriesMobileCart from "../AccessoriesMobileCart";
import axiosInstance from "../../../../consts/axiosInstance";
import ErrorComponent from "../../../../components/Button/error/ErrorComponent";
import LoadingSpinner from "../../../../components/Button/Loading/LoadingSpinner";
import { AuthContext } from "../../../../consts/AuthContext";
import PrinterType from "../../../../components/Modal/PrinterType";
import InstallModal from "../../../../components/Modals/InstallModal";




const PrinterHome = () => {

  const navigation = useNavigate();

  const [authUser, setAuthUser] = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [components, setComponent] = useState([]);


  const [printerCategories, setPrinterCategories] = useState([]);
  const [total, setTotal] = useState(0);





  // for mobile screen
  const [cartVisible, setCartVisible] = useState(false);
  const toggleCart = () => {
    setCartVisible(!cartVisible);
  };






  //lap,mob,tab,desk accessories categories
  const getAccessoriesCategories = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getAccessoriesByProduct/9`);
      // console.log(response.data, " response of accessories catagory")
      setComponent(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    getAccessoriesCategories();
  }, []);














  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024 && cartVisible) {
        setCartVisible(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [cartVisible]);



  const categorycolor = JSON.parse(localStorage.getItem("productcode"));


  const pcartpid = JSON.parse(localStorage.getItem("cartpid"));
  // console.log(pcartpid, "cart id ")


  const parentProductid = JSON.parse(localStorage.getItem("Productid"));
  // console.log(parentProductid, "parent p id ")



  const [cartData, setCartData] = useState([]);








  //getting accessories based on parent product - temp cart
  const getCartProducts = async () => {
    try {
      setLoading(true);

      const response = await axiosInstance.get(`productaccessories/${pcartpid}/${parentProductid}`);
      // console.log(response?.data[0])
      setCartData(response?.data?.products[0]);
      // console.log(response?.data?.products[0]);
      // setCalcTotal(response?.data?.products)
      setTotal(response?.data?.total_amount);

    } catch (error) {

      setError(error);
    } finally {

      setLoading(false);
    }
  };



  useEffect(() => {
    getCartProducts();
  }, [])









  //remove item from cart only accessory not main product
  async function removeItemFromCart(item) {
    try {
      const formData = {
        cart_id: pcartpid,
        product_id: item.productId,
        // total_amount: -(item.qnty * item.comboPrice),
        total_amount: item.parent_product_id ? -(item.qnty * item.comboPrice) : -(item.qnty * item.offer_price),
      };
      const options = {
        method: "POST",
        url: 'removeProduct',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      const response = await axiosInstance.request(options);
      // console.log("Response:", response.data);

      getCartProducts();

    } catch (error) {
      console.error("Error:", error.message || error);
    }
  }



  // remove accesssories
  const accessoriesRemoveHandler = (item) => {
    // console.log(item)
    removeItemFromCart(item);
  }



  // remove parent product
  const parentProductRemoveHandler = (item) => {
    removeItemFromCart(item);
    navigation('/')

  }







  //increase quantity
  async function increaseQuantity(item) {
    try {
      const formData = {
        id: pcartpid,
        product_category_id: item.product_category_id,
        product_id: item.productId,
        amount: item.parent_product_id ? (item.comboPrice) : (item.offer_price),
        total_amount: 1,
        qnty: ++item.qnty,
      };

      const options = {
        method: "POST",
        url: 'changeQuantity',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      const response = await axiosInstance.request(options);
      // console.log("Response: for increase quantity", response.data);
      getCartProducts();

    } catch (error) {
      console.error("Error:", error.message || error);

    }

  }





  // decrease quantity
  async function decreaseQuantity(item) {
    try {
      const formData = {
        id: pcartpid,
        product_category_id: item.product_category_id,
        product_id: item.productId,
        amount: item.parent_product_id ? (item.comboPrice) : (item.offer_price),
        total_amount: -1,
        qnty: --item.qnty,
      };




      // console.log(formData)

      const options = {
        method: "POST",
        url: 'changeQuantity',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      const response = await axiosInstance.request(options);
      // console.log("Response:", response.data);
      getCartProducts();
    } catch (error) {
      console.error("Error:", error.message || error);

    }

  }



  const handleAddQuantity = (item) => {
    if (item?.qnty < 10) {
      increaseQuantity(item);
    }
  };



  const handleRemoveQuantity = (item) => {
    if (item?.qnty > 1) {
      decreaseQuantity(item);
    }
  };









  const [printerTypeModal, setPrinterTypeModal] = useState(false);
  const [installModal, setInstallModal] = useState(false);


  //id of  product
  const product_category_id = JSON.parse(localStorage.getItem("productid"));
  // color code id
  const productCode = JSON.parse(localStorage.getItem("productcode"));





  const nextButtonHandler = () => {

    if (productCode === "001" || productCode === "004") {
      //lap and desk top
      setInstallModal(true)
    } else {
      navigation('/sales/shoppingcart')
    }


  }










  // printer section
  const getPrinterCategory = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getPrintersByProduct/${product_category_id}`);
      setPrinterCategories(response.data);
      localStorage.setItem("printercatId", JSON.stringify(response?.data[0]?.printer_category_id));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getPrinterCategory();
  }, [])










  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }



  return (
    <>
      <Pdiv>
        {printerTypeModal && <PrinterType data={setPrinterTypeModal} navi={"/printer"} />}
        {installModal && <InstallModal close={setInstallModal} />}
        <Cdiv1>
          <C1sub1>
            <div>Choose Category</div>
            <CartIcon onClick={toggleCart}>
              <AiOutlineShoppingCart size={30} />
            </CartIcon>
          </C1sub1>


          <C1sub2>

            <C1sub3>
              {printerCategories?.map((item) => (
                <C1sub4 key={item.id}
                  onClick={() => [
                    setPrinterTypeModal(true),
                    localStorage.setItem("Typeids", JSON.stringify(item?.id)),
                  ]}
                >
                  <Imgs src={item?.printer_image} alt="printer" />
                  <div>{item?.printer_type_name}</div>
                </C1sub4>
              ))}
            </C1sub3>

          </C1sub2>
        </Cdiv1>


        <Cdiv2>
          <AccessoriesCart data={cartData}
            accRemove={accessoriesRemoveHandler}
            parentRemove={parentProductRemoveHandler}
            handleRemoveQuantity={handleRemoveQuantity}
            handleAddQuantity={handleAddQuantity}
            nextHandler={nextButtonHandler}
            total={total}
          />
        </Cdiv2>

        {cartVisible && <AccessoriesMobileCart onClose={toggleCart} data={cartData}
          accRemove={accessoriesRemoveHandler}
          parentRemove={parentProductRemoveHandler}
          handleRemoveQuantity={handleRemoveQuantity}
          handleAddQuantity={handleAddQuantity}
          nextHandler={nextButtonHandler}
          total={total}
        />}
      </Pdiv>
    </>

  );
};
export default PrinterHome;









const Pdiv = styled.div`
padding-top: 5rem;
/* height: calc(100vh - 5rem); */
@media only screen and (min-width: 1024px) {
display: grid; 
grid-template-columns: repeat(12, minmax(0, 1fr)); 
}`;

const Cdiv1 = styled.div`
@media only screen and (min-width: 1024px) {grid-column: span 8;}
@media only screen and (min-width: 250px) and (max-width: 400px) {padding:0 1rem;}
@media only screen and (min-width: 401px) and (max-width: 767px) {padding:0 2rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0 4rem;}
@media only screen and (min-width: 1024px) and (max-width:x) { padding:0 1rem;}
@media only screen and (min-width: 1500px)and (max-width: 1600px) { padding:0 4rem;}
@media only screen and (min-width: 1600px)and (max-width: 1799px) { padding:0 6rem;}
@media only screen and (min-width: 1800px) { padding:0 7rem;}
`;

const Cdiv2 = styled.div`
  @media only screen and (max-width: 1023px) {display:none;}
  @media only screen and (min-width: 1024px) {
  grid-column: span 4 / span 4; 
  box-shadow: 0px 7px 30px 0px #00000012;
  }
`;

const Imgs = styled.img`
object-fit:contain;
@media only screen and (min-width: 250px) and (max-width: 767px) {width:5rem; height:5rem}
@media only screen and (min-width: 768px) and (max-width: 1023px) {width:7rem; height:7rem}
@media only screen and (min-width: 1024px) and (max-width: 1499px){width:8rem; height:7rem}
@media only screen and (min-width: 1500px){width:10rem; height:9rem}
`

// new
const C1sub1 = styled.div`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  /* height: 2rem; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  display: flex;
  gap: 1rem;

  @media only screen and (min-width: 1024px) {
    justify-content: center;
  }
  @media only screen and (max-width: 1023px) {
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;


const C1sub2 = styled.div` 
height: calc(100vh - 9rem);
overflow-y: auto;
`;

const C1sub3 = styled.div` 
display: grid; 
grid-template-columns: repeat(12, minmax(0, 1fr)); 
padding:1rem;
@media only screen and (min-width: 250px) and (max-width: 767px) {gap:1rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {gap:1rem;}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {gap:1rem;}
@media only screen and (min-width: 1200px) {gap:2rem;}
`;

const C1sub4 = styled.div`
background-color:#FFFFFF;
padding:0.5rem;
gap:0.5rem;
display:flex;
flex-direction: column;
justify-content: center;
align-items:center;
box-shadow: 0px 7px 30px 0px #00000012;
border-radius:10px;

@media only screen and (min-width: 250px) and (max-width: 767px) {grid-column: span 6 / span 6; }
@media only screen and (min-width: 768px) and (max-width: 1023px) {grid-column: span 4 / span 4; }
@media only screen and (min-width: 1024px) {grid-column: span 3 / span 3; }
@media only screen and (min-width: 1600px){max-width:12rem; max-height:12rem;}
`


const CartItemsConatiner = styled.div`
display: flex;
align-items: center;
gap:3rem;
padding-left:1.5rem;
padding-right:3.8rem;
`

const CartDetailsConatiner = styled.div`
flex-grow: 1;
`

const ItemImg = styled.img`
height:6.6rem;
width:6.6rem;
background-color: white;
border:1px solid black;
`

const ItemTitle = styled.div`
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

`


const ItemDetails = styled.div`

display:flex;
justify-content: space-between;
padding-top:1rem;
`
const ItemDetail = styled.div`
display:flex;
gap:0.5rem;
`


const CartWrapper = styled.div`
position: fixed;
top: 0;
right: 0;
bottom: 0;
width: 95%;
background-color:#F8F8F8;
z-index: 2;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
padding-top: 5rem;
height: calc(100vh - 5rem);
`;

const CartHeader = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
`;



const CloseButton = styled.button`
  color: #fff;
  border: none;
  cursor: pointer;
  margin:1rem;
  font-size:0.8rem;
  font-weight: bold;
  display:flex;
  justify-content: center;
  align-items: center;
  gap:0.5rem;
  border-radius:5px;
  background-color: #0095ff;
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
  height:2rem;
  padding:0 0.5rem;
`;



const CartItems = styled.div`
height: calc(100vh - 5rem);
overflow-y: auto;
`
const CartIcon = styled.div` 
  cursor: pointer;
  display: none;
  @media only screen and (max-width: 1023px) {display:block;}
`;


const ButtonConatiner = styled.div` 
display:flex;
gap:0.5rem;
align-items: center;
justify-content: center;
`;



const CartItemsContainer = styled.div`
margin-top:1rem;
margin-bottom:5rem;
`;