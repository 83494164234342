import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
  Radio,
  RadioInput,
  ButtonDiv,
  MainSide,
  LeftSide,
  BillingDiv,
  BoxsDiv,
  SideDiv,
  ContainerDiv,
} from './FormfileStyle';
import Price from '../price/price';
import Address from '../../../components/Modal/Address/Address';
import axios from 'axios';
import { AuthContext } from '../../../consts/AuthContext';
import ErrorComponent from '../../../components/Button/error/ErrorComponent';
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
import { getAllCart, insertInvoice } from '../../../api/api';
import styled from 'styled-components';
import axiosInstance from '../../../consts/axiosInstance';
import { useNavigate } from 'react-router-dom';
import AddressListModal from '../../../components/Modals/Address/AddressListModal';
import AssistModal from '../../../components/Modals/AssistModal';
import ErrorModal from '../../../components/Modals/ErrorModal';




const FormFile = () => {


  // user redeem coin
  const redeemtotal = localStorage.getItem('redeemtotal');
  const coininserted = localStorage.getItem('coininserted');

  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [authUser, setAuthUser] = useContext(AuthContext);

  // open address modal
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState(1)
  const [sameActiveAddress, setsameActiveAddress] = useState(null);
  const [billingActiveAddress, setBillingActiveAddress] = useState(null);
  const [serviceActiveAddress, setserviceActiveAddress] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [total, setTotal] = useState(0);
  const [cartId, setCartId] = useState('')
  const [open, setOpen] = useState(true);


  //fetch default address
  const fetchAddresses = async () => {
    try {
      const response = await axiosInstance.get(`getCustomerAddress/${authUser?.user?.id}`);
      const data = response.data;
      // Find the address with "default": 1
      const defaultAddr = data.find(address => address.default === 1);
      setsameActiveAddress(defaultAddr);
      setserviceActiveAddress(defaultAddr);
      setBillingActiveAddress(defaultAddr);
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };



  // new section
  function handleClicks(val) {
    setStatus(val);
    if (val === 1) {
      setserviceActiveAddress(null);
      setBillingActiveAddress(null);
      getallcart();
      fetchAddresses();
    } else if (val === 0) {
      setsameActiveAddress(null);
      getallcart();
      fetchAddresses();
    }
  }



  const onSelectionHandler = (addressFromModal) => {
    if (selectedSection === 1) {
      setsameActiveAddress(addressFromModal);
    } else if (selectedSection === 2) {
      setserviceActiveAddress(addressFromModal);
    } else if (selectedSection === 3) {
      setBillingActiveAddress(addressFromModal);
    }
  }


  const addressModalOpenHandler = (value) => {
    setOpenModal(true);
    setSelectedSection(value);
  }

  const getallcart = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`cartsDisplay/${authUser?.user?.id}`);
      setTotal(response?.data?.total);
      setCartId(response?.data?.cart[0]?.id);

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  // insert invoice api call
  const insertInvoiceFn = async () => {
    try {
      setLoading(true);
      const formData = {
        cart_id: cartId ? cartId : 0,
        user_id: authUser?.user?.id,
        name: status === 1 && sameActiveAddress ? sameActiveAddress.name : status === 0 && billingActiveAddress ? billingActiveAddress.name : '',
        designation: authUser ? authUser?.user?.designation : "",
        mob1: status === 1 && sameActiveAddress ? sameActiveAddress.mob1 : status === 0 && billingActiveAddress ? billingActiveAddress.mob1 : '',
        mob2: status === 1 && sameActiveAddress ? sameActiveAddress.mob2 : status === 0 && billingActiveAddress ? billingActiveAddress.mob2 : '',
        house_name: status === 1 && sameActiveAddress ? sameActiveAddress.house_name : status === 0 && billingActiveAddress ? billingActiveAddress.house_name : '',
        street_name: status === 1 && sameActiveAddress ? sameActiveAddress.street_name : status === 0 && billingActiveAddress ? billingActiveAddress.street_name : '',
        pincode: status === 1 && sameActiveAddress ? sameActiveAddress.pincode : status === 0 && billingActiveAddress ? billingActiveAddress.pincode : '',
        state: status === 1 && sameActiveAddress ? sameActiveAddress.state : status === 0 && billingActiveAddress ? billingActiveAddress.state : '',
        gst_no: status === 1 && sameActiveAddress ? sameActiveAddress.gst : status === 0 && billingActiveAddress ? billingActiveAddress.gst : '',
        address_status: status === 1 && sameActiveAddress ? sameActiveAddress.default : status === 0 && billingActiveAddress ? billingActiveAddress.default : '',
        total_amount: redeemtotal ? redeemtotal : total,
        payment_status: 0,
        shipping_address_id: status === 1 && sameActiveAddress ? sameActiveAddress.id : status === 0 && serviceActiveAddress ? serviceActiveAddress.id : '',
        billing_address_id: status === 1 && sameActiveAddress ? sameActiveAddress.id : status === 0 && billingActiveAddress ? billingActiveAddress.id : '',
        added_points: coininserted ? coininserted : 0,
      };
      const options = {
        method: "POST",
        url: 'insertInvoice',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      await axiosInstance.request(options).then((response) => {
        // '/sales/invoice/:id'
        if (response.data.code == 200) {
          const invoiceId = response?.data?.invoice_id;
          navigation(`/sales/invoice/${invoiceId}`)
        } else if (response.data.code == 403) {
          const invoiceId = response?.data?.invoice[0].id;
          navigation(`/sales/invoice/${invoiceId}`)
        }
      })
    } catch (error) {
      if (error.response.status === 404) {
        setErrorMessage(error.response.data.error)
      } else {
        setError(error);
      }
      // console.log("🚀 ~ update cart ", error);
    }
    finally {
      setLoading(false);
    }
  };




  useEffect(() => {
    getallcart();
    fetchAddresses();
  }, []);


  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }
  const nav = '/';
  return (
    <ContainerDiv className='min-h-screen flex flex-col md:flex-row md:justify-between px-[95%] py-10 gap-10'>
      {errorMessage ? <ErrorModal nav={nav} text={errorMessage} /> : null}
      {openModal && <AddressListModal setActiveAddress={onSelectionHandler} close={setOpenModal} />}
      {/* {openModal && <Address setActiveAddress={onSelectionHandler} close={setOpenModal} />} */}
      <MainSide className='flex items-center justify-center'>
        <LeftSide className='w-full lg:w-[70%]  xl:w-[40%] flex flex-col gap-y-4'>
          <span>Is shipping address and billing address the Same?</span>
          <Radio>

            <RadioInput className='space-x-4'>
              <div className='flex space-x-2'>
                <input
                  type='radio'
                  name='address_selection'
                  value='1'
                  onClick={() => handleClicks(1)}
                  checked={status === 1}
                />
                <p>Yes</p>
              </div>
              <div className='flex space-x-2'>
                <input
                  type='radio'
                  name='address_selection'
                  value='0'
                  onClick={() => handleClicks(0)}
                  checked={status === 0}
                />
                <p>No</p>
              </div>
            </RadioInput>
          </Radio>


          {status === 1 &&
            <div className='w-full'>
              <BillingDiv className='w-full p-2 space-y-4'>
                <p>Shipping & Billing Address</p>
                {sameActiveAddress &&
                  <>
                    <p className='text-[#716f6f]'>
                      <span>{sameActiveAddress?.name}</span><br />
                      {sameActiveAddress?.type_name} <br />
                      {sameActiveAddress?.mob1} <br />
                      {sameActiveAddress?.house_name} , {sameActiveAddress?.street_name} <br />
                      {sameActiveAddress?.pincode} <br />
                      {sameActiveAddress?.district} , {sameActiveAddress?.state}
                    </p>
                  </>}
              </BillingDiv>
              <ButtonDiv>
                <button className='bg-[#4669e8] text-white' onClick={() => addressModalOpenHandler(1)}>
                  Choose address
                </button>
              </ButtonDiv>

            </div>
          }



          {status === 0 &&
            <div>
              <div>
                <BillingDiv className='p-2 '>
                  <p>Shipping Address</p>
                  {serviceActiveAddress &&
                    <>
                      <span>{serviceActiveAddress?.name}</span>
                      <a>{serviceActiveAddress?.type_name}</a>
                      <a>{serviceActiveAddress?.mob1}</a>
                      <a>{serviceActiveAddress?.house_name}</a>
                      <a>{serviceActiveAddress?.street_name}</a>
                      <a>{serviceActiveAddress?.pincode}</a>
                      <a>{serviceActiveAddress?.district},{serviceActiveAddress?.state}</a>
                    </>}
                </BillingDiv>
                <ButtonDiv>
                  <button className='bg-[#4669e8] text-white' onClick={() => addressModalOpenHandler(2)}>
                    Choose address
                  </button>
                </ButtonDiv>
              </div>


              <div>
                <BillingDiv className='p-2'>
                  <p>Billing Address</p>
                  {billingActiveAddress &&
                    <>
                      <span>{billingActiveAddress?.name}</span>
                      <a>{billingActiveAddress?.type_name}</a>
                      <a>{billingActiveAddress?.mob1}</a>
                      <a>{billingActiveAddress?.house_name}</a>
                      <a>{billingActiveAddress?.street_name}</a>
                      <a>{billingActiveAddress?.pincode}</a>
                      <a>{billingActiveAddress?.district},{serviceActiveAddress?.state}</a>
                    </>}
                </BillingDiv>

                <ButtonDiv>
                  <button className='bg-[#4669e8] text-white' onClick={() => addressModalOpenHandler(3)}>
                    Choose address
                  </button>
                </ButtonDiv>
              </div>

            </div>
          }

          {/* {((status === 1 && sameActiveAddress) || (status === 0 && billingActiveAddress && serviceActiveAddress)) ? (
            <Submit
              style={{ color: 'white', display: 'block', backgroundColor: '#4669E8', }}> Submit
            </Submit>
          ) : ''} */}

        </LeftSide>
      </MainSide>


      <SideDiv className='flex flex-col justify-center gap-10 lg:w-[90%] xl:w-[70%]'>
        <BoxsDiv>
          <Price total={redeemtotal ? redeemtotal : total} />
        </BoxsDiv>

        {((status === 1 && sameActiveAddress) || (status === 0 && billingActiveAddress && serviceActiveAddress)) ? (
          <ProceedPayDiv>
            <button onClick={insertInvoiceFn}>Continue to payment</button>
          </ProceedPayDiv>
        ) : ''}


      </SideDiv>

    </ContainerDiv>

  );
};

export default FormFile;


const ProceedPayDiv = styled.div`
/* width:50%; */
display:flex;
flex-direction:row;
margin:10px 0;

button{
width:100%;
/* width:200px; */
height:50px;
border-radius:8px;
border:none;
/* margin-right:10px; */
cursor:pointer;
background-color:#4669E8;
color:white;
}`






//old code;
// import React, { useState, useEffect, useMemo, useContext } from 'react';
// import {
//   Radio,
//   RadioInput,
//   ButtonDiv,
//   MainSide,
//   LeftSide,
//   BillingDiv,
//   BoxsDiv,
//   SideDiv,
//   ContainerDiv,
// } from './FormfileStyle';
// import Price from '../price/price';
// import Address from '../../../components/Modal/Address/Address';
// import axios from 'axios';
// import { AuthContext } from '../../../consts/AuthContext';
// import ErrorComponent from '../../../components/Button/error/ErrorComponent';
// import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
// import { getAllCart, insertInvoice } from '../../../api/api';
// import styled from 'styled-components';
// import axiosInstance from '../../../consts/axiosInstance';
// import { useNavigate } from 'react-router-dom';
// import AddressListModal from '../../../components/Modals/Address/AddressListModal';




// const ProceedPayDiv = styled.div`
// width:50%;
// display:flex;
// flex-direction:row;
// margin:10px 0;

// button{
// width:200px;
// height:50px;
// border-radius:8px;
// border:none;
// margin-right:10px;
// cursor:pointer;
// background-color:#4669E8;
// color:white;
// }`



// const FormFile = () => {

//   const navigation = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [authUser, setAuthUser] = useContext(AuthContext);
//   console.log("authUser", authUser?.user?.designation)


//   // open address modal
//   const [openModal, setOpenModal] = useState(false);
//   const [status, setStatus] = useState('')
//   const [sameActiveAddress, setsameActiveAddress] = useState(null);
//   const [billingActiveAddress, setBillingActiveAddress] = useState(null);
//   const [serviceActiveAddress, setserviceActiveAddress] = useState(null);
//   const [selectedSection, setSelectedSection] = useState(null);
//   const [total, setTotal] = useState(0);
//   const [cartId, setCartId] = useState('')


//   const [open, setOpen] = useState(true);




//   // new section
//   function handleClicks(val) {

//     setStatus(val);

//     if (val === 1) {
//       setserviceActiveAddress(null);
//       setBillingActiveAddress(null);
//     } else if (val === 0) {
//       setsameActiveAddress(null);
//     }
//   }



//   const onSelectionHandler = (addressFromModal) => {

//     if (selectedSection === 1) {
//       setsameActiveAddress(addressFromModal);


//     } else if (selectedSection === 2) {
//       setserviceActiveAddress(addressFromModal);


//     } else if (selectedSection === 3) {
//       setBillingActiveAddress(addressFromModal);
//     }
//   }



//   const addressModalOpenHandler = (value) => {
//     setOpenModal(true);
//     setSelectedSection(value);
//   }



//   const getallcart = async () => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get(`getAllCart/${authUser?.user?.id}`);
//       // console.log('Response: of get all cart', response.data[0].productArray);
//       setCartId(response?.data[0].id);
//       setTotal(response?.data[0]?.total_amount);

//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };





//   // insert invoice api call
//   const insertInvoiceFn = async () => {

//     try {

//       setLoading(true);

//       const formData = {
//         cart_id: cartId ? cartId : '',
//         user_id: authUser?.user?.id,
//         name: status === 1 && sameActiveAddress ? sameActiveAddress.name : status === 0 && billingActiveAddress ? billingActiveAddress.name : '',
//         designation: authUser ? authUser?.user?.designation : "",
//         mob1: status === 1 && sameActiveAddress ? sameActiveAddress.mob1 : status === 0 && billingActiveAddress ? billingActiveAddress.mob1 : '',
//         mob2: status === 1 && sameActiveAddress ? sameActiveAddress.mob2 : status === 0 && billingActiveAddress ? billingActiveAddress.mob2 : '',
//         house_name: status === 1 && sameActiveAddress ? sameActiveAddress.house_name : status === 0 && billingActiveAddress ? billingActiveAddress.house_name : '',
//         street_name: status === 1 && sameActiveAddress ? sameActiveAddress.street_name : status === 0 && billingActiveAddress ? billingActiveAddress.street_name : '',
//         pincode: status === 1 && sameActiveAddress ? sameActiveAddress.pincode : status === 0 && billingActiveAddress ? billingActiveAddress.pincode : '',
//         state: status === 1 && sameActiveAddress ? sameActiveAddress.state : status === 0 && billingActiveAddress ? billingActiveAddress.state : '',
//         gst_no: status === 1 && sameActiveAddress ? sameActiveAddress.gst : status === 0 && billingActiveAddress ? billingActiveAddress.gst : '',
//         address_status: status === 1 && sameActiveAddress ? sameActiveAddress.default : status === 0 && billingActiveAddress ? billingActiveAddress.default : '',
//         total_amount: total ? total : '',
//         payment_status: 1,
//         shipping_address_id: status === 1 && sameActiveAddress ? sameActiveAddress.id : status === 0 && serviceActiveAddress ? serviceActiveAddress.id : '',
//         billing_address_id: status === 1 && sameActiveAddress ? sameActiveAddress.id : status === 0 && billingActiveAddress ? billingActiveAddress.id : '',
//       };

//       const options = {
//         method: "POST",
//         url: 'insertInvoice',
//         headers: {
//           "Content-Type": "application/json",
//         },
//         data: formData,
//       };

//       await axiosInstance.request(options).then((response) => {
//         console.log("🚀 ~ insert invoice response", response);

//         // '/sales/invoice/:id'

//         if (response.data.code == 200) {
//           const invoiceId = response?.data?.invoice_id;
//           console.log('🙌🙌', invoiceId);
//           navigation(`/sales/invoice/${invoiceId}`)
//         }

//       })


//     } catch (error) {
//       setError(error);
//       console.log("🚀 ~ update cart ", error);

//     }

//     finally {
//       setLoading(false);
//     }
//   };




//   useEffect(() => {
//     getallcart();
//   }, []);


//   if (loading) {
//     return <LoadingSpinner />
//   }

//   if (error) {
//     return <ErrorComponent error={error.message} />
//   }

//   return (
//     <ContainerDiv>
//       {openModal && <AddressListModal setActiveAddress={onSelectionHandler} close={setOpenModal} />}
//       {/* {openModal && <Address setActiveAddress={onSelectionHandler} close={setOpenModal} />} */}
//       <MainSide>
//         <LeftSide>
//           <span>Is shipping address and billing address the Same?</span>
//           <Radio>

//             <RadioInput>
//               <input
//                 type='radio'
//                 name='address_selection'
//                 value='1'
//                 onClick={() => handleClicks(1)}
//                 checked={status === 1}
//               />
//               <p>Yes</p>

//               <input
//                 type='radio'
//                 name='address_selection'
//                 value='0'
//                 onClick={() => handleClicks(0)}
//                 checked={status === 0}
//               />
//               <p>No</p>
//             </RadioInput>
//           </Radio>


//           {status === 1 &&
//             <div>
//               <BillingDiv>
//                 <p>Shipping & Billing Address</p>

//                 {sameActiveAddress &&
//                   <>
//                     <span>{sameActiveAddress?.name}</span>
//                     <a>{sameActiveAddress?.type_name}</a>
//                     <a>{sameActiveAddress?.mob1}</a>
//                     <a>{sameActiveAddress?.house_name}</a>
//                     <a>{sameActiveAddress?.street_name}</a>
//                     <a>{sameActiveAddress?.pincode}</a>
//                     <a>{sameActiveAddress?.district},{sameActiveAddress?.state}</a>
//                   </>}
//               </BillingDiv>

//               <ButtonDiv>
//                 <button style={{ color: 'black' }} onClick={() => addressModalOpenHandler(1)}>
//                   Choose address
//                 </button>
//               </ButtonDiv>

//             </div>
//           }



//           {status === 0 &&
//             <div>
//               <div>
//                 <BillingDiv>
//                   <p>Shipping Address</p>
//                   {serviceActiveAddress &&
//                     <>
//                       <span>{serviceActiveAddress?.name}</span>
//                       <a>{serviceActiveAddress?.type_name}</a>
//                       <a>{serviceActiveAddress?.mob1}</a>
//                       <a>{serviceActiveAddress?.house_name}</a>
//                       <a>{serviceActiveAddress?.street_name}</a>
//                       <a>{serviceActiveAddress?.pincode}</a>
//                       <a>{serviceActiveAddress?.district},{serviceActiveAddress?.state}</a>
//                     </>}
//                 </BillingDiv>

//                 <ButtonDiv>
//                   <button style={{ color: 'black' }} onClick={() => addressModalOpenHandler(2)}>
//                     Choose address
//                   </button>
//                 </ButtonDiv>
//               </div>


//               <div>
//                 <BillingDiv>
//                   <p>Billing Address</p>
//                   {billingActiveAddress &&
//                     <>
//                       <span>{billingActiveAddress?.name}</span>
//                       <a>{billingActiveAddress?.type_name}</a>
//                       <a>{billingActiveAddress?.mob1}</a>
//                       <a>{billingActiveAddress?.house_name}</a>
//                       <a>{billingActiveAddress?.street_name}</a>
//                       <a>{billingActiveAddress?.pincode}</a>
//                       <a>{billingActiveAddress?.district},{serviceActiveAddress?.state}</a>
//                     </>}
//                 </BillingDiv>

//                 <ButtonDiv>
//                   <button style={{ color: 'black' }} onClick={() => addressModalOpenHandler(3)}>
//                     Choose address
//                   </button>
//                 </ButtonDiv>
//               </div>

//             </div>
//           }


//           {/* {((status === 1 && sameActiveAddress) || (status === 0 && billingActiveAddress && serviceActiveAddress)) ? (
//             <Submit
//               style={{ color: 'white', display: 'block', backgroundColor: '#4669E8', }}> Submit
//             </Submit>
//           ) : ''} */}


//         </LeftSide>
//       </MainSide>



//       <SideDiv>
//         <BoxsDiv>
//           <Price total={total} />
//         </BoxsDiv>

//         {((status === 1 && sameActiveAddress) || (status === 0 && billingActiveAddress && serviceActiveAddress)) ? (
//           <ProceedPayDiv>
//             <button onClick={insertInvoiceFn}>Proceed to Payment</button>
//           </ProceedPayDiv>
//         ) : ''}

//       </SideDiv>

//     </ContainerDiv>

//   );
// };

// export default FormFile;
