
import styled from "styled-components";

export const HomeContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
`;
export const TopSection = styled.div`

  background: linear-gradient(
    90deg,
    #d9d9d9 0%,
    rgba(217, 217, 217, 0) 51.56%,
    #d9d9d9 100%

  );
  border-radius: 5px;
  height: 15vh;
  margin: auto;
  margin-top: 20px;
  width: 65vw;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 39px;
    padding: 5px 10px;
    text-align: center;
    @media only screen and (max-width: 838px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 626px) {
      font-size: 18px;
      line-height: 20px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 769px) {
    width: 90vw;
  }
  @media only screen and (max-width: 1025px) {
    width: 75vw;
  }
  @media only screen and (max-width: 600px) {
    width: auto;
    margin: 10px 20px;
  }
`;
export const Button = styled.button`
  background-color: #4669e8;
  border: none;
  padding: 8px 30px;
  color: #fff;
  font-size: 18px;
  font-weight: 100;
  border-radius: 8px;
`;

export const MenuContainer = styled.div`
  margin: auto;
  margin-top: 10px;
  width: 65vw;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* animation-name:menu;
  animation-duration:2s;
  @keyframes menu{
    from {opacity:0}
    to { opacity:1}
  } */
  @media only screen and (max-width: 769px) {
    width: 90vw;
  }
  @media only screen and (max-width: 1025px) {
    width: 75vw;
  }
  /* @media only screen and (max-width: 838px) {
    width: 600px;
  }
  @media only screen and (max-width: 626px) {
    width: 400px;
  }
  */
  @media only screen and (max-width: 600px) {
    width: auto;
    margin: 10px 20px;
  } 
`;
export const SubMenuContainer = styled.div`
  display: flex;
  gap: 15px;
  height: 40vh;
  overflow: hidden;
  position: relative;
  /* @media only screen and (max-width: 838px) {
    height: 180px;
  }
  @media only screen and (max-width: 626px) {
    height: 140px;
  }
  */
  @media only screen and (max-width: 426px) {
    flex-direction: column;
    height: 300px;
  }
`;
export const LeftChild = styled.div`
  /* background: linear-gradient(270deg, #03effe 0%, #4fadfe 100%); */
  /* background: ${(props) => props.background}; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: ${(props) => props.width};
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 426px) {
    height: 70%;
    width: 100%;
  }
  h3 {
    color: #fff;
    padding: 1px 15px;
    font-weight: 500;
    font-size: 18px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 10;
    /* @media only screen and (max-width: 838px) {
      padding: 0px 10px;
      // font-size: 25px;
    }
    @media only screen and (max-width: 626px) {
      padding: 0px 7px;
      font-size: 18px;
    }
    @media only screen and (max-width: 426px) {
      padding: 5px 10px;
    } */
    @media only screen and (max-width: 768px) {
      // font-size: 30px;
    } 
  }
  img {
    position: absolute;
    object-fit: cover;
    width: 110%;
    height: 100%;
    /* opacity: 0.7; */
    transition: all 0.5s ease-in-out;
    :hover {
      transform: scale(1.1);
    }
    /* @media only screen and (max-width: 838px) {
      height: 100%;
      width: 110%;
    } */
  }
`;
export const ChildWrapper = styled.div`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media only screen and (max-width: 426px) {
    flex-direction: row;
    width: 100%;
    height: 30%;
  }
`;
export const SubChild = styled.div`
  position: relative;
  display: flex;
  /* background: ${(props) => props.background}; */
  background-position: center;
  background-size: cover;
  width: ${(props) => props.width};
  height: ${(props) => (props.bottom ? "100%" : "50%")};
  border-radius: 5px;
  align-items: ${(props) => (props.primary ? "flex-end" : "center")};
  justify-content: ${(props) => (props.primary ? "" : "center")};
  overflow: hidden;
  h3 {
    color: #fff;
    padding: 5px 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
    @media only screen and (max-width: 838px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 626px) {
      font-size: 13px;
      padding: 5px 10px;
      text-align: center;
    }
  }
  img {
    position: absolute;
    object-fit: cover;
    /* opacity: 0.7; */
    transition: all 0.5s ease-in-out;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    :hover {
      transform: scale(1.2);
    }
    @media only screen and (max-width: 838px) {
      height: 100%;
      width: 100%;
    }
  }
  @media only screen and (max-width: 426px) {
    width: 100%;
    height: 100%;
  }
`;
export const BottomSubMenuContainer = styled.div`
  display: flex;
  gap: 15px;
  height: 20vh;
  overflow: hidden;
  position: relative;
  @media only screen and (max-width: 768px) {
    height: 20vh;
  }
  @media only screen and (max-width: 626px) {
    height: 65px;
  }
`;

export const MiddleMenuContainer = styled.div`
  display: flex;
  gap: 15px;
  height: 40vh;
  overflow: hidden;
  position: relative;
  @media only screen and (max-width: 838px) {
    height: 180px;
  }
  @media only screen and (max-width: 626px) {
    height: 140px;
  }
  @media only screen and (max-width: 426px) {
    flex-direction: column;
    height: 350px;
  }
`;

export const PosterContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(5px);
`;

export const PosterContent = styled.div`
  background: white;
  padding: 1rem;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;

  h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #333;
  }

  p {
    /* margin-bottom: 1.5rem; */
    color: #666;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  /* margin-top: 1rem; */

  button {
    background: #007aff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.7rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;

    &.playstore {
      background: #34a853;
    }

    &.appstore {
      background: #007aff;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  a {
    text-decoration: none;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  /* top: 10px; */
  /* right: 15px; */
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #ffffff;
`;