import { More } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import LoadingSpinner from '../../components/Button/Loading/LoadingSpinner';
import ErrorComponent from '../../components/Button/error/ErrorComponent';
import axios from 'axios';
import { checkPayment, getBidProduct, insertCustomerBid } from '../../api/api';
import { AuthContext } from '../../consts/AuthContext';
import AuctionClosed from './AuctionClosed';
import axiosInstance from '../../consts/axiosInstance';
import parse from "html-react-parser";



const Auctions = () => {

  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [bidProducts, setBidProducts] = useState([]);
  const [bidId, setBidId] = useState(null);
  const [entryPayment, setEntryPayment] = useState([]);
  const [openEntroll, setOpenEntroll] = useState(false);
  const [previousBid, setPreviousBid] = useState(true);
  // entroll btn
  const [entrolledBtn, setEntrolledBtn] = useState(false);
  // participate btn
  const [participationPay, setParticipationPay] = useState(false);
  const [participate, setParticipate] = useState(false);
  // for sub product
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [actualImg, setActaulImg] = useState([]);
  const [bidImg, setBidImg] = useState([]);
  const [bidStatus, setBidStatus] = useState(false);

  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const fetchTermsAndConditions = async () => {
    try {
      const response = await axiosInstance.get('/selectBidTerms');
      console.log(response.data)
      setTermsAndConditions(response.data[0] || 'No terms and conditions available.');
    } catch (error) {
      console.error('Error fetching terms and conditions:', error);
    }
  };
  useEffect(() => {
    fetchTermsAndConditions();
  }, []);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleTermsModalToggle = () => {
    setTermsModalOpen(!termsModalOpen);
  };


  const entrollHandler = () => {
    setOpenEntroll(true);
  }


  const participateHandler = () => {
    // navigation('/auction/buynow')
    navigation(`/auction/buynow/${bidId}/${bidProducts?.end_time}`)
  }




  // bid products list
  const getBidProducts = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('customerBidProduct');
      console.log('-=-=--=-=-=-=-=-', response.data)

      setBidProducts(response?.data);
      setBidId(response?.data?.bid_details_id);

      response.data?.actual_image && setActaulImg(JSON?.parse(response.data?.actual_image));
      response.data?.bonus_image && setBidImg(JSON?.parse(response.data?.bonus_image));

      if (response?.data?.previous_product === 1) {
        setBidStatus(false);
      } else if (response?.data?.previous_product === 0) {
        setBidStatus(true);
      }
      // console.log(response.data.enroll)
      // if (response.data.enroll == 1) {
      //   setEntrolledBtn(false);
      // } else {
      //   setEntrolledBtn(true);
      // }
      if (response.data?.bid_status == 0 && response.data?.initial_payment == 0 && response?.data?.buynow_status == 0) {
        setEntrolledBtn(false)
        // console.log("getenroll1", false)
      } else {
        setEntrolledBtn(true)
        // console.log("bid status", response.data.bid_status)
        // console.log("payment status", response.data.initial_payment)
        // console.log("buyed status", response.data.buynow_status)
        // console.log('Getenroll', true)
      }

      if (response?.data?.initial_payment === 1 && response?.data?.bid_status === 1) {
        // console.log("intial payment", response?.data?.initial_payment)
        // console.log("bid status", response?.data?.bid_status)
        setParticipate(false);
        // console.log(participate)
      } else {
        // console.log("intial payment", response?.data?.initial_payment)
        // console.log("bid status", response?.data?.bid_status)
        setParticipate(true);
        // console.log(participate)
      }


    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };





  // check entry product payemnt status
  const getBidEntryPaymentStatus = async () => {
    // try {
    //   setLoading(true);
    //   const response = await axiosInstance.get('checkPayment', {
    //     params: {
    //       customerBidId: bidId && bidId,
    //       userId: authUser?.user?.id,
    //     }
    //   })

    //   setEntryPayment(response?.data);

    //   if (response.data.length > 0) {
    //     setEntrolledBtn(false);
    //     setParticipationPay(true);
    //   } else {
    //     setEntrolledBtn(true);
    //     setParticipationPay(false);
    //   }


    //   if (response.data.length > 0 && response.data[0].payment_status === 1) {
    //     setPaymentStatus(true);
    //   }
    // } catch (error) {
    //   setError(error);
    // } finally {
    //   setLoading(false);
    // }
  };


  // buy base product
  const buyHandler = async () => {
    if (!isCheckboxChecked) return;
    try {
      setLoading(true);
      const formData = {
        user_id: authUser?.user?.id,
        bid_id: bidId && bidId,
        payment_status: 0,
      }
      const options = {
        method: 'POST',
        url: 'insertCustomerBid',
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
        data: formData,
      };
      await axiosInstance.request(options).then((response) => {
        // console.log("🚀 ~ bulk order update response", response);
      })
    } catch (error) {
      setError(error);
      // console.log("🚀 ~ update cart ", error);

    } finally {
      getBidProducts();
      getBidEntryPaymentStatus();
      setOpenEntroll(false);
      navigation('/sales/shoppingcart')
    }
  }

  useEffect(() => {
    getBidProducts();
  }, [])


  useEffect(() => {
    // getBidProducts();
    if (bidId !== null) {
      getBidEntryPaymentStatus();
    }

  }, [bidId])


  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }


  return (
    <>
      {bidStatus ? (
        <Main>
          <Container1>
            <SubDiv>
              <ImgContainer>
                <img src={actualImg[0]} alt='bid-product' />
              </ImgContainer>
              <Price>MRP ₹{bidProducts?.actual_product_price}</Price>
              <Title>{bidProducts?.actual_product_title}<span onClick={() => navigation('/auction/actualproduct/details')}>More details</span></Title>
              <Wrapper>
                <BtnContainer>
                  <EntrollButton disabled={entrolledBtn} enableBuy={entrolledBtn}
                    onClick={entrollHandler} >GET ENROLLED</EntrollButton>
                  <ParticipateButton disabled={participate} btn={participate}
                    onClick={participateHandler}>PARTICIPATE</ParticipateButton>
                </BtnContainer>
                {!openEntroll &&
                  <BoxContainer mt="1.6rem">
                    <img src={bidImg[0]} alt='bid-product' />
                    <div>{bidProducts?.bonus_product_title}</div>
                  </BoxContainer>
                }
                {!openEntroll && (
                  paymentStatus ? <Footer>You have purchased the secondary product to participate in this bid.</Footer> :
                    <Footer>To Participate in the bid buy the secondary product and get your password.</Footer>
                )}
              </Wrapper>
            </SubDiv>
          </Container1>



          <Container2>
            {openEntroll && (
              <SubDiv2>
                <Header>Get Enrolled</Header>
                <EntrollSub>
                  <EntrollPrice>MRP ₹{bidProducts?.bonus_price}</EntrollPrice>
                  <BoxContainer bg='#F5F5F5' >
                    <img src={bidImg[0]} alt='bid-product' />
                    <div>{bidProducts?.bonus_product_title}</div>
                  </BoxContainer>
                  <Details onClick={() => navigation('/auction/bonusproduct/details')}>More Details</Details>
                </EntrollSub>
                {/* Checkbox for terms and conditions */}
                <CheckboxContainer>
                  <input
                    type="checkbox"
                    id="termsCheckbox"
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="termsCheckbox">
                    Check the{' '}
                    <TermsLink onClick={handleTermsModalToggle}>
                      terms and conditions
                    </TermsLink>
                  </label>
                </CheckboxContainer>

                <BuyNow onClick={buyHandler} disabled={!isCheckboxChecked} btn={!isCheckboxChecked}>
                  Buy Now</BuyNow>
              </SubDiv2>
            )}
          </Container2>
          {/* Terms and Conditions Modal */}
          {termsModalOpen && (
            <ModalOverlay onClick={handleTermsModalToggle}>
              <ModalContent className='h-[30rem] overflow-scroll' onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Terms and Conditions</ModalHeader>
                <ModalBody>{parse(termsAndConditions.description)}</ModalBody>
                <ModalFooter>
                  <CloseButton onClick={handleTermsModalToggle}>Close</CloseButton>
                </ModalFooter>
              </ModalContent>
            </ModalOverlay>
          )}
        </Main>

      ) : (
        <AuctionClosed actualImg={actualImg}
          title={bidProducts?.actual_product_title}
          price={bidProducts?.actual_product_price} />
      )}
    </>


  )
}




export default Auctions;



const BuyNow = styled.button`
  background-color: ${(props) => (props.btn ? '#BCBCBC' : '#4669E8')};
font-family: Poppins;
font-size: 0.9rem;
font-weight: 400;
text-align: center;
color:white;
border:none;
@media only screen and (min-width: 250px) and (max-width: 767px) {padding:0.8rem 2rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0.8rem 4rem;}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {padding:0.8rem 1rem;}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {padding:0.8rem 2rem}
@media only screen and (min-width: 1500px) {padding:0.8rem  3rem;}
`;


const SubDiv2 = styled.div`
@media only screen and (min-width: 1024px) {
  width:30rem;
} 
@media only screen and (min-width: 250px) and (max-width: 374px) {padding:0 0.5rem 2rem} 
@media only screen and (min-width: 375px) and (max-width: 499px) {padding:0 1rem 2rem} 
@media only screen and (min-width: 500px) and (max-width: 767px) {padding:0 2rem 2rem}            
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0 6rem 2rem}
`



// conatiner 2
const Header = styled.div`
  font-size:1.2rem;
  font-weight:400;
  line-height:1.5rem; 
  `


const EntrollSub = styled.div`

margin-top:2rem;
display:flex;
flex-direction:column;
align-items:end;
            `

const EntrollPrice = styled.div`
font-weight:500;
color:#4669E8;
font-size:1.5rem;
`

const Details = styled.div`
color:#4669E8;
            `

const Wrapper = styled.div`
width:100%;

`




// main
const Main = styled.div`


@media (min-width: 1024px) { 
display: grid; 
grid-template-columns: repeat(12, minmax(0, 1fr)); 
}
height:100vh;
padding-top:5rem;
`

const Container1 = styled.div`
  @media (min-width: 1024px) {
    grid-column: span 5 / span 5;
    background-color: #F5F5F5;
  }
`;


const Container2 = styled.div`
@media only screen and (min-width: 250px) and (max-width: 1023px) {
margin-top:2rem;
}


  @media (min-width: 1024px) { 
  grid-column: span 7 / span 7;
  padding: 2.1rem 4rem;
}
`;


const SubDiv = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
@media only screen and (min-width: 250px) and (max-width: 374px) {padding:0 0.5rem} 
@media only screen and (min-width: 375px) and (max-width: 499px) {padding:0 1rem} 
@media only screen and (min-width: 500px) and (max-width: 767px) {padding:0 2rem}            
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0 6rem}
@media only screen and (min-width: 1024px) and (max-width: 1500px) {padding:0 4rem}
@media only screen and (min-width: 1501px) {padding:0 8rem}
`
const Price = styled.div`
margin-top: 1.5rem;
color:#4669E8;
font-weight:500;
font-size:1.563rem;
`

const Title = styled.div`
font-size:0.9rem;
line-height:1.375rem;
span{
color:#4669E8;
}
`
const ImgContainer = styled.div`
margin-top:1.5rem;
display:flex;
justify-content:center;
align-items:center;
img{
border:1px solid #00000033;
object-fit: contain;
width:380px;
height:380px;
@media only screen and (min-width: 355px) and (max-width: 480px) {
width:300px;
height:300px;
}
@media only screen and (min-width: 250px) and (max-width: 354px) {
width:260px;
height:300px;
}
}

`




const BtnContainer = styled.div`
display:flex;
justify-content: space-between;
margin-top:2rem;
${'' /* width:100%; */}
`


const BaseButton = styled.button`
font-family: Poppins;
font-size: 0.9rem;
font-weight: 400;
text-align: center;
color:white;
border:none;


@media only screen and (min-width: 250px) and (max-width: 399px) {
  font-size: 0.8rem;
  padding:1rem;
}

@media only screen and (min-width: 250px) and (max-width: 767px) {padding:0.8rem 2rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0.8rem 4rem;}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {padding:0.8rem 1rem;}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {padding:0.8rem 2rem}
@media only screen and (min-width: 1500px) {padding:0.8rem  3rem;}
`


const EntrollButton = styled(BaseButton)`
  background-color: ${(props) => (props.enableBuy ? '#BCBCBC' : '#4669E8')};
`;


const ParticipateButton = styled(BaseButton)`
background-color: ${(props) => (props.btn ? '#BCBCBC' : '#4669E8')};
`;


const BoxContainer = styled.div`

margin-top: ${props => props.mt || '0'};
background-color: ${props => props.bg || '#FDFDFD'};
display:flex;
align-items: center;
gap:1rem;
 ${'' /* width:30rem; */}
width:100%;
padding:0.3rem;     
img{
height:5.4rem;
width:5.4rem;
object-fit: contain;
}

@media only screen and (min-width: 250px) and (max-width: 1023px) {
background-color: #F5F5F5;
} 

`

const Footer = styled.div`
margin-top:1.3rem;
${'' /* width:100%; */}
font-size:0.8rem;
font-weight: 400;
line-height:1.3rem;
`

const CheckboxContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;

  label {
    margin-left: 0.5rem;
    font-size: 14px;
    color: #333;
  }
`;

const TermsLink = styled.span`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  border-radius: 10px;
`;

const ModalHeader = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

const ModalBody = styled.div`
  margin: 1rem 0;
  font-size: 14px;
  color: #555;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: #0056b3;
  }
`;