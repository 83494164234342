import React, { useEffect, useState } from 'react';
import { ADDRESS_DATA } from '../../../MappingDatas/AddressData';
import {
  AddressArea,
  AddressCard,
  AddressType,
  AddText,
  Div100,
  Div50,
  Edit,
  Err,
  Flex2,
  Flex3,
  Flex4,
  FlexBetween,
  FlexD,
  FlexInput,
  FlexLoading,
  FlexR,
  FlexRadio,
  H3,
  H4,
  H5,
  Input,
  Label,
  LeftInner,
  Manage,
  Name,
  NameA,
  Optional,
  Radio,
  Select,
  TextArea,
} from './ProfilePageStyle';
import { HiOutlineTrash } from 'react-icons/hi';
import { FiEdit } from 'react-icons/fi';
import { BiSave } from 'react-icons/bi';
import { AiOutlineSave } from 'react-icons/ai';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import {
  deleteCustomerAddress,
  getDistrict,
  getUserDetailsForProfile,
  professionName,
  updateCustomer,
  updateCustomerAddress,
} from '../../../api/api';
import { useContext } from 'react';
import { AuthContext } from '../../../consts/AuthContext';
import Lottie from 'lottie-react';
import loadingJson from '../../../assets/lottie/loading.json';
import successJson from '../../../assets/lottie/success.json';
import Modals from '../../../components/Modal/AddAddress';
import { set } from 'react-hook-form';
import styled from 'styled-components';
import axiosInstance from '../../../consts/axiosInstance';
import { MdDeleteOutline } from "react-icons/md";

import Switch from 'react-switch';
import DefaultAddressChangeModal from '../../../components/Modals/DefaultAddressChangeModal';
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
import ErrorComponent from '../../../components/Button/error/ErrorComponent';
import AddAddressModal from '../../../components/Modals/Address/AddAddressModal';
import AddSubUserModal from '../../../components/Modals/Subuser/AddSubUserModal';
import ExistingSubUserModal from '../../../components/Modals/Subuser/ExistingSubUserModal';



const ProfilePage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [addAddres, setAddAddres] = useState(false);
  const [professionDropDown, setProfessionDropDown] = useState([]);




  // address section
  const [addresses, setAddresses] = useState([]);
  const [editableAddressId, setEditableAddressId] = useState(null);
  const [pincodeInfo, setPincodeInfo] = useState({});
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedDistrictId, setSelectedDistrictId] = useState('');

  //profilesection
  const [editProfileInfo, setEditableProfileInfo] = useState(false);
  const [profileInfo, setProfileInfo] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [userDetails, setUserDetails] = useState('');
  const [subUserDetails, setSubUserDetails] = useState([]);
  const [newSubUser, setNewSubUser] = useState(false);

  const [defaultAddressModal, setDefaultAddressModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const getProfession = async () => {
    try {
      const response = await axios.get(professionName)
      // console.log("response:pr ", response.data)
      setProfessionDropDown(response?.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const getUserDetails = async () => {
    try {
      const response = await axiosInstance.get(`getUserDetailsForProfile/${authUser?.user.id}`);
      setProfileInfo(response?.data?.user_basic_details[0]);
      setSubUserDetails(response?.data?.sub_users)

      setUserDetails(response?.data);
      setDeliveryAddress(response?.data.user_address_list);
      setAddresses(response?.data.user_address_list);

      const initialPincodeInfo = {};
      response?.data.user_address_list.forEach(address => {
        initialPincodeInfo[address.id] = {
          pincode: address.pincode,
          state: address.state,
          district: address.district,
        };
      });
      setPincodeInfo(initialPincodeInfo);
    } catch (error) {
      console.error("Error while fetching user details:", error);
      // Handle error as needed
    }
  };



  const deleteDeliveryAddress = async (id) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('deleteCustomerAddress', { id });
      if (response.data.code === 200) {
        getUserDetails();
      } else if (response.data.code === 401) {
        setDefaultAddressModal(true);
        setModalData({ content: 'Default address cannot be deleted' });
      }
    } catch (error) {
      console.error("Error while deleting delivery address:", error);
    } finally {
      setLoading(false);
    }
  };





  const toogleModal = () => {
    setAddAddres(!addAddres);
  };

  const closeModal = () => {
    setAddAddres(false);
  };


  // edit button handler of address section
  function handleEditClick(addressId) {
    setEditableAddressId(addressId);
  }


  //onchange for address section
  function handleInputChange(event, addressId, field) {
    const updatedAddresses = addresses.map(address => {
      if (address.id === addressId) {
        return { ...address, [field]: event.target.value };
      }
      return address;
    });
    setAddresses(updatedAddresses);
  }


  //pincode , state , district change handler of address section
  function handlePincodeChange(event, addressId) {

    const newPincode = event.target.value;

    // updated pincode
    setPincodeInfo((prevPincodeInfo) => ({
      ...prevPincodeInfo,
      [addressId]: {
        pincode: newPincode,
      }
    }))


    if (newPincode.length === 6) {
      // Make API call to fetch state and district based on the pin code

      fetch(`https://api.postalpincode.in/pincode/${newPincode}`)
        .then((response) => response.json())
        .then((response) => {
          if (response[0].Status !== 'Error') {
            setSelectedDistrict(response[0]?.PostOffice[0]?.District);
            setPincodeInfo(prevPincodeInfo => ({
              ...prevPincodeInfo,
              [addressId]: {
                pincode: newPincode,
                district: response[0]?.PostOffice[0]?.District,
                state: response[0]?.PostOffice[0]?.State,
              },
            }))
          }
        });
    }

  }



  // save button handler of address section
  async function handleUpdateClick() {

    const updatedNewAddress = addresses.find(address => address.id === editableAddressId);
    const updatedNewPinInfo = pincodeInfo[editableAddressId]

    try {

      if (updatedNewAddress || updatedNewPinInfo) {

        const formData = {
          user_id: authUser?.user?.id,
          id: updatedNewAddress?.id,
          house_name: updatedNewAddress?.house_name,
          street_name: updatedNewAddress?.street_name,
          name: updatedNewAddress?.name,
          type_name: updatedNewAddress?.type_name,
          mob1: updatedNewAddress?.mob1,
          mob2: updatedNewAddress?.mob2,
          pincode: updatedNewPinInfo?.pincode,
          state: updatedNewPinInfo?.state,
          district: updatedNewPinInfo?.district,
          district_id: selectedDistrictId ? selectedDistrictId : updatedNewAddress?.district_id,
          default: updatedNewAddress?.default,
          gst: updatedNewAddress?.gst,
          // gst: data.gst,
          // default: status ? status : 0,
        };

        const options = {
          method: "POST",
          url: 'updateCustomerAddress',
          headers: {
            "Content-Type": "application/json",
          },
          data: formData,
        };

        await axiosInstance.request(options).then((response) => {
          if (response.data.code === 200) {
            getUserDetails();
          } else if (response.data.code === 401) {
            setDefaultAddressModal(true);
            setModalData({ content: 'Please select one address as default' });
          }



        });

      } else {
        console.error("editedAddress is not present. Unable to update address.");
      }


    } catch (error) {
      // setError(error);
      console.error("🚀 ~ update cart ", error);
    } finally {
      // setLoading(false);
      // setShowModal(false)
      setEditableAddressId(null);
      setPincodeInfo({});
      setSelectedDistrict('');
      setSelectedDistrictId('')
    }
  }


  // save btn handller for profile section 
  async function handleProfileUpdate() {

    try {
      const formData = {
        id: authUser?.user?.id,
        mob1: profileInfo?.mob1,
        name: profileInfo?.name,
        pincode: profileInfo?.pincode,
        district: profileInfo?.district,
        state: profileInfo?.state,
        profession: profileInfo?.profession,
        dob: profileInfo?.dob,
        organization_name: profileInfo?.organization_name,
        designation: profileInfo?.designation,
        gst: profileInfo?.gst,
        // type_of_organization:'',
      };

      const options = {
        method: "POST",
        url: 'updateCustomer',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      await axiosInstance.request(options).then((response) => {
        // console.log("🚀 ~ update address response", response);
        getUserDetails();
      });

    } catch (error) {
      // setError(error);
      console.error("🚀 ~ update cart ", error);
    } finally {
      setEditableProfileInfo(false);
    }

  }



  //onchange for profile edit 
  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileInfo({ ...profileInfo, [name]: value });
  };



  //pincode , state , district change handler of profile section
  function pinChangeProfile(event) {
    const newPincode = event.target.value;
    // updated pincode
    setProfileInfo({ ...profileInfo, pincode: newPincode });
    if (newPincode.length === 6) {
      fetch(`https://api.postalpincode.in/pincode/${newPincode}`)
        .then((response) => response.json())
        .then((response) => {
          if (response[0].Status !== 'Error') {
            setProfileInfo({
              ...profileInfo,
              pincode: newPincode,
              district: response[0]?.PostOffice[0]?.District,
              state: response[0]?.PostOffice[0]?.State
            });
          }
        });
    }
  }



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('getDistrict');
        const data = response.data;

        data.map((district) => {
          district.district_name === selectedDistrict &&
            setSelectedDistrictId(district.id);
        });
      } catch (error) {
        console.error("Error while fetching district data:", error);
      }
    };

    fetchData();
  }, [selectedDistrict]);


  useEffect(() => {
    getProfession();
    getUserDetails();
  }, []);






  //default address toggle
  const handleToggle = (addressId, checked) => {
    // console.log('Toggle button clicked for address:', addressId, 'checked:', checked);
    setAddresses(prevAddresses => {
      return prevAddresses.map(address => {
        if (address.id === addressId) {
          return { ...address, default: checked ? 1 : 0 };
        }
        return address;
      });
    });
  };



  async function deleteSubUser(data) {
    try {
      setLoading(true);
      const formData = {
        parentid: data.parentId,
        mob: data.mob,
      };
      const options = {
        method: "POST",
        url: 'deleteSubCustomers',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      await axiosInstance.request(options).then((response) => {
        getUserDetails();
      });
    } catch (error) {
      console.error("sub user delete error ", error);
    } finally {
      setLoading(false);
    }
  }


  const [existing, setExisting] = useState(false);


  if (loading) {
    return <LoadingSpinner />
  }
  if (error) {
    return <ErrorComponent error={error.message} />
  }

  return (
    <LeftInner className='w-[80%]'>
      <div>

        {defaultAddressModal && <DefaultAddressChangeModal modalData={modalData} setModalData={setModalData}
          close={setDefaultAddressModal} getUserDetails={getUserDetails} />}

        {addAddres && <AddAddressModal close={setAddAddres} apiCallHandler={getUserDetails} />}


        {newSubUser && <AddSubUserModal close={setNewSubUser} apiCallHandler={getUserDetails} setExisting={setExisting} />}
        {existing && <ExistingSubUserModal close={setExisting} />}



        {/* {addAddres && (
        <Modals
          getUserDetails={getUserDetails}
          setShowModal={setAddAddres}
          showModal={addAddres}
          close={setAddAddres}
          toogleModal={toogleModal}
          closeModal={closeModal}
        />
      )} */}


        <div className='p-20'>
          <H4>My Profile</H4>
          <Flex2>
            <H3>Personal Information</H3>
            <Flex4>
              {editProfileInfo ?
                //save button
                <AiOutlineSave style={{ stroke: 'transparent' }} onClick={handleProfileUpdate} /> :
                //edit button
                <FiEdit onClick={() => [setEditableProfileInfo(true)]} />
              }
            </Flex4>
          </Flex2>

          <FlexInput>
            <Div50>
              <Label htmlFor='fullname'>Full Name</Label>
              <Input
                name='name'
                value={profileInfo?.name}
                onChange={handleProfileChange}
                readOnly={!editProfileInfo}
                editable={editProfileInfo}
              />
            </Div50>
            <Div50>
              <Label htmlFor='dob'>Date of Birth</Label>
              <Input
                name='dob'
                type='date'
                value={profileInfo?.dob}
                readOnly={!editProfileInfo}
                editable={editProfileInfo}
                onChange={handleProfileChange}
              />
            </Div50>
          </FlexInput>

          <FlexInput>
            <Div50>
              <Label htmlFor='pincode'>Pincode</Label>
              <Input
                name='pincode'
                value={profileInfo?.pincode}
                readOnly={!editProfileInfo}
                editable={editProfileInfo}
                onChange={pinChangeProfile}
              />
            </Div50>

            <Div50>
              <Label htmlFor='district'>District</Label>
              <Input
                name='district'
                value={profileInfo?.district}
              />
            </Div50>
          </FlexInput>

          <FlexInput>
            <Div50>
              <Label htmlFor='state'>State</Label>
              <Input
                name='state'
                value={profileInfo?.state}
              />
            </Div50>


            <Div50>
              {profileInfo.profession !== "" && <>
                <Label htmlFor='profession'>Profession</Label>
                <Select
                  name='profession'
                  id='profession'
                  editable={editProfileInfo}
                  onChange={handleProfileChange}
                  value={profileInfo?.profession}
                >
                  {professionDropDown.map((item, index) => (
                    <option key={index} value={item.profession_name}>
                      {item.profession_name}
                    </option>
                  ))}
                </Select></>
              }
            </Div50>
          </FlexInput>

          <FlexInput>
            {profileInfo.organization_name !== "" &&
              <Div50>
                <Label htmlFor='organization_name'>Organization Name</Label>
                <Input
                  name='organization_name'
                  value={profileInfo.organization_name}
                  onChange={handleProfileChange}
                  readOnly={!editProfileInfo}
                  editable={editProfileInfo}
                />
              </Div50>
            }

            {profileInfo.designation !== "" &&
              <Div50 Div50 >
                <Label htmlFor='designation'>Designation</Label>
                <Input
                  name='designation'
                  value={profileInfo.designation}
                  onChange={handleProfileChange}
                  readOnly={!editProfileInfo}
                  editable={editProfileInfo}
                />
              </Div50>
            }

          </FlexInput>

          <FlexInput>
            <Div50>
              <Label htmlFor='mobile'>Mobile</Label>
              <Input
                name='mob1'
                value={profileInfo.mob1}
              />
            </Div50>

            <Div50>
              <Label htmlFor='gst'>Gst no <Optional>(optional)</Optional></Label>
              <Input
                name='gst'
                value={profileInfo.gst}
                onChange={handleProfileChange}
                readOnly={!editProfileInfo}
                editable={editProfileInfo}
              />
            </Div50>
          </FlexInput>








          {/* secondary user */}

          <Manage>
            <Flex3>
              <H5>Secondary User</H5>
              <div onClick={() => setNewSubUser(true)}><Edit> Add User</Edit>
              </div>
            </Flex3>


            {/* <FlexInput>
        <Div50>
          <Label htmlFor='fullname'>Full Name</Label>
          <Input
            name='name'
          />
        </Div50>
        <Div50>
          <Label htmlFor='dob'>Date of Birth</Label>
          <Input
            name='dob'
          />
        </Div50>
      </FlexInput> */}


            {subUserDetails.map((item) => (
              <AddressCard >
                <FlexBet>
                  <FlexD>
                    <div>
                      <label>Name</label>
                      <NameA
                        name='name'
                        value={item?.name}
                      />
                    </div>
                    <div>
                      <label>Mobile</label>
                      <NameA
                        name='mobile'
                        value={item?.mob}
                      />
                    </div>
                  </FlexD>
                  <DeleteIcon onClick={() => deleteSubUser(item)} >
                    <MdDeleteOutline size={25} style={{ stroke: 'transparent', color: '#4669E8', }} />
                  </DeleteIcon>
                </FlexBet>
              </AddressCard>
            ))}


          </Manage>















          {/* address section */}

          <Manage>
            <Flex3>
              <H5>Manage Delivery Address</H5>
              <div onClick={toogleModal}>
                <Edit>
                  Add Address
                </Edit>
              </div>
            </Flex3>

            {addresses?.map((address) => {
              return (
                <AddressCard key={address?.id}>
                  <AddressType>{address.type_name}</AddressType>

                  <FlexBetween>
                    <Div100>
                      <label htmlFor='name'>Name</label>
                      <NameA
                        name='name'
                        value={address.name}
                        onChange={(e) => handleInputChange(e, address?.id, 'name')}
                        readOnly={editableAddressId !== address.id}
                        editable={editableAddressId === address?.id ? true : false}
                      />
                    </Div100>


                    <Flex4>
                      {editableAddressId !== address.id ? (
                        //edit button
                        <FiEdit onClick={() => handleEditClick(address.id)} />
                      ) : (
                        //save button
                        <AiOutlineSave style={{ stroke: 'transparent' }} onClick={handleUpdateClick} />
                      )}

                      <MdDeleteOutline style={{ stroke: 'transparent' }} onClick={() => deleteDeliveryAddress(address?.id)} />
                    </Flex4>
                  </FlexBetween>

                  <FlexD>
                    <div>
                      <label>House Name</label>
                      <NameA
                        name='house_name'
                        value={address.house_name}
                        onChange={(e) => handleInputChange(e, address?.id, 'house_name')}
                        readOnly={editableAddressId !== address.id}
                        editable={editableAddressId === address?.id ? true : false}
                      />
                    </div>
                    <div>
                      <label>Street Name</label>
                      <NameA
                        name='street_name'
                        value={address.street_name}
                        onChange={(e) => handleInputChange(e, address?.id, 'street_name')}
                        readOnly={editableAddressId !== address.id}
                        editable={editableAddressId === address?.id ? true : false}
                      />
                    </div>
                  </FlexD>

                  <FlexD>
                    <div>
                      <label>District</label>
                      <NameA
                        value={pincodeInfo[address.id]?.district}
                        readOnly
                        editable={false}
                      />
                    </div>
                    <div>
                      <label>Pincode</label>
                      <NameA
                        name='pincode'
                        value={pincodeInfo[address.id]?.pincode}
                        onChange={(e) => handlePincodeChange(e, address.id)}
                        readOnly={editableAddressId !== address.id}
                        editable={editableAddressId === address?.id ? true : false}
                      />
                    </div>
                  </FlexD>
                  <FlexD>
                    <div>
                      <label>State</label>
                      <NameA
                        name='state'
                        value={pincodeInfo[address.id]?.state}  // Use pincodeInfo or fallback to address.state
                        readOnly
                        editable={false}
                      />
                    </div>

                    <div>
                      <label>Type</label>
                      <NameA
                        name='type_name'
                        value={address.type_name}
                        onChange={(e) => handleInputChange(e, address?.id, 'type_name')}
                        readOnly={editableAddressId !== address.id}
                        editable={editableAddressId === address?.id ? true : false}
                      />
                    </div>
                  </FlexD>


                  <FlexD>
                    <div>
                      <label>Mob 1</label>
                      <NameA
                        name='mob1'
                        value={address.mob1}
                        onChange={(e) => handleInputChange(e, address?.id, 'mob1')}
                        readOnly={editableAddressId !== address.id}
                        editable={editableAddressId === address?.id ? true : false}
                      />
                    </div>
                    <div>
                      <label>Mob 2</label>
                      <NameA
                        name='mob2'
                        value={address.mob2}
                        onChange={(e) => handleInputChange(e, address?.id, 'mob2')}
                        readOnly={editableAddressId !== address.id}
                        editable={editableAddressId === address?.id ? true : false}
                      />
                    </div>
                  </FlexD>

                  <FlexD>
                    <div>
                      <label>Gst</label>
                      <NameA
                        name='gst'
                        value={address.gst}
                        onChange={(e) => handleInputChange(e, address?.id, 'gst')}
                        readOnly={editableAddressId !== address.id}
                        editable={editableAddressId === address?.id ? true : false}
                      />
                    </div>
                  </FlexD>

                  <div>
                    {editableAddressId === address?.id && <SwitchDiv>
                      <div>default address</div>
                      <Switch
                        checked={address.default === 1}
                        onChange={(checked) => handleToggle(address.id, checked)}
                        height={20}
                        width={45}
                        handleDiameter={20}
                        offColor="#ff0000"
                        onColor="#4669E8"
                        offHandleColor="#ffffff"
                        onHandleColor="#ffffff"
                      />
                    </SwitchDiv>}
                  </div>

                </AddressCard>
              );
            })}
          </Manage>
        </div>
      </div>
    </LeftInner>
  );
};

export default ProfilePage;


const SwitchDiv = styled.div`
margin-top: 0.5rem;;
display: flex;
gap:1rem;
align-items: center;
`


const FlexBet = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
  @media (max-width: 420px) {
    flex-direction: column-reverse;
      align-items: flex-start;
  }
`;

const DeleteIcon = styled.div`
`;