import { More } from '@mui/icons-material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'



const AuctionClosed = ({ actualImg, price, title }) => {

  // console.log("actualImg", actualImg[0])
  // console.log("rateg", price)
  // console.log(" titile", title)


  return (
    <Main>
      <SubDiv>
        <ImgContainer>
          <img src={actualImg[0]} alt='bid-product' />
        </ImgContainer>
        <Price>MRP ₹{price}</Price>
        <Title>{title}</Title>
      </SubDiv>
      <OverlayText>Bid Closed</OverlayText>
    </Main>
  )
}

export default AuctionClosed;

const OverlayText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #4669E8;
  font-weight: 700;
  pointer-events: none;

  @media only screen and (min-width: 250px) and (max-width: 767px) {font-size: 4.5rem;}
  @media only screen and (min-width: 768px) and (max-width: 1023px) {font-size: 3rem;}
  @media only screen and (min-width: 1024px)  {font-size: 5rem;}
`;



// main
const Main = styled.div`
/* padding-top: 5rem; */
/* height: calc(100vh - 5rem); */
display:flex;
justify-content: center;
align-items: center;
`

const SubDiv = styled.div`
border:1px solid #00000033;     
padding:0.7rem;
background-color:#F5F5F5;
display:flex;
flex-direction: column;
justify-content:center;
align-items:center;
border-radius:10px;

pointer-events: none;
position: relative;
filter: grayscale(100%);

width:fit-content;
heigth:fit-content;

margin:10px;
padding:10px;

@media only screen and (min-width: 250px) and (max-width: 399px) {
  padding:0.5rem;
  margin:0 2rem;
}


`

const Price = styled.div`
margin-top: 1.5rem;
color:#4669E8;
font-weight:500;
font-size:1.563rem;
@media only screen and (min-width: 250px) and (max-width: 399px) {
  font-size:1rem;
  margin-top: 0.5rem;
}
`
const Title = styled.div`
font-size:0.9rem;
@media only screen and (min-width: 250px) and (max-width: 399px) {
  font-size:0.8rem;
}
`





const ImgContainer = styled.div`
display:flex;
justify-content:center;
align-items:center;
border:1px solid #00000033;

img{
  width: 300px;
  height: 350px;
  object-fit: contain;
  @media only screen and (min-width: 250px) and (max-width: 350px) {
  width: 200px;
  height: 250px;
}
}








`