import styled from 'styled-components';
import { fonts } from '../../../consts/themes';
import { colors } from '../../../consts/themes';

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-block: 3rem;
`;

export const Container = styled.div`
  padding-inline: 1rem;
  max-width: 1680px;
  margin: auto;
  * {
    box-sizing: border-box;
  }
`;
export const H4 = styled.p`
  color: ${colors.black};
  font-size: 22px;
  font-weight: ${fonts.fontWeight5};
`;

export const Div50 = styled.div`
  width: 50%;
  @media (max-width: 640px) {
    width: 100%;
  }
`;
export const FlexLoading = styled.div`
  display: flex;
  svg {
    height: 40px !important;
    width: 40px !important;
    margin-bottom: -9px;
  }
`;

export const FlexD = styled.div`
  display: flex;
  gap: 18px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const H3 = styled.p`
  font-size: 24px;
  @media (max-width: 420px) {
    margin-block: 0.2rem;
  }
`;

export const Flex2 = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 1rem;
  flex-direction: column;
  gap: 0;
  align-items: baseline;
`;
export const Edit = styled.p`
  cursor: pointer;
  color: ${colors.blue};
  text-decoration: underline;
  font-weight: ${fonts.fontWeight5};
  display: flex;
  align-items: center;
  margin: 0;
  ${'' /* pointer-events: ${(props) => (props.clickable ? 'unset' : 'none')}; */}
`;
export const Input = styled.input`
  height: 3.5rem;
  border: 1px solid ${colors.medium_gray};
  padding: 0.8rem;
  font-size: 1rem;
  outline: none;
  border-radius: 6px;
  width: 100%;
  ${'' /* color: ${(props) => !props.editable && colors.light_grey}; */}
  ${'' /* pointer-events: ${(props) => !props.editable && 'none'}; */}
  ${'' /* -moz-appearance: textfield;
  ::placeholder {
    color: ${(props) => !props.editable && colors.light_grey};
  }
  ::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  } */}

color:${(props) => props.editable ? '' : colors.light_grey};
border:${(props) => props.editable ? `1px solid ${colors.black}` : `1px solid ${colors.medium_gray}`};
pointer-events: ${(props) => props.editable ? 'auto' : 'none'};



`;

export const Select = styled.select`
  height: 3.5rem;
  border: 1px solid ${colors.medium_gray};
  padding: 0.8rem;
  font-size: 1rem;
  outline: none;
  border-radius: 6px;
  width: 100%;
  ${'' /* color: ${(props) => !props.editable && colors.light_grey};
  pointer-events: ${(props) => !props.editable && 'none'}; */}

color:${(props) => props.editable ? '' : colors.light_grey};
border:${(props) => props.editable ? `1px solid ${colors.black}` : `1px solid ${colors.medium_gray}`};
pointer-events: ${(props) => props.editable ? 'auto' : 'none'};



  -moz-appearance: textfield;
  ::placeholder {
    color: ${(props) => !props.editable && colors.light_grey};
  }
  ::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const TextArea = styled.textarea`
  height: 6rem;
  border: 1px solid ${colors.medium_gray};
  padding: 0.8rem;
  outline: none;
  border-radius: 6px;
  width: 100%;
  font-size: 1rem;
  resize: none;
  color: ${(props) => !props.editable && colors.light_grey};
  pointer-events: ${(props) => !props.editable && 'none'};
  margin-bottom: 1rem;
  ::placeholder {
    color: ${(props) => !props.editable && colors.light_grey};
  }
  @media (max-width: 640px) {
    margin-bottom: 0rem;
  }
`;
export const Label = styled.label`
  color: ${colors.black};
  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: block;
`;

export const FlexInput = styled.div`
  display: flex;
  gap: 3rem;
  margin-bottom: 1rem;
  @media (max-width: 640px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const FlexRadio = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  margin-top: 1rem;
`;
export const FlexR = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  pointer-events: ${(props) => !props.editable && 'none'};
  Label {
    margin-bottom: 0;
  }
`;
export const Radio = styled.input`
  margin: 0;
  filter: ${(props) => !props.editable && 'grayscale(100%)'};
`;

export const Flex3 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 2rem;
  align-items: center;
  @media (max-width: 460px) {
    flex-direction: column;
    align-items: baseline;
  }
`;

export const H5 = styled.p`
  font-size: 24px;
  margin: 0;
`;
export const Manage = styled.div`
  max-width: 700px;
`;
export const AddressCard = styled.div`
  border: 1px solid ${colors.medium_gray};
  padding: 0.8rem;
  border-radius: 6px;
  margin-bottom: 1rem;
`;
export const AddressType = styled.span`
  padding: 0.2rem;
  background-color: ${colors.medium_gray};
  color: ${colors.ash};
  font-size: 12px;
  border-radius: 5px;
  text-transform: capitalize;
  display: flex;
  min-width: 80px;
  justify-content: center;
  width: fit-content;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  @media (max-width: 420px) {
    flex-direction: column-reverse;
    gap: 1rem;
  }
`;


export const Flex4 = styled.div`
  display: flex;
  gap:0.5rem;
  margin-top: 2rem;
  @media (max-width: 420px) {
    margin-top: 1rem;
  }
  > :first-child {
    height: 28px;
    width: 24px;
  }
  svg {
    color: ${colors.blue};
    height: 28px;
    width: 28px;
    stroke-width: 1;
    cursor: pointer;
  }
  path {
    stroke-width: 1;
  }
`;

const border = `1px solid ${colors.medium_gray}`;
// const borderbl = `1px solid ${colors.black}`;
const borderbl = `1px solid red`;

export const NameA = styled.input`
  font-size: 16px;
  display: block;
  /* margin-top: 0.3rem; */
  font-weight: ${fonts.fontWeight5};
  text-transform: capitalize;
  margin-bottom: 0.2rem;
  padding: 0.4rem 0.4rem;
  ${'' /* border-radius: 6px; */}

  ${'' /* border: ${(props) => (!props.editable ? border : borderbl)};
  pointer-events: ${(props) => !props.editable && 'none'};
  color: ${(props) => !props.editable && colors.light_grey}; */}


color:${(props) => props.editable ? '' : colors.light_grey};
border:${(props) => props.editable ? `1px solid ${colors.black}` : `1px solid ${colors.medium_gray}`};
pointer-events: ${(props) => props.editable ? 'auto' : 'none'};




  outline: none;
  @media (max-width: 420px) {
    width: 100%;
  }
`;
export const Name = styled.span`
  font-size: 16px;
  display: block;
  margin-top: 0.8rem;
  font-weight: ${fonts.fontWeight5};
  text-transform: capitalize;
  margin-bottom: 0.2rem;
`;

export const AddressArea = styled.textarea`
  height: 6rem;
  border: ${(props) => (!props.editable ? 'none' : border)};
  padding: 0.8rem;
  outline: none;
  border-radius: 6px;
  width: 60%;
  font-size: 1rem;
  resize: none;
  color: ${(props) => (!props.editable ? colors.ash : colors.black)};
  pointer-events: ${(props) => !props.editable && 'none'};
  margin-bottom: 1rem;
  ::placeholder {
    color: ${(props) => !props.editable && colors.light_grey};
  }
  @media (max-width: 620px) {
    width: 100%;
  }
`;
export const Div100 = styled.div`
  width: 100%;
`;
export const LeftInner = styled.div`
  /* width: 70%; */
  ${'' /* margin-top: 5%; */}
  /* margin:0;
  padding:0; */
  ${'' /* background-color:orange; */}
  /* @media (max-width: 1200px) {
    width: 100%;
  } */
`;

export const Optional = styled.span`
  font-style: italic;
  font-size: 12px;
  color: #999;
`;

export const Err = styled.div`
  margin-top: 10px;
  font-size: 10px;
  color: red;
`;
